import {
  Box,
  Button,
  Container,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./CheckoutRentalAgreement.css";
import { useDispatch, useSelector } from "react-redux";
import {
  docuSign,
  getdocuSign,
  resetBrand,
} from "../StateSlices/docusignSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import { toast } from "react-toastify";
import docusignImageSample from "../../assets/docusign-sample.png";

const pdfUrl =
  "https://res.cloudinary.com/closet-closest/image/upload/v1742063013/DocuSign%20Agreements/dizel-rental-agreement-preview_ofu6ao.pdf";

const CheckoutRentalAgreement = ({
  rrent,
  setActiveStep,
  docusignDone,
  setDocusignDone,
  docuSignAgreement,
  setDocuSignAgreement,
  saveDocuSignAgreement,
  setSaveDocuSignAgreement,
}) => {
  const dispatch = useDispatch();
  const { dsInfo, dsError, dsFileInfo } = useSelector(
    (state) => state.docusign
  );
  const { userInfo, error, profile } = useSelector((state) => state.user);
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState(null);

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const openDocusign = () => {
    if (userInfo) {
      setSpinner(true);
      toast.info("Opening Docusign Interface...", toastOption);
      const token = localStorage.getItem("inktoken");
      const values = {
        signerEmail: userInfo.userDetailsId.userEmail,
        signerName: userInfo.userDetailsId.userFName,
      };
      dispatch(docuSign({ token, values }));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("inktoken");
    if (userInfo && userInfo.userDetailsId.docusignAgreement) {
      setSpinner(true);
      dispatch(
        getdocuSign({ token, id: userInfo.userDetailsId.docusignAgreement })
      );
    }
  }, []);

  useEffect(() => {
    if (dsFileInfo) {
      setData(dsFileInfo);
      setDocuSignAgreement(userInfo.userDetailsId.docusignAgreement);
      dispatch(resetBrand());
      // setActiveStep(2);
      setDocusignDone(true);
      toast.success("DocuSign Document Signed Successfully!", toastOption);
      setSpinner(false);
    }
  }, [dsFileInfo]);

  useEffect(() => {
    if (dsError) {
      console.log("ERROR");
      setSpinner(false);
      dispatch(resetBrand());
    }
  }, [dsError]);

  useEffect(() => {
    if (dsInfo) {
      setSpinner(false);
      window.location.href = dsInfo;
      // const width = window.innerWidth * 0.75;
      // const height = window.innerHeight * 0.75;
      // const left = (window.innerWidth - width) / 2;
      // const top = (window.innerHeight - height) / 2;

      // const popupWindow = window.open(
      //   dsInfo, // Replace with your actual hyperlink
      //   "_blank",
      //   `width=${width}, height=${height}, left=${left}, top=${top}`
      // );

      // window.addEventListener("beforeunload", () => {
      //   if (popupWindow && !popupWindow.closed) {
      //     popupWindow.close();
      //   }
      // });

      // // Set scroll position after 200px scrollY
      // setTimeout(() => {
      //   if (popupWindow && !popupWindow.closed) {
      //     popupWindow.scrollTo(0, 200);
      //   }
      // }, 100); // Adjust the delay as needed
    }
  }, [dsInfo]);

  return (
    <Container
      maxWidth={"lg"}
      sx={{ margin: "2rem auto", paddingLeft: 0, paddingRight: 0 }}
      data-aos="fade-in"
      data-aos-duration="1000"
    >
      {spinner ? (
        <Spinner pos={"static"} />
      ) : (
        <>
          {rrent && rrent.length > 0 ? (
            <Box
              component={"section"}
              padding={"1rem"}
              margin={".5rem"}
              sx={{
                // background: "#f3a9502b",
                borderRadius: "10px",
              }}
            >
              {userInfo &&
              userInfo.userDetailsId.docusignAgreement &&
              docusignDone ? (
                <>
                  <Box component={"section"} className="upload-rental-ins-con">
                    <Box marginBottom={".5rem"}>
                      <Typography
                        variant="h3"
                        margin={"0 0 1rem 0"}
                        textAlign={"center"}
                        fontWeight={700}
                      >
                        Rental Agreement Preview
                      </Typography>
                      {docusignDone ? (
                        <>
                          <Typography
                            color={"green"}
                            variant="body2"
                            textAlign={"center"}
                            // marginBottom={"1rem"}
                          >
                            Document Signed Successfully!
                            <br />
                            You'll receive an email once checkout completes.
                          </Typography>
                          <Box
                            maxWidth={"75%"}
                            width={"auto"}
                            borderRadius={"10px"}
                            // maxHeight={"70%"}
                            textAlign={"center"}
                            // margin={"auto"}
                            margin={"1rem auto"}
                            // marginBottom={"2.5rem"}
                          >
                            {/* <DocViewer
                                config={{
                                  header: {
                                    disableHeader: false,
                                    disableFileName: false,
                                    retainURLParams: false,
                                  },
                                }}
                                style={{ height: "94%", borderRadius: "10px" }}
                                pluginRenderers={DocViewerRenderers}
                                documents={[
                                  {
                                    uri: pdfUrl,
                                  },
                                ]}
                              /> */}
                            <img
                              style={{
                                width: "100%",
                                height: "800px",
                                borderRadius: "10px",
                              }}
                              src={docusignImageSample}
                            />
                          </Box>
                        </>
                      ) : (
                        ""
                      )}
                    </Box>

                    <Box textAlign={"center"}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            name="saveDocument"
                            value="yes"
                            defaultChecked={
                              userInfo.userDetailsId.saveDocuSignInsurance !==
                              undefined
                                ? userInfo.userDetailsId.saveDocuSignInsurance
                                : true
                            }
                            onChange={() =>
                              setSaveDocuSignAgreement((prev) => !prev)
                            }
                          />
                        }
                        label="Save agreement for easy checkout next time"
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <div className="checkout-docusign-con">
                  <div
                    className="checkout-file-upload-con"
                    style={{
                      margin: "1rem auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h3"
                      textAlign={"center"}
                      margin={"1rem 0 1rem 0"}
                      fontWeight={700}
                    >
                      Dizel DocuSign Agreement
                    </Typography>
                    {!docusignDone ? (
                      <Box sx={{ textAlign: "center" }}>
                        <Button
                          onClick={openDocusign}
                          // disabled={Files.length > 0 ? true : false}
                          variant="contained"
                          centerRipple
                          sx={{
                            background: "var(--secondary-color)",
                            mt: 1,
                            padding: "8px 45px",
                            borderRadius: "30px",
                            fontSize: "1.1rem",
                          }}
                        >
                          Sign
                        </Button>
                      </Box>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    if (!docusignDone) {
                      toast.error("Please Sign Document First", toastOption);
                      return;
                    }
                    window.scrollTo(0, 0);
                    setActiveStep(2);
                  }}
                  // disabled={activeStep === 2}
                  sx={{
                    mr: 1,
                    background: "var(--secondary-color)",
                    "&: hover": {
                      background: "var(--grey-color)",
                      color: "var(--black-color)",
                    },
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography variant="h3" fontWeight={700}>
              Coming Soon!
            </Typography>
          )}
        </>
      )}
    </Container>
  );
};

export default CheckoutRentalAgreement;
