import React, { useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Container,
  Divider,
  Fab,
  Stack,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { set } from "rsuite/esm/utils/dateUtils";
import { brands } from "../AddNewItem/data";
import Spinner from "../../components/Spinner/Spinner";
import Confetti from "react-confetti";

const ProductPostedConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [product, setProduct] = React.useState(null);
  const [confetti, setConfetti] = React.useState(false);

  useEffect(() => {
    debugger
    if (location && location.state) {
    
      setProduct(location.state?.product);
    } else {
      //   navigate('/shop')
    }
  }, [location]);

  useEffect(() => { 
    console.log("Product", product)
    if (location.state.showConfetti) {
        setConfetti(true);
        const timer = setTimeout(() => {
          setConfetti(false);
        }, 5000);
        return () => clearTimeout(timer);
      }
    // if (!product) {
    //   navigate("/shop");
    // }
  } , [product])

  return (
    <>
      {confetti && (
        <Confetti
          wind={0.01}
          gravity={0.15}
          numberOfPieces={400}
          width={window.innerWidth - 30}
          height={2 * window.innerHeight}
          colors={["#161b21", "#f05023", "#f3a950", "#f1f1f1"]}
          recycle={confetti}
        />
      )}
      {product ? (
        <Container maxWidth="xl" data-aos="fade-in">
          <Typography
            variant="h2"
            align="center"
            sx={{ marginTop: 4, marginBottom: 4, fontWeight: 700 }}
          >
            Equipment Added Successfully!
          </Typography>
          <Divider />
          <Grid
            container
            maxWidth={"xl"}
            spacing={4}
            justifyContent="center"
            sx={{ padding: 4 }}
          >
            {/* Product Image Section */}
            <Grid item xs={12} sm={6} md={6} lg={6} textAlign="center">
              <Card elevation={0}>
                <CardContent>
                  <Avatar
                    alt={product.itemName}
                    src={product?.itemImages[0]}
                    variant="rounded"
                    sx={{
                      width: "100%",
                      height: "auto",
                      maxWidth: 500,
                      margin: "0 auto",
                      borderRadius: "10px",
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Product Details Section */}
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Card elevation={0}>
                <CardContent>
                  <Typography variant="h3" gutterBottom fontWeight={700}>
                    {product.itemName}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    {product.itemDescription}
                  </Typography>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyItems={"flex-start"}
                    gap={1}
                  >
                    {product.itemForRent && (
                      <Chip
                        sx={{
                          padding: "1rem 1.25rem",
                          color: "var(--white-color)",
                          background: "var(--black-color)",
                          cursor: "pointer",
                          transition: ".3s ease",
                          borderRadius: "30px",
                          margin: "0",
                          cursor: "pointer",
                          // minWidth: "320px",
                          marginBottom: 2,
                          "&:hover": {
                            background: "var(--dark-grey-color)",
                            transform: "scale(1.05)",
                            transition: ".3s ease",
                          },
                        }}
                        label={"Item For Rent"}
                      />
                    )}
                    {product.itemToSell && (
                      <Chip
                        sx={{
                          padding: "1rem 1.25rem",
                          color: "var(--white-color)",
                          background: "var(--black-color)",
                          cursor: "pointer",
                          transition: ".3s ease",
                          borderRadius: "30px",
                          cursor: "pointer",
                          // minWidth: "320px",
                          marginBottom: 2,
                          "&:hover": {
                            background: "var(--dark-grey-color)",
                            transform: "scale(1.05)",
                            transition: ".3s ease",
                          },
                        }}
                        label={"Item To Sell"}
                      />
                    )}
                  </Stack>

                  {product.itemToSell && (
                    <Typography
                      variant="h6"
                      color="var(--black-color)"
                      sx={{ marginBottom: 1 }}
                    >
                      <strong>Item Amount:</strong> {`$${product.itemAmount}`}
                    </Typography>
                  )}
                  {product.itemForRent && (
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: 1, color: "var(--black-color)" }}
                    >
                      <strong>Rental Price:</strong>
                      {product.itemForRent &&
                        "$" + product.itemDailyPrice &&
                        product.itemDailyPrice > 0 &&
                        "$" +
                          product.itemDailyPrice.toLocaleString("en-us") +
                          " Daily"}
                      {product.itemForRent &&
                        product.itemWeeklyPrice &&
                        product.itemWeeklyPrice > 0 &&
                        ", $" +
                          product.itemWeeklyPrice.toLocaleString("en-us") +
                          " Weekly"}
                      {product.itemMonthlyPrice &&
                        product.itemMonthlyPrice > 0 &&
                        ", $" +
                          product.itemMonthlyPrice.toLocaleString("en-us") +
                          " Monthly"}
                    </Typography>
                  )}

                  <Typography
                    variant="h6"
                    color="var(--black-color)"
                    sx={{ marginBottom: 1 }}
                  >
                    <strong>Brand: </strong>
                    {
                      brands.find((brand) => brand._id === product.itemBrand)
                        ?.label
                    }
                  </Typography>
                  <Typography
                    variant="h6"
                    color="var(--black-color)"
                    sx={{ marginBottom: 1 }}
                  >
                    <strong>Year of Manufacture: </strong>
                    {product.itemYearManufacture}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="var(--black-color)"
                    sx={{ marginBottom: 1 }}
                  >
                    <strong>Category: </strong> {product.itemGoal},{" "}
                    {product.itemCategoryName}, {product.itemSubCategoryName}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="var(--black-color)"
                    sx={{ marginBottom: 1 }}
                  >
                    <strong>Location: </strong>
                    {product.itemLocation}
                  </Typography>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => navigate("/product/" + product._id)}
                    sx={{
                      borderRadius: "10px",
                      background: "var(--black-color)",
                      color: "var(--white-color)",
                      marginTop: 2,
                      "&: hover": { background: "var(--dark-grey-color)" },
                    }}
                  >
                    View Full Product Details
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/user/equipment")}
                    sx={{
                      borderRadius: "10px",
                      background: "var(--black-color)",
                      color: "var(--white-color)",
                      marginTop: 2,
                      "&: hover": { background: "var(--dark-grey-color)" },
                    }}
                  >
                    View My Equipments
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default ProductPostedConfirmation;
