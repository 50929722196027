import cooling from "../../assets/category-cards/1.png";
import highReach from "../../assets/category-cards/2.png";
import generator from "../../assets/category-cards/3.png";
import skidSteers from "../../assets/category-cards/4.png";
import tractors from "../../assets/category-cards/5.png";
import excavator from "../../assets/category-cards/6.png";
import dozers from "../../assets/category-cards/7.png";
import dumptrucks from "../../assets/category-cards/8.png";
import forklifts from "../../assets/category-cards/9.png";
import fuelTanks from "../../assets/category-cards/16.png";
import heating from "../../assets/category-cards/15.png";
import trenchers from "../../assets/category-cards/13.png";
import liftingPeople from "../../assets/category-cards/12.png";
import accessories from "../../assets/category-cards/18.png";
import drying from "../../assets/category-cards/17.png";
import purifying from "../../assets/category-cards/10.png";
import loaders from "../../assets/category-cards/11.png";
import dummy from "../../assets/category-cards/dummy.jpg";
import homeDepot from "../../assets/other-party-logos/home-depot.png";
import hercRentals from "../../assets/other-party-logos/herc-rentals.png";
import rentalMax from "../../assets/other-party-logos/rental-max.png";
import sunBeltRentals from "../../assets/other-party-logos/sunbelt-rentals.png";
import unitedRentals from "../../assets/other-party-logos/united-rentals.png";
import bigRentz from "../../assets/other-party-logos/bigrentz.jpg";
import equipmentShare from "../../assets/other-party-logos/equipmentshare.png";
import visa from "../../assets/cards/visa.png";
import mastercard from "../../assets/cards/mastercard.png";
import amex from "../../assets/cards/amex.png";
import discover from "../../assets/cards/discover.png";
import unionpay from "../../assets/cards/unionpay.png";
import jcb from "../../assets/cards/jcb.png";
import maestro from "../../assets/cards/maestro.png";
import dinersclub from "../../assets/cards/dinersclub.png";
import efptos from "../../assets/cards/eftpos.png";
import unknown from "../../assets/cards/unknown.jpg";
// import goal1 from "../../assets/category-cards/goal-1.png";
// import goal2 from "../../assets/category-cards/goal-2.png";
// import goal3 from "../../assets/category-cards/goal-3.png";
// import goal4 from "../../assets/category-cards/goal-4.png";
import goal1 from "../../assets/small-tabs/earth-manipulation.png";
import goal2 from "../../assets/small-tabs/lifting-people-and-material.png";
import goal3 from "../../assets/small-tabs/managing-air.png";
import goal4 from "../../assets/small-tabs/power-solutions.png";

export const creditCards = {
  Visa: visa,
  Mastercard: mastercard,
  "American Express": amex,
  Discover: discover,
  Maestro: maestro,
  UnionPay: unionpay,
  JCB: jcb,
  "Eftpos Australia": efptos,
  "Diners Club": dinersclub,
  Unknown: unknown,
};

export const otherLogos = {
  comparePowerRents: homeDepot,
  hercRentals: hercRentals,
  rentalMax: rentalMax,
  sunBeltRentals: sunBeltRentals,
  unitedRentals: unitedRentals,
  bigRentz: bigRentz,
  equipmentshare: equipmentShare,
};

export const categoryImagesOld = {
  "65eaba8c9b109ef32be78564": excavator,
  "65eaba8c9b109ef32be7856d": fuelTanks, //fuel tanks
  "65eaba8c9b109ef32be78569": heating, //heating
  "65eaba8c9b109ef32be78565": skidSteers,
  "65eaba8c9b109ef32be78563": dozers,
  "65eaba8c9b109ef32be78566": tractors,
  "65eaba8c9b109ef32be78567": trenchers, //trenchers
  "65eaba8c9b109ef32be78570": liftingPeople, //lifting people
  "65eaba8c9b109ef32be7856c": generator,
  "65eaba8c9b109ef32be78568": cooling,
  "65eaba8c9b109ef32be7856e": accessories, //accessories
  "65eaba8c9b109ef32be7856a": drying, //drying
  "65eaba8c9b109ef32be7856b": purifying, //purifying
  "65eaba8c9b109ef32be7856f": forklifts, //lifting material
  "65f33fd67557aba7f912ee92": loaders, //loaders
};

export const tempCategoryImagesOld = [
  excavator,
  fuelTanks,
  heating,
  skidSteers,
  dozers,
  tractors,
  trenchers,
  liftingPeople,
  generator,
  cooling,
  accessories,
  drying,
  purifying,
  forklifts,
  loaders,
];

export const categoryNameImageMapping = {
  "Earth Manipulation": goal1,
  "Lifting People and Material": goal2,
  "Managing Air": goal3,
  "Power Solutions": goal4,
};

export const categoryTitleOld = {
  "65eaba8c9b109ef32be78564": "excavator",
  "65eaba8c9b109ef32be7856d": "fuel tanks",
  "65eaba8c9b109ef32be78569": "heating",
  "65eaba8c9b109ef32be78565": "skid steers",
  "65eaba8c9b109ef32be78563": "dozers",
  "65eaba8c9b109ef32be78566": "tractors",
  "65eaba8c9b109ef32be78567": "trenchers",
  "65eaba8c9b109ef32be78570": "lifting people",
  "65eaba8c9b109ef32be7856c": "generator",
  "65eaba8c9b109ef32be78568": "cooling",
  "65eaba8c9b109ef32be7856e": "accessories",
  "65eaba8c9b109ef32be7856a": "drying",
  "65eaba8c9b109ef32be7856b": "purifying",
  "65eaba8c9b109ef32be7856f": "lifting material",
  "65f33fd67557aba7f912ee92": "loaders",
};

export const brands = [
  {
    _id: "633eb7bbde5850c79a25d001",
    label: "Caterpillar",
    value: "caterpillar",
  },
  { _id: "633eb7bbde5850c79a25d002", label: "Komatsu", value: "komatsu" },
  { _id: "633eb7bbde5850c79a25d003", label: "Volvo", value: "volvo" },
  { _id: "633eb7bbde5850c79a25d004", label: "John Deere", value: "john_deere" },
  { _id: "633eb7bbde5850c79a25d005", label: "Hitachi", value: "hitachi" },
  { _id: "633eb7bbde5850c79a25d006", label: "Liebherr", value: "liebherr" },
  { _id: "633eb7bbde5850c79a25d007", label: "Bobcat", value: "bobcat" },
  { _id: "633eb7bbde5850c79a25d008", label: "Kubota", value: "kubota" },
  { _id: "633eb7bbde5850c79a25d009", label: "Doosan", value: "doosan" },
  { _id: "633eb7bbde5850c79a25d010", label: "JCB", value: "jcb" },
  { _id: "633eb7bbde5850c79a25d011", label: "Case", value: "case" },
  {
    _id: "633eb7bbde5850c79a25d012",
    label: "New Holland",
    value: "new_holland",
  },
  { _id: "633eb7bbde5850c79a25d013", label: "Terex", value: "terex" },
  { _id: "633eb7bbde5850c79a25d014", label: "Hyundai", value: "hyundai" },
  { _id: "633eb7bbde5850c79a25d015", label: "Sany", value: "sany" },
  { _id: "633eb7bbde5850c79a25d016", label: "XCMG", value: "xcmg" },
  { _id: "633eb7bbde5850c79a25d017", label: "Zoomlion", value: "zoomlion" },
  {
    _id: "633eb7bbde5850c79a25d018",
    label: "Wacker Neuson",
    value: "wacker_neuson",
  },
  {
    _id: "633eb7bbde5850c79a25d019",
    label: "Atlas Copco",
    value: "atlas_copco",
  },
  { _id: "633eb7bbde5850c79a25d020", label: "BOMAG", value: "bomag" },
  { _id: "633eb7bbde5850c79a25d021", label: "Manitou", value: "manitou" },
  { _id: "633eb7bbde5850c79a25d022", label: "Takeuchi", value: "takeuchi" },
  { _id: "633eb7bbde5850c79a25d023", label: "Vermeer", value: "vermeer" },
  {
    _id: "633eb7bbde5850c79a25d024",
    label: "Ingersoll Rand",
    value: "ingersoll_rand",
  },
  { _id: "633eb7bbde5850c79a25d025", label: "Dynapac", value: "dynapac" },
  { _id: "633eb7bbde5850c79a25d026", label: "Yanmar", value: "yanmar" },
  { _id: "633eb7bbde5850c79a25d027", label: "Hidromek", value: "hidromek" },
  { _id: "633eb7bbde5850c79a25d028", label: "Volvo CE", value: "volvo_ce" },
  { _id: "633eb7bbde5850c79a25d029", label: "Kobelco", value: "kobelco" },
  { _id: "633eb7bbde5850c79a25d030", label: "Sullair", value: "sullair" },
  {
    _id: "633eb7bbde5850c79a25d031",
    label: "Ditch Witch",
    value: "ditch_witch",
  },
  // { _id: "633eb7bbde5850c79a25d032", label: "John Deere", value: "john_deere" },
  { _id: "65ceab790a582b9f43f4a86b", label: "Kubola", value: "kubola" },
  { _id: "65ceab790a582b9f43f4a86c", label: "Gehl", value: "gehl" },
  { _id: "65ceab790a582b9f43f4a86d", label: "CAPS", value: "caps" },
  {
    _id: "65ceab790a582b9f43f4a86e",
    label: "Oceanaire",
    value: "oceanaire",
  },
  {
    _id: "65ceab790a582b9f43f4a86f",
    label: "Sunbelt",
    value: "sunbelt",
  },
  { _id: "65ceab790a582b9f43f4a870", label: "Airrex", value: "airrex" },
  {
    _id: "65ceab790a582b9f43f4a871",
    label: "Cyclone",
    value: "cyclone",
  },
  {
    _id: "65ceab790a582b9f43f4a872",
    label: "United CoolAir",
    value: "united_coolair",
  },
  { _id: "65ceab790a582b9f43f4a873", label: "AES", value: "aes" },
  { _id: "65ceab790a582b9f43f4a874", label: "MEC", value: "mec" },
  {
    _id: "65ceab790a582b9f43f4a875",
    label: "SKYJACK",
    value: "skyjack",
  },
  { _id: "65ceab790a582b9f43f4a876", label: "JLG", value: "jlg" },
  { _id: "65ceab790a582b9f43f4a877", label: "Genie", value: "genie" },
  { _id: "65ceab790a582b9f43f4a878", label: "HONDA", value: "honda" },
  {
    _id: "65ceab790a582b9f43f4a879",
    label: "GENERAC",
    value: "generac",
  },
  {
    _id: "65ceab790a582b9f43f4a87a",
    label: "MQ POWER",
    value: "mq_power",
  },
  {
    _id: "65ceab790a582b9f43f4a87b",
    label: "Makinex",
    value: "makinex",
  },
  {
    _id: "65ceab790a582b9f43f4a87c",
    label: "Mitsubishi",
    value: "mitsubishi",
  },
  { _id: "65ceab790a582b9f43f4a87d", label: "Toyota", value: "toyota" },
  {
    _id: "65ceab790a582b9f43f4a87e",
    label: "KOMATSU",
    value: "komatsu",
  },
  {
    _id: "65ceab790a582b9f43f4a87f",
    label: "Skytrak",
    value: "skytrak",
  },
  { _id: "65ceab790a582b9f43f4a880", label: "Other", value: "other" },
];

export const conditions = [
  { label: "New", value: "new" },
  { label: "Used", value: "used" },
  { label: "Refurbished", value: "refurbished" },
];

export const categoies = [
  {
    _id: "65eaba8c9b109ef32be78564",
    label: "Excavator",
    value: "excavator",
  },
  {
    _id: "65eaba8c9b109ef32be7856d",
    label: "Fuel Tanks",
    value: "fuel-tanks",
  },
  {
    _id: "65eaba8c9b109ef32be78569",
    label: "Heating",
    value: "heating",
  },
  {
    _id: "65eaba8c9b109ef32be78565",
    label: "Skid Steers",
    value: "skid-steers",
  },
  { _id: "65eaba8c9b109ef32be78563", label: "Dozers", value: "dozers" },
  {
    _id: "65eaba8c9b109ef32be78566",
    label: "Tractors",
    value: "tractors",
  },
  {
    _id: "65eaba8c9b109ef32be78567",
    label: "Trenchers",
    value: "trenchers",
  },
  {
    _id: "65eaba8c9b109ef32be78570",
    label: "Lifting People",
    value: "lifting-people",
  },
  {
    _id: "65eaba8c9b109ef32be7856c",
    label: "Generator",
    value: "generator",
  },
  {
    _id: "65eaba8c9b109ef32be78568",
    label: "Cooling",
    value: "cooling",
  },
  {
    _id: "65eaba8c9b109ef32be7856e",
    label: "Accessories",
    value: "accessories",
  },
  { _id: "65eaba8c9b109ef32be7856a", label: "Drying", value: "drying" },
  {
    _id: "65eaba8c9b109ef32be7856b",
    label: "Purifying",
    value: "purifying",
  },
  {
    _id: "65eaba8c9b109ef32be7856f",
    label: "Lifting Material",
    value: "lifting-material",
  },
  {
    _id: "65f33fd67557aba7f912ee92",
    label: "Loaders",
    value: "loaders",
  },
];

export const subCategories = [
  { _id: "633eb7bbde5850c79a25d001", label: "Excavators", value: "excavators" },
  { _id: "633eb7bbde5850c79a25d002", label: "Bulldozers", value: "bulldozers" },
  { _id: "633eb7bbde5850c79a25d003", label: "Loaders", value: "loaders" },
  {
    _id: "633eb7bbde5850c79a25d004",
    label: "Wheel Loaders",
    value: "wheel_loaders",
  },
  {
    _id: "633eb7bbde5850c79a25d005",
    label: "Skid Steer Loaders",
    value: "skid_steer_loaders",
  },
  { _id: "633eb7bbde5850c79a25d006", label: "Graders", value: "graders" },
  { _id: "633eb7bbde5850c79a25d007", label: "Backhoes", value: "backhoes" },
  { _id: "633eb7bbde5850c79a25d008", label: "Forklifts", value: "forklifts" },
  {
    _id: "633eb7bbde5850c79a25d009",
    label: "Telehandlers",
    value: "telehandlers",
  },
  { _id: "633eb7bbde5850c79a25d010", label: "Cranes", value: "cranes" },
  {
    _id: "633eb7bbde5850c79a25d011",
    label: "Mobile Cranes",
    value: "mobile_cranes",
  },
  {
    _id: "633eb7bbde5850c79a25d012",
    label: "Tower Cranes",
    value: "tower_cranes",
  },
  {
    _id: "633eb7bbde5850c79a25d013",
    label: "Conveyor Systems",
    value: "conveyor_systems",
  },
  {
    _id: "633eb7bbde5850c79a25d014",
    label: "Concrete Mixers",
    value: "concrete_mixers",
  },
  {
    _id: "633eb7bbde5850c79a25d015",
    label: "Concrete Pumps",
    value: "concrete_pumps",
  },
  {
    _id: "633eb7bbde5850c79a25d016",
    label: "Concrete Vibrators",
    value: "concrete_vibrators",
  },
  {
    _id: "633eb7bbde5850c79a25d017",
    label: "Concrete Cutters",
    value: "concrete_cutters",
  },
  {
    _id: "633eb7bbde5850c79a25d018",
    label: "Asphalt Pavers",
    value: "asphalt_pavers",
  },
  {
    _id: "633eb7bbde5850c79a25d019",
    label: "Road Rollers",
    value: "road_rollers",
  },
  { _id: "633eb7bbde5850c79a25d020", label: "Compactors", value: "compactors" },
  {
    _id: "633eb7bbde5850c79a25d021",
    label: "Road Grinders",
    value: "road_grinders",
  },
  {
    _id: "633eb7bbde5850c79a25d022",
    label: "Drilling Rigs",
    value: "drilling_rigs",
  },
  { _id: "633eb7bbde5850c79a25d023", label: "Trenchers", value: "trenchers" },
  { _id: "633eb7bbde5850c79a25d024", label: "Augers", value: "augers" },
  {
    _id: "633eb7bbde5850c79a25d025",
    label: "Hydraulic Breakers",
    value: "hydraulic_breakers",
  },
  {
    _id: "633eb7bbde5850c79a25d026",
    label: "Demolition Hammers",
    value: "demolition_hammers",
  },
  {
    _id: "633eb7bbde5850c79a25d027",
    label: "Concrete Crushers",
    value: "concrete_crushers",
  },
  { _id: "633eb7bbde5850c79a25d028", label: "Generators", value: "generators" },
  {
    _id: "633eb7bbde5850c79a25d029",
    label: "Compressors",
    value: "compressors",
  },
  {
    _id: "633eb7bbde5850c79a25d030",
    label: "Welding Machines",
    value: "welding_machines",
  },
  {
    _id: "633eb7bbde5850c79a25d031",
    label: "Lighting Towers",
    value: "lighting_towers",
  },
  {
    _id: "633eb7bbde5850c79a25d032",
    label: "Total Stations",
    value: "total_stations",
  },
  {
    _id: "633eb7bbde5850c79a25d033",
    label: "Laser Levels",
    value: "laser_levels",
  },
  {
    _id: "633eb7bbde5850c79a25d034",
    label: "GPS Systems",
    value: "gps_systems",
  },
  { _id: "633eb7bbde5850c79a25d035", label: "Buckets", value: "buckets" },
  { _id: "633eb7bbde5850c79a25d036", label: "Blades", value: "blades" },
  {
    _id: "633eb7bbde5850c79a25d037",
    label: "Quick Couplers",
    value: "quick_couplers",
  },
  { _id: "633eb7bbde5850c79a25d038", label: "Rippers", value: "rippers" },
  { _id: "633eb7bbde5850c79a25d039", label: "Hard Hats", value: "hard_hats" },
  {
    _id: "633eb7bbde5850c79a25d040",
    label: "Safety Glasses",
    value: "safety_glasses",
  },
  { _id: "633eb7bbde5850c79a25d041", label: "Gloves", value: "gloves" },
  {
    _id: "633eb7bbde5850c79a25d042",
    label: "High-Visibility Vests",
    value: "high_visibility_vests",
  },
  { _id: "633eb7bbde5850c79a25d043", label: "Drills", value: "drills" },
  { _id: "633eb7bbde5850c79a25d044", label: "Saws", value: "saws" },
  { _id: "633eb7bbde5850c79a25d045", label: "Grinders", value: "grinders" },
  {
    _id: "633eb7bbde5850c79a25d046",
    label: "Impact Wrenches",
    value: "impact_wrenches",
  },
  {
    _id: "633eb7bbde5850c79a25d047",
    label: "Temporary Fencing",
    value: "temporary_fencing",
  },
  {
    _id: "633eb7bbde5850c79a25d048",
    label: "Portable Toilets",
    value: "portable_toilets",
  },
  {
    _id: "633eb7bbde5850c79a25d049",
    label: "Site Trailers",
    value: "site_trailers",
  },
  {
    _id: "633eb7bbde5850c79a25d050",
    label: "Dust Suppressors",
    value: "dust_suppressors",
  },
  {
    _id: "633eb7bbde5850c79a25d051",
    label: "Erosion Control Products",
    value: "erosion_control_products",
  },
  {
    _id: "633eb7bbde5850c79a25d052",
    label: "Lowboy Trailers",
    value: "lowboy_trailers",
  },
  {
    _id: "633eb7bbde5850c79a25d053",
    label: "Mini Excavator",
    value: "mini_excavator",
  },
  {
    _id: "65ceab062e5c11b9f816e4d8",
    label: "5.5-6 Ton Mini Excavator",
    value: "5.5-6_ton_mini_excavator",
  },
  {
    _id: "65ceab062e5c11b9f816e4d9",
    label: "1-Ton Mini Excavator",
    value: "1-ton_mini_excavator",
  },
  {
    _id: "65ceab062e5c11b9f816e4da",
    label: "6 Ton Mini Excavator",
    value: "6_ton_mini_excavator",
  },
  {
    _id: "65ceab062e5c11b9f816e4db",
    label: "4-5 Ton Mini Excavator",
    value: "4-5_ton_mini_excavator",
  },
  {
    _id: "65ceab062e5c11b9f816e4dc",
    label: "3-4 Ton Mini Excavator",
    value: "3-4_ton_mini_excavator",
  },
  {
    _id: "65ceab062e5c11b9f816e4dd",
    label: "2-3 Ton Mini Excavator",
    value: "2-3_ton_mini_excavator",
  },
  {
    _id: "65ceab062e5c11b9f816e4de",
    label: "2-4 Ton Mini Excavator",
    value: "2-4_ton_mini_excavator",
  },
  {
    _id: "65ceab062e5c11b9f816e4df",
    label: "Skid Steer",
    value: "skid_steer",
  },
  {
    _id: "65ceab062e5c11b9f816e4e0",
    label: "4-6 Ton Mini Excavator",
    value: "4-6_ton_mini_excavator",
  },
  {
    _id: "65ceab062e5c11b9f816e4e1",
    label: "5-25 Ton AC Units",
    value: "5-25_ton_ac_units",
  },
  {
    _id: "65ceab062e5c11b9f816e4e2",
    label: "Portable 1-5 Ton AC Units",
    value: "portable_1-5_ton_ac_units",
  },
  {
    _id: "65ceab062e5c11b9f816e4e3",
    label: "10 TON AIR CONDITIONER W/ HEATER 208V 3 PH",
    value: "10_ton_air_conditioner_w/_heater_208v_3_ph",
  },
  {
    _id: "65ceab062e5c11b9f816e4e4",
    label: "56 TON SCROLL CHILLER",
    value: "56_ton_scroll_chiller",
  },
  {
    _id: "65ceab062e5c11b9f816e4e5",
    label: "5 TON PORTABLE AIR CONDITIONER 230V",
    value: "5_ton_portable_air_conditioner_230v",
  },
  {
    _id: "65ceab062e5c11b9f816e4e6",
    label: "12 TON AIR CONDITIONER 480V 3 PH",
    value: "12_ton_air_conditioner_480v_3_ph",
  },
  {
    _id: "65ceab062e5c11b9f816e4e7",
    label: "Air Conditioner and Heater Rental",
    value: "air_conditioner_and_heater_rental",
  },
  {
    _id: "65ceab062e5c11b9f816e4e8",
    label: "Air Conditioners",
    value: "air_conditioners",
  },
  {
    _id: "65ceab062e5c11b9f816e4e9",
    label: "Portable 5-12 Ton AC Units",
    value: "portable_5-12_ton_ac_units",
  },
  {
    _id: "65ceab062e5c11b9f816e4ea",
    label: "Vertical AC Units",
    value: "vertical_ac_units",
  },
  {
    _id: "65ceab062e5c11b9f816e4eb",
    label: "Water Cooled AC Units",
    value: "water_cooled_ac_units",
  },
  {
    _id: "65ceab062e5c11b9f816e4ec",
    label: "Air Handlers",
    value: "air_handlers",
  },
  {
    _id: "65ceab062e5c11b9f816e4ed",
    label: "Fans & Blowers",
    value: "fans_&_blowers",
  },
  {
    _id: "65ceab062e5c11b9f816e4ee",
    label: "Skid Steers & Compact Track Loaders",
    value: "skid_steers_&_compact_track_loaders",
  },
  {
    _id: "65ceab062e5c11b9f816e4ef",
    label: "Steers & Compact Track Loaders",
    value: "steers_&_compact_track_loaders",
  },
  {
    _id: "65ceab062e5c11b9f816e4f0",
    label: "Skid Steers",
    value: "skid_steers",
  },
  {
    _id: "65ceab062e5c11b9f816e4f1",
    label: "SKIDSTEER LOADERS",
    value: "skidsteer_loaders",
  },
  {
    _id: "65ceab062e5c11b9f816e4f2",
    label: "Tool Rental",
    value: "tool_rental",
  },
  {
    _id: "65ceab062e5c11b9f816e4f3",
    label: "TRACTORS & LANDSCAPE LOADERS",
    value: "tractors_&_landscape_loaders",
  },
  {
    _id: "65ceab062e5c11b9f816e4f4",
    label: "Tractors",
    value: "tractors",
  },
  {
    _id: "65ceab062e5c11b9f816e4f5",
    label: "Scissor Lifts",
    value: "scissor_lifts",
  },
  {
    _id: "65ceab062e5c11b9f816e4f6",
    label: "Boom Lifts",
    value: "boom_lifts",
  },
  {
    _id: "65ceab062e5c11b9f816e4f7",
    label: "ELECTRIC SCISSORLIFTS",
    value: "electric_scissorlifts",
  },
  {
    _id: "65ceab062e5c11b9f816e4f8",
    label: "MANLIFT ARTICULATING",
    value: "manlift_articulating",
  },
  {
    _id: "65ceab062e5c11b9f816e4f9",
    label: "Electric Scissor ifts",
    value: "electric_scissor_ifts",
  },
  {
    _id: "65ceab062e5c11b9f816e4fa",
    label: "Articulating Boom ifts",
    value: "articulating_boom_ifts",
  },
  {
    _id: "65ceab062e5c11b9f816e4fb",
    label: "Push Around Single Man Lifts",
    value: "push_around_single_man_lifts",
  },
  {
    _id: "65ceab062e5c11b9f816e4fc",
    label: "Electric Scissor Lifts",
    value: "electric_scissor_lifts",
  },
  {
    _id: "65ceab062e5c11b9f816e4fd",
    label: "Articulating Boom Lifts",
    value: "articulating_boom_lifts",
  },
  {
    _id: "65ceab062e5c11b9f816e4fe",
    label: "GENERATORS - GAS",
    value: "generators_-_gas",
  },
  {
    _id: "65ceab062e5c11b9f816e4ff",
    label: "Towable Generators",
    value: "towable_generators",
  },
  {
    _id: "65ceab062e5c11b9f816e500",
    label: "Portable Generators",
    value: "portable_generators",
  },
  {
    _id: "65ceab062e5c11b9f816e501",
    label: "FORKLIFTS - INDUSTRIAL",
    value: "forklifts_-_industrial",
  },
  {
    _id: "65ceab062e5c11b9f816e502",
    label: "FORKLIFTS - TELEHANDLER",
    value: "forklifts_-_telehandler",
  },
  {
    _id: "65ceab062e5c11b9f816e503",
    label: "FORKLIFTS - STRAIGHT MAST RT",
    value: "forklifts_-_straight_mast_rt",
  },
  {
    _id: "65ceab062e5c11b9f816e504",
    label: "Warehouse Forklifts",
    value: "warehouse_forklifts",
  },
  {
    _id: "65ceab062e5c11b9f816e505",
    label: "Straight Mast Rough Terrain Forklifts",
    value: "straight_mast_rough_terrain_forklifts",
  },
  {
    _id: "65ceab062e5c11b9f816e506",
    label: "Telehandler Forklifts",
    value: "telehandler_forklifts",
  },
  {
    _id: "65ceab339ddad92c5b98999c",
    label: "Electric Forklifts",
    value: "electric_forklifts",
  },
  { _id: "65ceab339ddad92c5b98999d", label: "Dozers", value: "dozers" },
  {
    _id: "65ceab339ddad92c5b98999e",
    label: "EquipmentBulldozers",
    value: "equipmentbulldozers",
  },
  {
    _id: "65ceab339ddad92c5b98999f",
    label: "RENTAL VEHICLES",
    value: "rental_vehicles",
  },
  { _id: "65ceab339ddad92c5b9899a0", label: "Trucks", value: "trucks" },
  {
    _id: "65ceab339ddad92c5b9899a1",
    label: "TRAILERS",
    value: "trailers",
  },
  { _id: "65ceab339ddad92c5b9899a2", label: "Truck", value: "truck" },
];

export const years = [
  { label: "1950", value: "1950" },
  { label: "1951", value: "1951" },
  { label: "1952", value: "1952" },
  { label: "1953", value: "1953" },
  { label: "1954", value: "1954" },
  { label: "1955", value: "1955" },
  { label: "1956", value: "1956" },
  { label: "1957", value: "1957" },
  { label: "1958", value: "1958" },
  { label: "1959", value: "1959" },
  { label: "1960", value: "1960" },
  { label: "1961", value: "1961" },
  { label: "1962", value: "1962" },
  { label: "1963", value: "1963" },
  { label: "1964", value: "1964" },
  { label: "1965", value: "1965" },
  { label: "1966", value: "1966" },
  { label: "1967", value: "1967" },
  { label: "1968", value: "1968" },
  { label: "1969", value: "1969" },
  { label: "1970", value: "1970" },
  { label: "1971", value: "1971" },
  { label: "1972", value: "1972" },
  { label: "1973", value: "1973" },
  { label: "1974", value: "1974" },
  { label: "1975", value: "1975" },
  { label: "1976", value: "1976" },
  { label: "1977", value: "1977" },
  { label: "1978", value: "1978" },
  { label: "1979", value: "1979" },
  { label: "1980", value: "1980" },
  { label: "1981", value: "1981" },
  { label: "1982", value: "1982" },
  { label: "1983", value: "1983" },
  { label: "1984", value: "1984" },
  { label: "1985", value: "1985" },
  { label: "1986", value: "1986" },
  { label: "1987", value: "1987" },
  { label: "1988", value: "1988" },
  { label: "1989", value: "1989" },
  { label: "1990", value: "1990" },
  { label: "1991", value: "1991" },
  { label: "1992", value: "1992" },
  { label: "1993", value: "1993" },
  { label: "1994", value: "1994" },
  { label: "1995", value: "1995" },
  { label: "1996", value: "1996" },
  { label: "1997", value: "1997" },
  { label: "1998", value: "1998" },
  { label: "1999", value: "1999" },
  { label: "2000", value: "2000" },
  { label: "2001", value: "2001" },
  { label: "2002", value: "2002" },
  { label: "2003", value: "2003" },
  { label: "2004", value: "2004" },
  { label: "2005", value: "2005" },
  { label: "2006", value: "2006" },
  { label: "2007", value: "2007" },
  { label: "2008", value: "2008" },
  { label: "2009", value: "2009" },
  { label: "2010", value: "2010" },
  { label: "2011", value: "2011" },
  { label: "2012", value: "2012" },
  { label: "2013", value: "2013" },
  { label: "2014", value: "2014" },
  { label: "2015", value: "2015" },
  { label: "2016", value: "2016" },
  { label: "2017", value: "2017" },
  { label: "2018", value: "2018" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  // { label: "2025", value: "2025" },
];

export const deliveryOptions = [
  {
    _id: "633eb7bbde5850c79a25d155",
    label: "Self-Pickup",
    value: "self_pickup",
  },
  {
    _id: "633eb7bbde5850c79a25d156",
    label: "Delivery by Owner",
    value: "delivery_by_owner",
  },
  {
    _id: "633eb7bbde5850c79a25d157",
    label: "Third-Party Delivery Service",
    value: "third_party_delivery_service",
  },
  {
    _id: "633eb7bbde5850c79a25d158",
    label: "Equipment Transporter",
    value: "equipment_transporter",
  },
  {
    _id: "633eb7bbde5850c79a25d159",
    label: "Local Transport",
    value: "local_transport",
  },
  {
    _id: "633eb7bbde5850c79a25d150",
    label: "Renter Responsibility",
    value: "renter_responsibility",
  },
  {
    _id: "633eb7bbde5850c79a25d152",
    label: "Truck Rental",
    value: "truck_rental",
  },
  {
    _id: "633eb7bbde5850c79a25d151",
    label: "On-Site Setup",
    value: "on_site_setup",
  },
];

export const transportationOptions = [
  {
    label: "Asset Owner manages transportation",
    value: "seller_willing_to_drop_equipment_to_buyer",
  },
  {
    label: "Buyer manages transportation",
    value: "buyer_needs_to_pick_up_the_equipment",
  },
  {
    label: "Buyer and Asset Owner will figure it out",
    value: "buyer_and_seller_will_figure_out_transportation",
  },
];

export const goalOptions = [
  {
    label: "Earth Manipulation",
    value: "earth_manipulation",
  },
  {
    label: "Lifting People and Material",
    value: "lifting_people_and_material",
  },
  {
    label: "Managing Air",
    value: "managing_air",
  },
  {
    label: "Power Solutions",
    value: "power_solutions",
  },
];

export const itemGoalsNested = {
  "Earth Manipulation": {
    Attachments: [
      "Skidsteer Auger Bit",
      "Skidsteer Boring Attachment",
      "Skidsteer Bucket",
      "Skidsteer Grapple Bucket",
      "Skidsteer Power Rake",
      "Skidsteer Leveler Attachment",
      "Skidsteer Multipurpose Tool",
      "Skidsteer Pallet Forks",
      "Skidsteer Soil Cultivator",
      "Skidsteer Trencher",
      "Skidsteer Vibratory Plow",
      "Skidsteer Rock Tip Auger",
      "Skidsteer Snow Pusher",
      "Skidsteer Tooth Bar",
      "Skidsteer Asphalt Planer",
      "Skidsteer Box Broom",
      "Skidsteer Brush Hog",
      "Skidsteer Dozer Blade",
      "Skidsteer Grader Blade",
      "Skidsteer Grapple Bucket",
      "Skidsteer Rake",
      "Skidsteer Snow Blade Attachment",
      "Skidsteer Sweeper Broom",
      "Skidsteer Tree Fork Attachment",
      "Skidsteer Hydraulic Hammer",

      "Excavator Hydraulic Hammer",

      "Tractor Aerator",
      "Tractor Backhoe Attachment",
      "Tractor Box Scraper",
      "Tractor Grooming Mower",
      "Tractor Power Rake",
      "Tractor Rake - Non Powered",
      "Tractor Posthole Auger",
      "Tractor Root Rake",
      "Tractor Rotary Cutter",
      "Tractor Rotary Tiller",
      "Tractor Soil Pulverizer",

      "Loader Forks",
      "Loader Root Rake",
    ],
    Compaction: [
      "Smooth Roller",
      "Jumping Jack",
      "Plate Compactor",
      "Padfoot Roller",
      "Trench Compactor",
      "Walk Behind Roller",
    ],

    "Dirt Movers": [
      "Backhoe",
      "Crawler Dozer",
      "Excavator",
      "Road Grader",
      "Skidsteer",
      "Tractor",
      "Trencher",
      "Loaders",
    ],
    "Tree Machines": ["Stump Grinder", "Chipper"],
  },
  "Lifting People and Material": {
    Manlift: [
      "Atrium Lift",
      "Articulating",
      "Straight Boom",
      "Mast Boom",
      "Single Person - Drivable",
      "Single Person - Manual Placement",
    ],
    Crane: ["Carry Deck", "Walk Behind"],
    Forklift: [
      "Telehandler - Rotating",
      "Industrial",
      "Telehandler",
      "Walkie Stacker",
      "Rought Terrain",
      "Manual",
    ],
    "Lift Attachments": [
      "Pipe/Conduit Rack",
      "Telehandler Forks",
      "Industrial Forks",
      "Telehandler Block Forks",
      "Industrial Block Forks",
      "Telehandler Bucket",
      "Forklift Bucket",
      "Telehandler Boom Extension",
      "Forklift Hook Attachment",
      "Trash Hopper",
      "Extension Forks",
    ],
    Scissorlift: ["Electric", "Rough Terrain", "Warehouse Industrial"],
  },
  "Managing Air": {
    Purification: ["Air Scrubber"],
    Dehumidification: ["Desiccant Dehumidifier", "Refrigerant Dehumidifier"],
    "Moving Air": ["Air Movers"],
    "Cooling Air": [
      "Air Conditioner",
      "Air Handler",
      "Chiller",
      "Cooling Tower",
    ],
    "Heating Air": [
      "Direct Fired Heaters",
      "Electric Heaters",
      "Flameless Heaters",
      "Ground Heaters",
      "Indirect Heaters",
      "Steam Heaters",
    ],
  },
  "Power Solutions": {
    "Creating Electricity": ["Gas Generators", "Diesel Generators"],
    "Fueling & Power Distribution": [
      "Containment Berm",
      "Fuel Tanks",
      "Distribution Cables",
      "Power Distribution Boxes & Panels",
      "Transformer ",
      "Step Down Panel",
    ],
  },
};

const superListOptions = [];

// Iterate over each goal
for (const [goal, categories] of Object.entries(itemGoalsNested)) {
  // Add goal-level option
  superListOptions.push({
    type: "goal",
    label: goal,
  });

  // Iterate over each category in the goal
  for (const [category, subCategories] of Object.entries(categories)) {
    // Add category-level option with parent goal
    superListOptions.push({
      type: "category",
      label: category,
      parentGoal: goal,
    });

    // Iterate over each subcategory in the category
    subCategories.forEach((subCategory) => {
      superListOptions.push({
        type: "subCategory",
        label: subCategory,
        parentCategory: category,
        parentGoal: goal,
      });
    });
  }
}

export default superListOptions

export const getSuperListSelectedOption = (type, value) => {
  if (!value) return null;
  return superListOptions.find((opt) => opt.type === type && opt.label === value) || null;
};

export const earthManipulationAttachments = [
  "Skidsteer Auger Bit",
  "Skidsteer Boring Attachment",
  "Skidsteer Bucket",
  "Skidsteer Grapple Bucket",
  "Skidsteer Power Rake",
  "Skidsteer Leveler Attachment",
  "Skidsteer Multipurpose Tool",
  "Skidsteer Pallet Forks",
  "Skidsteer Soil Cultivator",
  "Skidsteer Trencher",
  "Skidsteer Vibratory Plow",
  "Skidsteer Rock Tip Auger",
  "Skidsteer Snow Pusher",
  "Skidsteer Tooth Bar",
  "Skidsteer Asphalt Planer",
  "Skidsteer Box Broom",
  "Skidsteer Brush Hog",
  "Skidsteer Dozer Blade",
  "Skidsteer Grader Blade",
  "Skidsteer Grapple Bucket",
  "Skidsteer Rake",
  "Skidsteer Snow Blade Attachment",
  "Skidsteer Sweeper Broom",
  "Skidsteer Tree Fork Attachment",
  "Skidsteer Hydraulic Hammer",

  "Excavator Hydraulic Hammer",

  "Tractor Aerator",
  "Tractor Backhoe Attachment",
  "Tractor Box Scraper",
  "Tractor Grooming Mower",
  "Tractor Power Rake",
  "Tractor Rake - Non Powered",
  "Tractor Posthole Auger",
  "Tractor Root Rake",
  "Tractor Rotary Cutter",
  "Tractor Rotary Tiller",
  "Tractor Soil Pulverizer",

  "Loader Forks",
  "Loader Root Rake",
];

export const liftingAttachments = [
  "Pipe/Conduit Rack",
  "Telehandler Forks",
  "Industrial Forks",
  "Telehandler Block Forks",
  "Industrial Block Forks",
  "Telehandler Bucket",
  "Forklift Bucket",
  "Telehandler Boom Extension",
  "Forklift Hook Attachment",
  "Trash Hopper",
  "Extension Forks",
];

export const fldMappings = {
  fldSize: "Size",
  fldMake: "Make",
  fldModel: "Model",
  fldMaxDigDepth: "Max Dig Depth",
  fldBitwheelSize: "Bit/Wheel Size",
  fldMachineattachmentWeight: "Machine/Attachment Weight",
  fldBucketBlade: "Bucket Blade",
  fldCapacityLiftcarryWeight: "Capacity (Lift/Carry Weight)",
  fldDrumplateattachmentWidth: "Drum/Plate/Attachment Width",
  fldForkLength: "Fork Length",
  fldHydraulicPressure: "Hydraulic Pressure",
  fldPoweredBy: "Powered By",
  fldDriveType: "Drive Type",
  fldChargingCord: "Charging Cord",
  fldHorsePower: "Horse Power",
  fldReach: "Reach",
  fldCapacityWeightDeckplatform: "Capacity Weight (Deck/Platform)",
  fldLiftHeight: "Lift Height",
  fldMaxLimbCapacity: "Max Limb Capacity",
  fldPlatformExtension: "Platform Extension",
  fldCapacityOutriggers: "Capacity (Outriggers)",
  fldSheaveHeight: "Sheave Height",
  fldSafetyHarnessProvided: "Safety Harness Provided",
  fldMaxOutputCubicFeetPerMinute: "Max Output Cubic Feet per Minute",
  fldMaxPintsPerDay: "Max Pints per Day",
  fldPowerPhase: "Power Phase",
  fldMaxTonnage: "Max Tonnage",
  fldMachineVoltageCanEnterMultipleEnteries:
    "Machine Voltage (Can Enter Multiple enteries)",
  fldMaxBtus: "Max BTUs",
  fldInputoutputPowerKw: "Input/Output Power (KW)",
  fldDimensions: "Dimensions",
  fldFuelTankGl: "Fuel Tank (GL)",
  fldInputPowerAmps: "Input Power (Amps)",
  fldPowerOutputrecepticlesAmps: "Power Output/Recepticles (Amps)",
  fldPowerOutputrecepticlesVolts: "Power Output/Recepticles Volts",
  fldCableLength: "Cable Length",
  attchName: "Name",
  attchDesc: "Description",
  attchGoal: "Category",
  attchCategory: "Sub-category",
  attchSubCategory: "Type",
  attchCondition: "Condition",
  attchBrand: "Brand",
  attchYearManufactured: "Year Manufactured",
  attchHoursUsed: "Hours Used",
  attchSize: "Size",
};

export const thirdPartyAccountEntity = [
  "johndowgd@gmail.com",
  "johndowgd2@gmail.com",
];

export const placeholderMapping = {
  "Bucket Blade": "CUYD",
  "Max Dig Depth": "FT",
  "Drum/Plate/Attachment Width": "IN",
  "Power Output/Recepticles Volts": "Volts",
  "Capacity (Outriggers)": "LBS",
  "Machine/Attachment Weight": "LBS",
  "Horse Power": "",
  "Hydraulic Pressure": "PSI",
  "Fuel Tank (GL)": "GL",
  "Input/Output Power (KW)": "",
  "Drive Type": "",
  Reach: "FT",
  "Lift Height": "",
  "Power Output/Recepticles (Amps)": "",
  "Cable Length": "FT",
  "Max Limb Capacity": "LBS",
  Dimensions: "IN",
  "Max Tonnage": "T",
  "Fork Length": "IN",
  "Platform Extension": "FT",
  "Charging Cord": "",
  "Max Output Cubic Feet per Minute": "CFM",
  "Sheave Height": "FT",
  "Machine Voltage (Can Enter Multiple enteries)": "",
  "Powered By": "",
  "Max Pints per Day": "PPD",
  "Capacity Weight (Deck/Platform)": "",
  "Power Phase": "",
  "Bit/Wheel Size": "IN",
  Size: "Any",
  "Capacity (Lift/Carry Weight)": "LBS",
  "Safety Harness Provided": "",
  "Max BTUs": "",
  "Input Power (Amps)": "Amps",
};

export const environmentalFeeOptions = [
  { label: "0%", value: "0" },
  { label: "1%", value: "1" },
  { label: "2%", value: "2" },
  { label: "3%", value: "3" },
  { label: "4%", value: "4" },
];

export const categoryPageMapping = [
  "Attachments",
  "Compaction",
  "Dirt Movers",
  "Tree Machines",
  "Manlift",
  "Crane",
  "Forklift",
  "Lift Attachments",
  "Scissorlift",
  "Purification",
  "Dehumidification",
  "Moving Air",
  "Cooling Air",
  "Heating Air",
  "Creating Electricity",
  "Fueling & Power Distribution",
];

export const pages = [
  {
    name: "About Dizel",
    path: "/about-dizel",
  },
  {
    name: "Shop",
    path: "/shop"
  }, 
  {
    name: "Categories",
    path: "categories"
  },
  {
    name: "Add Equipment",
    path: "add-new-item/#/0"
  },
  {
    name: "My Equipment",
    path: "/user/equipment"
  },
  {
    name: "My Profile",
    path: "/user/profile"
  },
  {
    name: "FAQs",
    path: "faqs"
  },
  {
    name: "Transactions",
    path: "/user/transactions"
  }, 
  {
    name: "Favorites",
    path: "/user/favorites"
  },
  {
    name: "Referrals",
    path: "/user/referrals"
  }
]

export const goalsPath = [
  "Earth Manipulation",
  "Lifting People and Material",
  "Managing Air",
  "Power Solutions"
]


// export const validationMapping = {
//   "Bucket Blade": "Number",
//   "Max Dig Depth": "Number",
//   "Drum/Plate/Attachment Width": "Number",
//   "Power Output/Recepticles Volts": "Number",
//   "Capacity (Outriggers)": "LBS",
//   "Machine/Attachment Weight": "LBS",
//   "Horse Power": "",
//   "Hydraulic Pressure": "PSI",
//   "Fuel Tank (GL)": "GL",
//   "Input/Output Power (KW)": "",
//   "Drive Type": "",
//   Reach: "FT",
//   "Lift Height": "",
//   "Power Output/Recepticles (Amps)": "",
//   "Cable Length": "FT",
//   "Max Limb Capacity": "LBS",
//   Dimensions: "IN",
//   "Max Tonnage": "T",
//   "Fork Length": "IN",
//   "Platform Extension": "FT",
//   "Charging Cord": "",
//   "Max Output Cubic Feet per Minute": "CFM",
//   "Sheave Height": "FT",
//   "Machine Voltage (Can Enter Multiple enteries)": "",
//   "Powered By": "",
//   "Max Pints per Day": "PPD",
//   "Capacity Weight (Deck/Platform)": "",
//   "Power Phase": "",
//   "Bit/Wheel Size": "IN",
//   Size: "Any",
//   "Capacity (Lift/Carry Weight)": "LBS",
//   "Safety Harness Provided": "",
//   "Max BTUs": "",
//   "Input Power (Amps)": "Amps",
// };
