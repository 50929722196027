import React, { useEffect, useState } from "react";
import "./OrderConfirmation.css";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { ShoppingCart } from "@mui/icons-material";
import ProductCard from "../../components/ProductCard/ProductCard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import { getuserInfo } from "../../components/StateSlices/getuserSlice";
import Confetti from "react-confetti";

const OrderConfirmation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [fees, setFees] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [buyProducts, setBuyProducts] = useState([]);
  const [rentProducts, setRentProducts] = useState([]);
  const [rentEstimates, setRentEstimates] = useState([]);

  const [rentTotal, setRentTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [buyTotal, setBuyTotal] = useState(0);
  const [tempTotal, setTempTotal] = useState(0);
  const [transportation, setTransportation] = useState(0);
  const [checkoutHistory, setCheckoutHistory] = useState(null);
  const [cart, setCart] = useState([])

  const { userInfo, error, profile } = useSelector((state) => state.user);

  useEffect(() => {
    if (!userInfo) {
      setLoading(true);
      let token = localStorage.getItem("inktoken");
      if (!token) {
        navigate("/signup", { replace: true });
      } else {
        dispatch(getuserInfo({ token: token }));
      }
      // If not logged in, redirect to the login page
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      setLoading(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (location.state) {
      console.log("king is here ", location.state);
      setBuyProducts(location.state.buyProducts);
      setRentProducts(location.state.rentProducts);
      setTax(
        location.state.tax ? location.state.tax.tax_amount_exclusive / 100 : 0
      );
      setFees(location.state.fees);
      setDiscount(location.state.discount);
      setRentEstimates(location.state.rentEstimates);
      setBuyTotal(!location.state.buyTotal ? 0 : location.state.buyTotal);
      setRentTotal(!location.state.rentTotal ? 0 : location.state.rentTotal);
      setSubTotal(location.state.subTotal);
      setCheckoutHistory(location.state.checkoutHistory);
      setTransportation(
        location.state.transportation ? location.state.transportation : 0
      );
      setCart(
        location.state.cart ? location.state.cart : []
      )

      setLoading(false);
    }
  }, [location.state]);

  // CONFETTI
  const [showConfetti, setShowConfetti] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <div className={`confetti-container ${showConfetti ? "show" : "hide"}`}>
        <Confetti
          wind={0.01}
          gravity={0.15}
          numberOfPieces={400}
          width={window.innerWidth - 30}
          height={2 * window.innerHeight}
          colors={["#161b21", "#f05023", "#f3a950", "#f1f1f1"]}
          recycle={showConfetti}
        />
      </div>
      <Container sx={{ mt: 3 }} data-aos="fade-in">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="order-conf-con">
              <div className="left" style={{ border: "none" }}>
                <div className="checkmark-con">
                  <div display={"flex"}>
                    <IoCheckmarkCircleSharp color="green" size={"4em"} />
                  </div>
                  <div className="checkmark-content">
                    <Typography variant="h6" mt={0} marginBottom={".3rem"}>
                      Order ID. {checkoutHistory.orderId}
                    </Typography>
                    <Typography variant="h3" fontWeight={700}>
                      Order Placed Successfully!
                    </Typography>
                  </div>
                </div>
                {/* <Divider /> */}
                <Paper
                  elevation={0}
                  style={{
                    padding: "1rem 2rem",
                    paddingBottom: "2rem",
                    marginTop: "1rem",
                    background: "var(--black-color)",
                    color: "var(--white-color)",
                    // minHeight: "100vh",
                    // border: "1px solid #ddd",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h3"fontWeight={700} margin={"1rem"}>
                    Order Status: <span style={checkoutHistory.status==="complete"?{color:"#4caf50"}:checkoutHistory.status==="cancelled"?{color:"#f05023"}:{color:"var(--primary-color)"}}>{checkoutHistory.status}</span>
                  </Typography>
                  <Typography variant="h5" fontWeight={700} margin={"1rem"}>
                    Order Summary
                  </Typography>

                  <Typography variant="h5" margin={"1rem"} >
                    {checkoutHistory.status==="Pending"?`Please Complete the next step as posted `:"It can take up to 2-3 days to reflect the order complete status"} {checkoutHistory.status==="Pending" && (
                      <Link
                        to={checkoutHistory.next_step}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "underline",
                          color: "var(--primary-color)",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          }}
                      >
                        here
                      </Link>
                    )}
                  </Typography>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: 700,
                              fontSize: "12px",
                              textTransform: "uppercase",
                              letterSpacing: 1.5,
                              color: "var(--grey-color)",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Description
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: 700,
                              fontSize: "12px",
                              textTransform: "uppercase",
                              letterSpacing: 1.5,
                              color: "var(--grey-color)",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {buyProducts && buyProducts.length > 0 && (
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "var(--white-color)",
                                border: "none",
                                borderBottomColor: "var(--grey-color)",
                              }}
                            >
                              Buy Total
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                color: "var(--white-color)",
                                border: "none",
                                borderBottomColor: "var(--grey-color)",
                              }}
                            >
                              $
                              {parseFloat(buyTotal).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            </TableCell>
                          </TableRow>
                        )}
                        {rentProducts && rentProducts.length > 0 && (
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "var(--white-color)",
                                border: "none",
                                borderBottomColor: "var(--grey-color)",
                                minWidth: "150px",
                              }}
                            >
                              Rent Total
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "var(--white-color)",
                                border: "none",
                                borderBottomColor: "var(--grey-color)",
                              }}
                              align="right"
                            >
                              
                              $
                              {parseFloat(rentTotal).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            </TableCell>
                          </TableRow>
                        )}
                        {buyProducts && buyProducts.length > 0 && rentProducts && rentProducts.length > 0 && 
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Subtotal
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                            align="right"
                          >
                            $
                            {parseFloat(subTotal).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                        </TableRow>}
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Transportation
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                            align="right"
                          >
                            $
                            {transportation.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Fees
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                            align="right"
                          >
                            $
                            {fees.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                        </TableRow>
                        {discount > 0 && (
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "var(--white-color)",
                                border: "none",
                                borderBottomColor: "var(--grey-color)",
                              }}
                            >
                              Referral Discount ({discount + "%"})
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "var(--white-color)",
                                border: "none",
                                borderBottomColor: "var(--grey-color)",
                              }}
                              align="right"
                            >
                              - $
                              {parseFloat(
                                (fees * discount) / 100
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Tax
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                              minWidth: "max-content",
                            }}
                            align="right"
                          >
                            $
                            {tax.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "700",
                              textTransform: "uppercase",
                              letterSpacing: 1.5,
                              color: "var(--white-color)",
                              border: "none",
                              borderTop: "2px solid var(--grey-color)",
                              borderBottom: "none",
                            }}
                          >
                            Total
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "700",
                              border: "none",
                              borderTop: "2px solid var(--grey-color)",
                              borderBottom: "none",
                              color: "var(--white-color)",
                            }}
                            align="right"
                          >
                            $
                            {Number(
                              checkoutHistory.totalPricePaid
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <Paper
                  elevation={0}
                  style={{
                    padding: "1rem 2rem",
                    paddingBottom: "2rem",
                    marginTop: "1rem",
                    background: "var(--black-color)",
                    color: "var(--white-color)",
                    // minHeight: "100vh",
                    // border: "1px solid #ddd",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    variant="h3"
                    fontWeight={700}
                    margin={"1rem"}
                    marginTop={"2rem"}
                    marginBottom={"1.5rem"}
                  >
                    Jobsite Delivery Details
                  </Typography>
                  <Typography variant="h6" margin={"1rem"}>
                    <strong>Contact: </strong>
                    <span style={{ marginLeft: "1rem" }}>
                      {checkoutHistory.email}
                    </span>
                  </Typography>
                  <Typography variant="h6" margin={"1rem"} display={"flex"}>
                    <Box>
                      <strong>Street: </strong>
                    </Box>
                    <Box
                      marginLeft={"1rem"}
                      component={"div"}
                      id="addressFormat"
                    >
                      {checkoutHistory.Address}
                    </Box>
                  </Typography>
                  <Typography variant="h6" margin={"1rem"}>
                    <strong>City: </strong>
                    <span style={{ marginLeft: "1rem" }}>
                      {checkoutHistory.city}
                    </span>
                  </Typography>
                  <Typography variant="h6" margin={"1rem"}>
                    <strong>State: </strong>
                    <span style={{ marginLeft: "1rem" }}>
                      {checkoutHistory.State}
                    </span>
                  </Typography>
                  <Typography variant="h6" margin={"1rem"}>
                    <strong>Zipcode: </strong>
                    <span style={{ marginLeft: "1rem" }}>
                      {checkoutHistory.zipCode}
                    </span>
                  </Typography>
                  <Typography variant="h6" margin={"1rem"}>
                    <strong>Special Instruction: </strong>
                    <span style={{ marginLeft: "1rem" }}>
                      {location.state?location.state.instruction?location.state.instruction:"-":""}
                    </span>
                  </Typography>
                </Paper>
                <div className="order-conf-footer">
                  <Box>
                    <Typography variant="h6">
                      Need any help ?{" "}
                      <strong
                        style={{ borderBottom: "1px solid var(--black-color)" }}
                      >
                        <a href="mailto:support@godizel.com" target="_blank">
                          Contact Support
                        </a>
                      </strong>
                    </Typography>
                  </Box>
                  <Box>
                    <Link to={"/shop"}>
                      <Button
                        sx={{
                          background: "var(--black-color)",
                          color: "var(--white-color)",
                          "&:hover": {
                            color: "var(-black-color)",
                            background: "var(--grey-color)",
                          },
                        }}
                      >
                        Continue Shopping
                      </Button>
                    </Link>
                  </Box>
                </div>
              </div>
              {/* <Box component={"div"} className="order-conf-vertical-divider">
          &nbsp;
        </Box> */}
              <div className="right">
                <div className="checkmark-con" style={{ marginTop: "1rem" }}>
                  <ShoppingCart />
                  <Typography variant="h3" mx={1.5} fontWeight={700}>
                    Your Equipment
                  </Typography>
                  <Chip
                    label={buyProducts.length + rentProducts.length}
                    sx={{
                      background: "var(--black-color)",
                      color: "Var(--white-color)",
                      fontWeight: 700,
                    }}
                  />
                </div>
                <div className="order-conf-products-con">
                  {buyProducts && buyProducts.length > 0 && (
                    <>
                      <Divider
                        sx={{
                          fontSize: "20px",
                          fontWeight: "700",
                          textTransform: "uppercase",
                          margin: "1rem 0",
                        }}
                        textAlign="left"
                      >
                        Equipment
                      </Divider>
                      {buyProducts &&
                        buyProducts.map((product) => (
                          <div style={{ margin: "1rem 0" }}>
                            <ProductCard
                              key={product._id}
                              product={product}
                              page={checkoutHistory.sellerProductsEmail.find(
                                (prod) => prod.productId == product._id
                              )}
                            />
                          </div>
                        ))}
                    </>
                  )}
                  {rentProducts && rentProducts.length > 0 && (
                    <>
                      <Divider
                        sx={{
                          fontSize: "20px",
                          fontWeight: "700",
                          textTransform: "uppercase",
                          margin: "1rem 0",
                        }}
                        textAlign="left"
                      >
                        Rental Equipment
                      </Divider>
                      {rentProducts &&
                        rentProducts.map((product) => (
                          <div style={{ margin: "1rem 0" }}>
                            <ProductCard
                              key={product._id}
                              product={product}
                              page={checkoutHistory.sellerProductsEmail.find(
                                (prod) => prod.productId == product._id
                              )}
                              dates={
                                cart.find(
                                  (crt) => crt.id == product._id
                                )
                              }
                            />
                          </div>
                        ))}
                    </>
                  )}
                  {buyProducts.length === 0 && rentProducts.length === 0 && (
                    <Typography variant="h3">No Equipment</Typography>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default OrderConfirmation;
