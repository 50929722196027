import {
  Backdrop,
  Box,
  Button,
  Chip,
  Fade,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
// import { referralData } from "../dummyData";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { referData } from "../../StateSlices/adminSlice";
import "../Users/Users.css";
import AnalyticsHeader from "../AnalyticsHeader/AnalyticsHeader";

const chipStyle = {
  padding: "1.25rem 1.5rem",
  color: "var(--white-color)",
  background: "var(--black-color)",
  cursor: "pointer",
  transition: ".3s ease",
  borderRadius: "30px",
  "&:hover": {
    background: "var(--dark-grey-color)",
    transform: "scale(1.05)",
    transition: ".3s ease",
  },
  fontWeight: 700,
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const Referrals = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [spinner, setSpinner] = useState(false);

  const getDate = (params) => {
    const date = new Date(params.value);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };

  const { allReferStatus, allReferInfo, allReferError } = useSelector(
    (state) => state.admin
  );

  const [openModal, setOpenModal] = React.useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const agGridHeight = window.innerHeight - 150;

  const [rowData, setRowData] = useState();
  const defaultColDef = useMemo(() => ({
    filter: true,
    cellStyle: { fontFamily: "Helvetica, Arial, sans-serif" },
  }));
  const [colDefs, setColDefs] = useState([
    {
      headerName: "Referred By",
      width: "200px",
      field: "referedByEmail",
      valueFormatter: (params) => (params.value ? params.value : "N/A"),
    },
    {
      headerName: "Referred By Refer Count",
      width: "200px",
      field: "referedByReferCount",
      valueFormatter: (params) => (params.value ? params.value : 0),
    },
    { headerName: "Referred To", field: "userEmail", width: "200px" },
    {
      headerName: "Referred To Refer Count",
      width: "200px",
      field: "referCount",
      valueFormatter: (params) => (params.value ? params.value : 0),
    },
    {
      headerName: "Item Posted",
      width: "200px",
      field: "userProductDetails",
      valueFormatter: (params) => {
        return params.value?params.value.productsId.length:0;
      }
    },
    {
      headerName: "Created At",
      field: "createdAt",
      width: "200px",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Referred By Credits",
      width: "200px",
      field: "referedByCredits",
      valueFormatter: (params) => (params.value ? params.value : "N/A"),
    },
  ]);
  //   const gridOptions = {
  //     domLayout: "autoWidth",
  //     suppressHorizontalScroll: true,
  //     // Other grid options...
  //   };

  useEffect(() => {
    let token = localStorage.getItem("inktoken");
    if (token) {
      // console.log(pageNumber);
      setSpinner(true);
      dispatch(
        referData({
          token,
          // p: location.search.slice(location.search.indexOf("=") + 1) - 1,
          // search: orderSearchVal,
        })
      );
      window.scrollTo(0, 0);
    } else {
      navigate("/404", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (allReferInfo && allReferInfo.length > 0) {
      setSpinner(false);
      setRowData(allReferInfo);
    }
  }, [allReferInfo]);

  return (
    <div className="tab-content">
      <AnalyticsHeader
        label="referral"
        spinner={spinner}
        setSpinner={setSpinner}
        hideSearchBar={true}
      />

      <div className="ag-theme-material" style={{ height: agGridHeight }}>
        <AgGridReact
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
        />
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={modalStyle}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className="mont-title"
              component="h2"
            >
              Edit Referral Credits
            </Typography>
            <Typography
              variant="body2"
              id="transition-modal-description"
              sx={{ mt: 2 }}
            >
              Modifying referral credits enables users to benefit from both
              earned and received credits when referring others or being
              referred.
            </Typography>
            <Grid container>
              <TextField
                fullWidth
                sx={{ marginTop: "1rem" }}
                type="number"
                label="Credits earned by referring user"
                helperText="E.g. 2 / 5 / 10"
              ></TextField>
              <TextField
                fullWidth
                sx={{ marginTop: "1rem" }}
                type="number"
                label="Credits earned by referred user"
                helperText="E.g. 2 / 5 / 10"
              ></TextField>

              <Button
                sx={{ marginTop: "1rem" }}
                color="secondary"
                variant="contained"
              >
                Update
              </Button>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Referrals;
