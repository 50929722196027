import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
  useMatches,
  useKBar,
  useRegisterActions,
} from "kbar";
import {
  Box,
  Button,
  Typography,
  Paper,
  Chip,
  Skeleton,
  debounce,
} from "@mui/material";
import {
  FaFileAlt,
  FaBarcode,
  FaLayerGroup,
  FaTags,
  FaClipboardList,
  FaHandshake,
} from "react-icons/fa";
import { brands, itemGoalsNested, pages } from "../../pages/AddNewItem/data";
import { useDispatch, useSelector } from "react-redux";
import { getElasticSearchForProducts } from "../StateSlices/elasticSearchSlice";

function ShimmerUI() {
  return (
    <Box sx={{ width: "100%" }}>
      {[...Array(6)].map((_, index) => (
        <Box
          key={index}
          sx={{
            mb: 2,
          }}
        >
          <Skeleton
            animation="wave"
            variant="text"
            width={50}
            height={20}
            sx={{ ml: 1 }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            width="100%"
            height={40}
            sx={{ ml: 1 }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            width="100%"
            height={40}
            sx={{ ml: 1 }}
          />
        </Box>
      ))}
    </Box>
  );
}

// Define your nested actions

function getBrandActions(navigate) {
  return brands.map((brand) => ({
    id: brand.value,
    name: brand.label,
    keywords: brand.label.toLowerCase(),
    perform: () => navigate(`/shop?brandName=${brand.label}`),
    icon: <FaBarcode />, // or your preferred icon
    section: "Brands",
  }));
}

function getPageActions(navigate) {
  return pages.map((page) => ({
    id: page.name,
    name: page.name,
    keywords: page.name.toLowerCase(),
    perform: () => navigate(page.path),
    icon: <FaBarcode />, // or your preferred icon
    section: "Pages",
  }));
}

const flattenedGoals = Object.entries(itemGoalsNested).flatMap(
  ([category, subcategories]) =>
    Object.entries(subcategories).flatMap(([subcategory, types]) =>
      types.map((type) => ({
        category,
        subcategory,
        type,
      }))
    )
);

export function getGoalActions(navigate) {
  // Each top-level key becomes an action.
  return Object.keys(itemGoalsNested).map((goal) => ({
    id: `goal-${goal}`,
    name: goal,
    keywords: goal.toLowerCase(),
    perform: () => navigate(`/shop?goal=${encodeURIComponent(goal)}`),
    section: "Categories", // optional grouping label
  }));
}

export function getCategoryActions(navigate) {
  // For each goal, every subcategory becomes an action.
  let actions = [];
  Object.entries(itemGoalsNested).forEach(([goal, subcategories]) => {
    Object.keys(subcategories).forEach((subcategory) => {
      actions.push({
        id: `cat-${goal}-${subcategory}`,
        name: subcategory,
        keywords: `${goal} ${subcategory}`.toLowerCase(),
        perform: () =>
          navigate(
            `/shop?goal=${encodeURIComponent(
              goal
            )}&categoryName=${encodeURIComponent(subcategory)}`
          ),
        section: "Subcategories", // This groups them under the parent goal if desired
      });
    });
  });
  return actions;
}

export function getTypeActions(navigate) {
  // For each goal and subcategory, each type becomes an action.
  let actions = [];
  Object.entries(itemGoalsNested).forEach(([goal, subcategories]) => {
    Object.entries(subcategories).forEach(([subcategory, types]) => {
      types.forEach((type) => {
        actions.push({
          id: `type-${goal}-${subcategory}-${type}`,
          name: type,
          keywords: `${goal} ${subcategory} ${type}`.toLowerCase(),
          perform: () =>
            navigate(
              `/shop?goal=${encodeURIComponent(
                goal
              )}&categoryName=${encodeURIComponent(
                subcategory
              )}&subCategoryName=${encodeURIComponent(type)}`
            ),
          section: "Types", // Grouped by goal and subcategory
        });
      });
    });
  });
  return actions;
}

function getNestedActions(navigate) {
  return [
    // {
    //   id: "pages",
    //   name: "Pages",
    //   icon: <FaFileAlt />,
    //   children: getPageActions(navigate),
    // },
    {
      id: "brands",
      name: "Brands",
      icon: <FaHandshake />,
      children: getBrandActions(navigate),
    },
    {
      id: "categories",
      name: "Categories",
      icon: <FaLayerGroup />,
      children: getGoalActions(navigate),
    },
    {
      id: "subcategories",
      name: "SubCategories",
      icon: <FaTags />,
      children: getCategoryActions(navigate),
    },
    {
      id: "types",
      name: "Types",
      icon: <FaClipboardList />,
      children: getTypeActions(navigate),
    },
    // {
    //   id: "products",
    //   name: "Products",
    //   icon: <GiBulldozer size={"1.5em"} />,
    //   children: [
    //     {
    //       id: "66e8afb66d489167563d0ddc",
    //       name: "Skid Steer Wheeled, ROC 1500-1750 lb Rental",
    //       keywords: "skid stter wheeled ROC 1500-1750 lb Rental ketul",
    //       perform: () => navigate("/product/66e8afb66d489167563d0ddc"),
    //       chips: ["Earth Manipulation", "Dirt Movers", "Skidsteer"],
    //     },
    //     {
    //       id: "66e2183280cf48535ff05d7a",
    //       name: "Diesel Generator 12kW/15kVA",
    //       keywords: "Power Solutions Creating Electricity Diesel Generators",
    //       perform: () => navigate("/product/66e2183280cf48535ff05d7a"),
    //       chips: [
    //         "Power Solutions",
    //         "Creating Electricity",
    //         "Diesel Generators",
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: "orders",
    //   name: "Orders",
    //   icon: <FaShoppingCart />,
    //   children: [
    //     {
    //       id: "current-orders",
    //       name: "Current Orders",
    //       keywords: "current orders",
    //       perform: () => navigate("/user/transactions"),
    //     },
    //     {
    //       id: "order-history",
    //       name: "Order History",
    //       keywords: "order history",
    //       perform: () => navigate("/user/profile"),
    //     },
    //   ],
    // },
  ];
}

function flattenActions(nestedActions) {
  return nestedActions.reduce((acc, section) => {
    const children = section.children.map((child) => ({
      ...child,
      section: section.name,
      sectionIcon: section.icon,
    }));
    return acc.concat(children);
  }, []);
}

// ----- Dynamic Product Actions Registration Component -----
function DynamicActions({ actions }) {
  // Register dynamic actions; re-register whenever actions changes.
  useRegisterActions(actions, [actions]);
  return null;
}

export default function KBarMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const { searchinfo } = useSelector((state) => state.elasticsearch);
  const [productActions, setProductActions] = useState([]);

  // ----- Fetch Suggestions on Input Change via Dispatch -----
  const fetchSuggestions = (query, options = {}) => {
    if (options.wildcard) {
      dispatch(getElasticSearchForProducts(`${query}*`));
    } else {
      dispatch(getElasticSearchForProducts(query));
    }
  };

  // Debounce the fetch function to avoid excessive calls
  const debouncedFetchSuggestions = useMemo(
    () =>
      debounce((query) => {
        fetchSuggestions(query, { wildcard: true });
      }, 300),
    [dispatch]
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value !== "") {
      setSearchTerm(value);
      setSuggestionLoading(value !== "");
      debouncedFetchSuggestions(value);
    }
  };

  // ----- Update Product Actions When Fetched Products Change -----
  useEffect(() => {
    // debugger
    if (searchinfo && searchinfo.length > 0) {
      const actions = searchinfo.map((product) => ({
        id: product.itemId,
        name: product.itemName,
        keywords: product.itemName || "",
        perform: () => navigate(`/product/${product.itemId}`),
        section: "Products",
        chips: product
          ? [
              product.itemGoal && product.itemGoal.trim(),
              product.itemSubCategoryName && product.itemSubCategoryName.trim(),
              product.itemSubCategoryName && product.itemSubCategoryName.trim(),
            ].filter(Boolean)
          : [],
      }));
      setProductActions(actions);
      setSuggestionLoading(false);
    }
  }, [searchinfo, navigate]);

  // Get static actions
  const nestedActions = getNestedActions(navigate);
  const flatActions = flattenActions(nestedActions);

  return (
    <KBarProvider actions={flatActions}>
      <DynamicActions actions={productActions} />
      <CommandBar
        handleInputChange={handleInputChange}
        suggestionLoading={suggestionLoading}
      />
      <CommandButton />
    </KBarProvider>
  );
}

function CommandBar({ handleInputChange, suggestionLoading }) {
  return (
    <KBarPortal>
      <KBarPositioner
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(30px)",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <KBarAnimator>
          <Paper
            sx={{
              width: "800px",
              maxHeight: "600px",
              overflowY: "scroll",
              p: 2,
              pt: 0,
              borderRadius: "10px",
              boxShadow: 5,
              background: "var(--black-color)",
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                pt: 2,
                zIndex: 10,
                background: "var(--black-color)",
                // boxShadow: "rgba(255, 225, 225, 0.2) 0px 10px 10px -10px",
                // borderBottom: "1px solid var(--dark-grey-color)",
                pb: 1,
              }}
            >
              {/* KBarSearch automatically attaches the required ref */}
              <KBarSearch
                placeholder=""
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "1.2rem",
                  border: "1px solid #656565",
                  color: "var(--white-color)",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
                onChange={handleInputChange}
              />
            </Box>

            <Box
              sx={{ overflowY: "auto", flex: 1 }}
              
            >
              {suggestionLoading ? <ShimmerUI /> : <RenderResults />}
            </Box>
            {/* </Box> */}
          </Paper>
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
}

function CommandButton() {
  const { query } = useKBar();
  return "";
  // <Button
  //   onClick={query.toggle}
  //   variant="contained"
  //   startIcon={<FaSearch />}
  //   sx={{
  //     position: "fixed",
  //     bottom: 16,
  //     right: 16,
  //     borderRadius: "8px",
  //     p: "8px 16px",
  //   }}
  // >
  //   Search
  // </Button>
}

function RenderResults() {
  const { results } = useMatches();
  if (!results) return null;
  // Group the results by the section field
  const groupedResults = results.reduce((acc, item) => {
    if (typeof item === "string") return acc;
    const section = item.section || "Other";
    if (!acc[section]) acc[section] = [];
    acc[section].push(item);
    return acc;
  }, {});

  return (
    <Box>
      {Object.entries(groupedResults).map(([section, items]) => {
        const topItems =
          section === "Products" ? items.slice(0, 5) : items.slice(0, 3);
        if (topItems.length === 0) return null;
        return (
          <Box key={section}>
            <Typography
              variant="h6"
              className="mont-title"
              sx={{
                px: 2,
                py: 1,
                fontWeight: "bold",
                color: "var(--white-color)",
                display: "flex",
                alignItems: "center",
                gap: 1,
                textTransform: "uppercase",
                letterSpacing: 1.4,
                // fontSize: 14,
                // borderBottom: "1px solid var(--dark-grey-color)",
              }}
            >
              {topItems[0].sectionIcon}
              {section}
            </Typography>
            {topItems.map((item) => (
              <ResultItem key={item.id} action={item} />
            ))}
          </Box>
        );
      })}
    </Box>
  );
}

const ResultItem = React.forwardRef(({ action }, ref) => {
  const { query } = useKBar();
  return (
    <Box
      ref={ref}
      onClick={() => {
        action.perform();
        query.toggle();
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          action.perform();
          query.toggle();
        }
      }}
      sx={{
        position: "relative",
        cursor: "pointer",
        px: 2,
        py: 1,
        color: "var(--grey-color)",
        borderRadius: "6px",
        transition: "all .15s ease",
        "&:hover": {
          backgroundColor: "var(--secondary-color)",
          color: "var(--white-color)",
          "& .MuiChip-root": {
            color: "var(--white-color)",
            border: "1px solid var(--white-color)",
          },
        },
        mb: action.chips ? 1 : 0,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="h5">{action.name}</Typography>
      </Box>
      {action.chips && (
        <Box sx={{ display: "flex", flexWrap: "wrap", my: 1, gap: 1 }}>
          {action.chips.map((chip, index) => (
            <Chip
              sx={{
                background: "transparent",
                color: "var(--grey-color)",
                border: "1px solid var(--grey-color)",
              }}
              key={index}
              label={chip}
              size="small"
            />
          ))}
        </Box>
      )}
    </Box>
  );
});
