import React from "react";
import { Add, Remove } from "@mui/icons-material";
import { Typography } from "@mui/material";

const QuantityButton = ({ onQuant, onRemove, onAdd }) => {
  return (
    <div className="amount">
      <button className="minus" onClick={onRemove} disabled={onQuant === 0}>
        <Remove />
      </button>
      <Typography variant="body1" color={"primary"}>
        {onQuant}
      </Typography>
      <button className="plus" onClick={onAdd} disabled={onQuant === 100}>
        <Add />
      </button>
    </div>
  );
};

export default QuantityButton;
