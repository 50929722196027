import { SearchRounded } from "@mui/icons-material";
import { alpha, Button, InputBase, Stack, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUsers } from "../../StateSlices/adminSlice";
import { FaFileExport } from "react-icons/fa6";
import {
  getOrderDataExport,
  getProductDataExport,
  getUserDataExport,
} from "../../StateSlices/adminDataExportSlice";
import { toast } from "react-toastify";
import { toastOption } from "../../../utils/utils";

const AdminSearch = ({ label, searchQuery, placeholder, showExportButton }) => {
  const inputSearchRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (searchQuery) {
      inputSearchRef.current.value = searchQuery;
    }
  }, []);

  const submitSearchValue = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("inktoken");
    if (token) {
      if (label === "user") {
        navigate(`/admin/users?page=1`, {
          replace: true,
          state: { searchQuery: inputSearchRef.current.value },
        });
      } else if (label === "equipment") {
        navigate(`/admin/products?page=1`, {
          replace: true,
          state: { searchQuery: inputSearchRef.current.value },
        });
      } else if (label === "orders") {
        navigate(`/admin/orders?page=1`, {
          replace: true,
          state: { searchQuery: inputSearchRef.current.value },
        });
      }
    } else {
      navigate("/404", { replace: true });
    }
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    fontFamily: `"Helvetica", Arial, "sans-serif"`,
    borderRadius: 5,
    backgroundColor: alpha(theme.palette.common.black, 0.1),
    "&:hover": {
      transition: ".5s ease",
      backgroundColor: alpha(theme.palette.common.black, 0.2),
    },
    transition: ".5s ease",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "20ch",
        "&:focus": {
          width: "28ch",
        },
      },
      fontSize: "14px",
      "::placeholder": {
        fontSize: "14px",
      },
    },
  }));

  const downloadCsv = () => {
    const urlPathName = window.location.pathname;
    let token = localStorage.getItem("inktoken");
    if (token) {
      if (urlPathName.includes("/admin/users")) {
        dispatch(
          getUserDataExport({
            token,
            p: location.search.slice(location.search.indexOf("=") + 1) - 1,
            search: inputSearchRef.current.value,
            // status: tab,
            // filter: userFilter,
            // type,
          })
        );
      }
      else if (urlPathName.includes("/admin/products")) {
        dispatch(
          getProductDataExport({
            token,
            p: location.search.slice(location.search.indexOf("=") + 1) - 1,
            search: inputSearchRef.current.value,
            // status: tab === "ban" ? true : false,
            // sort: sorting,
            // report: tab === "report" ? true : false,
            // purchased: tab === "purchased" ? true : false,
          })
        );
      }
      else if(urlPathName.includes("/admin/orders")) {
        dispatch(getOrderDataExport({
          token,
          p: location.search.slice(location.search.indexOf("=") + 1) - 1,
          search: inputSearchRef.current.value,
          // tab: tab,
        }));
      }
      toast.info("Please Wait for a While", toastOption);
    }
  };
  return (
    <Stack direction={"row"} justifyContent={"center"} alignItems={"stretch"}>
      <form
        class="search"
        onSubmit={(e) => {
          e.preventDefault();
          submitSearchValue(e);
        }}
      >
        <Search>
          <SearchIconWrapper>
            <SearchRounded fontSize="16px" />
          </SearchIconWrapper>
          <StyledInputBase
            defaultValue={searchQuery || ""}
            inputRef={inputSearchRef}
            placeholder={`Search ${placeholder}...`}
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
      </form>
      {showExportButton && (
        <Button
          onClick={downloadCsv}
          aria-label="Download CSV Content"
          title="Download CSV Content"
          color="secondary"
          sx={{ minWidth: "48px", marginLeft: ".5rem" }}
        >
          <FaFileExport />
        </Button>
      )}
    </Stack>
  );
};

export default AdminSearch;
