import React from "react";
import "./RightSection.css";
import {
  Routes,
  Route,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

import { equipmentsTab, ordersTab, usersTab } from "../Tabs/TabsContent";
import Referrals from "../Referrals/Referrals";
import Users from "../Users/Users";
import AdminTabs from "../AdminTabs/AdminTabs";
import PromoCode from "../PromoCode/PromoCode";
import Analytics from "../Analytics/Analytics";

// import ViewProduct from "../ViewProduct/ViewProduct";
const RightSection = ({ isOpen, currentTab }) => {
  return (
    <div
      className={isOpen ? "adm-right-section" : "adm-right-section collapsed"}
    >
      <div data-aos="fade-in">
        <Routes>
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/users" element={<AdminTabs tabs={usersTab} />} />
          <Route
            path="/products"
            element={<AdminTabs tabs={equipmentsTab} />}
          />
          <Route path="/orders" element={<AdminTabs tabs={ordersTab} />} />
          <Route path="/referrals" element={<Referrals />} />
          <Route path="/promos" element={<PromoCode />} />
        </Routes>
      </div>
    </div>
  );
};

export default RightSection;
