import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { debounce } from "lodash";
import {
  Autocomplete,
  Backdrop,
  Badge,
  Chip,
  CircularProgress,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Popover,
  Slide,
  Switch,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./Header.css";
import {
  AddCircle,
  Chat,
  Notifications,
  Settings,
  ShoppingCart,
} from "@mui/icons-material";
import Dropdown from "./Dropdown";
import { useState } from "react";
import GoDizel from "../../assets/godizel-brand-2.png";
import GoDIzelShort from "../../assets/godizel-logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ElasticAutocomplete from "../HeroSection/ElasticAutocomplete";
import { getElasticSearch } from "../StateSlices/elasticSearchSlice";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {
  FaChevronDown,
  FaChevronRight,
  FaChevronUp,
  FaExternalLinkAlt,
  FaSearch,
} from "react-icons/fa";
import HeaderSearch from "../HeaderSearch/HeaderSearch";
import Header2 from "../Header2/Header2";
import { useRef } from "react";
import MobileHeader from "./MobileHeader";
import { FaMessage } from "react-icons/fa6";
import { useKBar } from "kbar";
import { Searchbar } from "../KBar/SuperSearch";

const settings = [{ name: "Profile", link: "profile" }];

const menuItems = [
  {
    title: "Earth Manipulation",
    url: "?goal=Earth Manipulation",
    submenu: [
      {
        title: "Attachments",
        url: "?goal=Earth Manipulation&categoryName=Attachments",
        submenu: [
          {
            title: "Skidsteer Auger Bit",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Auger Bit",
          },
          {
            title: "Skidsteer Boring Attachment",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Boring Attachment",
          },
          {
            title: "Skidsteer Bucket",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Bucket",
          },
          {
            title: "Skidsteer Grapple Bucket",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Grapple Bucket",
          },
          {
            title: "Skidsteer Power Rake",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Power Rake",
          },
          {
            title: "Skidsteer Leveler Attachment",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Leveler Attachment",
          },
          {
            title: "Skidsteer Multipurpose Tool",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Multipurpose Tool",
          },
          {
            title: "Skidsteer Pallet Forks",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Pallet Forks",
          },
          {
            title: "Skidsteer Soil Cultivator",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Soil Cultivator",
          },
          {
            title: "Tiller Attachment",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tiller Attachment",
          },
          {
            title: "Skidsteer Trencher",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Trencher",
          },
          {
            title: "Skidsteer Vibratory Plow",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Vibratory Plow",
          },
          {
            title: "Skidsteer Rock Tip Auger",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Rock Tip Auger",
          },
          {
            title: "Skidsteer Snow Pusher",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Snow Pusher",
          },
          {
            title: "Skidsteer Tooth Bar",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Tooth Bar",
          },
          {
            title: "Skidsteer Asphalt Planer",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Asphalt Planer",
          },
          {
            title: "Skidsteer Box Broom",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Box Broom",
          },
          {
            title: "Skidsteer Brush Hog",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Brush Hog",
          },
          {
            title: "Skidsteer Dozer Blade",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Dozer Blade",
          },
          {
            title: "Skidsteer Grader Blade",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Grader Blade",
          },
          {
            title: "Skidsteer Grapple Bucket",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Grapple Bucket",
          },
          {
            title: "Skidsteer Rake",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Rake",
          },
          {
            title: "Skidsteer Pallet Forks",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Pallet Forks",
          },
          {
            title: "Skidsteer Snow Blade Attachment",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Snow Blade Attachment",
          },
          {
            title: "Skidsteer Sweeper Broom",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Sweeper Broom",
          },
          {
            title: "Skidsteer Tree Fork Attachment",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Tree Fork Attachment",
          },
          {
            title: "Excavator Hydraulic Hammer",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Excavator Hydraulic Hammer",
          },
          {
            title: "Skidsteer Hydraulic Hammer",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Hydraulic Hammer",
          },
          {
            title: "Tractor Aerator",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Aerator",
          },
          {
            title: "Tractor Backhoe Attachment",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Backhoe Attachment",
          },
          {
            title: "Tractor Box Scraper",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Box Scraper",
          },
          {
            title: "Tractor Grooming Mower",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Grooming Mower",
          },
          {
            title: "Tractor Power Rake",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Power Rake",
          },
          {
            title: "Tractor Rake - Non Powered",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Rake - Non Powered",
          },
          {
            title: "Tractor Posthole Auger",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Posthole Auger",
          },
          {
            title: "Tractor Root Rake",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Root Rake",
          },
          {
            title: "Tractor Rotary Cutter",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Rotary Cutter",
          },
          {
            title: "Tractor Rotary Tiller",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Rotary Tiller",
          },
          {
            title: "Tractor Soil Pulverizer",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Soil Pulverizer",
          },
          {
            title: "Loader Forks",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Loader Forks",
          },
          {
            title: "Loader Root Rake",
            url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Loader Root Rake",
          },
        ],
      },
      {
        title: "Compaction",
        url: "?goal=Earth Manipulation&categoryName=Compaction",
        submenu: [
          {
            title: "Smooth Roller",
            url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Smooth Roller",
          },
          {
            title: "Jumping Jack",
            url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Jumping Jack",
          },
          {
            title: "Plate Compactor",
            url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Plate Compactor",
          },
          {
            title: "Padfoot Roller",
            url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Padfoot Roller",
          },
          {
            title: "Trench Compactor",
            url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Trench Compactor",
          },
          {
            title: "Walk Behind Roller",
            url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Walk Behind Roller",
          },
        ],
      },
      {
        title: "Dirt Movers",
        url: "?goal=Earth Manipulation&categoryName=Dirt Movers",
        submenu: [
          {
            title: "Backhoe",
            url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Backhoe",
          },
          {
            title: "Crawler Dozer",
            url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Crawler Dozer",
          },
          {
            title: "Excavator",
            url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Excavator",
          },
          {
            title: "Road Grader",
            url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Road Grader",
          },
          {
            title: "Skidsteer",
            url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Skidsteer",
          },
          {
            title: "Tractor",
            url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Tractor",
          },
          {
            title: "Trencher",
            url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Trencher",
          },
          {
            title: "Loaders",
            url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Loaders",
          },
        ],
      },
      {
        title: "Tree Machines",
        url: "?goal=Earth Manipulation&categoryName=Tree Machines",
        submenu: [
          {
            title: "Stump Grinder",
            url: "?goal=Earth Manipulation&categoryName=Tree Machines&subCategoryName=Stump Grinder",
          },
          {
            title: "Chipper",
            url: "?goal=Earth Manipulation&categoryName=Tree Machines&subCategoryName=Chipper",
          },
        ],
      },
    ],
  },
  {
    title: "Lifting People and Material",
    url: "?goal=Lifting People",
    submenu: [
      {
        title: "Manlift",
        url: "?goal=Lifting People and Material&categoryName=Manlift",
        submenu: [
          {
            title: "Atrium Lift",
            url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Atrium Lift",
          },
        ],
      },
      {
        title: "Crane",
        url: "?goal=Lifting People and Material&categoryName=Crane",
        submenu: [
          {
            title: "Carry Deck",
            url: "?goal=Lifting People and Material&categoryName=Crane&subCategoryName=Carry Deck",
          },
          {
            title: "Walk Behind",
            url: "?goal=Lifting People and Material&categoryName=Crane&subCategoryName=Walk Behind",
          },
        ],
      },
      {
        title: "Forklift",
        url: "?goal=Lifting People and Material&categoryName=Forklift",
        submenu: [
          {
            title: "Telehandler - Rotating",
            url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Telehandler - Rotating",
          },
          {
            title: "Industrial",
            url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Industrial",
          },
          {
            title: "Telehandler",
            url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Telehandler",
          },
          {
            title: "Walkie Stacker",
            url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Walkie Stacker",
          },
          {
            title: "Rought Terrain",
            url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Rought Terrain",
          },
          {
            title: "Manual",
            url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Manual",
          },
        ],
      },
      {
        title: "Lift Attachments",
        url: "?goal=Lifting People and Material&categoryName=Lift Attachments",
        submenu: [
          {
            title: "Pipe/Conduit Rack",
            url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Pipe/Conduit Rack",
          },
          {
            title: "Telehandler Forks",
            url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Telehandler Forks",
          },
          {
            title: "Industrial Forks",
            url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Industrial Forks",
          },
          {
            title: "Telehandler Block Forks",
            url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Telehandler Block Forks",
          },
          {
            title: "Industrial Block Forks",
            url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Industrial Block Forks",
          },
          {
            title: "Telehandler Bucket",
            url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Telehandler Bucket",
          },
          {
            title: "Forklift Bucket",
            url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Forklift Bucket",
          },
          {
            title: "Telehandler Boom Extension",
            url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Telehandler Boom Extension",
          },
          {
            title: "Forklift Hook Attachment",
            url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Forklift Hook Attachment",
          },
          {
            title: "Trash Hopper",
            url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Trash Hopper",
          },
          {
            title: "Extension Forks",
            url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Extension Forks",
          },
        ],
      },
      {
        title: "Manlift",
        url: "?goal=Lifting People and Material&categoryName=Manlift",
        submenu: [
          {
            title: "Articulating",
            url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Articulating",
          },
          {
            title: "Straight Boom ",
            url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Straight Boom ",
          },
          {
            title: "Mast Boom ",
            url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Mast Boom ",
          },
          {
            title: "Single Person - Drivable",
            url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Single Person - Drivable",
          },
          {
            title: "Single Person - Manual Placement",
            url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Single Person - Manual Placement",
          },
        ],
      },
      {
        title: "Scissorlift",
        url: "?goal=Lifting People and Material&categoryName=Scissorlift",
        submenu: [
          {
            title: "Electric",
            url: "?goal=Lifting People and Material&categoryName=Scissorlift&subCategoryName=Electric",
          },
          {
            title: "Rough Terrain",
            url: "?goal=Lifting People and Material&categoryName=Scissorlift&subCategoryName=Rough Terrain",
          },
          {
            title: "Warehouse Industrial",
            url: "?goal=Lifting People and Material&categoryName=Scissorlift&subCategoryName=Warehouse Industrial",
          },
        ],
      },
    ],
  },
  {
    title: "Managing Air",
    url: "?goal=Managing Air",
    submenu: [
      {
        title: "Purification",
        url: "?goal=Managing Air&categoryName=Purification",
        submenu: [
          {
            title: "Air Scrubber",
            url: "?goal=Managing Air&categoryName=Purification&subCategoryName=Air Scrubber",
          },
        ],
      },
      {
        title: "Dehumidification",
        url: "?goal=Managing Air&categoryName=Dehumidification",
        submenu: [
          {
            title: "Desiccant Dehumidifier",
            url: "?goal=Managing Air&categoryName=Dehumidification&subCategoryName=Desiccant Dehumidifier",
          },
          {
            title: "Refrigerant Dehumidifier",
            url: "?goal=Managing Air&categoryName=Dehumidification&subCategoryName=Refrigerant Dehumidifier",
          },
        ],
      },
      {
        title: "Moving Air",
        url: "?goal=Managing Air&categoryName=Moving Air",
        submenu: [
          {
            title: "Air Movers",
            url: "?goal=Managing Air&categoryName=Moving Air&subCategoryName=Air Movers",
          },
        ],
      },
      {
        title: "Cooling Air",
        url: "?goal=Managing Air&categoryName=Cooling Air",
        submenu: [
          {
            title: "Air Handler",
            url: "?goal=Managing Air&categoryName=Cooling Air&subCategoryName=Air Handler",
          },
          {
            title: "Air Conditioner",
            url: "?goal=Managing Air&categoryName=Cooling Air&subCategoryName=Air Conditioner",
          },
          {
            title: "Chiller",
            url: "?goal=Managing Air&categoryName=Cooling Air&subCategoryName=Chiller",
          },
          {
            title: "Cooling Tower",
            url: "?goal=Managing Air&categoryName=Cooling Air&subCategoryName=Cooling Tower",
          },
        ],
      },
      {
        title: "Heating Air",
        url: "?goal=Managing Air&categoryName=Heating Air",
        submenu: [
          {
            title: "Direct Fired Heaters",
            url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Direct Fired Heaters",
          },
          {
            title: "Electric Heaters",
            url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Electric Heaters",
          },
          {
            title: "Flameless Heaters",
            url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Flameless Heaters",
          },
          {
            title: "Ground Heaters",
            url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Ground Heaters",
          },
          {
            title: "Indirect Heaters",
            url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Indirect Heaters",
          },
          {
            title: "Steam Heaters",
            url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Steam Heaters",
          },
        ],
      },
    ],
  },
  {
    title: "Power Solutions",
    url: "?goal=Power Solutions",
    submenu: [
      {
        title: "Creating Electricity",
        url: "?goal=Power Solutions&categoryName=Creating Electricity",
        submenu: [
          {
            title: "Gas Generators",
            url: "?goal=Power Solutions&categoryName=Creating Electricity&subCategoryName=Gas Generators",
          },
          {
            title: "Diesel Generators",
            url: "?goal=Power Solutions&categoryName=Creating Electricity&subCategoryName=Diesel Generators",
          },
        ],
      },
      {
        title: "Containment & Tanks",
        url: "?goal=Power Solutions&categoryName=Containment & Tanks",
        submenu: [
          {
            title: "Containment Berm",
            url: "?goal=Power Solutions&categoryName=Containment & Tanks&subCategoryName=Containment Berm",
          },
          {
            title: "Fuel Tanks",
            url: "?goal=Power Solutions&categoryName=Containment & Tanks&subCategoryName=Fuel Tanks",
          },
        ],
      },
    ],
  },
];

function Header({ toggleTheme, isDarkMode, addedtoCart, setAddedtoCart }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cartVal, setCartVal] = useState(0);
  const [notifications, setNotifications] = useState(0);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [suggestionLoading, setSuggestionLoading] = useState(false);

  let navigate = useNavigate();

  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    let cartItems = localStorage.getItem("cartItems") || [];
    if (cartItems.length > 0) cartItems = JSON.parse(cartItems);

    // console.log(cartItems);
    setCartVal(cartItems.length);
    // console.log(cartItems)
    // console.log(userInfo);
    if (userInfo && userInfo.userDetailsId) {
      settings[1] = { name: "Logout", link: "" };
      setIsLoggedIn(true);
    } else {
      settings[1] = { name: "LogIn", link: "login" };
      setIsLoggedIn(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (addedtoCart) {
      let cartItems = localStorage.getItem("cartItems") || [];
      if (cartItems.length > 0) cartItems = JSON.parse(cartItems);

      // console.log(cartItems);
      setCartVal(cartItems.length);
      setAddedtoCart(false);
    }
  }, [addedtoCart]);

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    color: "var(--white-color)",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "350px",
        "&:focus": {
          width: "400px",
        },
      },
    },
  }));

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  //Search
  const inputSearchRef1 = React.useRef();
  const inputSearchRef2 = React.useRef();
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const queryy = inputSearchRef1.current.value;
    if (queryy !== "") {
      navigate(`/shop?search=${queryy}`);
    }
  };

  const [hoverClass, setHoverClass] = useState("");

  const handleLinkClick = () => {
    setHoverClass("no-hover");
  };

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo.userNotification &&
        userInfo.userNotification.notification.length > 0
      ) {
        const countSeenFalse = userInfo.userNotification.notification.filter(
          (item) => item.seen === false
        ).length;
        setNotifications(countSeenFalse);
      }
    }
  }, [userInfo]);

  const dispatch = useDispatch();
  const { searchinfo } = useSelector((state) => state.elasticsearch);

  useEffect(() => {
    if (searchinfo && searchinfo.length > 0) {
      setFilteredSuggestions(searchinfo);
      setSuggestionLoading(false);
    } else {
      setFilteredSuggestions([]);
    }

    // return () => setRses([]);
  }, [searchinfo]);

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [valuee, setValuee] = useState("");
  const [valuee2, setValuee2] = useState("");
  const [excludeHover, setExcludeHover] = useState(false);

  const debouncedFetchSuggestions = debounce((inputQuery) => {
    if (inputQuery.trim() !== "") {
      setSuggestionLoading(true);
      dispatch(getElasticSearch(inputQuery));
    } else {
      setFilteredSuggestions([]); // Clear suggestions if inputQuery is empty
    }
  }, 1500);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedFetchSuggestions(value);
    // dispatch(getElasticSearch(value));
  };

  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setInputValue2(value);
    debouncedFetchSuggestions(value);
    // dispatch(getElasticSearch(value));
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const selectedValue = inputValue.trim();
      setInputValue(selectedValue);
      if (inputSearchRef1.current) {
        inputSearchRef1.current.value = e.target.value;
      }
    }
  };
  const StyledAutocomplete = styled(Autocomplete)`
    .MuiAutocomplete-popupIndicator {
      color: var(
        --grey-color
      ); /* Optional: Customize the popup indicator color */
    }
    .MuiAutocomplete-clearIndicator {
      color: var(
        --grey-color
      ) !important; /* Set color of the clear icon to white */
    }
    .MuiAutocomplete-listbox {
      padding: 0 !important;
      background-color: var(--black-color) !important;
    }
    .MuiAutocomplete-option {
      background-color: var(--black-color) !important;
      color: #fff;
    }
    .Mui-expanded {
      padding: 0 !important; /* Override padding for the expanded component */
    }
  `;

  const { query } = useKBar();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showSearchPopup}
        onClose={() => setShowSearchPopup(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
            style: {
              background: "#fff",
            },
          },
        }}
        style={{ background: "#fff" }}
      >
        <Fade in={showSearchPopup}>
          <div
            style={{
              // display: "flex",
              textAlign: "center",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <Autocomplete
              loading={suggestionLoading}
              value={valuee2}
              onChange={(event, newValue) => {
                // console.log("valuee", valuee, newValue);
                setValuee2(newValue);
                setShowSearchPopup(false);
                if (newValue && newValue.itemName) {
                  if (newValue.isCategory)
                    navigate(`/shop?category=${newValue._id}`);
                  else if (newValue.isSubCategory)
                    navigate(`/shop?subcategory=${newValue._id}`);
                  else navigate(`/shop?search=${newValue.itemName}`);
                }
              }}
              inputValue={inputValue2}
              onInputChange={(event, newInputValue) => {
                // console.log("input valuee", newInputValue);
                inputSearchRef2.current.value = newInputValue;
                setInputValue2(newInputValue);
              }}
              size="small"
              options={filteredSuggestions}
              getOptionLabel={(option) => (option ? option.itemName : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  ref={inputSearchRef2}
                  onChange={(e) => handleInputChange2(e)}
                  label=""
                  margin="normal"
                  variant="outlined"
                  sx={{
                    m: 1,
                    width: { xs: "85%", md: "50%" },
                    // color: "var(--white-color)",
                    // background: "#393d42",
                    borderRadius: "5px",
                    border: "none",
                    // "& input": {
                    //   color: "var(--grey-color)", // Set input text color to grey
                    //   border: "none",
                    //   outline: "none",
                    // },
                    fontFamily: "Helvetica,Arial,sans-serif",
                    fontSize: "1.125rem",
                    "::placeholder": {
                      fontSize: "14px", // Set placeholder font size to 14px
                      fontFamily: "Helvetica, Arial, sans-serif", // Set placeholder font family
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {suggestionLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  placeholder="Search makes, models or keywords"
                />
              )}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.itemName, inputValue, {
                  insideWords: true,
                });
                const parts = parse(option.itemName, matches);

                return (
                  <li
                    {...props}
                    onKeyDown={(e) => handleEnterKeyPress(e)}
                    style={{
                      background: "#fff",
                      color: "var(--grey-color)",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                    <div>
                      {option.isCategory && (
                        <div
                          // onClick={() => {
                          //   if (option.isSubCategory) {
                          //     navigate("/shop?subcategory=" + option._id);
                          //   }
                          // }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Chip
                            size="small"
                            label="Category"
                            sx={{
                              marginRight: ".5rem",
                              fontSize: "12px",
                              background: "var(--grey-color)",
                            }}
                          />
                          <FaExternalLinkAlt
                            // onClick={() => {
                            //   if (option.isCategory) {
                            //     navigate("/shop?category=" + option._id);
                            //   }
                            // }}
                            size={".75em"}
                            color="var(--grey-color)"
                          />
                        </div>
                      )}
                      {option.isSubCategory && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Chip
                            size="small"
                            label="Sub Category"
                            sx={{
                              marginRight: ".5rem",
                              fontSize: "12px",
                              background: "var(--grey-color)",
                            }}
                          />
                          <FaExternalLinkAlt
                            size={".75em"}
                            color="var(--grey-color)"
                          />
                        </div>
                      )}
                    </div>
                  </li>
                );
              }}
              noOptionsText={
                filteredSuggestions.length === 0
                  ? "Please type in to get suggestions..."
                  : null
              }
              sx={{
                "::placeholder": {
                  fontSize: "14px", // Set placeholder font size to 14px
                  fontFamily: "Helvetica, Arial, sans-serif", // Set placeholder font family
                },
              }}
              //   value={inputValue}
            />
          </div>
        </Fade>
      </Modal>
      <AppBar
        position="sticky"
        sx={{
          top: 0,
          marginBottom: 0,
          boxShadow: "none",
          // borderBottom: "1px solid #bbb",
          background: "var(--black-color)",
          padding: { xs: 0, md: "inherit" },
        }}
        className={`${excludeHover ? "no-slide-hover" : ""}`}
      >
        <Container
          maxWidth="xl"
          sx={{
            paddingLeft: { xs: "5px", md: "16px" },
            paddingRight: { xs: "5px", md: "16px" },
          }}
        >
          <Toolbar
            sx={{
              paddingLeft: { xs: "5px", md: "16px" },
              paddingRight: { xs: "5px", md: "16px" },
            }}
          >
            <Link to={"/"}>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <img
                  alt="godizel-logo"
                  src={GoDizel}
                  style={{
                    maxWidth: "120px",
                    margin: "auto",
                    width: "200px",
                  }}
                ></img>
              </Box>
            </Link>
            {/* XS DISPLAY */}
            <Box
              sx={{ display: { xs: "flex", md: "none" } }}
              onClick={() => setExcludeHover(true)}
            >
              {/* <MenuIcon /> */}
              <MobileHeader />

              <Box
                sx={{ width: "25px", display: "flex", alignItems: "center" }}
              >
                <Link to={"/"} style={{ lineHeight: 0 }}>
                  <img src={GoDIzelShort} width={"100%"} alt="godizel-logo" />
                </Link>
              </Box>
            </Box>
            {/* END XS DISPLAY */}
            <Box
              onMouseOver={() => setExcludeHover(true)}
              onMouseOut={() => setExcludeHover(false)}
              sx={{ display: { xs: "none", md: "flex" }, ml: 2 }}
            >
              <DropdownMenu
                title={"Rent"}
                items={menuItems}
                // tabInd={ind}
                // title={menu.title}
                // items={menu.submenu}
              />
              {/* <DropdownMenu title={"Buy"} items={menuItems} /> */}
            </Box>
            <Box sx={{ display: "flex", flexGrow: 1 }}>&nbsp;</Box>
            <div
              className="elastic-navbar-search"
              onMouseOver={() => setExcludeHover(true)}
              onMouseOut={() => setExcludeHover(false)}
            >
              {/* <HeaderSearch /> */}
              <Searchbar style={{width: 300, background: "var(--grey-color)", border: "none", borderRadius: 8, outline: "none"}} placeholder="Search Equipment..." />

            </div>
            {/* </div> */}

            <div className="elastic-search-icon">
              <FaSearch
                style={{ marginRight: "1rem", cursor: "pointer" }}
                onClick={() => setShowSearchPopup(true)}
              />
            </div>
            <Box
              sx={{
                flexGrow: 0,
                minWidth: "100px",
                margin: "auto",
                marginRight: 0,
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {isLoggedIn && (
                <>
                  <div className="vertical-divider-header"></div>

                  {userInfo && userInfo.role === "admin" ? (
                    <Link to={"/admin/analytics"}>
                      <Button
                        sx={{
                          margin: "0.5rem",
                          padding: 0,
                          color: "#fff",
                          minHeight: "30px",
                          minWidth: "30px",
                        }}
                      >
                        <Settings />
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}
                  {isLoggedIn && (
                    <Link to={"/chat-support"}>
                      <Button
                        sx={{
                          margin: "0.5rem",
                          padding: 0,
                          color: "#fff",
                          minHeight: "30px",
                          minWidth: "30px",
                        }}
                      >
                        <FaMessage fontSize="1.2rem" style={{cursor: "pointer"}}  />
                      </Button>
                    </Link>
                  )}
                  {/* <Link to={"/user/notifications"}>
                    <Button
                      sx={{
                        margin: "0.5rem",
                        padding: 0,
                        color: "#fff",
                        minHeight: "30px",
                        minWidth: "30px",
                      }}
                    >
                      <Badge badgeContent={notifications} color="background">
                        <Notifications />
                      </Badge>
                    </Button>
                  </Link> */}
                </>
              )}

              <Link to={"add-new-item/#/0"}>
                <Button
                  sx={{
                    margin: "0.5rem",
                    padding: 0,
                    color: "#fff",
                    minHeight: "30px",
                    minWidth: "30px",
                  }}
                >
                  <AddCircle sx={{ fontSize: "24px" }} />
                  {!isLoggedIn && (
                    <>
                      <Typography
                        variant="body2"
                        marginLeft={".3rem"}
                        textTransform={"capitalize"}
                        sx={{ display: { xs: "none", md: "inherit" } }}
                      >
                        List Equipment
                      </Typography>
                    </>
                  )}
                </Button>
              </Link>
              <Link to={"checkout"}>
                <Button
                  sx={{
                    margin: ".5rem",
                    padding: 0,
                    color: "#fff",
                    minHeight: "30px",
                    minWidth: "30px",
                  }}
                >
                  <Badge
                    badgeContent={
                      userInfo && userInfo.userProductsInfo
                        ? userInfo.userProductsInfo.cartId.length
                        : cartVal
                    }
                    color="background"
                  >
                    <ShoppingCart color="fff" sx={{ fontSize: "24px" }} />
                  </Badge>
                  {!isLoggedIn && (
                    <Typography
                      variant="body2"
                      marginLeft={".3rem"}
                      textTransform={"capitalize"}
                      sx={{ display: { xs: "none", md: "inherit" } }}
                    >
                      Cart
                    </Typography>
                  )}
                </Button>
              </Link>
              <div className="vertical-divider-header"></div>

              <IconButton
                // onClick={handleOpenUserMenu}
                sx={{
                  cursor: "pointer",
                }}
                className={`header-dropdown ${hoverClass}`}
              >
                <label
                  for="dropdown"
                  style={{ width: "25px", height: "25px" }}
                  class="label"
                >
                  {userInfo &&
                  userInfo.userDetailsId &&
                  userInfo.userDetailsId.userIcon ? (
                    <img
                      alt="user-icon"
                      width={"100%"}
                      style={{
                        borderRadius: "50%",
                        boxShadow:
                          "rgba(153, 153, 153, 0.27) 0px 0px 0.25em, rgba(153, 153, 153, 0.05) 0px 0.25em 1em",
                        background: "var(--grey-color)",
                      }}
                      src={userInfo.userDetailsId.userIcon}
                    />
                  ) : (
                    <AccountCircleIcon
                      sx={{
                        color: "#f1f1f1",
                        cursor: "pointer",
                        fontSize: "26px",
                        "&:hover": {
                          color: "#fff",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        },
                      }}
                    />
                  )}
                </label>

                <div
                  sx={{ margin: 0, padding: 0, width: "100%" }}
                  className="slide"
                >
                  <Dropdown
                    isLoggedIn={isLoggedIn}
                    handleLinkClick={handleLinkClick}
                  />
                </div>
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {/* <Header2 /> */}
    </>
  );
}

const DropdownMenu = ({ title, items }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className="nested-dropdown"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Link to={"/shop"} state={{ tab: title === "Rent" ? "rent" : "buy" }}>
        <button className="nested-dropdown-button">{title}</button>
      </Link>
      {open && <DropdownList items={items} />}
    </div>
  );
};

const DropdownList = ({ items }) => {
  const isScrollBar = items.length > 8;
  const [scrollEnd, setScrollEnd] = useState(false);
  const listRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        console.log(scrollTop + clientHeight, " ", scrollHeight);
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          setScrollEnd(true);
        } else {
          setScrollEnd(false);
        }
      }
    };

    const refCurrent = listRef.current;
    if (refCurrent) {
      refCurrent.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  return (
    <>
      <ul
        ref={listRef}
        className="nested-dropdown-list"
        style={{
          maxHeight: isScrollBar && 420,
          overflowY: isScrollBar && "auto",
        }}
      >
        {items.map((item, index) => (
          <DropdownListItem key={index} item={item} scrollEnd={scrollEnd} />
        ))}
        {isScrollBar && (
          <div
            style={{
              position: "sticky",
              bottom: 0,
              left: 0,
              background: "rgba(255,255,255, 0.25)",
              textAlign: "center",
              width: "100%",
            }}
          >
            {scrollEnd ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        )}
      </ul>
    </>
  );
};

const DropdownListItem = ({ item }) => {
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const hasSubmenu = item.submenu && item.submenu.length > 0;

  return (
    <li
      className="nested-dropdown-item"
      onMouseEnter={() => setSubmenuOpen(true)}
      onMouseLeave={() => setSubmenuOpen(false)}
    >
      <Link
        to={`/shop${item.url}`}
        style={{
          fontWeight: submenuOpen && 700,
          background: submenuOpen && "var(--secondary-color)",
          // color: submenuOpen ? "var(--black-color)" : "var(--white-color)",
        }}
      >
        {item.title}{" "}
        {hasSubmenu && (
          <FaChevronRight
            size={".85em"}
            color={
              submenuOpen ? "var(--white-color)" : "var(--secondary-color)"
            }
          />
        )}
        {/* </div> */}
      </Link>
      {hasSubmenu && submenuOpen && <DropdownList items={item.submenu} />}
    </li>
  );
};

export default Header;
