import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TabsSorter from "../TabsSorter/TabsSorter";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  resetNotification,
} from "../StateSlices/notificationSlice";
import {
  calculateDateDifference,
  sortDataPurchaseOrder,
  toastOption,
} from "../../utils/utils";
import ProductCard from "../ProductCard/ProductCard";
import {
  cancelOrder,
  changeOrderStatus,
  extendOrder,
  resetCancel,
} from "../StateSlices/orderCancelSlice";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import { LoadingButton } from "@mui/lab";
import { Close, InfoOutlined, Save } from "@mui/icons-material";
import swal from "sweetalert";
import "rsuite/dist/rsuite-no-reset.min.css";
import { DateRangePicker } from "rsuite";
import { FaArrowRotateLeft } from "react-icons/fa6";
import noitem from "../../assets/no-item.png";
import { Link } from "react-router-dom";
import "../Notifications/Notifications.css";
import NoItemImage from "../../assets/no-item.png";
import { FiExternalLink } from "react-icons/fi";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const MyTransactionsNew = () => {
  const [spinner, setSpinner] = useState(false);
  const [sortType, setSortType] = useState("newest");
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [orderExtension, setOrderExtension] = useState("");
  const [rentalExtReqModal, setRentalExtReqModal] = useState(false);
  const [contactUsReqModal, setContactUsReqModal] = useState(false);
  const [equipTypeFilter, setEquipTypeFilter] = useState([]);
  const [orderTypeFilter, setOrderTypeFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [dateRangeFilter, setDateRangeFilter] = useState("");
  const rentalExtMsgRef = useRef();
  const contactUsRef = useRef();
  const [checkboxStatus, setCheckboxStatus] = useState([]);
  const [isPriceInfoOpen, setIsPriceInfoOpen] = useState(null);
  const [isPriceStats, setIsPriceStats] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusChangeInfo, setStatusChangeInfo] = useState(null);

  const dispatch = useDispatch();

  const { status, userInfo } = useSelector((state) => state.user);
  const { notificationMessage, notificationInfo } = useSelector(
    (state) => state.notification
  );
  const { cancelStatus, cancelMessage } = useSelector((state) => state.cancel);

  useEffect(() => {
    if (userInfo) {
      let token = localStorage.getItem("inktoken");
      dispatch(getNotification({ token, filter: "all" }));
      setSpinner(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (notificationInfo) {
      setData(notificationInfo);
      let filteredData = filterProducts(notificationInfo);
      console.log("noti", notificationInfo, filteredData);
      setFilterData(filteredData);
      dispatch(resetNotification());
      setSpinner(false);
    }
  }, [notificationInfo]);

  useEffect(() => {
    setSpinner(true);
    let filteredData = filterProducts(data);
    const sortedData = sortDataPurchaseOrders(filteredData, sortType, "both");
    if (sortedData) {
      // setData(sortedData);
      setFilterData(sortedData);
      setTimeout(() => {
        setSpinner(false);
      }, 2000);
    }
  }, [sortType]);

  const sortDataPurchaseOrders = (products, sort, type) => {
    if (products) {
      let sortedProducts = products;
      if (products.length > 1) {
        if (sort && sort === "recent") {
          const twoDaysAgo = new Date();
          twoDaysAgo.setDate(twoDaysAgo.getDate() - 5);
          sortedProducts = sortedProducts.filter(
            (p) => new Date(p.createdAt) >= twoDaysAgo
          );
        } else {
          sortedProducts = products.slice().sort((a, b) => {
            switch (sort) {
              case "newest":
                return new Date(b.createdAt) - new Date(a.createdAt);
              case "oldest":
                return new Date(a.createdAt) - new Date(b.createdAt);
              case "recent":
                const now = new Date();
                const twoDaysAgo = now.setDate(now.getDate() - 2);
                const isRecentA = new Date(a.createdAt) >= new Date(twoDaysAgo);
                const isRecentB = new Date(b.createdAt) >= new Date(twoDaysAgo);
                if (isRecentA && !isRecentB) return -1;
                if (!isRecentA && isRecentB) return 1;
                return 0;
              case "price":
                return a.pricePaid - b.pricePaid;
              case "price-desc":
                return b.pricePaid - a.pricePaid;
              default:
                return 0; // No sorting
            }
          });
        }
      }
      return sortedProducts;
    }
  };

  const handleOrderStatus = (status, id, productId) => {
    let token = localStorage.getItem("inktoken");
    let userStatus;
    let orderDetail = data.find((dt) => dt.order._id == id);
    console.log("ORDERSTATUSCHANGE", orderDetail, id, data);
    if (orderDetail && orderDetail.order.userId == userInfo._id) {
      userStatus = "buyer";
    } else {
      userStatus = "seller";
    }
    dispatch(
      changeOrderStatus({
        token,
        checkoutData: { id, productId, status, user: userStatus },
      })
    );
    setData([]);
    setFilterData([]);
    setSpinner(true);
  };

  useEffect(() => {
    if (cancelMessage == "Order Status Change") {
      toast.success("Order Status Updated successfully!", toastOption);
      dispatch(resetCancel());
      let token = localStorage.getItem("inktoken");
      dispatch(getNotification({ token, filter: "all" }));
    } else if (cancelMessage == "Order Extend") {
      toast.success("Order Extend Request Sent Successfully!", toastOption);
      dispatch(resetCancel());
      setRentalExtReqModal(false);
      rentalExtMsgRef.current.value = "";
      setOrderExtension("");
      setSpinner(false);
    } else if (cancelMessage == "Support Extend") {
      toast.success(
        "Order Support Request Extended Successfully!",
        toastOption
      );
      dispatch(resetCancel());
      setRentalExtReqModal(false);
      setContactUsReqModal(false);
      contactUsRef.current.value = "";
      setOrderExtension("");
      setSpinner(false);
    } else if (cancelMessage) {
      dispatch(
        getNotification({
          token: localStorage.getItem("inktoken"),
          filter: "all",
        })
      );
      toast.success("Order cancelled successfully!", toastOption);
      dispatch(resetCancel());
      // setSpinner(false);
    }
  }, [cancelMessage]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRentalExtRequest = () => {
    if (!rentalExtMsgRef.current.value) {
      toast.error("Please write the Reason for Extension", toastOption);
      return false;
    }
    let token = localStorage.getItem("inktoken");
    dispatch(
      extendOrder({
        extendData: {
          orderId: orderExtension,
          message: rentalExtMsgRef.current.value,
        },
        token,
      })
    );
  };

  const handleContactUs = () => {
    if (!contactUsRef.current.value) {
      toast.error("Please write the Support Reason", toastOption);
      return false;
    }
    let token = localStorage.getItem("inktoken");
    dispatch(
      extendOrder({
        extendData: {
          orderId: "support",
          message: contactUsRef.current.value,
        },
        token,
      })
    );
  };

  const handleContactUsMessage = () => {
    if (!contactUsRef.current.value) {
      toast.error("Please write a message to send", toastOption);
      return false;
    }
    let token = localStorage.getItem("inktoken");
    // dispatch(
    //   extendOrder({
    //     extendData: {
    //       orderId: orderExtension,
    //       message: rentalExtMsgRef.current.value,
    //     },
    //     token,
    //   })
    // );
  };

  const isLessThan72Hours = (endDateStr) => {
    const rentalEndDate = new Date(endDateStr);
    const now = new Date();

    const timeDifference = rentalEndDate - now;
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    console.log(hoursDifference);
    return hoursDifference > 72;
  };

  const handleCancelDispatch = (orderId, productId) => {
    swal({
      title: "Cancel Order?",
      text: "Once Cancelled, Order cannot be reactivated",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      if (willReactive) {
        let token = localStorage.getItem("inktoken");
        if (token) {
          dispatch(
            cancelOrder({ token, checkoutData: { orderId, productId } })
          );
          setSpinner(true);
        }
      }
    });
  };
  const { beforeToday } = DateRangePicker;

  const filterProducts = (orderData) => {
    let filteredData = orderData;

    let equipTypeFilter = [];
    let orderTypeFilter = [];

    if (checkboxStatus.length > 0) {
      checkboxStatus.forEach((type) => {
        if (type === "bought") {
          if (!orderTypeFilter.includes("buy")) orderTypeFilter.push("buy");
          if (!equipTypeFilter.includes("bought"))
            equipTypeFilter.push("bought");
        } else if (type === "rentedByMe") {
          if (!orderTypeFilter.includes("rent")) orderTypeFilter.push("rent");
          if (!equipTypeFilter.includes("rentedByMe"))
            equipTypeFilter.push("rentedByMe");
        } else if (type === "sold") {
          if (!orderTypeFilter.includes("sold")) orderTypeFilter.push("sold");
          if (!equipTypeFilter.includes("sold")) equipTypeFilter.push("sold");
        } else if (type === "rentedToOthers") {
          if (!orderTypeFilter.includes("rent")) orderTypeFilter.push("rent");
          if (!equipTypeFilter.includes("rentedToOthers"))
            equipTypeFilter.push("rentedToOthers");
        }
      });
    }

    if (equipTypeFilter.length > 0) {
      filteredData = filteredData.filter((dt) => {
        const product = dt.order.products.find(
          (pd) => pd.productId === dt.productId
        );

        if (product) {
          if (
            equipTypeFilter.includes("bought") &&
            product.type === "buy" &&
            dt.order.userId === userInfo._id
          ) {
            return true;
          }
          if (
            equipTypeFilter.includes("rentedByMe") &&
            product.type === "rent" &&
            dt.order.userId === userInfo._id
          ) {
            return true;
          }
          if (
            equipTypeFilter.includes("sold") &&
            product.type === "buy" &&
            dt.order.userId !== userInfo._id
          ) {
            return true;
          }
          if (
            equipTypeFilter.includes("rentedToOthers") &&
            product.type === "rent" &&
            dt.order.userId !== userInfo._id
          ) {
            return true;
          }
        }
        return false;
      });
    }

    if (statusFilter) {
      filteredData = filteredData.filter((dt) => {
        if (statusFilter === "pending") {
          return dt.order.userId == userInfo._id
            ? dt.order.products.find((pd) => pd.productId === dt.productId)
                .buyerStatus
              ? dt.order.products.find((pd) => pd.productId === dt.productId)
                  .buyerStatus == "pending"
              : true
            : dt.order.products.find((pd) => pd.productId === dt.productId)
                .sellerStatus
            ? dt.order.products.find((pd) => pd.productId === dt.productId)
                .sellerStatus == "pending"
            : true;
        } else if (statusFilter === "complete") {
          return dt.order.userId == userInfo._id
            ? dt.order.products.find((pd) => pd.productId === dt.productId)
                .buyerStatus == "complete"
            : dt.order.products.find((pd) => pd.productId === dt.productId)
                .sellerStatus == "complete";
        } else {
          return dt.order.userId == userInfo._id
            ? dt.order.products.find((pd) => pd.productId === dt.productId)
                .buyerStatus == "incomplete"
            : dt.order.products.find((pd) => pd.productId === dt.productId)
                .sellerStatus == "incomplete";
        }
      });
    }

    if (dateRangeFilter.length > 0) {
      filteredData = filteredData.filter((dt) => {
        let rentalDateRange = dt.order.products.find(
          (pd) => pd.productId === dt.productId
        ).rentalDateRange;

        if (rentalDateRange.length > 0) {
          let startDate = new Date(rentalDateRange[0]);
          let endDate = new Date(rentalDateRange[1]);
          let dateRange = new Date(dateRangeFilter[0]);
          let dateRangeEnd = new Date(dateRangeFilter[1]);
          if (startDate >= dateRange && endDate <= dateRangeEnd) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    }

    return filteredData;
  };

  useEffect(() => {
    if (
      equipTypeFilter ||
      orderTypeFilter ||
      statusFilter ||
      dateRangeFilter ||
      checkboxStatus.length > 0
    ) {
      setSpinner(true);
      let filteredData = filterProducts(data);
      setFilterData(filteredData);
      setTimeout(() => {
        setSpinner(false);
      }, 1000);
    }
  }, [
    equipTypeFilter,
    orderTypeFilter,
    statusFilter,
    dateRangeFilter,
    checkboxStatus,
  ]);

  const resetFilter = () => {
    setEquipTypeFilter("");
    setOrderTypeFilter("");
    setStatusFilter("");
    setDateRangeFilter([]);
    setCheckboxStatus([]);
  };

  const handlePriceModal = (props) => {
    // debugger;
    setIsPriceStats(props);
    setIsPriceInfoOpen(true);
    console.log(props);
    let productRentalRange = props?.order.products.find(
      (prod) => prod.productId === props.productId
    )?.rentalDateRange;
    setIsPriceStats({
      rentalRangeFrom: productRentalRange
        ? new Date(productRentalRange[0]).toLocaleDateString()
        : "",
      rentalRangeTo: productRentalRange
        ? new Date(productRentalRange[1]).toLocaleDateString()
        : "",
      transportationCost: props?.order?.transportation,
      platformFee: props?.order?.fees,
      maintenanceFee: props?.product?.itemMaintenancePrice,
      cleaningFee: props?.product?.itemCleaningPrice,
      fuelFee: props?.product?.itemFuelPrice,
      rentalSubtotal: props?.order?.products.find(
        (pd) => pd.productId === props.productId
      )?.price,
      tax: props?.order?.tax,
      eventName: props?.eventName,
      prod: props.product,
    });
  };
  debugger;
  return (
    <>
      <Box component={"div"} marginTop={"2rem"}>
        <Box component={"div"} className="tabs-header">
          <Typography variant="h2" className="title mont-title">
            My Transactions
          </Typography>
          <Stack direction={"row"}>
            {((Array.isArray(equipTypeFilter) && equipTypeFilter.length > 0) ||
              (Array.isArray(orderTypeFilter) && orderTypeFilter.length > 0) ||
              (Array.isArray(dateRangeFilter) && dateRangeFilter.length > 0) ||
              (Array.isArray(checkboxStatus) && checkboxStatus.length > 0) ||
              (typeof statusFilter === "string" &&
                statusFilter.trim() !== "")) && (
              <Typography
                display={"flex"}
                alignItems={"center"}
                sx={{ cursor: "pointer" }}
                variant="body2"
                onClick={resetFilter}
              >
                <span style={{ marginRight: ".5rem" }}>Reset</span>{" "}
                <FaArrowRotateLeft />
              </Typography>
            )}

            <TabsSorter setSortType={setSortType} />
            <Button
              size="small"
              onClick={() => setContactUsReqModal(true)}
              //   href="mailto:support@godizel.com"
              variant="contained"
              color="secondary"
            >
              Contact Us
            </Button>
          </Stack>
        </Box>
        <Stack
          width={"100%"}
          margin={"1rem 0"}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={"1rem"}
        >
          <Stack
            width={"100%"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={"1rem"}
          >
            <FormControl
              component="fieldset"
              sx={{ margin: "0 .5rem", minWidth: "120px" }}
            >
              <FormGroup
                sx={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "row",
                  flex: "2",
                  justifyContent: "space-evenly",
                }}
              >
                {/* <FormControlLabel
                  sx={{ flexBasis: "calc(23% - 0.5rem)" }}
                  control={
                    <Checkbox
                      checked={checkboxStatus.includes("bought")}
                      onChange={(e) => {
                        const newFilter = e.target.checked
                          ? [...checkboxStatus, "bought"]
                          : checkboxStatus.filter((type) => type !== "bought");
                        setCheckboxStatus(newFilter);
                      }}
                      name="bought"
                    />
                  }
                  label="Equipment bought by me"
                /> */}
                <FormControlLabel
                  // sx={{ flexBasis: "calc(50% - 0.5rem)" }}
                  control={
                    <Checkbox
                      checked={checkboxStatus.includes("rentedByMe")}
                      onChange={(e) => {
                        const newFilter = e.target.checked
                          ? [...checkboxStatus, "rentedByMe"]
                          : checkboxStatus.filter(
                              (type) => type !== "rentedByMe"
                            );
                        setCheckboxStatus(newFilter);
                        // const newFilter = e.target.checked
                        //   ? [...orderTypeFilter, "buy"]
                        //   : orderTypeFilter.filter((type) => type !== "buy");
                        //   setOrderTypeFilter(newFilter);
                        // const newTypeFilter = e.target.checked
                        //   ? [...equipTypeFilter, "rent"]
                        //   : equipTypeFilter.filter((type) => type !== "rent");
                        //   setEquipTypeFilter(newTypeFilter)
                      }}
                      name="rentedByMe"
                    />
                  }
                  label="Equipment rented by me"
                />
                {/* <FormControlLabel
                  sx={{ flexBasis: "calc(23% - 0.5rem)" }}
                  control={
                    <Checkbox
                      checked={checkboxStatus.includes("sold")}
                      onChange={(e) => {
                        const newFilter = e.target.checked
                          ? [...checkboxStatus, "sold"]
                          : checkboxStatus.filter((type) => type !== "sold");
                        setCheckboxStatus(newFilter);
                        // const newFilter = e.target.checked
                        // ? [...orderTypeFilter, "sold"]
                        // : orderTypeFilter.filter((type) => type !== "sold");
                        // setOrderTypeFilter(newFilter);
                        // const newTypeFilter = e.target.checked
                        //   ? [...equipTypeFilter, "buy"]
                        //   : equipTypeFilter.filter((type) => type !== "buy");
                        //   setEquipTypeFilter(newTypeFilter)
                      }}
                      name="sold"
                    />
                  }
                  label="Equipment sold to others"
                /> */}
                <FormControlLabel
                  // sx={{ flexBasis: "calc(50% - 0.5rem)" }}
                  control={
                    <Checkbox
                      checked={checkboxStatus.includes("rentedToOthers")}
                      onChange={(e) => {
                        const newFilter = e.target.checked
                          ? [...checkboxStatus, "rentedToOthers"]
                          : checkboxStatus.filter(
                              (type) => type !== "rentedToOthers"
                            );
                        setCheckboxStatus(newFilter);
                        //   const newFilter = e.target.checked
                        //   ? [...orderTypeFilter, "sold"]
                        //   : orderTypeFilter.filter((type) => type !== "sold");
                        //   setOrderTypeFilter(newFilter);
                        // const newTypeFilter = e.target.checked
                        //   ? [...equipTypeFilter, "rent"]
                        //   : equipTypeFilter.filter((type) => type !== "rent");
                        //   setEquipTypeFilter(newTypeFilter)
                      }}
                      name="rentedToOthers"
                    />
                  }
                  label="Equipment rented to others"
                />
              </FormGroup>
            </FormControl>
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={"1rem"}
            width={"100%"}
          >
            <FormControl
              size="small"
              margin="0 .5rem"
              sx={{ minWidth: "150px", height: "50px" }}
            >
              <InputLabel id="status-type-filter-label" size="small">
                Status
              </InputLabel>
              <Select
                labelId="status-type-filter-label"
                id="status-type-filter"
                value={statusFilter}
                label="Status"
                sx={{ height: "50px", display: "flex", alignItems: "center" }}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"complete"}>Complete</MenuItem>
                <MenuItem value={"incomplete"}>Incomplete</MenuItem>
              </Select>
            </FormControl>
            <DateRangePicker
              style={{
                width: "100%",
                lineHeight: 2,
                borderColor: "var(--grey-color)",
              }}
              placeholder="Select Date Range : From ~ To"
              disabledDate={beforeToday()}
              onOk={(newValue) => setDateRangeFilter(newValue)}
              onClean={(val) => {
                setDateRangeFilter([]);
              }}
            />
          </Stack>
        </Stack>
        <Divider />
        {spinner ? (
          <Spinner height={"60vh"} />
        ) : data.length == 0 ? (
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            minHeight={"60vh"}
            width={"100%"}
            sx={{
              background: `url(${noitem})`,
              backgroundSize: "cover",
            }}
          >
            <Typography color={"var(--black-color)"} variant="h3">
              No orders found!
            </Typography>
          </Stack>
        ) : (
          <>
            <Box
              component={"div"}
              margin={"1rem 0"}
              className="purchases-item-con"
            >
              {filterData &&
                filterData.length > 0 &&
                filterData.map((dt, ind) => (
                  <Box
                    component={"div"}
                    key={ind}
                    className="notif-card"
                    // gap={2}
                    mt={"1rem"}
                  >
                    <Box className="border-box"></Box>
                    <Box className="notif-card-wrapper">
                      {/* Left Section: Details */}
                      <Box className="notif-details">
                        <Typography
                          variant="h3"
                          my={1}
                          fontWeight={700}
                          display={"flex"}
                          flexWrap={"wrap"}
                          alignItems={"center"}
                        >
                          {dt.eventName === "ordered"
                            ? "Rented Gear"
                            : "Rented Out"}
                          &nbsp;&nbsp;
                          <span style={{ color: "var(--grey-color)" }}>
                            #{dt.orderId}
                          </span>
                          {/* &nbsp;&nbsp;
                          <span>
                            {dt.order.products.find(
                              (pd) => pd.productId === dt.productId
                            ).type &&
                            dt.order.products.find(
                              (pd) => pd.productId === dt.productId
                            ).type === "rent" &&
                            isLessThan72Hours(
                              dt.order.products.find(
                                (pd) => pd.productId === dt.productId
                              ).rentalDateRange[1]
                            ) ? (
                              <Button
                                onClick={() => {
                                  setRentalExtReqModal(true);
                                  setOrderExtension(dt.order._id);
                                }}
                                variant="contained"
                                sx={{
                                  display: "inline-block",
                                  borderBottom: "1px solid var(--white-color)",
                                  color: "var(--white-color)",
                                  background: "transparent",
                                  width: "auto",
                                  borderRadius: 0,
                                  float: "right",
                                  paddingBottom: "2px",
                                  fontSize: "14px",
                                  marginTop: "-8px"
                                }}
                              >
                                Request Rental Extension
                              </Button>
                            ) : (
                              ""
                            )}
                          </span> */}
                        </Typography>
                        <Typography
                          variant="h6"
                          my={1}
                          color={"var(--grey-color)"}
                        >
                          <strong style={{ color: "var(--white-color)" }}>
                            Name:{" "}
                          </strong>
                          {dt.productName}
                        </Typography>
                        <Typography
                          variant="h6"
                          my={1}
                          color={"var(--grey-color)"}
                        >
                          <strong style={{ color: "var(--white-color)" }}>
                            Transaction Date:{" "}
                          </strong>
                          {new Date(dt.createdAt).toLocaleDateString("en-US", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}
                        </Typography>
                        <Typography
                          variant="h6"
                          my={1}
                          color={"var(--grey-color)"}
                        >
                          <strong style={{ color: "var(--white-color)" }}>
                            Period:{" "}
                          </strong>
                          {dt.order.products.find(
                            (pd) => pd.productId === dt.productId
                          ).rentalDateRange?.length > 0
                            ? new Date(
                                dt.order.products.find(
                                  (pd) => pd.productId === dt.productId
                                ).rentalDateRange[0]
                              ).toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }) +
                              " - " +
                              new Date(
                                dt.order.products.find(
                                  (pd) => pd.productId === dt.productId
                                ).rentalDateRange[1]
                              ).toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              })
                            : "N/A"}
                        </Typography>
                        <Typography
                          variant="h6"
                          my={1}
                          color={"var(--grey-color)"}
                        >
                          <strong style={{ color: "var(--white-color)" }}>
                            Price Paid:{" "}
                          </strong>
                          $ {dt.pricePaid.toLocaleString("en-US")}
                          <span
                            onClick={() => {
                              handlePriceModal(dt);
                            }}
                            style={{
                              cursor: "pointer",
                              marginLeft: ".5rem",
                              position: "relative",
                            }}
                          >
                            <InfoOutlined
                              sx={{
                                fontSize: 16,
                                position: "absolute",
                                top: 0,
                                cursor: "pointer",
                              }}
                            />
                          </span>
                        </Typography>
                        <Typography
                          variant="h6"
                          my={1}
                          color={"var(--grey-color)"}
                        >
                          <strong style={{ color: "var(--white-color)" }}>
                            Address:{" "}
                          </strong>
                          {dt.address || "N/A"}
                        </Typography>

                        {dt.eventName === "ordered" && dt.order.status && (
                          <>
                            <Typography
                              variant="h6"
                              my={1}
                              color={"var(--grey-color)"}
                            >
                              <strong style={{ color: "var(--white-color)" }}>
                                Order Status:
                              </strong>{" "}
                              {dt.order.status}
                            </Typography>
                            {(dt.order.status === "pending" ||
                              dt.order.status === "processing") && (
                              <Typography
                                variant="h6"
                                my={1}
                                color={"var(--grey-color)"}
                              >
                                <strong style={{ color: "var(--white-color)" }}>
                                  {dt.order.status === "pending"
                                    ? "Next Step"
                                    : "Info"}
                                  :{" "}
                                </strong>
                                {dt.order.status === "pending"
                                  ? `Please Complete the next step as posted `
                                  : "It can take up to 2-3 days to reflect the order complete status"}{" "}
                                {dt.order.status === "pending" && (
                                  <Link
                                    to={dt.order.next_step}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      textDecoration: "underline",
                                      color: "var(--primary-color)",
                                      fontSize: "1rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    here
                                  </Link>
                                )}
                              </Typography>
                            )}
                            {dt.order.status === "cancelled" && (
                              <Typography
                                variant="h6"
                                my={1}
                                color={"var(--grey-color)"}
                              >
                                <strong style={{ color: "var(--white-color)" }}>
                                  Reason:
                                </strong>{" "}
                                {dt.order.cancel_reason}
                              </Typography>
                            )}
                          </>
                        )}

                        {(dt.eventName !== "ordered" ||
                          dt.order.status === "complete" ||
                          !dt.order.status) && (
                          <>
                            <Typography
                              variant="h6"
                              my={1}
                              color={"var(--grey-color)"}
                            >
                              <strong style={{ color: "var(--white-color)" }}>
                                Company Name:{" "}
                              </strong>
                              {dt.userDetail.companyName || "N/A"}
                            </Typography>
                            <Typography
                              variant="h6"
                              my={1}
                              color={"var(--grey-color)"}
                            >
                              <strong style={{ color: "var(--white-color)" }}>
                                {/* {dt.eventName === "ordered"
                                    ? "Machine Owner"
                                    : "Contact Point"}{" "} */}
                                Contact Phone:{" "}
                              </strong>

                              {dt.eventName === "ordered"
                                ? dt.userDetail.userPhone
                                  ? dt.userDetail.userPhone
                                  : "--"
                                : dt.order.phone
                                ? dt.order.phone
                                : "N/A"}
                            </Typography>
                            <Typography
                              variant="h6"
                              my={1}
                              color={"var(--grey-color)"}
                            >
                              <strong style={{ color: "var(--white-color)" }}>
                                {/* {dt.eventName === "ordered"
                                    ? "Machine Owner"
                                    : "Renter"}{" "} */}
                                Email:{" "}
                              </strong>
                              {dt.contact || "N/A"}
                            </Typography>
                          </>
                        )}
                      </Box>

                      {/* Right Section: Image and Buttons */}
                      <Box className="notif-side">
                        {/* Image */}
                        <Box className="notif-image">
                          <img
                            src={dt?.product?.itemImages[0] || NoItemImage}
                            alt="Product"
                            style={{
                              width: "100%",
                              borderRadius: "5px",
                            }}
                          />
                          <Link
                            target="_blank"
                            to={`/product/${dt.productId}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "var(--white-color)",
                              width: "30px",
                              height: "30px",
                              background: "var(--black-color)",
                              borderRadius: "50%",
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                            }}
                          >
                            <FiExternalLink
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </Link>
                        </Box>

                        {/* Buttons */}
                        <Box className="notif-btns">
                          <Link
                            to={`/order-details/${dt.orderId}/${dt.productId}`}
                          >
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{
                                color: "var(--black-color)",
                                background: "var(--white-color)",
                                my: 0.5,
                                fontSize: "14px",
                                border: "none",
                                "&:hover": { background: "var(--grey-color)" },
                              }}
                            >
                              Order Details
                            </Button>
                          </Link>

                          {(dt.eventName !== "ordered" ||
                            dt.order.status === "complete" ||
                            !dt.order.status) && (
                            <Link
                              to={"/chat-support"}
                              state={{ sellerEmail: dt.contact }}
                            >
                              <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                  color: "var(--black-color)",
                                  fontSize: "14px",
                                  background: "var(--white-color)",
                                  my: 0.5,
                                  border: "none",
                                  "&:hover": {
                                    background: "var(--grey-color)",
                                  },
                                }}
                              >
                                Contact{" "}
                                {dt.eventName === "ordered"
                                  ? "Machine Owner"
                                  : "Renter"}
                              </Button>
                            </Link>
                          )}

                          {(dt.eventName !== "ordered" ||
                            dt.order.status === "complete" ||
                            !dt.order.status) && (
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{
                                color: "var(--black-color)",
                                fontSize: "14px",
                                background: "var(--white-color)",
                                my: 0.5,
                                border: "none",
                                "&:hover": { background: "var(--grey-color)" },
                              }}
                              onClick={() => {
                                setStatusChangeInfo(dt);
                                setShowStatusModal(true);
                              }}
                            >
                              Status :{" "}
                              {dt.order.userId === userInfo._id
                                ? dt.order.products.find(
                                    (pd) => pd.productId === dt.productId
                                  )?.buyerStatus || "pending"
                                : dt.order.products.find(
                                    (pd) => pd.productId === dt.productId
                                  )?.sellerStatus || "pending"}
                            </Button>
                          )}

                          {(dt.eventName !== "ordered" ||
                            dt.order.status !== "cancelled" ||
                            !dt.order.status) && (
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{
                                color: "var(--black-color)",
                                fontSize: "14px",
                                background: "var(--white-color)",
                                my: 0.5,
                                border: "none",
                                "&:hover": { background: "var(--grey-color)" },
                              }}
                              onClick={() =>
                                handleCancelDispatch(dt.order._id, dt.productId)
                              }
                            >
                              Cancel Order
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box
              margin={"auto"}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {/* <Pagination
                count={Math.ceil(products.length / 10)}
                // variant="outlined"
                shape="rounded"
                // variant="text"
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  padding: "1rem",
                  borderRadius: "5px",
                  border: "1px solid var(--primary-color)",
                  marginTop: "1rem",
                }}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBack, next: ArrowForward }}
                    {...item}
                  />
                )}
              /> */}
            </Box>
          </>
        )}
      </Box>

      {/* MODALS */}
      <Modal
        aria-labelledby="rental-extension-request-modal-title"
        aria-describedby="rental-extension-request-modal-description"
        open={rentalExtReqModal}
        onClose={() => {
          setRentalExtReqModal(false);
          rentalExtMsgRef.current.value = "";
          setOrderExtension("");
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 200,
          },
        }}
      >
        <Fade in={rentalExtReqModal}>
          <Box sx={modalStyle} position={"relative"}>
            <Typography
              id="rental-extension-request-modal-title"
              variant="h3"
              className="mont-title"
              marginLeft={0}
            >
              Rental Period Extension Request
            </Typography>
            <Box
              sx={{
                marginTop: "1rem",
                width: 50,
                height: 5,
                background: "var(--secondary-color)",
              }}
            >
              &nbsp;
            </Box>

            <Close
              onClick={() => {
                setRentalExtReqModal(false);
                rentalExtMsgRef.current.value = "";
                setOrderExtension("");
              }}
              sx={{
                position: "absolute",
                right: "2rem",
                top: "2rem",
                cursor: "pointer",
              }}
            />
            <Grid container>
              <Input
                inputRef={rentalExtMsgRef}
                multiline
                rows={5}
                fullWidth
                sx={{ marginTop: "1rem" }}
                type="text"
                color="secondary"
                placeholder=" Enter your message for rental period extension"
                // helperText="E.g. 2 / 5 / 10"
              />

              {cancelStatus === "loading" ? (
                <LoadingButton
                  fullWidth
                  sx={{ marginTop: "1rem" }}
                  loading
                  loadingPosition="end"
                  endIcon={<Save />}
                  variant="outlined"
                  disabled={true}
                >
                  <span>Please Wait</span>
                </LoadingButton>
              ) : (
                <Button
                  fullWidth
                  onClick={handleRentalExtRequest}
                  sx={{ marginTop: "1rem" }}
                  color="secondary"
                  variant="contained"
                >
                  Request
                </Button>
              )}
            </Grid>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="contact-us-extension-request-modal-title"
        aria-describedby="contact-us-extension-request-modal-description"
        open={contactUsReqModal}
        onClose={() => {
          setContactUsReqModal(false);
          contactUsRef.current.value = "";
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 200,
          },
        }}
      >
        <Fade in={contactUsReqModal}>
          <Box sx={modalStyle} position={"relative"}>
            <Typography
              id="contact-us-extension-request-modal-title"
              variant="h3"
              className="mont-title"
              marginLeft={0}
            >
              Write a message to our support team
            </Typography>
            <Box
              sx={{
                marginTop: "1rem",
                width: 50,
                height: 5,
                background: "var(--secondary-color)",
              }}
            >
              &nbsp;
            </Box>

            <Close
              onClick={() => {
                setContactUsReqModal(false);
                contactUsRef.current.value = "";
                setOrderExtension("");
              }}
              sx={{
                position: "absolute",
                right: "2rem",
                top: "2rem",
                cursor: "pointer",
              }}
            />
            <Grid container>
              <Input
                inputRef={contactUsRef}
                multiline
                rows={5}
                fullWidth
                sx={{ marginTop: "1rem" }}
                type="text"
                color="secondary"
                placeholder=" Enter your message for Contacting Support Team"
                // helperText="E.g. 2 / 5 / 10"
              />

              {cancelStatus === "loading" ? (
                <LoadingButton
                  fullWidth
                  sx={{ marginTop: "1rem" }}
                  loading
                  loadingPosition="end"
                  endIcon={<Save />}
                  variant="outlined"
                  disabled={true}
                >
                  <span>Please Wait</span>
                </LoadingButton>
              ) : (
                <Button
                  fullWidth
                  onClick={handleContactUs}
                  sx={{ marginTop: "1rem" }}
                  color="secondary"
                  variant="contained"
                >
                  Send
                </Button>
              )}
            </Grid>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isPriceInfoOpen}
        onClose={() => {
          setIsPriceInfoOpen(false);
          setIsPriceStats(null);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 250,
          },
        }}
      >
        <Fade in={isPriceStats}>
          <Box sx={modalStyle}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className="mont-title"
              component="h2"
            >
              Price Drilldown
            </Typography>
            <Divider sx={{ margin: "1rem 0" }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Rental Range:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {`${isPriceStats?.rentalRangeFrom || "-"} to ${
                    isPriceStats?.rentalRangeTo || "-"
                  }`}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Equipment Total:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {(() => {
                  const rentPeriodInDays = calculateDateDifference(
                    new Date(isPriceStats?.rentalRangeFrom),
                    new Date(isPriceStats?.rentalRangeTo)
                  );
                  const monthlyPrice =
                    parseInt(isPriceStats?.prod?.itemMonthlyPrice) || 0;
                  const weeklyPrice =
                    parseInt(isPriceStats?.prod?.itemWeeklyPrice) || 0;
                  const dailyPrice =
                    parseInt(isPriceStats?.prod?.itemDailyPrice) || 0;

                  let semirent = rentPeriodInDays;

                  let months = 0;
                  let weeks = 0;
                  let days = 0;

                  while (semirent > 0) {
                    if (semirent > 17) {
                      // More than 2.5 weeks
                      months += 1;
                      semirent -= 28;
                    } else if (semirent > 3) {
                      // More than 3 days
                      weeks += 1;
                      semirent -= 7;
                    } else {
                      days += 1 * semirent;
                      semirent = 0;
                    }
                  }

                  return (
                    <>
                      {monthlyPrice && months > 0 ? (
                        <Typography variant="h6">
                          {/* Monthly Price:  */}${monthlyPrice} x {months}{" "}
                          month
                          {months > 1 ? "s" : ""}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {weeklyPrice && weeks > 0 ? (
                        <Typography variant="h6">
                          {/* Weekly Price:  */}${weeklyPrice} x {weeks} week
                          {weeks > 1 ? "s" : ""}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {dailyPrice && days > 0 ? (
                        <Typography variant="h6">
                          {/* Daily Price:  */}${dailyPrice} x {days} day
                          {days > 1 ? "s" : ""}
                        </Typography>
                      ) : (
                        ""
                      )}

                      <Typography variant="h6">
                        = $
                        {dailyPrice * days +
                          weeklyPrice * weeks +
                          monthlyPrice * months}
                        {days > 1 ? "s" : ""}
                      </Typography>
                    </>
                  );
                })()}
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Transportation Cost:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {isPriceStats?.transportationCost
                    ? `$ ${Number(
                        isPriceStats.transportationCost
                      ).toLocaleString("en-US")}`
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Maintenance Fee:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {isPriceStats?.maintenanceFee
                    ? `$ ${Number(isPriceStats.maintenanceFee).toLocaleString(
                        "en-US"
                      )}`
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Cleaning Fee:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {isPriceStats?.cleaningFee
                    ? `$ ${Number(isPriceStats.cleaningFee).toLocaleString(
                        "en-US"
                      )}`
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Tax
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {isPriceStats?.tax
                    ? `$ ${Number(isPriceStats.tax).toLocaleString("en-US")}`
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Platform Fee:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {isPriceStats?.platformFee
                    ? `$ ${Number(isPriceStats.platformFee).toLocaleString(
                        "en-US"
                      )}`
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Rent Subtotal
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {" "}
                  {isPriceStats?.rentalSubtotal
                    ? `$ ${Number(isPriceStats.rentalSubtotal).toLocaleString(
                        "en-US"
                      )}`
                    : "--"}
                </Typography>
              </Grid>

              {isPriceStats?.eventName == "purchase" && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="h6" fontWeight="bold">
                      Amount Earned
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      {Number(isPriceStats?.rentalSubtotal) -
                        Number(isPriceStats?.platformFee)}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="status-change-modal-title"
        aria-describedby="status-change-modal-description"
        open={showStatusModal}
        onClose={() => {
          setShowStatusModal(false);
          setStatusChangeInfo(null);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 250,
          },
        }}
      >
        <Fade in={showStatusModal}>
          <Box sx={modalStyle}>
            {statusChangeInfo ? (
              <>
                <Typography variant="h3" fontWeight={700} marginBottom={"1rem"}>
                  Change status
                </Typography>
                <FormControl fullWidth sx={{ padding: ".5rem" }}>
                  <InputLabel sx={{ padding: ".5rem" }} id="orderStatusField">
                    Status
                  </InputLabel>
                  <Select
                    labelId="orderStatusField"
                    id="demo-simple-select"
                    defaultValue={
                      statusChangeInfo.order.userId === userInfo._id
                        ? statusChangeInfo.order.products.find(
                            (pd) => pd.productId === statusChangeInfo.productId
                          )?.buyerStatus || "pending"
                        : statusChangeInfo.order.products.find(
                            (pd) => pd.productId === statusChangeInfo.productId
                          )?.sellerStatus || "pending"
                    }
                    label="Status"
                    onChange={(e) => {
                      swal({
                        title: "Update Status",
                        text:
                          "Are you sure you want to mark this as " +
                          e.target.value +
                          "?",
                        icon: "warning",
                        buttons: true,
                      }).then((willReactive) => {
                        if (willReactive) {
                          handleOrderStatus(
                            e.target.value,
                            statusChangeInfo.order._id,
                            statusChangeInfo.productId
                          );
                        }
                      });
                    }}
                  >
                    <MenuItem color="secondary" value={"pending"}>
                      Pending
                    </MenuItem>
                    <MenuItem color="secondary" value={"complete"}>
                      Complete
                    </MenuItem>
                    <MenuItem color="secondary" value={"incomplete"}>
                      Incomplete
                    </MenuItem>
                  </Select>
                </FormControl>
              </>
            ) : null}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default MyTransactionsNew;
