import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import BlogBanner from "../components/BlogBanner/BlogBanner";

const Article7 = () => {
//   const [data, setData] = useState(
//     `${process.env.PUBLIC_URL}/assets/rental-terms.pdf`
//   );
  return (
    <>
    <BlogBanner/>
    <Container
      maxWidth="lg"
      sx={{ textAlign: "justify", marginBottom: "5rem" }}
    >
      <Box data-aos="fade-in" data-aos-duration={500}>
        <Typography
          variant="h1"
          textAlign={"left"}
          fontWeight={700}
          component="h1"
          gutterBottom
          marginTop={"2rem"}
        >
          Here’s how Dizel is transforming how we use construction equipment.
        </Typography>
        <>
        <div>
        <p style={{ lineHeight: 1.38, marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
  <strong>Dizel</strong> is changing the way we think about using and owning things, and one of the areas where it's making a big difference is in construction equipment. Traditionally, construction companies or contractors would buy or rent the machinery they needed, like bulldozers, cranes, and excavators. These machines are expensive, and they might not get used all the time. 
  <strong>Dizel</strong> is helping to solve this problem by allowing companies to share equipment with each other, saving money and resources.
</p>
<h3 style={{ lineHeight: 1.38, marginBottom: '4pt', marginTop: '14pt' }} dir="ltr">
  <strong>What Is the Sharing Economy?</strong>
</h3>
<p style={{ lineHeight: 1.38, marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
  The sharing economy, like <strong>Dizel</strong>, is a system where people or companies share resources, usually through online platforms. Instead of owning something outright, like a car or an apartment, people can rent it from someone else when they need it. You might be familiar with services like <strong>Uber</strong> or <strong>Airbnb</strong>, which let you share cars or homes for short periods. The same idea is being applied to construction equipment, with companies sharing machinery instead of buying it.
</p>
<h3 style={{ lineHeight: 1.38, marginBottom: '4pt', marginTop: '14pt' }} dir="ltr">
  <strong>Why Dizel Works for Construction</strong>
</h3>
<p style={{ lineHeight: 1.38, marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
  In the construction industry, equipment is crucial, but it often sits idle when it’s not needed. Buying and maintaining expensive machinery can be a big financial burden for companies, especially smaller ones. Now, thanks to <strong>Dizel</strong>, companies and individuals can rent out their idle equipment to others who need it, or rent equipment they don't own. This method offers greater flexibility and transparency than traditional approaches by creating a marketplace where rental companies of all sizes, as well as individual contractors, can list their equipment.
</p>
<p style={{ lineHeight: 1.38, marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
  A contractor working on a job site might need an excavator for just a few days. Instead of spending a lot of money on a long-term rental or buying one, they can rent it from the best option nearby that has idle equipment. This not only saves money but also reduces waste. Machinery that might otherwise sit unused can now be put to work.
</p>
<h3 style={{ lineHeight: 1.38, marginBottom: '4pt', marginTop: '14pt' }} dir="ltr">
  <strong>Benefits for the Construction Industry</strong>
</h3>
<p style={{ lineHeight: 1.38, marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
  <strong>Dizel</strong> offers many benefits to construction companies. First, it saves money. Instead of buying expensive equipment that they may only use occasionally, companies can rent what they need only when they need it. This reduces the upfront cost of purchasing machinery and lowers maintenance costs, as they don't have to worry about upkeep when they're not using the equipment.
</p>
<p style={{ lineHeight: 1.38, marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
  Another benefit is increased flexibility. Construction projects often face delays or changes in schedules. Having the ability to quickly rent equipment for just the time it’s needed means companies can adapt more easily to unexpected changes. This helps keep projects on track and within budget.
</p>
<p style={{ lineHeight: 1.38, marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
  There are also environmental benefits. The construction industry is responsible for a lot of pollution, and sharing equipment can help reduce this. Fewer machines are needed overall when companies share, which means less energy is used to manufacture new machinery. This helps lower the carbon footprint of the entire industry.
</p>
<h3 style={{ lineHeight: 1.38, marginBottom: '4pt', marginTop: '14pt' }} dir="ltr">
  <strong>Challenges of Dizel in Construction</strong>
</h3>
<p style={{ lineHeight: 1.38, marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
  While <strong>Dizel</strong> is transforming the construction industry, it’s not without challenges. One issue is trust. Contractors need to feel confident that the equipment they rent is well-maintained and will work properly. To combat this, we have integrated a rating system for asset owners and allowed machine owners to have fluid flexibility to instantly control their available assets.
</p>
<p style={{ lineHeight: 1.38, marginBottom: '0pt', marginTop: '0pt' }} dir="ltr">
  Another challenge is managing insurance and liability. If issues arise with rented equipment, it’s crucial to have a clear plan outlining who is responsible for repairs or accidents. Dizel includes this coverage within our service fees to ensure protection. While many rental companies and individuals carry liability and inland marine insurance, we want to prevent any problems stemming from lapses in coverage, exclusions, or policy limits that fall outside of Dizel’s control.
</p>
<h3 style={{ lineHeight: 1.38, marginBottom: '4pt', marginTop: '14pt' }} dir="ltr">
  <strong>Dizel</strong>
</h3>
<p style={{ lineHeight: 1.38, marginBottom: '0pt', marginTop: '0pt' }} dir="ltr">
  Dizel is transforming the construction industry by simplifying and reducing the cost of accessing essential equipment. We provide flexibility, cost savings, and environmental advantages. As our model gains traction in the marketplace, Dizel will continue driving innovation in how construction equipment is shared and utilized, shaping the future of the industry.
</p>


</div>


        </>

      </Box>
    </Container>
    </>
  );
};


export default Article7;
