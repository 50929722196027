import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getUsers,
  resetUpdateStatus,
  unbanUser,
  updateUser,
} from "../../StateSlices/adminSlice";
import Spinner from "../../Spinner/Spinner";
import PopupCellRenderer from "./populCellRender";
import "./Users.css";
import Modal from "react-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { FaPen } from "react-icons/fa6";
import AnalyticsHeader from "../AnalyticsHeader/AnalyticsHeader";
import { Pagination, PaginationItem, Stack } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const toastOption = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#f3a950" : "inherit",
    width: "100%",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#f3a950",
    },
    fontSize: "14px",
    borderRadius: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
    marginTop: "3px",
    color: state.isSelected ? "#fff" : "#000",
    backgroundColor: state.isSelected ? "#f3a950" : "#fff",
    "&:hover": {
      backgroundColor: state.isSelected ? "#f3a950" : "#f3a95089",
      color: "#fff",
    },
  }),
  // placeholder:(provided,state) => ({
  //   ...provided,
  //   textTransform: "capitalize",
  // }),
  singleValue: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
  }),
};

const Users = ({ tab, type }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    allUserStatus,
    allUsersInfo,
    allUserError,
    updateUserStatus,
    totalCount,
  } = useSelector((state) => state.admin);

  const [rowData, setRowData] = useState([]);
  const [hangerCredits, setHangerCredits] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [role, setRole] = useState(null);
  const [userStatus, setUserStatus] = useState("");
  const [currentUserName, setCurrentUserName] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 1
  );
  const [pageChanged, setPageChanged] = useState(false);
  const agGridHeight = window.innerHeight - 150;

  const handlePageChange = (e, value) => {
    if (value !== pageNumber) {
      window.scrollTo(0, 0);
      setPageChanged(true);
      setPageNumber(value);
    }
  };
  const getDate = (params) => {
    const date = new Date(params.value);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };

  const defaultColDef = useMemo(() => ({
    filter: true,
    cellStyle: { fontFamily: "Helvetica, Arial, sans-serif" },
  }));

  const actionButton = (props) => {
    // const dispatch = useDispatch();

    return (
      <span style={{ width: "48px" }}>
        <button
          onClick={() => {
            setCurrentId(props.data._id);
            setIsOpen(true);
            setCurrentUserName(props.data.userFName);
            setCurrentEmail(props.data.userEmail);
            setRole({ label: props.data.role, value: props.data.role });
            setHangerCredits(props.data.referCount ? props.data.referCount : 0);
            setUserStatus(props.data.status);
            console.log("HELLO");
          }}
        >
          <FaPen color="var(--grey-color)" size={"1em"} />
        </button>
      </span>
    );
  };

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    // {
    //   headerName: "Action",
    //   width: "40px",
    //   field: "",
    //   cellRenderer: actionButton,
    // },
    { headerName: "Email", field: "userEmail" },
    { headerName: "First Name", field: "userFName" },
    { headerName: "Last Name", field: "userLName" },
    {
      headerName: "Referred Credit",
      field: "referCount",
      valueFormatter: (params) => (params && params.value ? params.value : 0),
    },
    { headerName: "Phone", field: "userPhone" },
    { headerName: "Company Name", field: "companyName" },
    { headerName: "Company Description", field: "companyDesc" },
    { headerName: "Company Designation", field: "companyDesgn" },
    { headerName: "Address", field: "userAddress" },
    // { headerName: "Profile Status", field: "profileStatus" },
    // { headerName: "Verified", field: "verified" },
    // { headerName: "Status", field: "status" },
    {
      headerName: "Created At",
      field: "createdAt",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Updated At",
      field: "updatedAt",
      valueFormatter: (params) => getDate(params),
    },
    { headerName: "Referred By", field: "referedBy" },

    // { headerName: "Agreement Link", field: "agreement" },
    { headerName: "Role", field: "role" },
    { headerName: "Status", field: "status" },
  ]);

  useEffect(() => {
    let token = localStorage.getItem("inktoken");
    if (token) {
      // console.log(pageNumber);
      if (location.search.slice(-1)) {
        if (
          location.search.slice(location.search.indexOf("=") + 1) - 1 ===
          pageNumber
        ) {
          // console.log("THen this");
          dispatch(
            getUsers({
              token,
              p: location.search.slice(location.search.indexOf("=") + 1) - 1,
              search: location?.state?.searchQuery || "",
              status: tab,
              type,
            })
          );
          window.scrollTo(0, 0);
        } else {
          setPageNumber(
            location.search.slice(location.search.indexOf("=") + 1) - 1
          );
        }
      } else {
        // console.log("This");
        dispatch(
          getUsers({
            token,
            p: pageNumber - 1,
            search: location?.state?.searchQuery || "",
            status: tab,
            type,
          })
        );
        window.scrollTo(0, 0);
      }
    } else {
      navigate("/404", { replace: true });
    }
  }, [pageNumber, location?.search?.slice(-1), tab, type]);

  useEffect(() => {
    if (allUsersInfo) {
      setRowData([...allUsersInfo]);
      setSpinner(false);
    }
  }, [allUsersInfo]);

  const updateDetails = (e) => {
    e.preventDefault();
    if (currentId) {
      let token = localStorage.getItem("inktoken");
      // console.log(hangerCredits);
      if (token) {
        dispatch(
          updateUser({
            token,
            id: currentId,
            payload: {
              role: role.value,
              referCount: hangerCredits,
            },
          })
        );
        setSpinner(true);
      }
      //       } else {
      //         toast.error(
      //           "Please Enter Reason for Hanger Credits Update",
      //           toastOption
      //         );
      //       }
      //     } else {
      //       toast.error("Please Enter Valid Hanger Credits", toastOption);
      //     }
      //   } else {
      //     navigate("/404", { replace: true });
      //   }
      // } else {
      //   toast.error("Something went wrong!! Please Try Again", toastOption);
      //   setIsOpen(false);
      // }
    }
  };

  const deleteUserWar = (id) => {
    swal({
      title: userStatus === "ban" ? "Unban User" : "Delete User",
      text:
        userStatus === "ban"
          ? "Once unbanned, User will be able to log In"
          : "Once Deleted, User will be not able to log In",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("inktoken");
        if (token) {
          if (userStatus === "ban") {
            dispatch(
              unbanUser({
                token,
                id,

                // status: tab,
              })
            );
          } else {
            dispatch(
              deleteUser({
                token,
                id,
                // p: pageNumber,
                // search: userSearchVal,
                // type,
              })
            );
          }
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  if (updateUserStatus === "succeeded") {
    toast.success("User Details Updated Successfully", toastOption);
    dispatch(resetUpdateStatus());
    setCurrentEmail(null);
    // setHangerHistory([]);
    // setEmailEdit(false);
    // setRoleEdit(false);
    // setOriginalEmail(null);
    setCurrentId(null);
    setCurrentUserName(null);
    setHangerCredits(0);
    setRole(null);
    setUserStatus("");
    // setStatus(null);
    setIsOpen(false);
    setSpinner(false);
    // setSubModalIsOpen(false);
  }

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "900px",
            maxHeight: "500px",
            minHeight: "250px",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          {/* <button
                className="btn dark-btn"
                style={{ padding: "4px 9.5px", borderRadius: "2px" }}
                onClick={() => this.setState({ modalIsOpen: false })}
              >
                X
              </button> */}
        </div>

        <div className="terms-modal-con">
          <div className="terms-modal-wrapper">
            <form onSubmit={(e) => updateDetails(e)}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div>
                  <h3>User Details Edit</h3>
                  <hr
                    style={{
                      borderStyle: "solid",
                      borderColor: "var(--secondary-color)",
                      width: "50px",
                      borderWidth: "2px",
                      marginBottom: "10px",
                    }}
                  ></hr>
                </div>
                <div>
                  <button
                    className="btn dark-btn user-submit-details-btn"
                    type="submit"
                    style={{
                      borderRadius: "2px",
                      // display: window.innerWidth < 640 && "none",
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="" className="item_title">
                    User Name
                  </label>
                  <input
                    type="text"
                    id="userName"
                    name="userName"
                    // onChange={(e) => setBrandName(e.target.value)}
                    value={currentUserName}
                    disabled={true}
                    style={{
                      padding: "9.5px",
                      cursor: "not-allowed",
                      background: "#eee",
                      fontSize: "14px",
                    }}
                  />
                </div>
                <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="" className="item_title">
                    User Email
                  </label>
                  <input
                    type="text"
                    id="userEmail"
                    name="userEmail"
                    onChange={(e) => setCurrentEmail(e.target.value)}
                    value={currentEmail}
                    disabled={true}
                    style={{
                      padding: "9.5px",
                      cursor: "not-allowed",
                      background: "#eee",
                      fontSize: "14px",
                    }}
                  />
                </div>
              </div>

              <>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    className=""
                    style={{
                      margin: "15px 10px 15px 0px",
                      display: "flex",
                      flexDirection: "column",
                      flex: 1 / 2,
                    }}
                  >
                    <label htmlFor="" className="item_title">
                      Role
                    </label>
                    <Select
                      options={[
                        { label: "admin", value: "admin" },
                        { label: "user", value: "user" },
                      ]}
                      onChange={(value) => {
                        setRole(value);
                      }}
                      value={role}
                      styles={customStyles}
                      placeholder="Assign Role"
                    ></Select>
                  </div>
                  <div
                    className=""
                    style={{
                      margin: "15px 10px 15px 0px",
                      display: "flex",
                      flexDirection: "column",
                      flex: 1 / 2,
                    }}
                  >
                    <label htmlFor="" className="item_title">
                      Refered Credits
                    </label>
                    <input
                      type="number"
                      id="userEmail"
                      name="userEmail"
                      // onChange={(e) => setBrandName(e.target.value)}
                      onChange={(e) => setHangerCredits(e.target.value)}
                      value={hangerCredits}
                      placeholder="Update Hanger Credits"
                      style={{
                        padding: "9.5px",
                        fontSize: "14px",
                      }}
                    />
                  </div>
                </div>

                <div>
                  <button
                    className="btn dark-btn user-submit-details-btn"
                    type="button"
                    style={{
                      borderRadius: "2px",
                      // display: window.innerWidth < 640 && "none",
                    }}
                    onClick={() => deleteUserWar(currentId)}
                  >
                    {userStatus === "Active" ? "Ban User" : "Unban User"}
                  </button>
                </div>

                {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div
                          className=""
                          style={{
                            margin: "15px 10px 15px 0px",
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                          }}
                        >
                          <label htmlFor="" className="item_title">
                            Reason for Change
                          </label>
                          <input
                            type="text"
                            id="reasonChange"
                            name="reasonChange"
                            // onChange={(e) => setBrandName(e.target.value)}
                            onChange={(e) => setReasonChange(e.target.value)}
                            value={reasonChange}
                            placeholder="Reason for Hanger Credits Update"
                            style={{
                              padding: "9.5px",
                              fontSize: "14px",
                            }}
                          />
                        </div>
                      </div> */}
              </>

              <div
                className="brand_error"
                style={{ color: "red", marginBottom: "10px" }}
              >
                {/* {brandErrorDisplay} */}
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {spinner ? (
        <Spinner />
      ) : (
        <>
          <AnalyticsHeader
            showExportButton={true}
            label="user"
            placeholder="user email"
            searchQuery={location?.state?.searchQuery || ""}
            spinner={spinner}
            setSpinner={setSpinner}
          />
          <div>
            {/* // <div style={{ height: "480px", overflow: "hidden" }}> */}
            <div
              className="ag-theme-material custom-font-class"
              style={{ height: agGridHeight }} //520
            >
              <AgGridReact
                defaultColDef={defaultColDef}
                rowData={rowData}
                columnDefs={colDefs}
                gridOptions={{
                  suppressColumnVirtualisation: true,
                  rowStyle: { scrollBehavior: "auto" },
                }}
                // onCellClicked={(e) => {
                //   console.log(e)
                //   if (e.colDef.field === "referCount") {
                //     setCurrentId(e.data._id)
                //     setIsOpen(true)
                //     setCurrentUserName(e.data.userFName)
                //     setCurrentEmail(e.data.userEmail)
                //     setRole({ label: e.data.role, value: e.data.role })
                //     setHangerCredits(e.data.referCount?e.data.referCount:0)
                //     setUserStatus(e.data.status)
                //     console.log("HELLO")
                //   }
                // }}
              />
              {totalCount && totalCount > 20 && (
                <Stack
                  spacing={2}
                  direction={"row"}
                  justifyContent={"center"}
                  sx={{ margin: "1rem", paddingBottom: "1rem" }}
                >
                  <Pagination
                    count={Math.ceil(totalCount / 20)}
                    // variant="outlined"
                    shape="rounded"
                    // variant="text"
                    page={pageNumber === 0 ? 1 : pageNumber}
                    onChange={handlePageChange}
                    color="secondary"
                    sx={{
                      padding: ".5rem",
                      borderRadius: "5px",
                      border: "1px solid var(--black-color)",
                    }}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: ArrowBack, next: ArrowForward }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Users;
