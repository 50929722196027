import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import dizelLogo from "../../../assets/godizel-brand-2.png";

const Sidebar = ({ isOpen, setCurrentTab, setIsOpen }) => {
  // const [current, setCurrent] = useState("")
  const location = useLocation();

  useEffect(() => {
    if (window.innerWidth < 640) setIsOpen(false);
  }, [location.pathname]);

  return (
    <>
      <div className={isOpen ? "adm-sidebar" : "adm-sidebar collapsed"}>
        <div className="logo">
          <img src={dizelLogo} style={{ width: "80%" }} />
        </div>
        <nav className="navigation">
          <ul>
            <li>
              <Link
                to="analytics"
                className={`nav_link ${
                  location.pathname.indexOf("analytics") > -1
                    ? "active_admin"
                    : ""
                }`}
              >
                Analytics
              </Link>
            </li>
            <li>
              <Link
                to="users"
                className={`nav_link ${
                  location.pathname.indexOf("users") > -1 ? "active_admin" : ""
                }`}
              >
                Users
              </Link>
            </li>
            <li>
              <Link
                to="products"
                className={`nav_link ${
                  location.pathname.indexOf("products") > -1
                    ? "active_admin"
                    : ""
                }`}
              >
                Equipment
              </Link>
            </li>
            <li>
              <Link
                to="orders"
                className={`nav_link ${
                  location.pathname.indexOf("orders") > -1 ? "active_admin" : ""
                }`}
              >
                Orders
              </Link>
            </li>
            <li>
              <Link
                to="referrals"
                className={`nav_link ${
                  location.pathname.indexOf("referrals") > -1
                    ? "active_admin"
                    : ""
                }`}
              >
                Referrals
              </Link>
            </li>
            <li>
              <Link
                to="promos"
                className={`nav_link ${
                  location.pathname.indexOf("promos") > -1 ? "active_admin" : ""
                }`}
              >
                Promo Codes
              </Link>
            </li>
            <hr />
            <li>
              <Link to="/user/equipment" className="nav_link">
                Go Back
              </Link>
            </li>
            {/* <li>
              <a href="#">Settings</a>
            </li> */}
          </ul>
        </nav>
        <div className="user-info">{/* Add user information here */}</div>
      </div>
    </>
  );
};

export default Sidebar;
