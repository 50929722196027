import React, { useEffect, useState } from "react";
import "./LoginForm.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getuserInfo } from "../StateSlices/getuserSlice";
import { Link } from "react-router-dom";
import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  IResolveParams,
} from "reactjs-social-login";

import {
  FacebookLoginButton,
  GoogleLoginButton,
  AmazonLoginButton,
} from "react-social-login-buttons";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {FcGoogle} from "react-icons/fc"
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
// const REDIRECT_URI =
//   'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
const REDIRECT_URI = "http://localhost:3000/login";

const SignupForm = ({
  setTc,
  status,
  formRef,
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  errors,
  setId,
  membership,
  setFieldValue,
  setProvider,
  provider,
  setPEmail,
  setSpinner
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, error, profile } = useSelector((state) => state.user);

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("inktoken");
    if (token) {
      // dispatch(getuserInfo({token:token}))
      // window.scrollTo(0,0)
      // navigate("/");
    } else {
      // navigate("/feed")
      dispatch(getuserInfo({ token: token }));
      window.scrollTo(0, 0);
    }
  }, []);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {

      axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${codeResponse.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setSpinner(true)
                        console.log(res.data);
                        
                        setPEmail(res.data.email);
                        // formikRef.current.setFieldValue("password", "google1234");
                        // formikRef.current.setFieldValue("confirmPassword", "google1234");
                        setProvider("google");
                        
                        // formikRef.current.setErrors({});
                        // formikRef.current.touched({});
                        setTimeout(()=>{
                          handleSubmit();
                        },1000)
                        
                        // submitForm();
                        // console.log(formikRef.current)
                    })
                    .catch((err) => console.log(err));
      console.log('Login Success:', codeResponse);
      // Send code to server to exchange for access token
    },
    onError: (error) => console.log('Login Failed:', error)
});

  useEffect(() => {
    if (userInfo) {
      console.log(profile);
      if (userInfo && userInfo.userDetailsId.verified === false) {
        navigate("/verify-email?error=AccountNotVerified", {
          replace: true,
          state: userInfo.userDetailsId,
        });
      }
      if (userInfo.userDetailsId.profileStatus !== "complete") {
        navigate("/setup-profile");
      }
    }
  }, [userInfo]);

  return (
    <div className="signupform_container">
      <div className="social_signup">
        <Typography
          variant="h3"
          align="center"
          marginBottom={"2rem"}
          fontWeight={700}
          className="mont-title"
        >
          Login
        </Typography>
        {/* <div className="social_btn_container"> */}
        {/* <button className='btn dark-btn google-btn social-btn-width'><i class="fab fa-google"></i>Continue With <strong>Google</strong></button> */}
        {/* <LoginSocialGoogle
          client_id={
            process.env.REACT_APP_GG_APP_ID ||
            "847743167985-8r5scn91q6pbpo2m85jilhmvj3bjog14.apps.googleusercontent.com"
          }
          // onLoginStart={onLoginStart}
          redirect_uri={REDIRECT_URI}
          scope="openid profile email"
          discoveryDocs="claims_supported"
          access_type="offline"
          onResolve={({ provider, data }) => {
            console.log(provider);
            console.log(data);
            setProvider(provider);
            setPEmail(data.email);
            handleSubmit();
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <GoogleLoginButton
            style={{
              minWidth: "90%",
              boxShadow: "none",
              background: "#f1f1f1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "16px",
              margin: "auto",
            }}
            activeStyle={{ background: "#eee" }}
          />
        </LoginSocialGoogle> */}
        <Button onClick={login} sx={{
          
                    width:"100%",
                    background:"#f1f1f1",
                    color:"black",
                    fontWeight:"normal",
                    padding:"10px 0px",
                    display:"flex",
                    gap:"0.5rem"
                  }}><FcGoogle size="1.5em" /> <span>Log In with Google</span></Button>
        {/* <button className='btn dark-btn facebook-btn social-btn-width'><i class="fab fa-facebook-f"></i>Continue With <strong>Facebook</strong></button> */}
        <div style={{ margin: "1rem" }}></div>
        {/* <LoginSocialFacebook
          appId={process.env.REACT_APP_FB_APP_ID || "642917193774283"}
          fieldsProfile={
            "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
          }
          // onLoginStart={onLoginStart}
          // onLogoutSuccess={onLogoutSuccess}
          redirect_uri={REDIRECT_URI}
          onResolve={({ provider, data }) => {
            console.log(provider);
            console.log(data);
            setProvider(provider);
            setPEmail(data.email);
            handleSubmit();
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <FacebookLoginButton
            style={{
              minWidth: "90%",
              boxShadow: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "16px",
              margin: "auto",
            }}
          />
        </LoginSocialFacebook> */}
      </div>
      <div className="email_signup">
        <Typography
          textAlign={"center"}
          textTransform={"uppercase"}
          fontWeight={"700"}
          className="mont-title"
        >
          OR
        </Typography>
        <form onSubmit={handleSubmit}>
          <div className="email_signup_form">
            <TextField
              label="Email Address"
              fullWidth
              margin="normal"
              name="email"
              onChange={handleChange}
              value={values.email}
              required
              InputProps={
                {
                  // startAdornment: (
                  //   <InputAdornment position="start">
                  //     <AccountCircle></AccountCircle>
                  //   </InputAdornment>
                  // ),
                }
              }
              autoComplete="off"
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              margin="normal"
              name="password"
              id="password"
              onChange={handleChange}
              value={values.password}
              required
              autoComplete="off"
              InputProps={{
                // startAdornment: (
                //   <InputAdornment position="start">
                //     <Key></Key>
                //   </InputAdornment>
                // ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              component={"div"}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <FormControlLabel
                control={<Checkbox onChange={(prev) => setTc(!prev)} />}
                label="Remember Me"
              />
              <Link to="/forgotpassword"  underline="hover" style={{ lineHeight: "2.5" }}>
                Forgot Password
              </Link>
            </Box>
            <Button
              disabled={status === "loading" ? true : false}
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                marginTop: "2rem",
                background: "var(--black-color)",
                border: "1px solid transparent",
                "&:hover": {
                  background: "var(--grey-color)",
                  borderColor: "var(--black-color)",
                  color: "var(--black-color)",
                },
              }}
              type="submit"
            >
              {status === "loading" ? "Please Wait" : "Login"}
            </Button>
            <Link to="/signup" underline="hover">
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  marginTop: "1rem",
                  marginBottom: "2rem",
                  color: "var(--black-color)",
                  borderColor: "var(--black-color)",
                  "&:hover": {
                    borderColor: "var(--dark-grey-color)",
                    background: "var(--grey-color)",
                  },
                }}
              >
                {" "}
                Create New Account
              </Button>
            </Link>
            {/* <div className="loginOptionsContainer">
              <h3 className="loginOptions">
                Not a Member?{" "}
                <Link
                  to="../signup"
                  style={{
                    color: "#F26654",
                    borderBottom: "2px solid var(--peel-orange-color)",
                  }}
                >
                  <span
                    style={{
                      color: "#F26654",
                      borderBottom: "2px solid var(--peel-orange-color)",
                    }}
                  >
                    Click here
                  </span>
                </Link>
              </h3>
              <h3 className="loginOptions">
                Forgot your Password?{" "}
                <Link
                  to="/forgotpassword"
                  style={{
                    color: "#F26654",
                    borderBottom: "2px solid var(--peel-orange-color)",
                  }}
                >
                  <span
                    style={{
                      color: "#F26654",
                      borderBottom: "2px solid var(--peel-orange-color)",
                    }}
                  >
                    Click here
                  </span>
                </Link>
              </h3>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupForm;
