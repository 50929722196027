import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckoutPayment from "./CheckoutPayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import InjectedCheckoutSection from "./CheckoutCard";
import { ExpandCircleDownRounded, ExpandMore, InfoOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { creditCards } from "../../pages/AddNewItem/data";
import { useDispatch, useSelector } from "react-redux";
import cardBg from "../../assets/card-bg.jpg";
import { fetchProducts } from "../StateSlices/getCheckoutProductsSlice";
import { DarkToolTip, LightToolTip } from "../../utils/utils";
import { FaInfo, FaInfoCircle } from "react-icons/fa";

export default function CheckoutShipping({
  pointOfContact,
  setPointOfContact,
  contactPhoneNumber,
  setContactPhoneNumber,
  Address,
  setAddress,
  instruction,
  setInstruction,
  State,
  setState,
  zipCode,
  setZipCode,
  city,
  setCity,
  country,
  setCountry,
  tempTotal,
  handleSubmit,
  spinner1,
  setSpinner1,
  tax,
  taxCalculated,
  validateShipping,
  setToken,
  loaderBtnSpinner,
  setCardNumber,
  setCardExpiry,
  setCardCvc,
  cardNumber,
  cardExpiry,
  cardCvc,
  cardDetails,
  setSaveCard,
  setSaveAddress,
  fees,
  discount,
  transportFees,
  promocode,
  setPromocode,
  secret,
  docuSignDoneForRentalProtection,
}) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const { userInfo, error, profile } = useSelector((state) => state.user);
  React.useEffect(() => {
    if (taxCalculated) {
      window.scroll(0, 600);
    }
  }, [taxCalculated]);

  const { cproducts } = useSelector((state) => state.checkoutproducts);
  const [buyProducts, setBuyProducts] = React.useState([]);
  const [rentProducts, setRentProducts] = React.useState([]);
  const [rentEstimates, setRentEstimates] = useState([]);

  const [rentTotal, setRentTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [buyTotal, setBuyTotal] = useState(0);
  const [rentExpand, setRentExpand] = useState(true);

  const dispatch = useDispatch();

  const calculateTotal = () => {
    console.log("idhar raaja", rentEstimates);
    let subTotal =
      rentEstimates &&
      rentEstimates.length > 0 &&
      rentEstimates.reduce((sum, rentObj) => {
        const rentValues = Object.values(rentObj)[0]; // Extracting values from the object
        console.log(rentValues);
        return (
          sum +
            rentValues.totalRentPrice +
            rentValues.incCost || 0
        );
      }, 0);
    console.log("SUBTOTAL", subTotal);
    setRentTotal(subTotal);

    let buyT =
      buyProducts &&
      buyProducts.reduce(
        (total, product) => total + Number(product.itemAmount),
        0
      );
    console.log(buyProducts);
    let buyTotal = buyT;
    setBuyTotal(buyTotal);
    subTotal += buyT;
    setSubTotal(subTotal);

    // const tax = 0.1 * subTotal; // 10% tax (you can adjust this)
    // setTax(0);

    console.log("Tax in shipping ", tax);

    console.log(transportFees, "TRANSPORTFESS");
    console.log(fees, discount, "FEES & DISCOUNT");
    let totalAmountt = subTotal + transportFees;
    if (docuSignDoneForRentalProtection) {
      totalAmountt += 100;
    }
    if (tax && tax.tax_amount_exclusive) {
      totalAmountt += tax.tax_amount_exclusive / 100;
    }
    if (discount > 0) {
      setTotalAmount(totalAmountt + (fees * discount) / 100);
      console.log(totalAmount);
      // setTempTotal(totalAmount + (fees * discount) / 100);
    } else {
      setTotalAmount(totalAmountt + fees);
      console.log(totalAmount);
      // setTempTotal(totalAmount + fees);
    }

    return { subTotal, tax, totalAmount };
  };

  const calculateDays = (start, end, type) => {
    const difference = end - start;
    console.log("diiff", difference, type);
    if (type === "days") {
      return Math.floor(difference / (1000 * 60 * 60 * 24)) + 1;
    }
    if (type === "months") {
      return Math.floor(difference / (1000 * 60 * 60 * 24 * 30.44));
    }
    if (type === "weeks") {
      return Math.floor(difference / (1000 * 60 * 60 * 24 * 7));
    }
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("inktoken");
  //   let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  //   if (userInfo && token && cartItems.length > 0) {
  //     dispatch(
  //       fetchProducts({
  //         token: localStorage.getItem("inktoken"),
  //         userId: userInfo._id,
  //       })
  //     );
  //   }
  // }, []);

  React.useEffect(() => {
    console.log("triggered CProducts", cproducts);
    if (cproducts) {
      console.log("cproducts k2l ", cproducts);
      if (cproducts.length > 0) {
        console.log("CPRODUCTS", cproducts);
        let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        setBuyProducts(
          cproducts.filter(
            (item) =>
              item.itemToSell &&
              cartItems &&
              cartItems.length > 0 &&
              cartItems.find((ct) => ct.id === item._id)?.itemType === "buy"
          )
        );
        setRentProducts(
          cproducts.filter(
            (item) =>
              item.itemForRent &&
              cartItems &&
              cartItems.length > 0 &&
              cartItems.find((ct) => ct.id === item._id)?.itemType === "rent"
          )
        );
        console.log(
          "CPRODUCTS",
          cproducts.filter((item) => item.itemForRent)
        );
      }
    }
  }, [cproducts]);

  useEffect(() => {
    console.log("Triggered userInfo");
    // dispatch(reset());
    if (userInfo) {
      console.log("RELLOD");
      let cart = JSON.parse(localStorage.getItem("cartItems")) || [];
      if (cart.length > 0) {
        calculateTotal();
      } else {
        setBuyProducts([]);
        setRentProducts([]);
      }
    }
  }, [userInfo]);

  const calculateDateDifference = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Calculate the difference in milliseconds
    const timeDifference = endDate - startDate;

    // Convert the time difference to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Calculate months, weeks, and additional days
    // const months = Math.floor(daysDifference / 30);
    // const remainingDays = daysDifference % 30;
    // const weeks = Math.floor(remainingDays / 7);
    // const additionalDays = remainingDays % 7;

    // return { days: additionalDays, weeks, months };
    return daysDifference + 1;
  };

  useEffect(() => {
    console.log("triggered Fees and TransportFees");
    console.log(transportFees, "TRANSPORTFESS");
    calculateTotal();
  }, [fees, transportFees, tax]);

  useEffect(() => {
    console.log(
      "triggered buy products and rent prodcuts",
      buyProducts,
      rentProducts
    );
    calculateTotal();
  }, [buyProducts, rentProducts]);

  useEffect(() => {
    console.log("Checkout Shipping");
    console.log("triggered rent products", rentProducts);
    if (rentProducts.length > 0) {
      let rentalItem = [];
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      if (cartItems && cartItems.length > 0) {
        rentalItem = cartItems.filter((item) => item.itemType === "rent");
      }
      console.log("cart items", cartItems);
      rentProducts.map((item, ind) => {
        const indRentalItem = rentalItem.filter(
          (itemm) => itemm.id === item._id
        );
        if (indRentalItem.length > 0) {
          let rentPeriodInDays = calculateDateDifference(
            indRentalItem[0].checkoutRentalDateRange[0],
            indRentalItem[0].checkoutRentalDateRange[1]
          );
          let totalRentPrice = 0;

          if (rentPeriodInDays > 0) {
            const dailyPrice = parseInt(item.itemDailyPrice) || 0;
            const weeklyPrice = parseInt(item.itemWeeklyPrice) || 0;
            const monthlyPrice = parseInt(item.itemMonthlyPrice) || 0;

            // const weeks = Math.floor(rentPeriodInDays / 7);
            // const remainingDays = rentPeriodInDays % 7;

            let weeks = 0;
            let months = 0;
            let days = 0;

            let semirent = rentPeriodInDays;

            while (semirent > 0) {
              if (semirent > 17) {
                // More than 3 weeks
                months += 1;
                totalRentPrice += monthlyPrice;
                semirent -= 28;
              } else if (semirent > 3) {
                // More than 3 days
                weeks += 1;
                totalRentPrice += weeklyPrice;
                semirent -= 7;
              } else {
                days += 1;
                totalRentPrice += dailyPrice * semirent;
                semirent = 0;
              }
            }

            let incCost = 0;
            if (
              item.rentalInsurancePercentage &&
              item.rentalInsurancePercentage > 0
            ) {
              incCost = parseInt(
                (totalRentPrice * item.rentalInsurancePercentage) / 100
              );
            }

            if (item.itemFuelPrice) {
              totalRentPrice += item.itemFuelPrice;
            }
            if (item.itemMaintenancePrice) {
              const hoursPerDay = 24; // Number of hours in a day
              const baseMaintenancePrice = item.itemMaintenancePrice; // The base maintenance price for 250 hours

              // Calculate the total rental period in hours
              const totalRentPeriodInHours = rentPeriodInDays * hoursPerDay;

              // Calculate the number of 250-hour intervals
              const intervals = Math.ceil(totalRentPeriodInHours / 250);

              // Calculate the total maintenance cost
              const maintenanceCost = intervals * baseMaintenancePrice;

              // Add to the total rent price
              totalRentPrice += maintenanceCost;
              console.log(`Total Maintenance Cost: ${totalRentPeriodInHours}`);
            }
            if (item.itemCleaningPrice) {
              totalRentPrice += item.itemCleaningPrice;
            }

            setRentEstimates((prev) => {
              // Check if [item._id] is already present in the state
              if (prev) {
                const isItemPresent = prev.some((rentEstimate) =>
                  rentEstimate.hasOwnProperty(item._id)
                );

                //console.log("debugger", isItemPresent);

                // If [item._id] is not present, add it
                if (!isItemPresent) {
                  return [
                    ...prev,
                    {
                      [item._id]: {
                        rentalPeriodRange: [
                          indRentalItem[0]?.checkoutRentalDateRange[0],
                          indRentalItem[0]?.checkoutRentalDateRange[1],
                        ],
                        rentPeriodInDays: rentPeriodInDays,
                        totalRentPrice: totalRentPrice,
                        incCost: incCost
                      },
                    },
                  ];
                }

                // If [item._id] is already present, return the unchanged state
                return prev;
              } else {
                // //console.log(
                //   "hello first",
                //   rentPeriodInDays,
                //   totalRentPrice
                // );
                return [
                  ...prev,
                  {
                    [item._id]: {
                      rentalPeriodRange: [
                        indRentalItem[0]?.checkoutRentalDateRange[0],
                        indRentalItem[0]?.checkoutRentalDateRange[1],
                      ],
                      rentPeriodInDays: rentPeriodInDays,
                      totalRentPrice: totalRentPrice,
                      incCost: incCost
                    },
                  },
                ];
              }
            });
          }
        }
      });
    } else {
      // console.log("SDSD")
      setRentEstimates([]);
    }

    // setSpinner(false);
  }, [rentProducts]);

  useEffect(() => {
    console.log("triggered rent estimates", rentEstimates);
    calculateTotal();
  }, [rentEstimates]);

  return (
    <React.Fragment>
      <Container
        maxWidth={"lg"}
        sx={{ margin: "2rem auto", paddingLeft: 0, paddingRight: 0 }}
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <Box component={"div"} sx={{ padding: "0 16px", mb: 4 }}>
          <Divider
            sx={{
              fontSize: "20px",
              fontWeight: "700",
              textTransform: "uppercase",
              my: "1rem",
            }}
            textAlign="left"
          >
            Jobsite Delivery Information
          </Divider>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="pointOfContact"
                name="pointOfContact"
                label="Delivery Point of Contact Name"
                fullWidth
                value={pointOfContact}
                onChange={(e) => setPointOfContact(e.target.value)}
                autoComplete="given-name"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="contactPhoneNumber"
                name="contactPhoneNumber"
                label="Point of Contact Phone"
                fullWidth
                value={contactPhoneNumber}
                onChange={(e) => setContactPhoneNumber(e.target.value)}
                autoComplete="family-name"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="address1"
                name="address1"
                label="Delivery Address"
                fullWidth
                value={Address}
                onChange={(e) => setAddress(e.target.value)}
                autoComplete="shipping address-line1"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="city"
                name="city"
                label="City"
                fullWidth
                value={city}
                onChange={(e) => setCity(e.target.value)}
                autoComplete="shipping address-level2"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="state"
                name="state"
                label="State/Province/Region"
                fullWidth
                value={State}
                onChange={(e) => setState(e.target.value)}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="zip"
                name="zip"
                label="Zip / Postal code"
                fullWidth
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                autoComplete="shipping postal-code"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="country"
                name="country"
                label="Country"
                fullWidth
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                autoComplete="shipping country"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="instruction"
                name="instruction"
                label="Instructions"
                fullWidth
                value={instruction}
                onChange={(e) => setInstruction(e.target.value)}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="saveAddress"
                    value="yes"
                    defaultChecked={
                      userInfo &&
                      userInfo.userDetailsId.addressCheckBox !== undefined
                        ? userInfo.userDetailsId.addressCheckBox
                        : true
                    }
                    onChange={() => setSaveAddress((prev) => !prev)}
                  />
                }
                label="Save address for easy checkout next time"
              />

              {/* <Divider
            sx={{
              fontSize: "20px",
              fontWeight: "700",
              textTransform: "uppercase",
              my: "1rem",
            }}
            textAlign="left"
          >
            Promo Code
          </Divider>
          <Grid item xs={12}>
              <TextField
                required
                id="promocode"
                name="promocode"
                label="Promo Code"
                fullWidth
                value={promocode}
                onChange={(e) => setPromocode(e.target.value)}
                autoComplete="Promo Code"
                variant="standard"
              />
            </Grid> */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <LoadingButton
                  size="small"
                  variant="contained"
                  sx={{
                    background: "var(--secondary-color)",
                    "&: hover": {
                      background: "var(--grey-color)",
                      color: "var(--black-color)",
                    },
                  }}
                  loading={loaderBtnSpinner}
                  loadingPosition="end"
                  onClick={validateShipping}
                  disabled={loaderBtnSpinner}
                >
                  <span style={{ paddingRight: loaderBtnSpinner ? "2rem" : 0 }}>
                    Next
                  </span>
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {taxCalculated && (
          <Accordion
            expanded={taxCalculated}
            defaultExpanded={taxCalculated}
            sx={{ boxShadow: "none" }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header"
              disabled={!taxCalculated}
              expanded={taxCalculated}
              sx={{ height: "50px" }}
            >
              <Box sx={{ width: "100%" }}>
                <Divider
                  sx={{
                    fontSize: "20px",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    my: "1rem",
                  }}
                  textAlign="left"
                >
                  Enter Payment Information
                </Divider>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                padding={"0 1rem"}
                borderLeft={"5px solid var(--secondary-color)"}
                marginBottom={"2rem"}
              >
                <Typography variant="h5" marginRight={"2rem"}>
                  Accepted Payments {totalAmount ? totalAmount > 1000 ? "for over $1000" : "" : ""}
                </Typography>
                <img
                  src="https://help.zazzle.com/hc/article_attachments/360010513393/Logos-01.png"
                  width={"300px"}
                  alt="payment_img"
                />
              </Stack>

              <div className="payment-con">
                <div className="payment-form">
                  {cardDetails && Object.keys(cardDetails).length > 100 ? (
                    <>
                      {/* <div className="checkout-docusign-con">
                <div className="checkout-file-upload-con" style={{margin:"20px 0px 20px 20px"}}>
                  <Typography
                    variant="h4"
                    // textAlign={"center"}
                    // margin={".5rem auto"}
                    fontWeight={700}
                  >
                    Saved Card
                  </Typography>
                  <br/>
                  <div style={{display:"flex",gap:"10px"}}>
                  <Typography
                    variant="h6"
                    // textAlign={"center"}
                    // margin={".5rem auto"}
                    fontWeight={600}
                  >
                    Card Number: 
                  </Typography>
                  <Typography
                  >
                    **** **** **** {cardDetails.last4}
                  </Typography>
                  </div>
                  <div style={{display:"flex",gap:"10px"}}>
                  <Typography
                    variant="h6"
                    // textAlign={"center"}
                    // margin={".5rem auto"}
                    fontWeight={600}
                  >
                    Expiration: 
                  </Typography>
                  <Typography
                  >
                    {cardDetails.exp_month}/{cardDetails.exp_year}
                  </Typography>
                  </div>
                  
                  <div style={{display:"flex",gap:"10px"}}>
                  <Typography
                    variant="h6"
                    // textAlign={"center"}
                    // margin={".5rem auto"}
                    fontWeight={600}
                  >
                    CVV: 
                  </Typography>
                  <Typography
                  >
                    ***
                  </Typography>
                  </div>
                  <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              // onClick={this.handleSubmit}
              onClick={()=>setToken(cardDetails.id)}
              // disabled={activeStep === 2}
              sx={{
                mr: 1,
                background: "var(--secondary-color)",
                "&: hover": {
                  background: "var(--grey-color)",
                  color: "var(--black-color)",
                },
              }}
            >
              Use this card
            </Button>
          </Box>
                </div>
              </div> */}
                      <div style={{ width: "500px", margin: "auto" }}>
                        <div class="credit-card">
                          <div class="credit-card-inner">
                            <div class="front">
                              <img
                                alt="map logo"
                                src={cardBg}
                                style={{ backgroundPosition: "center" }}
                                class="map-img"
                              />
                              <div
                                class="row"
                                style={{ justifyContent: "flex-end" }}
                              >
                                {/* <img
                                  alt="chip logo"
                                  src="https://i.ibb.co/G9pDnYJ/chip.png"
                                  width="60px"
                                /> */}
                                <img
                                  alt="credit-card logo"
                                  src={
                                    creditCards[cardDetails.brand] ||
                                    creditCards["Unknown"]
                                  }
                                  width="60px"
                                />
                              </div>
                              <div class="row card-no">
                                <p>xxxx</p>
                                <p>xxxx</p>
                                <p>xxxx</p>
                                <p>{cardDetails.last4}</p>
                              </div>
                              <div class="row card-holder">
                                {/* <p>CARD HOLDER</p> */}
                                <p>VALID TILL</p>
                              </div>
                              <div class="row name">
                                {/* <p>JOE ALISON</p> */}
                                <p>
                                  {cardDetails.exp_month}/{cardDetails.exp_year}
                                </p>
                              </div>
                            </div>
                            <div class="back">
                              <img
                                alt="map logo"
                                src={cardBg}
                                class="map-img"
                              />
                              <div class="bar"></div>
                              <div class="row card-cvv">
                                {/* <div>
                                  <img
                                    alt="pattern"
                                    src="https://i.ibb.co/S6JG8px/pattern.png"
                                  />
                                </div> */}
                                <p>***</p>
                              </div>
                              <div class="row card-text">
                                <p>
                                  This is a virtual card used only for better
                                  User Interface purpose.
                                </p>
                              </div>
                              <div class="row signature">
                                <p>CUSTOMER SIGNATURE</p>
                                <img
                                  alt="credit card logo"
                                  src={
                                    creditCards[cardDetails.brand] ||
                                    creditCards["Unknown"]
                                  }
                                  width="60px"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <Button
                          variant="contained"
                          fullWidth
                          // onClick={this.handleSubmit}
                          onClick={() => {
                            window.scrollTo(0, 0);
                            setToken(cardDetails.id);
                          }}
                          // disabled={activeStep === 2}
                          sx={{
                            mr: 1,
                            mt: 2,
                            background: "var(--secondary-color)",
                            "&: hover": {
                              background: "var(--grey-color)",
                              color: "var(--black-color)",
                            },
                          }}
                        >
                          Use this card and Place Order
                        </Button> */}
                      </div>
                      {/* <br />
                      <br /> */}
                      <Typography
                        variant="h3"
                        fontWeight={"700"}
                        textAlign={"center"}
                        color={"var(--grey-color)"}
                        my={4}
                      >
                        OR
                      </Typography>
                    </>
                  ) : (
                    ""
                  )}

                  <Elements
                    stripe={stripePromise}
                    options={{ clientSecret: secret }}
                  >
                    <InjectedCheckoutSection
                      handleSubmit={handleSubmit}
                      // submitForm={submitForm}
                      // formRef={formRef}
                      // setId={setId}
                      spinner={spinner1}
                      // errors={errors}
                      setSpinner={setSpinner1}
                      setToken={setToken}
                      setCardNumber={setCardNumber}
                      setCardExpiry={setCardExpiry}
                      setCardCvc={setCardCvc}
                      cardNumber={cardNumber}
                      cardExpiry={cardExpiry}
                      cardCvc={cardCvc}
                      setSaveCard={setSaveCard}
                      checkBoxStatus={
                        userInfo.userDetailsId.cardCheckBox !== undefined
                          ? userInfo.userDetailsId.cardCheckBox
                          : true
                      }
                      clientSecret={secret}
                      // addressDone={addressDone}
                    />
                  </Elements>
                </div>

                <div className="payment-summary">
                  {/* <Typography variant="h6" fontWeight={700}>
                    Base Amount: ${tempTotal}
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    Tax:{" "}
                    {tax
                      ? tax.tax_amount_exclusive
                        ? (tax.tax_amount_exclusive / 100).toFixed(2)
                        : 0
                      : 0}
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    Total Amount: $
                    {tempTotal +
                      (tax.tax_amount_exclusive
                        ? parseFloat(tax.tax_amount_exclusive / 100)
                        : 0)}
                  </Typography>
                  <i style={{ color: "var(--grey-color)", textAlign: "right" }}>
                    Incl. of all taxes
                  </i> */}
                  <Grid item xs={12} sm={4}>
                    <Paper
                      elevation={0}
                      style={{
                        padding: "1rem 2rem",
                        paddingBottom: "2rem",
                        background: "var(--black-color)",
                        color: "var(--white-color)",
                        // minHeight: "100vh",
                        // border: "1px solid #ddd",
                        borderRadius: "10px",
                      }}
                    >
                      <Typography variant="h3" fontWeight={700} margin={"1rem"}>
                        Summary
                      </Typography>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  textTransform: "uppercase",
                                  letterSpacing: 1.5,
                                  color: "var(--grey-color)",

                                  borderBottomColor: "var(--grey-color)",
                                }}
                              >
                                Description
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  textTransform: "uppercase",
                                  letterSpacing: 1.5,
                                  color: "var(--grey-color)",

                                  borderBottomColor: "var(--grey-color)",
                                }}
                              >
                                Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {buyProducts && buyProducts.length > 0 && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    color: "var(--white-color)",
                                    border: "none",
                                    borderBottomColor: "var(--grey-color)",
                                  }}
                                >
                                  Buy Total
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    color: "var(--white-color)",
                                    border: "none",
                                    borderBottomColor: "var(--grey-color)",
                                  }}
                                >
                                  $
                                  {parseFloat(buyTotal).toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                style={{ padding: 0, border: "none" }}
                              >
                                {rentProducts && rentProducts.length > 0 && (
                                  // <>
                                  <Accordion
                                    sx={{
                                      background: "Var(--black-color)",
                                      color: "var(--white-color)",
                                      // width: "152%",
                                      margin: 0,
                                      overflowX: "hidden",
                                    }}
                                    onChange={(e, expanded) =>
                                      setRentExpand(expanded)
                                    }
                                    elevation={0}
                                    expanded={rentExpand}
                                  >
                                    <AccordionSummary
                                      aria-controls="panel1-content"
                                      id="panel1-header"
                                      className="summary-accordion"
                                      sx={{
                                        margin: 0,
                                        padding: 0,
                                        border: "none",
                                        boxShadow: "none",
                                        // width: "159%",
                                        display: "flow",
                                      }}
                                    >
                                      <ExpandCircleDownRounded
                                        sx={{
                                          position: "absolute",
                                          left: 0,
                                          top: 10,
                                          transform: rentExpand
                                            ? "rotate(180deg)"
                                            : "rotate(0)",
                                          fontSize: 14,
                                          color: "var(--grey-color)",
                                        }}
                                      />
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            color: "var(--white-color)",
                                            border: "none",
                                            borderBottomColor:
                                              "var(--grey-color)",
                                            // minWidth: "150px",
                                          }}
                                          align="left"
                                        >
                                          Rent Total
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            color: "var(--white-color)",
                                            border: "none",
                                            borderBottomColor:
                                              "var(--grey-color)",
                                          }}
                                          align="right"
                                        >
                                          $
                                          {parseFloat(rentTotal).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      className="summary-details"
                                      sx={{ padding: 0, fontSize: 12 }}
                                    >
                                      {rentProducts.map((product, ind) => (
                                        <Box
                                          marginBottom={"1rem"}
                                          sx={{
                                            color: "var(--grey-color)",
                                            position: "relative",
                                            // left: "",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              position: "relative",
                                              left: "2rem",
                                            }}
                                          >
                                            <Typography
                                              variant="h6"
                                              fontSize="12px"
                                            >
                                              Equipment:&nbsp;&nbsp;
                                              {product.itemName}
                                            </Typography>
                                            {(() => {
                                              const rentPeriodInDays =
                                                calculateDateDifference(
                                                  new Date(
                                                    rentEstimates &&
                                                      rentEstimates.length >
                                                        0 &&
                                                      rentEstimates[ind] &&
                                                      rentEstimates[ind][
                                                        product._id
                                                      ] &&
                                                      rentEstimates[ind][
                                                        product._id
                                                      ].rentalPeriodRange[0]
                                                  ),
                                                  new Date(
                                                    rentEstimates &&
                                                      rentEstimates.length >
                                                        0 &&
                                                      rentEstimates[ind] &&
                                                      rentEstimates[ind][
                                                        product._id
                                                      ] &&
                                                      rentEstimates[ind][
                                                        product._id
                                                      ].rentalPeriodRange[1]
                                                  )
                                                );
                                              const monthlyPrice =
                                                parseInt(
                                                  product.itemMonthlyPrice
                                                ) || 0;
                                              const weeklyPrice =
                                                parseInt(
                                                  product.itemWeeklyPrice
                                                ) || 0;
                                              const dailyPrice =
                                                parseInt(
                                                  product.itemDailyPrice
                                                ) || 0;

                                              let semirent = rentPeriodInDays;

                                              let months = 0;
                                              let weeks = 0;
                                              let days = 0;

                                              while (semirent > 0) {
                                                if (semirent > 17) {
                                                  // More than 2.5 weeks
                                                  months += 1;
                                                  semirent -= 28;
                                                } else if (semirent > 3) {
                                                  // More than 3 days
                                                  weeks += 1;
                                                  semirent -= 7;
                                                } else {
                                                  days += 1 * semirent;
                                                  semirent = 0;
                                                }
                                              }

                                              return (
                                                <>
                                                  {monthlyPrice &&
                                                  months > 0 ? (
                                                    <Typography
                                                      variant="h6"
                                                      fontSize="12px"
                                                    >
                                                      Monthly Price: $
                                                      {monthlyPrice} x {months}{" "}
                                                      month
                                                      {months > 1 ? "s" : ""}
                                                    </Typography>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {weeklyPrice && weeks > 0 ? (
                                                    <Typography
                                                      variant="h6"
                                                      fontSize="12px"
                                                    >
                                                      Weekly Price: $
                                                      {weeklyPrice} x {weeks}{" "}
                                                      week
                                                      {weeks > 1 ? "s" : ""}
                                                    </Typography>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {dailyPrice && days > 0 ? (
                                                    <Typography
                                                      variant="h6"
                                                      fontSize="12px"
                                                    >
                                                      Daily Price: ${dailyPrice}{" "}
                                                      x {days} day
                                                      {days > 1 ? "s" : ""}
                                                    </Typography>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <Typography
                                                    variant="h6"
                                                    fontSize={"12px"}
                                                  ></Typography>

                                                  <Typography
                                                    variant="h6"
                                                    fontSize={"12px"}
                                                  >
                                                    {
                                                      rentEstimates &&
                                                      rentEstimates.length >
                                                        0 &&
                                                      rentEstimates.find(
                                                        (p) =>
                                                          p[product._id] &&
                                                          p[product._id].incCost
                                                      )
                                                        ? "Insurance: $" +
                                                          rentEstimates.find(
                                                            (p) =>
                                                              p[product._id]
                                                                .incCost
                                                          )[product._id].incCost
                                                        : "-" // Fallback message
                                                    }
                                                  </Typography>
                                                </>
                                              );
                                            })()}

                                            <Typography
                                              variant="h6"
                                              fontSize="12px"
                                            >
                                              Cleaning Fees:&nbsp;&nbsp; $
                                              {product.itemCleaningPrice
                                                ? product.itemCleaningPrice
                                                : 0}
                                            </Typography>
                                            <Typography
                                              variant="h6"
                                              fontSize="12px"
                                            >
                                              Maintenance Fees:&nbsp;&nbsp; $
                                              {product.itemMaintenancePrice
                                                ? Math.ceil(
                                                    (calculateDays(
                                                      new Date(
                                                        rentEstimates &&
                                                          rentEstimates.length >
                                                            0 &&
                                                          rentEstimates[ind] &&
                                                          rentEstimates[ind][
                                                            product._id
                                                          ] &&
                                                          rentEstimates[ind][
                                                            product._id
                                                          ].rentalPeriodRange[0]
                                                      ),
                                                      new Date(
                                                        rentEstimates &&
                                                          rentEstimates.length >
                                                            0 &&
                                                          rentEstimates[ind] &&
                                                          rentEstimates[ind][
                                                            product._id
                                                          ] &&
                                                          rentEstimates[ind][
                                                            product._id
                                                          ].rentalPeriodRange[1]
                                                      ),
                                                      product.itemDailyPrice &&
                                                        product.itemDailyPrice !==
                                                          0
                                                        ? "days"
                                                        : product.itemWeeklyPrice &&
                                                          product.itemWeeklyPrice !==
                                                            0
                                                        ? "weeks"
                                                        : "months"
                                                    ) *
                                                      24) /
                                                      250
                                                  ) *
                                                  product.itemMaintenancePrice
                                                : 0}
                                            </Typography>
                                            <Typography
                                              variant="h6"
                                              fontSize="12px"
                                            >
                                              Fuel Fees:&nbsp;&nbsp; $
                                              {product.itemFuelPrice
                                                ? product.itemFuelPrice
                                                : 0}
                                            </Typography>
                                            <Typography
                                              variant="h6"
                                              fontSize="12px"
                                            >
                                              Rental :&nbsp;&nbsp;
                                              {calculateDays(
                                                new Date(
                                                  rentEstimates &&
                                                    rentEstimates.length > 0 &&
                                                    rentEstimates[ind] &&
                                                    rentEstimates[ind][
                                                      product._id
                                                    ] &&
                                                    rentEstimates[ind][
                                                      product._id
                                                    ].rentalPeriodRange[0]
                                                ),
                                                new Date(
                                                  rentEstimates &&
                                                    rentEstimates.length > 0 &&
                                                    rentEstimates[ind] &&
                                                    rentEstimates[ind][
                                                      product._id
                                                    ] &&
                                                    rentEstimates[ind][
                                                      product._id
                                                    ].rentalPeriodRange[1]
                                                ),
                                                product.itemDailyPrice &&
                                                  product.itemDailyPrice !== 0
                                                  ? "days"
                                                  : product.itemWeeklyPrice &&
                                                    product.itemWeeklyPrice !==
                                                      0
                                                  ? "weeks"
                                                  : "months"
                                              )}{" "}
                                              {product.itemDailyPrice &&
                                              product.itemDailyPrice !== 0
                                                ? "days"
                                                : product.itemWeeklyPrice &&
                                                  product.itemWeeklyPrice !== 0
                                                ? "weeks"
                                                : "months"}{" "}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      ))}
                                    </AccordionDetails>
                                  </Accordion>
                                  // </>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {/* <TableCell
                                sx={{
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderBottomColor: "var(--grey-color)",
                                }}
                              >
                                Subtotal
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderBottomColor: "var(--grey-color)",
                                }}
                                align="right"
                              >
                                ${parseInt(subTotal).toFixed(2)}
                              </TableCell> */}
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderBottomColor: "var(--grey-color)",
                                }}
                              >
                                Transportation
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderBottomColor: "var(--grey-color)",
                                }}
                                align="right"
                              >
                                $
                                {parseFloat(transportFees).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderBottomColor: "var(--grey-color)",
                                }}
                              >
                                Fees
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderBottomColor: "var(--grey-color)",
                                }}
                                align="right"
                              >
                                $
                                {parseFloat(fees).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                            </TableRow>
                            {discount > 0 && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    color: "var(--white-color)",
                                    border: "none",
                                    borderBottomColor: "var(--grey-color)",
                                  }}
                                >
                                  Referral Discount ({discount + "%"})
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "var(--white-color)",
                                    border: "none",
                                    borderBottomColor: "var(--grey-color)",
                                  }}
                                  align="right"
                                >
                                  - $
                                  {parseFloat(
                                    (fees * discount) / 100
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderBottomColor: "var(--grey-color)",
                                }}
                              >
                                Tax {tax.tax_amount_exclusive / 100 == 0 && <LightToolTip placement="bottom" title={tax.tax_breakdown && tax.tax_breakdown.length > 0 && tax.tax_breakdown[0]?.taxability_reason || ""}><span style={{position: "relative"}} >&nbsp;<InfoOutlined fontSize={"8px"} style={{position: "absolute", top: "2px", color: "var(--grey-color)"}} /></span></LightToolTip>}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderBottomColor: "var(--grey-color)",
                                  minWidth: "max-content",
                                }}
                                align="right"
                              >
                                {/* ${parseInt(tax).toFixed(2)} */}$
                                {tax.tax_amount_exclusive
                                  ? parseFloat(
                                      tax.tax_amount_exclusive / 100
                                    ).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : 0}
                              </TableCell>
                            </TableRow>
                            {docuSignDoneForRentalProtection && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    color: "var(--white-color)",
                                    border: "none",
                                    borderBottomColor: "var(--grey-color)",
                                  }}
                                >
                                  Protection Fee
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "var(--white-color)",
                                    border: "none",
                                    borderBottomColor: "var(--grey-color)",
                                    minWidth: "max-content",
                                  }}
                                  align="right"
                                >
                                  {/* ${parseInt(tax).toFixed(2)} */}$ 100
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight: "700",
                                  textTransform: "uppercase",
                                  letterSpacing: 1.5,
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderTop: "1px solid var(--grey-color)",
                                  borderBottom: "none",
                                }}
                              >
                                Total
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: "700",
                                  border: "none",
                                  borderTop: "1px solid var(--grey-color)",
                                  borderBottom: "none",
                                  color: "var(--white-color)",
                                }}
                                align="right"
                              >
                                $
                                {/* {parseFloat(
                                  tempTotal +
                                    (tax.tax_amount_exclusive
                                      ? tax.tax_amount_exclusive / 100
                                      : 0)
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })} */}
                                {parseFloat(totalAmount).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>

                    {/* <Paper
                      elevation={0}
                      style={{
                        padding: "1rem 2rem",
                        paddingBottom: "2rem",
                        background: "var(--black-color)",
                        color: "var(--white-color)",
                        // minHeight: "100vh",
                        // border: "1px solid #ddd",
                        borderRadius: "10px",
                      }}
                    >
                      <Typography variant="h3" fontWeight={700} margin={"1rem"}>
                        Summary
                      </Typography>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  textTransform: "uppercase",
                                  letterSpacing: 1.5,
                                  color: "var(--grey-color)",

                                  borderBottomColor: "var(--grey-color)",
                                }}
                              >
                                Description
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  textTransform: "uppercase",
                                  letterSpacing: 1.5,
                                  color: "var(--grey-color)",

                                  borderBottomColor: "var(--grey-color)",
                                }}
                              >
                                Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {buyProducts && buyProducts.length > 0 && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    color: "var(--white-color)",
                                    border: "none",
                                    borderBottomColor: "var(--grey-color)",
                                  }}
                                >
                                  Buy Total
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    color: "var(--white-color)",
                                    border: "none",
                                    borderBottomColor: "var(--grey-color)",
                                  }}
                                >
                                  ${parseInt(buyTotal).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            )}
                            {rentProducts && rentProducts.length > 0 && (
                              <>
                                <Accordion
                                  sx={{
                                    background: "Var(--black-color)",
                                    color: "var(--white-color)",
                                    width: "159%",
                                    margin: 0,
                                    overflowX: "hidden",
                                  }}
                                  onChange={(e, expanded) =>
                                    setRentExpand(expanded)
                                  }
                                  elevation={0}
                                >
                                  <AccordionSummary
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    className="summary-accordion"
                                    sx={{
                                      margin: 0,
                                      padding: 0,
                                      border: "none",
                                      boxShadow: "none",
                                      // width: "159%",
                                      display: "flow",
                                    }}
                                  >
                                    <ExpandCircleDownRounded
                                      sx={{
                                        position: "absolute",
                                        left: 0,
                                        top: 10,
                                        transform: rentExpand
                                          ? "rotate(180deg)"
                                          : "rotate(0)",
                                        fontSize: 12,
                                        color: "var(--grey-color)",
                                      }}
                                    />
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          color: "var(--white-color)",
                                          border: "none",
                                          borderBottomColor:
                                            "var(--grey-color)",
                                          // minWidth: "150px",
                                        }}
                                        align="left"
                                      >
                                        Rent Total
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          color: "var(--white-color)",
                                          border: "none",
                                          borderBottomColor:
                                            "var(--grey-color)",
                                        }}
                                        align="right"
                                      >
                                        ${parseInt(rentTotal).toFixed(2)}
                                      </TableCell>
                                    </TableRow>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    className="summary-details"
                                    sx={{ padding: 0, fontSize: 12 }}
                                  >
                                    {rentProducts.map((product, ind) => (
                                      <Box
                                        marginBottom={"1rem"}
                                        sx={{
                                          color: "var(--grey-color)",
                                          position: "relative",
                                          // left: "",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            position: "relative",
                                            left: "2rem",
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            fontSize="12px"
                                          >
                                            Equipment:&nbsp;&nbsp;
                                            {product.itemName}
                                          </Typography>
                                          {product.itemDailyPrice &&
                                          product.itemDailyPrice !== 0 ? (
                                            <>
                                              <Typography
                                                variant="h6"
                                                fontSize="12px"
                                              >
                                                Daily Price:&nbsp;&nbsp; $
                                                {product.itemDailyPrice}
                                              </Typography>
                                            </>
                                          ) : product.itemWeeklyPrice &&
                                            product.itemWeeklyPrice != 0 ? (
                                            <>
                                              <Typography
                                                variant="h6"
                                                fontSize="12px"
                                              >
                                                Weekly Price:&nbsp;&nbsp; $
                                                {product.itemWeeklyPrice}
                                              </Typography>
                                            </>
                                          ) : (
                                            <>
                                              <Typography
                                                variant="h6"
                                                fontSize="12px"
                                              >
                                                Monthly Price:&nbsp;&nbsp; $
                                                {product.itemMonthlyPrice}
                                              </Typography>
                                            </>
                                          )}

                                          <Typography
                                            variant="h6"
                                            fontSize="12px"
                                          >
                                            Cleaning Fees:&nbsp;&nbsp; $
                                            {product.itemCleaningPrice
                                              ? product.itemCleaningPrice
                                              : 0}
                                          </Typography>
                                          <Typography
                                            variant="h6"
                                            fontSize="12px"
                                          >
                                            Maintenance Fees:&nbsp;&nbsp; $
                                            {product.itemMaintenancePrice
                                              ? product.itemMaintenancePrice
                                              : 0}
                                          </Typography>
                                          <Typography
                                            variant="h6"
                                            fontSize="12px"
                                          >
                                            Fuel Fees:&nbsp;&nbsp; $
                                            {product.itemFuelPrice
                                              ? product.itemFuelPrice
                                              : 0}
                                          </Typography>
                                          <Typography
                                            variant="h6"
                                            fontSize="12px"
                                          >
                                            Rental :&nbsp;&nbsp;
                                            {calculateDays(
                                              new Date(
                                                rentEstimates &&
                                                  rentEstimates.length > 0 &&
                                                  rentEstimates[ind] &&
                                                  rentEstimates[ind][
                                                    product._id
                                                  ] &&
                                                  rentEstimates[ind][
                                                    product._id
                                                  ].rentalPeriodRange[0]
                                              ),
                                              new Date(
                                                rentEstimates &&
                                                  rentEstimates.length > 0 &&
                                                  rentEstimates[ind] &&
                                                  rentEstimates[ind][
                                                    product._id
                                                  ] &&
                                                  rentEstimates[ind][
                                                    product._id
                                                  ].rentalPeriodRange[1]
                                              ),
                                              product.itemDailyPrice &&
                                                product.itemDailyPrice !== 0
                                                ? "days"
                                                : product.itemWeeklyPrice &&
                                                  product.itemWeeklyPrice !== 0
                                                ? "weeks"
                                                : "months"
                                            )}{" "}
                                            {product.itemDailyPrice &&
                                            product.itemDailyPrice !== 0
                                              ? "days"
                                              : product.itemWeeklyPrice &&
                                                product.itemWeeklyPrice !== 0
                                              ? "weeks"
                                              : "months"}{" "}
                                            &nbsp; x &nbsp;$
                                            {product.itemDailyPrice &&
                                            product.itemDailyPrice !== 0
                                              ? product.itemDailyPrice
                                              : product.itemWeeklyPrice &&
                                                product.itemWeeklyPrice !== 0
                                              ? product.itemWeeklyPrice
                                              : product.itemMonthlyPrice}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    ))}
                                  </AccordionDetails>
                                </Accordion>
                              </>
                            )}
                            <TableRow>
                             
                            </TableRow>
                           
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderBottomColor: "var(--grey-color)",
                                }}
                              >
                                Tax
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderBottomColor: "var(--grey-color)",
                                  minWidth: "max-content",
                                }}
                                align="right"
                              >
                                $
                                {tax.tax_amount_exclusive
                                  ? tax.tax_amount_exclusive / 100
                                  : 0}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight: "700",
                                  textTransform: "uppercase",
                                  letterSpacing: 1.5,
                                  color: "var(--white-color)",
                                  border: "none",
                                  borderTop: "1px solid var(--grey-color)",
                                  borderBottom: "none",
                                }}
                                align="left"
                              >
                                Total
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: "700",
                                  border: "none",
                                  borderTop: "1px solid var(--grey-color)",
                                  borderBottom: "none",
                                  color: "var(--white-color)",
                                  position: "relative",
                                  padding: 0,
                                }}
                                align="right"
                              >
                                $
                                {tempTotal +
                                  (tax.tax_amount_exclusive
                                    ? tax.tax_amount_exclusive / 100
                                    : 0)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper> */}
                  </Grid>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </Container>
    </React.Fragment>
  );
}
