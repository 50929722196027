import React, { useEffect, useRef, useState } from "react";
import "./About.css";
import {
  Box,
  Button,
  Container,
  InputBase,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import aboutDizelVideo from "../../assets/about-dizel.mp4";
import About2 from "../../assets/Pictures/about-2.png";
import About3 from "../../assets/Pictures/about-3.png";
import About4 from "../../assets/Pictures/about-4.png";
import { Link, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { useDispatch } from "react-redux";
const About = () => {
  const [searchVal, setSearchVal] = useState(null);
  const inputSearchRef = useRef();
  const navigate = useNavigate();
  const submitSearch = (e) => {
    e.preventDefault();
    if (searchVal !== "") {
      navigate(`/search/${searchVal}`);
    }
  };

  const submitSearchValue = (e) => {
    e.preventDefault();
    if (inputSearchRef.current.value) {
      // navigate(`/search/${inputSearchRef.current.value}`);
      navigate(`/shop?search=${inputSearchRef.current.value}`);
    }
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      marginTop: "1.8rem",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        "&:focus": {
          width: "100%",
        },
      },
    },
  }));

  useEffect(() => {
    const videoElement = document.getElementById("video-about");
    if (videoElement) {
      videoElement.play();
    }
  }, []);

  return (
    <>
      <div className="abt-sec-1" style={{ width: "100%" }}>
        <Container>
          <div className="about-sec-1-content">
            <Typography variant="h1" margin={"1rem 0"} className="mont-title">
              DIZEL Marketplace Built for Contractors
            </Typography>
            <Typography variant="h3" margin={"1rem 0"}>
              Rent, Buy and List Equipment with Ease
            </Typography>
            <form class="search" onSubmit={(e) => submitSearchValue(e)}>
              <Search>
                <SearchIconWrapper>
                  <SearchRounded />
                </SearchIconWrapper>
                <StyledInputBase
                  inputRef={inputSearchRef}
                  placeholder="Search Equipment…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </form>
          </div>
        </Container>
      </div>
      <Container maxWidth="xl" sx={{margin: "1rem auto"}}>
        <div className="abt-page-con">
          <div className="abt-sec-2">
            <Box className="col text-content" sx={{textAlign: {xs: "center", lg: "left"}}}>
              <Typography
                variant="h1"
                fontWeight={700}
                my={"1rem"}
                className="mont-title"
              >
                DIZEL Equipment Rental{" "}
              </Typography>
              <Typography variant="h5">
                Rent equipment hassle-free. Find, compare, and book your rental
                with just a few clicks. Say goodbye to traditional equipment
                rental and experience the convenience of DIZEL
              </Typography>
            </Box>
            <div className="col">
              <video
                playsInline
                id="video-about"
                autoPlay
                preload="metdata"
                loop={true}
                muted
              >
                <source src={aboutDizelVideo}></source>
              </video>
            </div>
          </div>
          <div className="abt-sec-2">
            <div className="col">
              <img src={About2} width={"350px"} />
            </div>
            <Box component={"div"} className="col text-content" sx={{textAlign: {xs: "center", lg: "right"}}}>
              <Typography
                variant="h1"
                fontWeight={700}
                my={"1rem"}
                className="mont-title"
              >
                Ease of Access
              </Typography>
              <Typography variant="h5">
                Get access to availability and pricing information from
                thousands of rental companies, all from a single pane of glass.
              </Typography>
            </Box>
          </div>
          <div className="abt-sec-2">
            <Box className="col text-content" sx={{textAlign: {xs: "center", lg: "left"}}}>
              <Typography
                variant="h1"
                fontWeight={700}
                my={"1rem"}
                className="mont-title"
              >
                Maximize your equipment usage and efficiency
              </Typography>
              <Typography variant="h5">
                Earn additional rental revenue by putting your idle machines to
                work
              </Typography>
              <Link to={"/add-new-item"}>
                <Button variant="contained" sx={{ margin: "1rem 0" }}>
                  List Your Machines
                </Button>
              </Link>
            </Box>
            <div className="col">
              <img src={About3} width={"350px"} alt="about-img-3" />
            </div>
          </div>
          <div className="abt-sec-2">
            <div className="col">
              <img src={About4} width={"350px"} />
            </div>
            <Box component={"div"} className="col text-content" sx={{textAlign: {xs: "center", lg: "right"}}}>
              <Typography
                variant="h1"
                fontWeight={700}
                my={"1rem"}
                className="mont-title"
              >
                Search Now!
              </Typography>
              <Typography variant="h5">
                Explore wide range of equipment, everything from trailers to
                excavators!
              </Typography>
              <Link to={"/shop"}>
                <Button variant="contained" sx={{ margin: "1rem 0" }}>
                  Explore
                </Button>
              </Link>
              {/* <form class="abt-search" onSubmit={(e) => submitSearch(e)}>
              <div class="search__wrapper">
                <input
                  type="text"
                  name=""
                  placeholder="Search for equipments, tools, etc..."
                  class="search__field"
                  value={searchVal}
                  onChange={(e) => setSearchVal(e.target.value)}
                />
              </div>
            </form> */}
            </Box>
          </div>
        </div>
        
      </Container>
    </>
  );
};

export default About;
