import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import Modal from "react-modal";
import swal from "sweetalert";
import * as Yup from "yup";

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import AnalyticsHeader from "../AnalyticsHeader/AnalyticsHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPen, FaRedo, FaTrash } from "react-icons/fa";
import { Form, Formik } from "formik";
import Spinner from "../../Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalytics
} from "../../StateSlices/adminSlice";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import { subMonths, startOfMonth, endOfMonth } from "date-fns";
import "rsuite/dist/rsuite.min.css";
import { Inventory, Inventory2, People, PersonAdd } from "@mui/icons-material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' ,
    },
    title: {
      display: true,
      text: 'New Users & Equipment Over Time',
    },
  },
};

const Analytics = () => {
  const dispatch = useDispatch();
  const { analyticsInfo, analyticsStatus } = useSelector(
    (state) => state.admin
  );

  const [rowData, setRowData] = useState(null);
  const [chartData, setChartData] = useState(null)
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    new Date(),
  ]);

  const getDate = (params) => {
    const date = new Date(params.value);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };

  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("inktoken");
    if (token) {
      fetchAnalytics(token, dateRange);
    }
  }, [dateRange]);

  const fetchAnalytics = (token, range) => {
    setSpinner(true);
    const [start, end] = range;
    dispatch(getAnalytics({ 
      token, 
      startDate: start.toISOString().split("T")[0], 
      endDate: end.toISOString().split("T")[0] 
    }));
  };

  useEffect(()=>{
    if(analyticsStatus == "succeeded"){
      setSpinner(false);
      console.log(analyticsInfo)
      setRowData(analyticsInfo);
      if (analyticsInfo.newUsersByDate && analyticsInfo.newEquipmentByDate) {
        setChartData({
          labels: analyticsInfo?.newUsersByDate?.length
            ? analyticsInfo.newUsersByDate.map((d) => d._id)
            : ["No Data"],
          datasets: [
            {
              label: "New Users",
              data: analyticsInfo?.newUsersByDate?.length
                ? analyticsInfo.newUsersByDate.map((d) => d.count)
                : [0],
              borderColor: "#f05023",
              backgroundColor: "#f0502300",
              // fill: true,
            },
            {
              label: "New Equipment",
              data: analyticsInfo?.newEquipmentByDate?.length
                ? analyticsInfo.newEquipmentByDate.map((d) => d.count)
                : [0],
              borderColor: "#f3a950",
              backgroundColor: "#f0502300",
              // fill: true,
            },
          ],
        });
        ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
      }
      
    }
  },[analyticsInfo])

  useEffect(() => {
console.log(chartData)
  }, [chartData]);


  const initialValues = {
    promocode: "",
    percentageOff: "",
    expirationDate: "",
    usageNumber:0,
  };

  const validationSchema = Yup.object().shape({
    promocode: Yup.string().required("Promo code is required"),
    percentageOff: Yup.string().required("Percentage off is required"),
    expirationDate: Yup.date()
      .required("Expiration date is required")
      .min(new Date(), "Expiration date must be in the future"),
    usageNumber: Yup.number()
    .required("Usage Times is required")
  });

  return (
    <Container maxWidth="xl" sx={{paddingTop: "1rem"}}>
      <>
        <AnalyticsHeader
          placeholder="Promo Code"
          label={"Analytics"}
          hideSearchBar={true}
        />
        <Box mb={2}>
          <Typography variant="body2" marginBottom={".5rem"} marginLeft={'.2rem'}>Select Date Range</Typography>
          <DateRangePicker
            value={dateRange}
            onChange={(range) => setDateRange(range)}
            format="yyyy-MM-dd"
            block
            disabledDate={(date) => date > new Date()}
            placeholder="Select Date Range"
            cleanable={false}
            
          />
        </Box>
        {spinner ? (
          <Spinner />
        ) : (
          <>
            {rowData && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <StatCard
                      icon={<People sx={{fontSize: "2em"}} />}
                      label="Total Users"
                      value={rowData.totalUsers}
                      color="var(--primary-color)"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatCard
                      icon={<PersonAdd  sx={{fontSize: "2em"}}/>}
                      label="New Users"
                      value={rowData.newUsers}
                      color="var(--secondary-color)"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatCard
                      icon={<Inventory sx={{fontSize: "2em"}} />}
                      label="Total Equipment"
                      value={rowData.totalProducts}
                      color="var(--primary-color)"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatCard
                      icon={<Inventory2 sx={{fontSize: "2em"}} />}
                      label="New Equipment"
                      value={rowData.newProducts}
                      color="var(--secondary-color)"
                    />
                  </Grid>
                </Grid>

                {/* Chart */}
                {chartData && (
                  <Card sx={{ mt: 4, p: 2, width: "100%" }} elevation={0}>
                    {chartData.labels.length > 0 ? (
                      // ""
                      <Line data={chartData} options={options} />
                     
                    ) : (
                      <Typography variant="body1">
                        No data available for the selected range.
                      </Typography>
                    )}
                  </Card>
                )}
              </>
            )}
          </>
        )}
      </>
    </Container>
  );
};

const StatCard = ({ icon, label, value, color }) => (
  <Card sx={{ display: "flex", alignItems: "center", p: 2, background: color, color: "var(--white-color)", borderRadius: 3 }} elevation={0}>
    <CardContent sx={{ flexGrow: 1, textAlign: "left" }}>
      <Typography variant="h5">
        {label}
      </Typography>
      <Typography variant="h1" fontWeight={700}>{value ?? 0}</Typography>
    </CardContent>
    <Typography>
      {icon}
    </Typography>
  </Card>
);

export default Analytics;
