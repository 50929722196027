import { otherLogos } from "../pages/AddNewItem/data";
import dizelLogo from "../assets/godizel-logo.png";
import {
  colors,
  styled,
  Tooltip,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { addHours } from "date-fns";
import { addDays } from "rsuite/esm/utils/dateUtils";

export const getThirdPartyLogo = (link) => {
  console.log("LINK ", link);
  const domainLogoMapping = {
    unitedrentals: otherLogos["unitedRentals"],
    bigrentz: otherLogos["bigRentz"],
    sunbeltrentals: otherLogos["sunBeltRentals"],
    rentalmax: otherLogos["rentalMax"],
    compactpowerrents: otherLogos["comparePowerRents"],
    hercrentals: otherLogos["hercRentals"],
    homedepot: otherLogos["comparePowerRents"],
    equipmentshare: otherLogos["equipmentshare"],
  };
  const parsedUrl = new URL(link);
  const hostname = parsedUrl.hostname;
  const domainParts = hostname.split(".");
  let domainName = "";
  if (domainParts.length > 2) {
    domainName = domainParts.slice(1, -1).join(".");
  } else {
    domainName = domainParts.join(".");
  }
  console.log("domain name", domainName);
  return domainLogoMapping[domainName] || dizelLogo;
};

export const getThirdPartyName = (link) => {
  const nameMappings = {
    unitedrentals: "United Rentals",
    bigrentz: "Big Rentz",
    sunbeltrentals: "Sunbelt Rentals",
    rentalmax: "Rental Max",
    compactpowerrents: "Home Depot",
    homedepot: "Home Depot",
    hercrentals: "Herc Rentals",
    equipmentshare: "Equipment Share",
  };
  const parsedUrl = new URL(link);
  const hostname = parsedUrl.hostname;
  const domainParts = hostname.split(".");
  if (domainParts.length > 2) {
    return nameMappings[domainParts.slice(1, -1).join(".")] || "Dizel";
  } else {
    return nameMappings[domainParts.join(".")] || "Dizel";
  }
};

const isDateWithinRange = (date, rangeStart, rangeEnd) => {
  const normalizeDate = (d) => {
    const normalized = new Date(d);
    normalized.setHours(0, 0, 0, 0); // Reset time to 00:00:00
    return normalized;
  };

  return (
    normalizeDate(date) >= normalizeDate(rangeStart) &&
    normalizeDate(date) <= normalizeDate(rangeEnd)
  );
};

const beforeTodayy = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};

export const shouldDisableDatee = (date, leaseFields, itemBuyRange) => {
  const now = new Date();

  // Condition 1: Add a 12-hour buffer to the current time
  const twelveHoursLater = addHours(now, 12); // Date + 12 hours

  // Condition 2: If the current time is after 12 PM today, don't allow booking for the next day
  const isAfterNoon = now.getHours() >= 12;
  const isNextDay = isDateWithinRange(date, addDays(now, 1), addDays(now, 1)); // Check if booking is for the next day

  // Condition 3: If current time is before noon, disallow bookings for today, but allow for tomorrow
  const isBeforeNoon = now.getHours() < 12;
  const isToday = isDateWithinRange(
    date,
    now.setHours(0, 0, 0, 0),
    addHours(now, 24)
  ); // Check if booking is for today

  // Condition 4: If the date is within the next 12 hours, it should be disabled
  const isWithin12Hours = isDateWithinRange(date, now, twelveHoursLater);

  // Condition 5: Check for lease fields
  const updatedLeaseFields = leaseFields
    ? leaseFields.map(({ from, to }) => ({
        from: new Date(from),
        to: addHours(new Date(to), 20), // Add 20 hours to rangeEnd for lease fields (can adjust if needed)
      }))
    : [];

  const isWithinLeaseField = updatedLeaseFields?.some(({ from, to }) =>
    isDateWithinRange(date, from, to)
  );

  // Condition 6: Handle buy range
  const updatedBuyRange = itemBuyRange
    ? itemBuyRange.map(({ from, to }) => ({
        from: new Date(from),
        to: new Date(to),
      }))
    : [];

  const isWithinBuyRange = updatedBuyRange?.some(({ from, to }) =>
    isDateWithinRange(date, from, to)
  );

  // Condition 7: Check if the date is before today
  const isBeforeToday = beforeTodayy(date);

  // Condition 8: Default range handling (if lease fields are empty)
  const isWithinDefaultRange =
    !leaseFields && isDateWithinRange(date, now, addHours(now, 20));


  // Combining all conditions:
  return (
    // Buffer and disable booking within the next 12 hours
    isWithin12Hours ||
    // Disable bookings for the next day after 12 PM today
    (isAfterNoon && isNextDay) ||
    // Disable bookings for today if current time is before noon
    (isBeforeNoon && isToday) ||
    // Disable if within lease field range
    isWithinLeaseField ||
    // Disable if within buy range
    isWithinBuyRange ||
    // Disable if the date is before today
    isBeforeToday ||
    // Disable if within default range when lease fields are empty
    isWithinDefaultRange
  );
};

export const toastOption = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

export const sortDataProducts = (products, sort, type) => {
  if (products) {
    let sortedProducts = products;
    if (products.length > 1) {
      if (sort && sort === "recent") {
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 5);
        sortedProducts = sortedProducts.filter(
          (p) => new Date(p.createdAt) >= twoDaysAgo
        );
      } else {
        sortedProducts = products.slice().sort((a, b) => {
          switch (sort) {
            case "newest":
              return new Date(b.createdAt) - new Date(a.createdAt);
            case "oldest":
              return new Date(a.createdAt) - new Date(b.createdAt);
            case "recent":
              const now = new Date();
              const twoDaysAgo = now.setDate(now.getDate() - 2);
              const isRecentA = new Date(a.createdAt) >= new Date(twoDaysAgo);
              const isRecentB = new Date(b.createdAt) >= new Date(twoDaysAgo);
              if (isRecentA && !isRecentB) return -1;
              if (!isRecentA && isRecentB) return 1;
              return 0;
            case "price":
              if (type === "rent") {
                return a?.itemDailyPrice - b?.itemDailyPrice || 0;
              } else if (type === "buy") {
                return a.itemAmount - b.itemAmount;
              } else if (type === "both") {
                if (a.itemToSell && b.itemToSell) {
                  return a.itemAmount - b.itemAmount;
                } else if (a.itemForRent && b.itemForRent) {
                  return a?.itemDailyPrice - b?.itemDailyPrice || 0;
                } else if (a.itemForRent && b.itemToSell) {
                  return a?.itemDailyPrice - b.itemAmount;
                } else if (a.itemToSell && b.itemForRent) {
                  return a.itemAmount - b?.itemDailyPrice;
                } else {
                  return 0;
                }
              }
              return 0;
            case "price-desc":
              if (type === "rent") {
                return b?.itemDailyPrice - a?.itemDailyPrice || 0;
              } else if (type === "buy") {
                return b.itemAmount - a.itemAmount;
              } else if (type === "both") {
                if (b.itemToSell && a.itemToSell) {
                  return b.itemAmount - a.itemAmount;
                } else if (b.itemForRent && a.itemForRent) {
                  return b?.itemDailyPrice - a?.itemDailyPrice || 0;
                } else if (b.itemForRent && a.itemToSell) {
                  return b?.itemDailyPrice - a.itemAmount;
                } else if (b.itemToSell && a.itemForRent) {
                  return b.itemAmount - a?.itemDailyPrice;
                } else {
                  return 0;
                }
              }
              return 0;
            default:
              return 0; // No sorting
          }
        });
      }
    }
    return sortedProducts;
  }
};

export const sortDataPurchaseOrder = (products, sort, type) => {
  if (products) {
    let sortedProducts = products;
    if (products.length > 1) {
      if (sort && sort === "recent") {
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 5);
        sortedProducts = sortedProducts.filter(
          (p) => new Date(p.createdAt) >= twoDaysAgo
        );
      } else {
        sortedProducts = products.slice().sort((a, b) => {
          switch (sort) {
            case "newest":
              return new Date(b.createdAt) - new Date(a.createdAt);
            case "oldest":
              return new Date(a.createdAt) - new Date(b.createdAt);
            case "recent":
              const now = new Date();
              const twoDaysAgo = now.setDate(now.getDate() - 2);
              const isRecentA = new Date(a.createdAt) >= new Date(twoDaysAgo);
              const isRecentB = new Date(b.createdAt) >= new Date(twoDaysAgo);
              if (isRecentA && !isRecentB) return -1;
              if (!isRecentA && isRecentB) return 1;
              return 0;
            case "price":
              return a.pricePaid - b.pricePaid;
            case "price-desc":
              return b.pricePaid - a.pricePaid;
            default:
              return 0; // No sorting
          }
        });
      }
    }
    return sortedProducts;
  }
};

export const DarkToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--black-color)",
    borderRadius: 0,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 30,
    padding: 10,
    backgroundColor: "var(--black-color)",
    border: "2px solid var(--black-color)",
    textAlign: "center",
    fontSize: "14px",
  },
}));

export const LightToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--grey-color)",
    borderRadius: 0,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 30,
    padding: 10,
    backgroundColor: "var(--grey-color)",
    border: "2px solid var(--grey-color)",
    textAlign: "center",
    color: "var(--black-color)",
    fontSize: "14px",
  },
}));

export const US_PHONE_REGEX = /^\(\d{3}\) \d{3}-\d{4}$/;

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  zIndex: 9999,
};

export const formatPhoneNumber = (value) => {
  // Remove all non-numeric characters
  const cleaned = value.replace(/\D/g, "");

  // Format the number as (###) ###-####
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (!match) return value;

  const formattedNumber = [
    match[1] ? `(${match[1]}` : "",
    match[2] ? `) ${match[2]}` : "",
    match[3] ? `-${match[3]}` : "",
  ]
    .join("")
    .trim();

  return formattedNumber;
};

export const calculateDateDifference = (startDateString, endDateString) => {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  const timeDifference = endDate - startDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference + 1;
};

export const calculateTotal = ({
  rentEstimates = [],
  bbuy = [],
  cproducts = [],
  transportation = 0,
  discount = 0,
  tax,
}) => {
  // Calculate rent subtotal
  const rentSubTotal =
    rentEstimates &&
    rentEstimates.reduce((sum, rentObj) => {
      const rentValues = Object.values(rentObj)[0];
      return sum + (rentValues.totalRentPrice || 0) + (rentValues.incCost || 0);
    }, 0);

  // Calculate buy subtotal and sales tax
  let saleTax = 0;
  const buySubTotal =
    bbuy &&
    bbuy.reduce((total, product) => {
      // saleTax += Number(((product.itemAmount * 4.5) / 100).toFixed(2));
      return total + Number(product.itemAmount);
    }, 0);

  // Check if all products are seller-borne charges
  const checkForFees =
    cproducts && cproducts.every((product) => product.chargesIncurredBySeller);

  // Calculate fees (if applicable) only if transportation > 0
  let feesLocal = 0;
  if (!checkForFees) {
    feesLocal = Number(
      (((rentSubTotal + transportation + buySubTotal) * 11.5) / 100).toFixed(2)
    );
    feesLocal += saleTax;
  }

  // Calculate total amount
  const subTotal = rentSubTotal + buySubTotal;
  let taxNum = 0;
  if (tax) {
    taxNum = Number(Number(tax.tax_amount_exclusive / 100).toFixed(2));
  }
  console.log("TAX", tax, taxNum);
  const totalAmount = Number(
    Number(subTotal + transportation + feesLocal + taxNum).toFixed(2)
  );

  // Apply discount
  const discountedTotal =
    discount > 0
      ? totalAmount + (feesLocal * discount) / 100
      : totalAmount + feesLocal;

  // Return results
  return {
    rentSubTotal,
    buySubTotal,
    subTotal,
    saleTax,
    feesLocal,
    totalAmount,
    discountedTotal,
  };
};
