import { Box, Container, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabsComponent from "../../components/TabsComponent/TabsComponent";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import EditProfile from "../../components/EditProfile/EditProfile";
import MyEquipments from "../../components/MyEquipments/MyEquipments";
import MyPurchases from "../../components/MyPurchases/MyPurchases";
import MyOrders from "../../components/MyOrders/MyOrders";
import Transactions from "../../components/Transactions/Transactions";
import Favorites from "../../components/Favorites/Favorites";
import Referrals from "../../components/Referrals/Referrals";
import Notifications from "../../components/Notifications/Notifications";
import "./TabsLayout.css";
import { getuserInfo } from "../../components/StateSlices/getuserSlice";
import { useDispatch, useSelector } from "react-redux";
import MyTransactions from "../../components/MyTransactions/MyTransactions";
import MyTransactionsNew from "../../components/MyTransactions/MyTransactionsNew";

const TabsLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo, status } = useSelector((state) => state.user);

  const tabMappings = {
    "/user/profile": 0,
    "/user/equipment": 1,
    // "/user/purchases": 2,
    // "/user/orders": 3,
    "/user/transactions": 4,
    "/user/favorites": 5,
    "/user/notifications": 6,
    "/user/referrals": 7,
  };
  const [value, setValue] = useState(
    location.pathname ? tabMappings[location.pathname] || 0 : 0
  );

  useEffect(() => {
    if (!userInfo) {
      let token = localStorage.getItem("inktoken");
      dispatch(getuserInfo({ token }));
    }
  }, []);

  useEffect(() => {
    if (status === "failed") {
      navigate("/signup", { replace: true });
    }
  }, [status]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          {/* <Paper style={{ height: "100%" }}> */}
          <TabsComponent value={value} setValue={setValue} />
          {/* </Paper> */}
        </Grid>

        <Grid item xs={12} md={9}>
          <Box
            sx={{
              minHeight: "100vh",
              borderRadius: "10px",
              margin: "2rem 0",
              marginTop: "4rem",
            }}
            component={"div"}
          >
            <Routes>
              <Route path="profile" element={<EditProfile />} />
              <Route path="equipment" element={<MyEquipments />} />
              {/* <Route path="purchases" element={<MyPurchases />} />
              <Route path="orders" element={<MyOrders />} /> */}
              <Route path="transactions" element={<MyTransactionsNew />} />
              <Route path="favorites" element={<Favorites />} />
              {/* <Route path="notifications" element={<Notifications />} /> */}
              <Route path="referrals" element={<Referrals />} />
            </Routes>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TabsLayout;
