import React, { useState } from "react";
import { Box, List, ListItem, ListItemText, IconButton, Drawer, AppBar, Toolbar, Typography, Button, Divider } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";

const MobileHeader = () => {
    const menuItems = [
        {
          title: "Earth Manipulation",
          url: "?goal=Earth Manipulation",
          submenu: [
            {
              title: "Attachments",
              url: "?goal=Earth Manipulation&categoryName=Attachments",
              submenu: [
                {
                  title: "Skidsteer Auger Bit",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Auger Bit",
                },
                {
                  title: "Skidsteer Boring Attachment",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Boring Attachment",
                },
                {
                  title: "Skidsteer Bucket",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Bucket",
                },
                {
                  title: "Skidsteer Grapple Bucket",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Grapple Bucket",
                },
                {
                  title: "Skidsteer Power Rake",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Power Rake",
                },
                {
                  title: "Skidsteer Leveler Attachment",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Leveler Attachment",
                },
                {
                  title: "Skidsteer Multipurpose Tool",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Multipurpose Tool",
                },
                {
                  title: "Skidsteer Pallet Forks",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Pallet Forks",
                },
                {
                  title: "Skidsteer Soil Cultivator",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Soil Cultivator",
                },
                {
                  title: "Tiller Attachment",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tiller Attachment",
                },
                {
                  title: "Skidsteer Trencher",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Trencher",
                },
                {
                  title: "Skidsteer Vibratory Plow",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Vibratory Plow",
                },
                {
                  title: "Skidsteer Rock Tip Auger",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Rock Tip Auger",
                },
                {
                  title: "Skidsteer Snow Pusher",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Snow Pusher",
                },
                {
                  title: "Skidsteer Tooth Bar",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Tooth Bar",
                },
                {
                  title: "Skidsteer Asphalt Planer",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Asphalt Planer",
                },
                {
                  title: "Skidsteer Box Broom",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Box Broom",
                },
                {
                  title: "Skidsteer Brush Hog",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Brush Hog",
                },
                {
                  title: "Skidsteer Dozer Blade",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Dozer Blade",
                },
                {
                  title: "Skidsteer Grader Blade",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Grader Blade",
                },
                {
                  title: "Skidsteer Grapple Bucket",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Grapple Bucket",
                },
                {
                  title: "Skidsteer Rake",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Rake",
                },
                {
                  title: "Skidsteer Pallet Forks",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Pallet Forks",
                },
                {
                  title: "Skidsteer Snow Blade Attachment",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Snow Blade Attachment",
                },
                {
                  title: "Skidsteer Sweeper Broom",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Sweeper Broom",
                },
                {
                  title: "Skidsteer Tree Fork Attachment",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Tree Fork Attachment",
                },
                {
                  title: "Excavator Hydraulic Hammer",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Excavator Hydraulic Hammer",
                },
                {
                  title: "Skidsteer Hydraulic Hammer",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Skidsteer Hydraulic Hammer",
                },
                {
                  title: "Tractor Aerator",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Aerator",
                },
                {
                  title: "Tractor Backhoe Attachment",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Backhoe Attachment",
                },
                {
                  title: "Tractor Box Scraper",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Box Scraper",
                },
                {
                  title: "Tractor Grooming Mower",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Grooming Mower",
                },
                {
                  title: "Tractor Power Rake",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Power Rake",
                },
                {
                  title: "Tractor Rake - Non Powered",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Rake - Non Powered",
                },
                {
                  title: "Tractor Posthole Auger",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Posthole Auger",
                },
                {
                  title: "Tractor Root Rake",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Root Rake",
                },
                {
                  title: "Tractor Rotary Cutter",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Rotary Cutter",
                },
                {
                  title: "Tractor Rotary Tiller",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Rotary Tiller",
                },
                {
                  title: "Tractor Soil Pulverizer",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Tractor Soil Pulverizer",
                },
                {
                  title: "Loader Forks",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Loader Forks",
                },
                {
                  title: "Loader Root Rake",
                  url: "?goal=Earth Manipulation&categoryName=Attachments&subCategoryName=Loader Root Rake",
                },
              ],
            },
            {
              title: "Compaction",
              url: "?goal=Earth Manipulation&categoryName=Compaction",
              submenu: [
                {
                  title: "Smooth Roller",
                  url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Smooth Roller",
                },
                {
                  title: "Jumping Jack",
                  url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Jumping Jack",
                },
                {
                  title: "Plate Compactor",
                  url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Plate Compactor",
                },
                {
                  title: "Padfoot Roller",
                  url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Padfoot Roller",
                },
                {
                  title: "Trench Compactor",
                  url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Trench Compactor",
                },
                {
                  title: "Walk Behind Roller",
                  url: "?goal=Earth Manipulation&categoryName=Compaction&subCategoryName=Walk Behind Roller",
                },
              ],
            },
            {
              title: "Dirt Movers",
              url: "?goal=Earth Manipulation&categoryName=Dirt Movers",
              submenu: [
                {
                  title: "Backhoe",
                  url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Backhoe",
                },
                {
                  title: "Crawler Dozer",
                  url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Crawler Dozer",
                },
                {
                  title: "Excavator",
                  url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Excavator",
                },
                {
                  title: "Road Grader",
                  url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Road Grader",
                },
                {
                  title: "Skidsteer",
                  url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Skidsteer",
                },
                {
                  title: "Tractor",
                  url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Tractor",
                },
                {
                  title: "Trencher",
                  url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Trencher",
                },
                {
                  title: "Loaders",
                  url: "?goal=Earth Manipulation&categoryName=Dirt Movers&subCategoryName=Loaders",
                },
              ],
            },
            {
              title: "Tree Machines",
              url: "?goal=Earth Manipulation&categoryName=Tree Machines",
              submenu: [
                {
                  title: "Stump Grinder",
                  url: "?goal=Earth Manipulation&categoryName=Tree Machines&subCategoryName=Stump Grinder",
                },
                {
                  title: "Chipper",
                  url: "?goal=Earth Manipulation&categoryName=Tree Machines&subCategoryName=Chipper",
                },
              ],
            },
          ],
        },
        {
          title: "Lifting People and Material",
          url: "?goal=Lifting People",
          submenu: [
            {
              title: "Manlift",
              url: "?goal=Lifting People and Material&categoryName=Manlift",
              submenu: [
                {
                  title: "Atrium Lift",
                  url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Atrium Lift",
                },
              ],
            },
            {
              title: "Crane",
              url: "?goal=Lifting People and Material&categoryName=Crane",
              submenu: [
                {
                  title: "Carry Deck",
                  url: "?goal=Lifting People and Material&categoryName=Crane&subCategoryName=Carry Deck",
                },
                {
                  title: "Walk Behind",
                  url: "?goal=Lifting People and Material&categoryName=Crane&subCategoryName=Walk Behind",
                },
              ],
            },
            {
              title: "Forklift",
              url: "?goal=Lifting People and Material&categoryName=Forklift",
              submenu: [
                {
                  title: "Telehandler - Rotating",
                  url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Telehandler - Rotating",
                },
                {
                  title: "Industrial",
                  url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Industrial",
                },
                {
                  title: "Telehandler",
                  url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Telehandler",
                },
                {
                  title: "Walkie Stacker",
                  url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Walkie Stacker",
                },
                {
                  title: "Rought Terrain",
                  url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Rought Terrain",
                },
                {
                  title: "Manual",
                  url: "?goal=Lifting People and Material&categoryName=Forklift&subCategoryName=Manual",
                },
              ],
            },
            {
              title: "Lift Attachments",
              url: "?goal=Lifting People and Material&categoryName=Lift Attachments",
              submenu: [
                {
                  title: "Pipe/Conduit Rack",
                  url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Pipe/Conduit Rack",
                },
                {
                  title: "Telehandler Forks",
                  url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Telehandler Forks",
                },
                {
                  title: "Industrial Forks",
                  url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Industrial Forks",
                },
                {
                  title: "Telehandler Block Forks",
                  url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Telehandler Block Forks",
                },
                {
                  title: "Industrial Block Forks",
                  url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Industrial Block Forks",
                },
                {
                  title: "Telehandler Bucket",
                  url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Telehandler Bucket",
                },
                {
                  title: "Forklift Bucket",
                  url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Forklift Bucket",
                },
                {
                  title: "Telehandler Boom Extension",
                  url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Telehandler Boom Extension",
                },
                {
                  title: "Forklift Hook Attachment",
                  url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Forklift Hook Attachment",
                },
                {
                  title: "Trash Hopper",
                  url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Trash Hopper",
                },
                {
                  title: "Extension Forks",
                  url: "?goal=Lifting People and Material&categoryName=Lift Attachments&subCategoryName=Extension Forks",
                },
              ],
            },
            {
              title: "Manlift",
              url: "?goal=Lifting People and Material&categoryName=Manlift",
              submenu: [
                {
                  title: "Articulating",
                  url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Articulating",
                },
                {
                  title: "Straight Boom ",
                  url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Straight Boom ",
                },
                {
                  title: "Mast Boom ",
                  url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Mast Boom ",
                },
                {
                  title: "Single Person - Drivable",
                  url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Single Person - Drivable",
                },
                {
                  title: "Single Person - Manual Placement",
                  url: "?goal=Lifting People and Material&categoryName=Manlift&subCategoryName=Single Person - Manual Placement",
                },
              ],
            },
            {
              title: "Scissorlift",
              url: "?goal=Lifting People and Material&categoryName=Scissorlift",
              submenu: [
                {
                  title: "Electric",
                  url: "?goal=Lifting People and Material&categoryName=Scissorlift&subCategoryName=Electric",
                },
                {
                  title: "Rough Terrain",
                  url: "?goal=Lifting People and Material&categoryName=Scissorlift&subCategoryName=Rough Terrain",
                },
                {
                  title: "Warehouse Industrial",
                  url: "?goal=Lifting People and Material&categoryName=Scissorlift&subCategoryName=Warehouse Industrial",
                },
              ],
            },
          ],
        },
        {
          title: "Managing Air",
          url: "?goal=Managing Air",
          submenu: [
            {
              title: "Purification",
              url: "?goal=Managing Air&categoryName=Purification",
              submenu: [
                {
                  title: "Air Scrubber",
                  url: "?goal=Managing Air&categoryName=Purification&subCategoryName=Air Scrubber",
                },
              ],
            },
            {
              title: "Dehumidification",
              url: "?goal=Managing Air&categoryName=Dehumidification",
              submenu: [
                {
                  title: "Desiccant Dehumidifier",
                  url: "?goal=Managing Air&categoryName=Dehumidification&subCategoryName=Desiccant Dehumidifier",
                },
                {
                  title: "Refrigerant Dehumidifier",
                  url: "?goal=Managing Air&categoryName=Dehumidification&subCategoryName=Refrigerant Dehumidifier",
                },
              ],
            },
            {
              title: "Moving Air",
              url: "?goal=Managing Air&categoryName=Moving Air",
              submenu: [
                {
                  title: "Air Movers",
                  url: "?goal=Managing Air&categoryName=Moving Air&subCategoryName=Air Movers",
                },
              ],
            },
            {
              title: "Cooling Air",
              url: "?goal=Managing Air&categoryName=Cooling Air",
              submenu: [
                {
                  title: "Air Handler",
                  url: "?goal=Managing Air&categoryName=Cooling Air&subCategoryName=Air Handler",
                },
                {
                  title: "Air Conditioner",
                  url: "?goal=Managing Air&categoryName=Cooling Air&subCategoryName=Air Conditioner",
                },
                {
                  title: "Chiller",
                  url: "?goal=Managing Air&categoryName=Cooling Air&subCategoryName=Chiller",
                },
                {
                  title: "Cooling Tower",
                  url: "?goal=Managing Air&categoryName=Cooling Air&subCategoryName=Cooling Tower",
                },
              ],
            },
            {
              title: "Heating Air",
              url: "?goal=Managing Air&categoryName=Heating Air",
              submenu: [
                {
                  title: "Direct Fired Heaters",
                  url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Direct Fired Heaters",
                },
                {
                  title: "Electric Heaters",
                  url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Electric Heaters",
                },
                {
                  title: "Flameless Heaters",
                  url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Flameless Heaters",
                },
                {
                  title: "Ground Heaters",
                  url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Ground Heaters",
                },
                {
                  title: "Indirect Heaters",
                  url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Indirect Heaters",
                },
                {
                  title: "Steam Heaters",
                  url: "?goal=Managing Air&categoryName=Heating Air&subCategoryName=Steam Heaters",
                },
              ],
            },
          ],
        },
        {
          title: "Power Solutions",
          url: "?goal=Power Solutions",
          submenu: [
            {
              title: "Creating Electricity",
              url: "?goal=Power Solutions&categoryName=Creating Electricity",
              submenu: [
                {
                  title: "Gas Generators",
                  url: "?goal=Power Solutions&categoryName=Creating Electricity&subCategoryName=Gas Generators",
                },
                {
                  title: "Diesel Generators",
                  url: "?goal=Power Solutions&categoryName=Creating Electricity&subCategoryName=Diesel Generators",
                },
              ],
            },
            {
              title: "Containment & Tanks",
              url: "?goal=Power Solutions&categoryName=Containment & Tanks",
              submenu: [
                {
                  title: "Containment Berm",
                  url: "?goal=Power Solutions&categoryName=Containment & Tanks&subCategoryName=Containment Berm",
                },
                {
                  title: "Fuel Tanks",
                  url: "?goal=Power Solutions&categoryName=Containment & Tanks&subCategoryName=Fuel Tanks",
                },
              ],
            },
          ],
        },
      ];
      const [currentMenu, setCurrentMenu] = useState(menuItems);
      const [history, setHistory] = useState([]);
      const [selectedItems, setSelectedItems] = useState([]); // For showing the selected path
      const [selectedUrl, setSelecedUrl] = useState("")
      const [drawerOpen, setDrawerOpen] = useState(false);
      const navigate = useNavigate();
    
      const handleMenuClick = (item) => {
        if (item.submenu) {
          setHistory((prevHistory) => [...prevHistory, currentMenu]);
          setCurrentMenu(item.submenu);
          setSelectedItems((prevSelected) => [...prevSelected, item.title]);
          setSelecedUrl(item.url)
        } else {
          // Update selection for the final item
          setSelectedItems((prevSelected) => [...prevSelected, item.title]);
          setSelecedUrl(item.url)
        }
      };
    
      const handleBackClick = () => {
        setCurrentMenu(history[history.length - 1]);
        setHistory((prevHistory) => prevHistory.slice(0, -1));
        setSelectedItems((prevSelected) => prevSelected.slice(0, -1));
      };
    
      const handleGoClick = () => {
        // const lastSelected = history.length > 0 ? history[history.length - 1].find(item => item.title === selectedItems[selectedItems.length - 1]) : currentMenu[0];
        if (selectedUrl ) {
          navigate(`/shop${selectedUrl}`); // Redirect based on the last selected item's URL
        }
        toggleDrawer(false); // Close the drawer after redirect
      };
    
      const toggleDrawer = (open) => {
        setDrawerOpen(open);
        if (!open) {
          setCurrentMenu(menuItems);  // Reset to main menu on drawer close
          setHistory([]);  // Clear history
          setSelectedItems([]);  // Clear selected path
        }
      };
  
    return (
      <>
        <IconButton
            size="small"
            
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => toggleDrawer(true)}
            color="inherit"
            sx={{ padding: 0, paddingRight: "1rem" }}
            
            >
            <MenuIcon />
        </IconButton>
        {/* Drawer Implementation */}
        <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        sx={{ width: 250 }}
      >
        <Box
          sx={{
            width: 250,
            backgroundColor: "var(--black-color)",
            height: "100%",
            color: "var(--white-color)",
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
          role="presentation"
        >
          {/* Selected Items Path */}
          

          {/* Menu List */}
          <Box sx={{ flexGrow: 1 }} data-aos="fade-in">
            {history.length > 0 && (
              <IconButton onClick={handleBackClick} sx={{fontSize: 14,  color: "var(--grey-color)" }}>
                <ArrowBackIcon fontSize="16px" /> Back
              </IconButton>
            )}
            <List     sx={{
                height: '70vh',  // 80% of viewport height
                overflowY: 'auto',  // Enable scrolling if content exceeds 80% of screen height
              }}>
              {currentMenu.map((item, index) => (
                <ListItem
              
                  button
                  key={index}
                  onClick={() => handleMenuClick(item)}
                >
                  <ListItemText primary={item.title} sx={{ color: "#fff" }} />
                </ListItem>
              ))}
            </List>
          </Box>
        <hr />
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="subtitle1" sx={{ fontSize: 12, textTransform:"capitalize", fontWeight: 700, color: "var(--grey-color)", marginBottom: 1 }}>
              Selected Path:
            </Typography>
            <Box>
              {selectedItems.length > 0 ? (
                <Typography variant="body2" sx={{ fontSize: 14, color: "#fff" }}>
                  {selectedItems.join(" > ")}
                </Typography>
              ) : (
                <Typography variant="body2" sx={{ color: "#fff" }}>None</Typography>
              )}
            </Box>
          </Box>
          {/* GO Button */}
          {selectedItems.length > 0 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleGoClick}
              sx={{ marginTop: 2 }}
            >
              GO
            </Button>
          )}
        </Box>
      </Drawer>
      </>
    );
  };
  
  export default MobileHeader