import {
  Box,
  Chip,
  Modal,
  TextField,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import "./Referrals.css";
import { toast } from "react-toastify";
import { referLink, resetItem } from "../StateSlices/referLinkSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdContentCopy } from "react-icons/md";
import noitem from "../../assets/no-item.png";
import { InfoOutlined } from "@mui/icons-material";
import { DarkToolTip } from "../../utils/utils";

const Referrals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, userInfo, profile } = useSelector((state) => state.user);

  // Referral Modal
  const [openReferralModal, setOpenReferralModal] = React.useState(false);
  const handleOpenReferralModal = () => setOpenReferralModal(true);
  const handleCloseReferralModal = () => setOpenReferralModal(false);
  const [refUrl, setRefUrl] = useState("");
  const referralModalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
  };
  const toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const { referStatus, referInfo, referError } = useSelector(
    (state) => state.referLink
  );
  if (referInfo) {
    let referId = referInfo;
    dispatch(resetItem());
    setRefUrl(
      "https://godizel.com/signup?referral=" + encodeURIComponent(referId)
      // +
      // "&user=" +
      // userInfo.userDetailsId.userFName +
      // "-" +
      // userInfo.userDetailsId.userLName
    );
    setOpenReferralModal((prev) => !prev);
  }

  const getUrl = () => {
    toast.info("Please Wait...", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    let token = localStorage.getItem("inktoken");
    if (token) {
      dispatch(referLink({ token: token }));
    } else {
      navigate("/signup");
    }
  };
  const refs = [1, 2, 3, 4, 5];
  return (
    <Box component={"div"}>
      <Modal
        open={openReferralModal}
        onClose={handleCloseReferralModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ transition: "none" }}
      >
        <Box sx={referralModalstyle}>
          <Typography
            variant="h5"
            className="primary-text"
            fontWeight={700}
            style={{ marginBottom: ".5rem" }}
          >
            Refer to your peers
          </Typography>
          <Typography variant="body2" mb={1} color={"text.secondary"}>
            Earn Credits on each Referral!
          </Typography>
          <div className="reference-section">
            <TextField
              className="referral-input-field"
              fullWidth
              type="text"
              id="reference-input"
              value={refUrl}
              disabled
            />
            <button
              id="copy-button"
              onClick={() => {
                // refUrl.select();
                // refUrl.setSelectionRange(0, 99999); // For mobile devices
                // Copy the text inside the text field
                navigator.clipboard.writeText(refUrl);
                toast.success("Referral URL copied successfully!", toastOption);
              }}
            >
              <MdContentCopy />
            </button>
          </div>
        </Box>
      </Modal>
      <Box component={"div"} marginTop={"2rem"}>
        <Box component={"div"} className="tabs-header">
          <Typography variant="h2" className="title mont-title">
            My Referrals
          </Typography>
          <Box>
            <Chip
              sx={{
                background: "var(--black-color)",
                color: "var(--white-color)",
                fontWeight: 700,
                letterSpacing: 1,
                padding: "1.3rem 1rem",
                borderRadius: "30px",
                "&: hover": {
                  color: "var(--black-color)",
                },
              }}
              onClick={() => getUrl()}
              label="Referral Link"
            />
            <Chip
              sx={{
                background: "var(--black-color)",
                color: "var(--white-color)",
                fontWeight: 700,
                letterSpacing: 1,
                marginLeft: "1rem",
                padding: "1.3rem 1rem",
                borderRadius: "30px",
              }}
              label={
                <Typography
                  fontSize={16}
                  display={"flex"}
                  alignItems={"center"}
                  fontWeight={700}
                >
                  <DarkToolTip
                    // arrow
                    className="gd-tooltip"
                    placement="bottom"
                    title={
                      "This refers to the credits earned by the user by sharing his/her referral link to others"
                    }
                  >
                    <InfoOutlined sx={{ fontSize: 16 }} />
                  </DarkToolTip>
                  &nbsp;&nbsp;Credits: $
                  {userInfo && userInfo.userDetailsId.referredCredits
                    ? userInfo.userDetailsId.referredCredits
                    : 0}
                </Typography>
              }
            />
          </Box>
        </Box>
        <Box marginTop={"2rem"}>
          {userInfo &&
          userInfo.userReferals &&
          userInfo.userReferals.length > 0 ? (
            <>
              {userInfo.userReferals.map((ref) => (
                <Box
                  component={"div"}
                  key={ref}
                  className="ref-card"
                  gap={2}
                  mt={"1rem"}
                >
                  <Box component={"div"} className="ref-line-divider">
                    &nbsp;
                  </Box>
                  <Box component={"div"}>
                    <Typography
                      variant="caption"
                      color={"var(--grey-color)"}
                      fontWeight={700}
                    >
                      Referred To:{" "}
                    </Typography>
                    <Typography
                      variant="h5"
                      display={"flex"}
                      alignItems={"center"}
                    >
                      {ref.userFName + " " + ref.userLName} &nbsp;
                      <span className="dot">&nbsp;</span> &nbsp; {ref.userEmail}
                    </Typography>
                    <Typography
                      variant="h5"
                      display={"flex"}
                      alignItems={"center"}
                    >
                      Equipments Posted &nbsp;
                      <span className="dot">&nbsp;</span> &nbsp;{" "}
                      {ref.userProducts.length}
                    </Typography>
                  </Box>
                  <Box component={"div"} className="ref-btns">
                    {/* <Chip
                    sx={{
                      background: "var(--grey-color)",
                      color: "var(--white-color)",
                      fontWeight: 700,
                      // letterSpacing: 1.1,
                      // padding: "1.2rem",
                      // borderRadius: "30px",
                    }}
                    label="Credits: 100"
                  /> */}
                    <Typography color={"var(--grey-color)"} variant="body2">
                      ~ {new Date(ref.createdAt).toDateString()}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              minHeight={"60vh"}
              width={"100%"}
              sx={{
                background: `url(${noitem})`,
                backgroundSize: "cover",
              }}
            >
              <Typography variant="h3" color={"var(--black-color)"}>
                No referrals yet! Start sharing and earn rewards!
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => getUrl()}
                sx={{ marginTop: "1rem" }}
              >
                Get Referral Link
              </Button>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Referrals;
