import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Popper,
  Fade,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  InputAdornment,
  CircularProgress,
  Chip,
  Skeleton,
  Stack,
  ListItemSecondaryAction,
  IconButton,
  Popover,
  Collapse,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { Search as SearchIcon, LocationOn, Clear } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { brands, itemGoalsNested } from "../../pages/AddNewItem/data";
import { FaBarcode } from "react-icons/fa";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getallProducts } from "../StateSlices/getproductsSlice";
import { LuExternalLink } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";

export const ShimmerUI = ({ count = 4 }) => {
  return (
    //Products Shimmer
    // <Box sx={{ width: "100%" }}>
    //   {[...Array(5)].map((_, index) => (
    //     <Box
    //       key={index}
    //       sx={{
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "space-between",
    //         mb: 2,
    //       }}
    //     >
    //       {/* Image Skeleton */}
    //       <Skeleton
    //         animation="wave"
    //         variant="rectangular"
    //         width={64}
    //         height={64}
    //         sx={{ borderRadius: 1, flexShrink: 0 }}
    //       />

    //       {/* Text Skeletons */}
    //       <Box sx={{ flex: 1, ml: 2 }}>
    //         <Skeleton animation="wave" variant="text" width={200} height={20} />
    //         <Skeleton
    //           animation="wave"
    //           variant="text"
    //           width="100%"
    //           height={25}
    //         />
    //         <Skeleton
    //           animation="wave"
    //           variant="text"
    //           width="100%"
    //           height={25}
    //         />
    //       </Box>
    //     </Box>
    //   ))}
    // </Box>
    <Box sx={{ width: "100%" }}>
      {Array.from({ length: count }).map((_, index) => (
        <Box key={index} sx={{ flex: 1, ml: 2, mt: 1.5 }}>
          <Skeleton animation="wave" variant="text" width={200} height={20} />
          <Skeleton animation="wave" variant="text" width="100%" height={25} />
          <Skeleton animation="wave" variant="text" width="100%" height={25} />
        </Box>
      ))}
    </Box>
  );
};

export function getGoalActions(navigate) {
  // Each top-level key becomes an action.
  return Object.keys(itemGoalsNested).map((goal) => ({
    id: `goal-${goal}`,
    name: goal,
    keywords: goal.toLowerCase(),
    perform: () => navigate(`/shop?goal=${encodeURIComponent(goal)}`),
    section: "categories", // optional grouping label
  }));
}

export function getCategoryActions(navigate) {
  // For each goal, every subcategory becomes an action.
  let actions = [];
  Object.entries(itemGoalsNested).forEach(([goal, subcategories]) => {
    Object.keys(subcategories).forEach((subcategory) => {
      actions.push({
        id: `cat-${goal}-${subcategory}`,
        name: subcategory,
        keywords: `${goal} ${subcategory}`.toLowerCase(),
        url: `/shop?goal=${encodeURIComponent(
          goal
        )}&categoryName=${encodeURIComponent(subcategory)}`,
        section: "subcategories", // This groups them under the parent goal if desired
      });
    });
  });
  return actions;
}

export function getTypeActions(navigate) {
  // For each goal and subcategory, each type becomes an action.
  let actions = [];
  Object.entries(itemGoalsNested).forEach(([goal, subcategories]) => {
    Object.entries(subcategories).forEach(([subcategory, types]) => {
      types.forEach((type) => {
        actions.push({
          id: `type-${goal}-${subcategory}-${type}`,
          name: type,
          keywords: `${goal} ${subcategory} ${type}`.toLowerCase(),
          url: `/shop?goal=${encodeURIComponent(
            goal
          )}&categoryName=${encodeURIComponent(
            subcategory
          )}&subCategoryName=${encodeURIComponent(type)}`,
          section: "types", // Grouped by goal and subcategory
        });
      });
    });
  });
  return actions;
}

function getBrandActions(navigate) {
  return brands.map((brand) => ({
    id: brand.value,
    name: brand.label,
    keywords: brand.label.toLowerCase(),
    url: `/shop?brandName=${brand.label}`,
    icon: <FaBarcode />, // or your preferred icon
    section: "brands",
  }));
}

// Mock search results - replace with your actual search logic
const searchResults = async (query) => {
  // Simulate API call delay
  await new Promise((resolve) => setTimeout(resolve, 300));

  // Mock categorized results
  const allData = {
    categories: getGoalActions(),
    subcategories: getCategoryActions(),
    types: getTypeActions(),
    brands: getBrandActions(),
  };

  // Filter function to find items matching the query
  const filterItems = (items, query) => {
    if (!query || query.length < 2) return [];

    const lowercaseQuery = query.toLowerCase();
    return items
      .filter((item) => item.name.toLowerCase().includes(lowercaseQuery))
      .sort((a, b) => {
        // Sort by how closely the items match the query
        const aStartsWithQuery = a.name
          .toLowerCase()
          .startsWith(lowercaseQuery);
        const bStartsWithQuery = b.name
          .toLowerCase()
          .startsWith(lowercaseQuery);

        if (aStartsWithQuery && !bStartsWithQuery) return -1;
        if (!aStartsWithQuery && bStartsWithQuery) return 1;

        // Secondary sort by string length (shorter = better match)
        return a.name.length - b.name.length;
      })
      .slice(0, 3); // Only return the top 3 matches
  };

  // Filter and sort each category
  return {
    categories: filterItems(allData.categories, query),
    subcategories: filterItems(allData.subcategories, query),
    types: filterItems(allData.types, query),
    brands: filterItems(allData.brands, query),
  };
};

// Context to manage the search popup state globally
const SearchPopupContext = React.createContext({
  open: false,
  openPopup: () => {},
  closePopup: () => {},
});

export const SearchPopupProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopup = (element = null) => {
    // setAnchorEl(element);
    setAnchorEl(null);
    setOpen(true);
  };

  const closePopup = () => {
    setOpen(false);
  };

  // Global keyboard shortcut handler
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Command+K (Mac) or Ctrl+K (Windows/Linux)
      if ((event.metaKey || event.ctrlKey) && event.key === "k") {
        event.preventDefault();
        if (!open) openPopup();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closePopup();
      }
    };

    // Only add the event listener when the popup is open
    if (open) {
      document.addEventListener("keydown", handleKeyDown);

      // Clean up the event listener when component unmounts or popup closes
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open, closePopup]);

  return (
    <SearchPopupContext.Provider
      value={{ open, openPopup, closePopup, anchorEl }}
    >
      {children}
      <Popover
        open={open}
        anchorEl={null} // Prevent attaching to any element
        onClose={closePopup}
        TransitionComponent={Fade} // Smooth transition
        transitionDuration={100} // Adjust speed
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            // width: 400, p: 2, borderRadius: "10px",
            // boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.3)"
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            backdropFilter: "blur(8px)",
          },
        }}
      >
        <SearchPopup />
      </Popover>
    </SearchPopupContext.Provider>
  );
};

// Hook to use the search popup context
export const useSearchPopup = () => {
  return React.useContext(SearchPopupContext);
};

// Searchbar component that opens the popup when clicked
export const Searchbar = ({
  placeholder = "Search...",
  style = {},
  className,
}) => {
  const { openPopup } = useSearchPopup();
  const searchbarRef = useRef(null);

  return (
    <TextField
      ref={searchbarRef}
      placeholder={placeholder}
      variant="outlined"
      size="small"
      className={className}
      style={{ ...style }}
      onClick={() => openPopup(searchbarRef.current)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        readOnly: true,
      }}
    />
  );
};

export const LandingSearchBar = ({
  placeholder = "Search makes, models or keywords",
  style = {},
  className,
}) => {
  const { openPopup } = useSearchPopup();
  const searchbarRef = useRef(null);

  return (
    <TextField
      ref={searchbarRef}
      onClick={() => openPopup(searchbarRef.current)}
      label=""
      placeholder={placeholder}
      margin="normal"
      sx={{
        m: 1,
        width: { xs: "320px", md: "350px" },
        color: "var(--grey-color)",
        background: "var(--white-color)",
        borderRadius: "5px",
        "&:hover": {
          border: "none",
          outline: "none",
        },
        fontFamily: "Helvetica,Arial,sans-serif",
        fontSize: "1.125rem",
      }}
    />
  );
};

// The actual popup component
const SearchPopup = () => {
  const { open, closePopup, anchorEl } = useSearchPopup();
  const [searchTerm, setSearchTerm] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [locationVal, setLocationVal] = useState(null);

  const [results, setResults] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [tempResults, setTempResults] = useState({});

  const searchInputRef = useRef(null);
  const startDateRef = useRef(null);
  const popperRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status, products, error, productCount, filters, remainingItems } =
    useSelector((state) => state.product);

  // Handle search suggestions as you type (not final results)
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (searchTerm.length < 2) {
        setTempResults({});
        return;
      }

      setIsLoading(true);
      try {
        const searchData = await searchResults(searchTerm);
        setTempResults(searchData);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (results) {
      setResults({});
    }
    const timeoutId = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    if (products.length > 0 || status === "succeeded") {
      // setResults(products);
      setIsLoading(false);
    }
  }, [products]);

  // Focus search input when popup opens
  useEffect(() => {
    if (open && startDateRef.current) {
      setTimeout(() => {
        startDateRef.current.focus();
      }, 10);
    }
  }, [open]);

  // Reset state when popup closes
  useEffect(() => {
    if (!open) {
      setSearchTerm("");
      setSelectedFilters([]);
      setResults({});
      setTempResults({});
    }
  }, [open]);

  const getCoordinates = async () => {
    try {
      // debugger
      if (!locationVal?.label) return null;
      const results = await geocodeByAddress(locationVal.label);
      const { lat, lng } = await getLatLng(results[0]);
      console.log("Successfully got latitude and longitude", { lat, lng });

      return { lat, lng }; // Return the values
    } catch (error) {
      console.error("Error fetching geocode", error);
      return null;
    }
  };

  const navigateSearch = async () => {
    console.log("Performing search with:", {
      searchTerm,
      fromDate,
      toDate,
      locationVal,
      selectedFilters,
    });

    const groupedFilters = selectedFilters.reduce((acc, item) => {
      const { section, name } = item;
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(name);
      return acc;
    }, {});

    const formattedDate =
      fromDate && toDate
        ? `${fromDate.toISOString()},${toDate.toISOString()}`
        : "";

    const langLong = await getCoordinates();

    // const queryParams = new URLSearchParams({
    //   brands: groupedFilters?.brands?.join(",") || "",
    //   date: formattedDate,
    //   langLong: langLong,
    //   goal: groupedFilters?.categories?.join(",") || "",
    //   categoryName: groupedFilters?.subcategories?.join(",") || "",
    //   subCategory: groupedFilters?.types?.join(",") || "",
    // }).toString();
    const queryParams = new URLSearchParams({
      location: locationVal?.value?.description,
    });

    navigate(`/shop?${queryParams}`, {
      state: {
        brandsParam: groupedFilters?.brands?.join(",") || "",
        dateParam: formattedDate,
        langLongParam: langLong,
        goalParam: groupedFilters?.categories?.join(",") || "",
        categoryNameParam: groupedFilters?.subcategories?.join(",") || "",
        subCategoryParam: groupedFilters?.types?.join(",") || "",
        locationValParam: locationVal?.value?.description || "",
      },
    });
    closePopup();
  };
  const handleSearch = async () => {
    console.log("Performing search with:", {
      searchTerm,
      fromDate,
      toDate,
      locationVal,
      selectedFilters,
    });

    // Start loading state
    try {
      // Grouping data by section
      const groupedFilters = selectedFilters.reduce((acc, item) => {
        const { section, name } = item;
        if (!acc[section]) {
          acc[section] = [];
        }
        acc[section].push(name);
        return acc;
      }, {});

      const formattedDate =
        fromDate && toDate
          ? `${fromDate.toISOString()},${toDate.toISOString()}`
          : "";

      const langLong = await getCoordinates();

      setIsLoading(true);
      dispatch(
        getallProducts({
          tab: "both",
          categories: "",
          subcategories: "",
          brands: groupedFilters?.brands?.join(",") || "",
          page: 1,
          date: formattedDate,
          priceFilterType: "",
          minRentPriceFilter: 0,
          maxRentPriceFilter: 25000,
          minBuyPriceFilter: 0,
          maxBuyPriceFilter: 25000,
          langLong: langLong,
          search: "",
          milesFilter: "",
          transportationFilter: [],
          sortFilter: "newest",
          goal: groupedFilters?.categories?.join(",") || [],
          categoryName: groupedFilters?.subcategories?.join(",") || [],
          subCategory: groupedFilters?.types?.join(",") || [],
          size: [],
          platforms: "",
        })
      );

      // This would be your actual API call
      // const response = await dispatch(searchProducts(params));

      // For now, simulate API call with a timeout
    } catch (error) {
      console.error("Error searching products:", error);
    }
  };

  const handleClearFilters = () => {
    setFromDate(null);
    setToDate(null);
    setLocationVal(null);
    setSelectedFilters([]);
  };

  const handleFilterClick = (item) => {
    // Check if the item is already selected
    const isSelected = selectedFilters.some(
      (filter) => filter.id === item.id && filter.category === item.category
    );

    if (isSelected) {
      // If already selected, remove it
      setSelectedFilters(
        selectedFilters.filter(
          (filter) =>
            !(filter.id === item.id && filter.category === item.category)
        )
      );
    } else {
      // Add to selected filters
      setSelectedFilters([...selectedFilters, item]);
    }
    searchInputRef.current.value = "";
  };

  const handleRemoveFilter = (filterToRemove) => {
    setSelectedFilters(
      selectedFilters.filter(
        (filter) =>
          !(
            filter.id === filterToRemove.id &&
            filter.category === filterToRemove.category
          )
      )
    );
  };

  const renderResultSection = (sectionName, items) => {
    if (!items || items.length === 0) return null;

    return (
      <Box key={sectionName} sx={{ mb: 2 }}>
        <Typography
          variant="subtitle2"
          sx={{
            color: "text.secondary",
            fontWeight: "bold",
            pl: 2,
            pt: 1,
            textTransform: "uppercase",
            letterSpacing: 1.6,
          }}
        >
          {sectionName}
        </Typography>
        <List dense>
          {items.map((item) => {
            // Check if this item is in the selected filters
            const isSelected = selectedFilters.some(
              (filter) =>
                filter.id === item.id && filter.section === item.section
            );

            return (
              <ListItem
                key={item.id}
                button
                onClick={() => handleFilterClick(item)}
                sx={{
                  borderRadius: 2,
                  backgroundColor: isSelected
                    ? "action.selected"
                    : "transparent",
                  "&:hover": {
                    backgroundColor: isSelected
                      ? "action.selected"
                      : "action.hover",
                  },
                }}
              >
                {/* {item.icon && (
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <Typography>{item.icon}</Typography>
                  </ListItemIcon>
                )} */}
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                />
              </ListItem>
            );
          })}
        </List>
        <Divider />
      </Box>
    );
  };

  if (!open) return null;

  return (
    <Popper
      ref={popperRef}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      transition
      disablePortal
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ]}
      style={{
        zIndex: 1300,
        width: "100%",
        maxWidth: 850,
        // If no anchor element, center in viewport
        position: anchorEl ? "absolute" : "fixed",
        left: anchorEl ? undefined : "50%",
        top: anchorEl ? undefined : "15%",
        transform: anchorEl ? undefined : "translateX(-50%)",
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={300}>
          <div>
            <ClickAwayListener onClickAway={closePopup}>
              <Paper
                elevation={1}
                sx={{
                  p: 5,
                  maxHeight: "80vh",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 4,
                  // background: "var(--black-color)",
                  // color: "var(--grey-color)"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    rowGap: 2,
                    columnGap: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      value={fromDate}
                      onChange={setFromDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={startDateRef}
                          autoFocus
                          color="primary"
                          variant="outlined"
                          size="small"
                          sx={{
                            width: 160,
                            borderRadius: 3,
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "var(--primary-color)",
                              },
                              "&:hover fieldset": {
                                borderColor: "var(--primary-color)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "var(--primary-color)",
                              },
                            },
                          }}
                        />
                      )}
                      sx={{
                        width: "49%",
                        "& .Mui-selected": {
                          backgroundColor: "var(--primary-color) !important", // Selected date color
                          color: "#fff", // Text color for contrast
                        },
                        "& .MuiPickersDay-root": {
                          "&:hover": {
                            backgroundColor: "var(--primary-color)", // Hover effect on dates
                            color: "#fff",
                          },
                        },
                      }}
                      PopperProps={{
                        placement: "bottom-start",
                      }}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      value={toDate}
                      onChange={setToDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color="primary"
                          variant="outlined"
                          size="small"
                          sx={{
                            width: 160,
                            borderRadius: 3,
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "var(--primary-color)",
                              }, // Default border
                              "&:hover fieldset": {
                                borderColor: "var(--primary-color)",
                              }, // Hover border
                              "&.Mui-focused fieldset": {
                                borderColor: "var(--primary-color)",
                              }, // Focus border
                            },
                          }}
                        />
                      )}
                      sx={{
                        width: "49%",
                        "& .Mui-selected": {
                          backgroundColor: "var(--primary-color) !important", // Selected date color
                          color: "#fff", // Text color for contrast
                        },
                        "& .MuiPickersDay-root": {
                          "&:hover": {
                            backgroundColor: "var(--primary-color)", // Hover effect on dates
                            color: "#fff",
                          },
                        },
                      }}
                      PopperProps={{
                        placement: "bottom-start",
                      }}
                    />
                  </LocalizationProvider>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems="center"
                    spacing={1}
                    sx={{ width: "100%" }}
                  >
                    <Box sx={{ flexGrow: 1, width: "100%" }}>
                      <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                        selectProps={{
                          value: locationVal,
                          onChange: (newValue) => {
                            debugger;
                            setLocationVal(newValue);
                          },
                          placeholder: "Location",
                          isClearable: true,
                          styles: {
                            control: (provided, state) => ({
                              ...provided,
                              minHeight: "56px", // Matches MUI TextField
                              borderRadius: 3,
                              borderWidth: state.isFocused ? "2px" : "1px",
                              padding: "0 14px", // Matches MUI TextField padding
                              borderColor: state.isFocused
                                ? "var(--primary-color)"
                                : provided.borderColor,
                              boxShadow: "none",
                              cursor: "pointer",
                              "&:hover": {
                                borderColor: "var(--primary-color)",
                              },
                            }),
                            indicatorsContainer: (provided) => ({
                              ...provided,
                              height: "56px",
                            }),
                            input: (provided) => ({
                              ...provided,
                              padding: "0",
                              margin: "0",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0px",
                              height: "56px",
                            }),
                          },
                          components: {
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          },
                        }}
                        onLoadFailed={(error) =>
                          console.error(
                            "Google Places API failed to load",
                            error
                          )
                        }
                      />
                    </Box>
                  </Stack>
                </Box>
                {/* Search Header */}
                <Box sx={{ my: 2 }}>
                  <TextField
                    color="primary"
                    fullWidth
                    placeholder="Search categories, subcategories, types, brands..."
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    inputRef={searchInputRef}
                    autoFocus
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: searchTerm && (
                        <InputAdornment position="end">
                          <Clear
                            sx={{ cursor: "pointer" }}
                            onClick={() => setSearchTerm("")}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "var(--primary-color)",
                        }, // Default border
                        "&:hover fieldset": {
                          borderColor: "var(--primary-color)",
                        }, // Hover border
                        "&.Mui-focused fieldset": {
                          borderColor: "var(--primary-color)",
                        }, // Focus border
                        "& input": {
                          fontSize: "16px", // Increase font size
                          fontFamily: "'Helvetica', Arial, 'sans-serif'", // Change font family as desired
                        },
                        // Style the placeholder text
                        "& input::placeholder": {
                          fontSize: "16px",
                          fontFamily: "'Helvetica', Arial, 'sans-serif'",
                          color: "#666",
                        },
                      },
                    }}
                  />

                  {/* Selected Filters Section */}
                  {selectedFilters.length > 0 && (
                    <Box
                      color={"primary"}
                      sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}
                    >
                      {selectedFilters.map((filter) => (
                        <Chip
                          key={`${filter.category}-${filter.id}`}
                          label={filter.name}
                          onDelete={() => handleRemoveFilter(filter)}
                          color="primary"
                          variant="outlined"
                          size="small"
                          icon={
                            filter.icon ? (
                              <Typography
                                color={"primary"}
                                sx={{
                                  fontSize: ".75rem",
                                  ml: 1,
                                  color: "var(--primary-color)",
                                }}
                              >
                                {filter.icon}
                              </Typography>
                            ) : undefined
                          }
                        />
                      ))}
                      {selectedFilters.length > 0 && (
                        <Chip
                          label="Clear All"
                          onClick={() => setSelectedFilters([])}
                          color="primary"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </Box>
                  )}

                  {/* Filters Section */}
                </Box>
                <Box sx={{ display: "flex", gap: 1, minHeight: "55px", mb: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={handleClearFilters}
                    sx={{ flex: 1 }}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ flex: 1, boxShadow: "none" }}
                    onClick={navigateSearch}
                    startIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                </Box>

                <Divider sx={{ mb: 2 }} />

                {/* Results Sections */}
                <Box sx={{ overflow: "auto", flex: 1, maxHeight: 400 }}>
                  {/* Temporary results while typing */}
                  {Object.keys(results).length === 0 && (
                    <>
                      <Box sx={{ overflow: "hidden" }}>
                        {/* <Collapse in={isLoading} timeout={250}> */}
                        {isLoading && (
                          <Box sx={{ p: 2 }}>
                            <ShimmerUI count={results.length || 4} />
                          </Box>
                        )}
                        {/* </Collapse> */}
                        {/* <Collapse in={!isLoading} timeout={300}> */}
                        {!isLoading && (
                          <>
                            {searchTerm.length > 1 ? (
                              <>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ pl: 2, mb: 1, fontWeight: "bold" }}
                                >
                                  Filter Options:
                                </Typography>
                                {Object.entries(tempResults).map(
                                  ([section, items]) =>
                                    renderResultSection(section, items)
                                )}
                                {Object.keys(tempResults).length === 0 && (
                                  <Typography
                                    align="center"
                                    sx={{ py: 3, color: "text.secondary" }}
                                  >
                                    No filter options found
                                  </Typography>
                                )}
                              </>
                            ) : (
                              <Typography
                                align="center"
                                sx={{ py: 3, color: "text.secondary" }}
                              >
                                Start typing to see filter options
                              </Typography>
                            )}
                          </>
                        )}
                        {/* </Collapse> */}
                      </Box>
                      {/* {isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            p: 0,
                          }}
                        >
                          <ShimmerUI />
                        </Box>
                      ) : searchTerm.length > 1 ? (
                        <>
                          <Typography
                            variant="subtitle1"
                            sx={{ pl: 2, mb: 1, fontWeight: "bold" }}
                          >
                            Filter Options:
                          </Typography>
                          {Object.entries(tempResults).map(([section, items]) =>
                            renderResultSection(section, items)
                          )}
                          {Object.keys(tempResults).length === 0 &&
                            !isLoading &&
                            searchTerm.length > 1 && (
                              <Typography
                                align="center"
                                sx={{ py: 3, color: "text.secondary" }}
                              >
                                No filter options found
                              </Typography>
                            )}
                        </>
                      ) : (
                        <Typography
                          align="center"
                          sx={{ py: 3, color: "text.secondary" }}
                        >
                          Start typing to see filter options
                        </Typography>
                      )} */}
                    </>
                  )}

                  {/* Final results after search button click */}
                  {results.length > 0 && (
                    <>
                      <Typography
                        variant="subtitle1"
                        sx={{ pl: 2, mb: 1, fontWeight: "bold" }}
                      >
                        Search Results:
                      </Typography>

                      {/* Filter results based on selected filters */}
                      {results.map((item) => (
                        <Box key={`result-${item._id}`} sx={{ mb: 2 }}>
                          <List dense>
                            <ListItem
                              sx={{
                                borderRadius: 2,
                                "&:hover": {
                                  backgroundColor: "action.hover",
                                },
                              }}
                            >
                              {item.itemImages?.[0] && (
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                  <img
                                    src={item.itemImages[0]}
                                    alt={item.itemName}
                                    style={{
                                      width: 64,
                                      height: 64,
                                      borderRadius: 4,
                                    }}
                                  />
                                </ListItemIcon>
                              )}
                              <ListItemText
                                primary={item.itemName}
                                secondary={`${
                                  brands.find(
                                    (brd) => brd._id === item.itemBrand
                                  )?.label || null
                                } - ${item.itemYearManufacture} - ${
                                  item.itemCity || ""
                                } - ${
                                  item.ownerDetails
                                    ? item.ownerDetails.companyName || ""
                                    : ""
                                }`}
                                primaryTypographyProps={{ noWrap: true, ml: 1 }}
                                secondaryTypographyProps={{
                                  noWrap: true,
                                  ml: 1,
                                }}
                              />
                              <ListItemSecondaryAction>
                                <Link
                                  to={`/product/${item._id}`}
                                  target="_blank"
                                >
                                  <IconButton edge="end">
                                    <LuExternalLink size={".75em"} />
                                  </IconButton>
                                </Link>
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>

                          <Divider />
                        </Box>
                      ))}

                      {/* Show "No results" message if filtering removes all items */}
                      {results.length === 0 && (
                        <Typography
                          align="center"
                          sx={{ py: 3, color: "text.secondary" }}
                        >
                          No results match your selected filters
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Paper>
            </ClickAwayListener>
          </div>
        </Fade>
      )}
    </Popper>
  );
};
