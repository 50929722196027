import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Typography from "@mui/material/Typography";
import {
  Container,
  Stack,
  StepConnector,
  StepLabel,
  Tooltip,
  stepConnectorClasses,
  styled,
  tooltipClasses,
} from "@mui/material";
import CheckoutRentalAgreement from "./CheckoutRentalAgreement";
import { fetchProducts } from "../StateSlices/getCheckoutProductsSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import CheckoutShipping from "./CheckoutShipping";
import "./Checkout.css";
import { checkout, resetCheckout } from "../StateSlices/checkoutSlice";
import { useNavigate } from "react-router-dom";
import { getShippingPrice, taxReset } from "../StateSlices/getShippingSlice";
import { Handshake, LocalShipping } from "@mui/icons-material";
import PropTypes from "prop-types";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { getuserInfo } from "../StateSlices/getuserSlice";
import CheckoutCart from "./CheckoutCart";
import { calculateTotal } from "../../utils/utils";
import TagManager from "react-gtm-module";

const DarkToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--black-color)",
    borderRadius: 0,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 30,
    padding: 10,
    backgroundColor: "var(--black-color)",
    border: "2px solid var(--black-color)",
    textAlign: "center",
    fontSize: "14px",
  },
}));

const toastOption = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

export default function CheckoutStepper({ setCart }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo, error, profile } = useSelector((state) => state.user);
  const { cherror, message, checkoutHistory } = useSelector(
    (state) => state.checkout
  );
  const { cproducts } = useSelector((state) => state.checkoutproducts);
  const {
    shippingStatus,
    shippingInfo,
    shippingError,
    salesTax,
    cardDetails,
    transportCost,
    secretInfo,
  } = useSelector((state) => state.shippingprice);

  const [steps, setSteps] = useState(["Equipment Cart", "Checkout"]);
  const [stepsTooltip, setStepsTooltip] = useState([
    "Selected equipment are added for rent or purchase",
    "Complete your transaction by providing payment and shipping details to finalize your order",
  ]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [secret, setSecret] = useState(null);
  const [bbuy, setBbuy] = React.useState([]);
  const [rrent, setRrent] = React.useState([]);
  const [spinner1, setSpinner1] = React.useState(false);
  const [loaderBtnSpinner, setLoaderBtnSpinner] = useState(false);
  const [tempTotal, setTempTotal] = useState(0);
  const [token, setToken] = useState(null);
  const [tax, setTax] = useState(0);
  const [fees, setFees] = useState(100);
  const [transportation, setTransportation] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [taxCalculated, setTaxCalculated] = useState(false);
  const [docuSignAgreement, setDocuSignAgreement] = useState(null);
  const [docusignDone, setDocusignDone] = useState(false);
  const [saveDocuSignAgreement, setSaveDocuSignAgreement] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [rentTotal, setRentTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [buyTotal, setBuyTotal] = useState(0);
  const [cardSavedDetails, setCardSavedDetails] = useState(null);
  const [saveCard, setSaveCard] = useState(true);
  const [saveAddress, setSaveAddress] = useState(true);
  const [promocode, setPromocode] = useState("");

  //Shipping Details Field
  const [pointOfContact, setPointOfContact] = useState("");
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const [Address, setAddress] = useState("");
  const [instruction, setInstruction] = useState("");
  const [State, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("USA");
  const [rentEstimates, setRentEstimates] = useState([]);

  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCvc, setCardCvc] = useState("");
  const [docuSignByOwn, setDocuSignByOwn] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1200);

    return () => {
      // Cleanup function
      clearTimeout(timeoutId);
    };
  }, []);

  // Commented by Ketul
  // useEffect(() => {
  //   const urlSearchParams = new URLSearchParams(window.location.search);
  //   const dsSuccessParam = urlSearchParams.get("event");
  //   if (dsSuccessParam === "signing_complete") {
  //     setSteps(["Equipment Cart", "Rental Agreement", "Checkout"]);

  //     setStepsTooltip([
  //       "Selected equipment are added for rent or purchase",
  //       "Rental Agreement",
  //       "Complete your transaction by providing payment and shipping details to finalize your order",
  //     ]);

  //     setActiveStep(1);
  //     toast.info("Document Signed Successfully", toastOption);
  //     setDocuSignAgreement(userInfo?.userDetailsId?.docuSignAgreement);
  //     setDocusignDone(true);
  //   }
  // }, []);

  useEffect(() => {
    // dispatch(reset());
    if (userInfo) {
      if (userInfo.userDetailsId.addressCheckBox !== undefined) {
        setSaveAddress(userInfo.userDetailsId.addressCheckBox);
      }
      if (userInfo.userDetailsId.cardCheckBox !== undefined) {
        setSaveCard(userInfo.userDetailsId.cardCheckBox);
      }
      if (userInfo.userDetailsId.promoCount) {
        setDiscount(50);
      }
      setPointOfContact(
        [
          userInfo.userDetailsId.userFName,
          userInfo.userDetailsId.userLName,
        ].join(" ")
      );
      setContactPhoneNumber(userInfo.userDetailsId.userPhone);
      setAddress(userInfo.userDetailsId.userAddress);
      setState(userInfo.userDetailsId.userState);
      setCity(userInfo.userDetailsId.userCity);
      setZipCode(userInfo.userDetailsId.userZipCode);
      let cart = JSON.parse(localStorage.getItem("cartItems")) || [];
      if (cart && cart.length > 0) {
        setSpinner1(true);
        dispatch(
          fetchProducts({
            token: localStorage.getItem("inktoken"),
            userId: userInfo._id,
          })
        );
      } else {
        setBbuy([]);
        setRrent([]);
      }
    }
  }, [userInfo]);

  // PRODUCT PURCHASED EFFECT
  useEffect(() => {
    if (cherror) {
      toast.error(cherror, toastOption);
      dispatch(resetCheckout());
      setSpinner1(false);
    }
    if (message) {
      toast.success("Product Sucessfully Purchased", toastOption);
      sessionStorage.clear("closetcart");
      let cart = JSON.parse(localStorage.getItem("cartItems"));
      localStorage.removeItem("cartItems");
      localStorage.removeItem("localUpload");
      dispatch(resetCheckout());
      let token = localStorage.getItem("inktoken");
      if (token) {
        dispatch(getuserInfo({ token: token }));
      }
      // console.log("King what happened", buyTotal, rentTotal);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Checkout Conversion",
        action: "Checkout",
        label: "Checkout",
        checkoutId: checkoutHistory.orderId,
        fees: fees,
        products: checkoutHistory.productArr,
        discount: discount,
        rentEstimates: rentEstimates,
        tax: tax,
        buyTotal: buyTotal,
        rentTotal: rentTotal,
        subTotal: subTotal,
        transportation,
        totalPricePaid: checkoutHistory.totalPricePaid,
        agreement: checkoutHistory.agreement
          ? checkoutHistory.agreement
          : checkoutHistory.docusignAgreement,
        stripCId: checkoutHistory.stripCId,
        shippingAddress: checkoutHistory.shippingAddress,
        shippingCity: checkoutHistory.shippingCity,
        shippingState: checkoutHistory.shippingState,
        shippingZipCode: checkoutHistory.shippingZipCode,
        userId: userInfo._id,
      });
      TagManager.dataLayer({ dataLayer: window.dataLayer });
      navigate("/order-confirmation", {
        state: {
          fees: fees,
          buyProducts: bbuy,
          rentProducts: rrent,
          discount: discount,
          rentEstimates: rentEstimates,
          tax: tax,
          checkoutHistory: checkoutHistory,
          buyTotal: buyTotal,
          rentTotal: rentTotal,
          subTotal: subTotal,
          transportation,
          instruction,
          cart,
        },
      });
      setSpinner1(false);
    }
  }, [cherror, message]);

  // CHECKOUT PRODUCTS FETCH EFFECT
  useEffect(() => {
    if (cproducts) {
      if (cproducts.length > 0) {
        console.log("CHECKOUT CPRODUCTS", cproducts);
        let cartItems = JSON.parse(localStorage.getItem("cartItems"));
        setBbuy(
          cproducts.filter(
            (item) =>
              item.itemToSell &&
              cartItems &&
              cartItems.length > 0 &&
              cartItems.find((ct) => ct.id === item._id)?.itemType === "buy"
          )
        );
        setRrent(
          cproducts.filter(
            (item) =>
              item.itemForRent &&
              cartItems &&
              cartItems.length > 0 &&
              cartItems.find((ct) => ct.id === item._id)?.itemType === "rent"
          )
        );
        setSpinner1(false);
      }
    }
  }, [cproducts]);

  // Commented by Ketul
  // useEffect(() => {
  //   if (userInfo && rrent && rrent.length > 0) {
  //     if (
  //       !userInfo.userDetailsId.docusignAgreement ||
  //       userInfo.userDetailsId.docuSignAgreement == ""
  //     ) {
  //       setSteps(["Equipment Cart", "Rental Agreement", "Checkout"]);
  //       setStepsTooltip([
  //         "Selected equipment are added for rent or purchase",
  //         "Rental Agreement",
  //         "Complete your transaction by providing payment and shipping details to finalize your order",
  //       ]);
  //     }
  //   }
  // }, [rrent, userInfo]);

  // TOTAL + SALES TAX + TRANSPORTATION COST
  useEffect(() => {
    console.log("salesTax", salesTax);
    if (secretInfo) {
      setSecret(secretInfo);
    }
    if (salesTax != null) {
      console.log(salesTax);
      setTax(salesTax);
      const result = calculateTotal({
        rentEstimates,
        bbuy,
        cproducts,
        transportation, // Pass the transportation value
        discount,
        tax: salesTax,
      });

      setRentTotal(result.rentSubTotal);
      setBuyTotal(result.buySubTotal);
      setSubTotal(result.subTotal);
      setFees(result.feesLocal);
      console.log("FEEES", result);
      // Only set fees if transportation > 0
      if (transportation > 0) {
        setFees(result.feesLocal);
      } else {
        // setFees(0); // Ensure fees are set to 0
      }

      setTotalAmount(result.discountedTotal);
      setTempTotal(result.discountedTotal);

      console.log(result);

      setCardSavedDetails(cardDetails);

      console.log("k2l saved card", cardDetails);
      setTransportation(transportCost);
      // setTempTotal(tempTotal+transportCost)
      // calculateTotal();
      dispatch(taxReset());
      setTaxCalculated(true);
      setLoaderBtnSpinner(false);
    }
  }, [salesTax, transportCost]);

  useEffect(() => {
    console.log("effect inside checkout stepper");
    console.log(rrent);
    if (rrent) {
      let rentalItem = [];
      let cartItems = JSON.parse(localStorage.getItem("cartItems"));
      if (cartItems && cartItems.length > 0) {
        rentalItem = cartItems.filter((item) => item.itemType === "rent");
      }
      rrent.map((item, ind) => {
        rentalItem = rentalItem.filter((itemm) => itemm.id === item._id);
        if (rentalItem.length > 0) {
          let rentPeriodInDays = calculateDateDifference(
            rentalItem[0].checkoutRentalDateRange[0],
            rentalItem[0].checkoutRentalDateRange[1]
          );
          console.log("DAY FIFF", rentPeriodInDays);
          let totalRentPrice = 0;
          console.log(rentPeriodInDays);
          if (rentPeriodInDays > 0) {
            const dailyPrice = parseInt(item.itemDailyPrice) || 0;
            const weeklyPrice = parseInt(item.itemWeeklyPrice) || 0;
            const monthlyPrice = parseInt(item.itemMonthlyPrice) || 0;

            // const weeks = Math.floor(rentPeriodInDays / 7);
            // const remainingDays = rentPeriodInDays % 7;
            let weeks = 0;
            let months = 0;
            let days = 0;

            let semirent = rentPeriodInDays;

            while (semirent > 0) {
              if (semirent > 17) {
                // More than 2.5 weeks
                months += 1;
                totalRentPrice += monthlyPrice;
                semirent -= 28;
              } else if (semirent > 3) {
                // More than 3 days
                weeks += 1;
                totalRentPrice += weeklyPrice;
                semirent -= 7;
              } else {
                days += 1;
                totalRentPrice += dailyPrice * semirent;
                semirent = 0;
              }
            }

            let incCost = 0;
            if (
              item.rentalInsurancePercentage &&
              item.rentalInsurancePercentage > 0
            ) {
              incCost = parseInt(
                (totalRentPrice * item.rentalInsurancePercentage) / 100
              );
            }

            if (item.itemFuelPrice) {
              totalRentPrice += item.itemFuelPrice;
            }
            if (item.itemMaintenancePrice) {
              const hoursPerDay = 24; // Number of hours in a day
              const baseMaintenancePrice = item.itemMaintenancePrice; // The base maintenance price for 250 hours

              // Calculate the total rental period in hours
              const totalRentPeriodInHours = rentPeriodInDays * hoursPerDay;

              // Calculate the number of 250-hour intervals
              const intervals = Math.ceil(totalRentPeriodInHours / 250);

              // Calculate the total maintenance cost
              const maintenanceCost = intervals * baseMaintenancePrice;

              // Add to the total rent price
              totalRentPrice += maintenanceCost;
              console.log(`Total Maintenance Cost: ${totalRentPeriodInHours}`);
            }
            if (item.itemCleaningPrice) {
              totalRentPrice += item.itemCleaningPrice;
            }

            setRentEstimates((prev) => {
              // Check if [item._id] is already present in the state
              if (prev) {
                const isItemPresent = prev.some((rentEstimate) =>
                  rentEstimate.hasOwnProperty(item._id)
                );

                //console.log("debugger", isItemPresent);

                // If [item._id] is not present, add it
                if (!isItemPresent) {
                  return [
                    ...prev,
                    {
                      [item._id]: {
                        rentPeriodInDays: rentPeriodInDays,
                        totalRentPrice: totalRentPrice,
                        incCost: incCost,
                      },
                    },
                  ];
                }

                // If [item._id] is already present, return the unchanged state
                return prev;
              } else {
                // //console.log(
                //   "hello first",
                //   rentPeriodInDays,
                //   totalRentPrice
                // );
                return [
                  ...prev,
                  {
                    [item._id]: {
                      rentPeriodInDays: rentPeriodInDays,
                      totalRentPrice: totalRentPrice,
                      incCost: incCost,
                    },
                  },
                ];
              }
            });
          }
        }
      });
    }
  }, [rrent]);

  // BUY + RENT PRODUCTS CHANGE EFFECT
  useEffect(() => {
    const result = calculateTotal({
      rentEstimates,
      bbuy,
      cproducts,
      transportation, // Pass the transportation value
      discount,
      tax,
    });

    setRentTotal(result.rentSubTotal);
    setBuyTotal(result.buySubTotal);
    setSubTotal(result.subTotal);
    setFees(result.feesLocal);
    console.log("FEEES", result);
    // Only set fees if transportation > 0
    if (transportation > 0) {
      setFees(result.feesLocal);
    } else {
      // setFees(0); // Ensure fees are set to 0
    }

    setTotalAmount(result.discountedTotal);
    setTempTotal(result.discountedTotal);

    console.log(result);
  }, [bbuy, rrent, transportation]);

  useEffect(() => {
    //console.log("rent estimates", rentEstimates);
    if (rentEstimates && rentEstimates.length > 0) {
      const result = calculateTotal({
        rentEstimates,
        bbuy,
        cproducts,
        transportation, // Pass the transportation value
        discount,
        tax,
      });

      setRentTotal(result.rentSubTotal);
      setBuyTotal(result.buySubTotal);
      setSubTotal(result.subTotal);
      setFees(result.feesLocal);
      // Only set fees if transportation > 0
      if (transportation > 0) {
        setFees(result.feesLocal);
      } else {
        // setFees(0); // Ensure fees are set to 0
      }

      setTotalAmount(result.discountedTotal);
      setTempTotal(result.discountedTotal);

      console.log(result);
    }
  }, [rentEstimates]);

  const handleNext = () => {
    window.scrollTo(0, 0);
    if (steps.includes("Rental Agreement") && activeStep === 1) {
      validateRentAgreement(activeStep + 1);
    } else {
      if (activeStep === 0) {
        setActiveStep(activeStep + 1);
      } else if (activeStep === 1) {
        validateShipping(activeStep + 1);
      }
    }
  };

  const handleBack = () => {
    // let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    // if (cart.length === 0) {
    //   return false;
    // }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (stepp) => () => {
    window.scrollTo(0, 0);
    let cart = JSON.parse(localStorage.getItem("cartItems")) || [];
    if (cart.length > 0) {
      if (steps.includes("Rental Agreement") && stepp === 2) {
        validateRentAgreement(stepp);
      } else if (activeStep === 1 && stepp > activeStep) {
        validateShipping(stepp);
      } else {
        setActiveStep(stepp);
      }
    } else {
      toast.info("Please add equipment to proceed further", toastOption);
    }
    if (taxCalculated && stepp < 2) {
      setTaxCalculated(false);
    }
  };

  const handleSubmit = (token) => {
    setSpinner1(true);

    //   if (fileAgreement && fileAgreement.file) {
    //     const timestamp = Math.round(new Date().getTime() / 1000);
    //     const params = {
    //       timestamp: timestamp,
    //       upload_preset: "closet-closest",
    //       // add any additional parameters here, such as transformation options
    //     };

    //     const signature = CryptoJS.SHA1(
    //       `timestamp=${params.timestamp}&upload_preset=${params.upload_preset}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`
    //     ).toString();

    //     const formData = new FormData();
    //     formData.append("file", fileAgreement.file);
    //     formData.append("upload_preset", "closet-closest");
    //     formData.append("cloud_name", "closet-closest");
    //     formData.append("signature", signature);
    //     formData.append("timestamp", timestamp);
    //     formData.append("api_key", process.env.REACT_APP_CLOUDINARY_API_TOKEN);

    //     return axios
    //       .post(process.env.REACT_APP_CLOUDINARY_API_URL, formData, {
    //         headers: { "X-Requested-With": "XMLHttpRequest" },
    //       })
    //       .then((response) => {
    //         const data = response.data;
    //         const fileURL = data.url; // You should store this URL for future references in your app
    //         console.log(
    //           tempTotal,
    //           tax.tax_amount_exclusive ? tax.tax_amount_exclusive / 100 : 0
    //         );
    //         const payload = {
    //           token: token,
    //           tax: tax,
    //           pointOfContact: pointOfContact,
    //           contactPhoneNumber: contactPhoneNumber,
    //           Address: Address,
    //           State: State,
    //           zipCode: zipCode,
    //           city: city,
    //           country: country,
    //           products: cproducts,
    //           fileAgreement: fileURL,
    //           docuSignAgreement: docuSignAgreement ? docuSignAgreement : "",
    //           saveAddress: saveAddress,
    //           saveCard: saveCard,
    //           fees: fees,
    //           transportation,
    //           instruction,
    //           total: tempTotal,
    //         };

    //         dispatch(
    //           checkout({
    //             checkoutData: payload,
    //             token: localStorage.getItem("inktoken"),
    //           })
    //         );
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //         throw new Error("Something went Wrong!!!!");
    //       });
    //   }
    // else {
    console.log(
      tempTotal,
      tax.tax_amount_exclusive ? tax.tax_amount_exclusive / 100 : 0
    );
    const payload = {
      token: token,
      tax: tax,
      pointOfContact: pointOfContact,
      contactPhoneNumber: contactPhoneNumber,
      Address: Address,
      State: State,
      zipCode: zipCode,
      city: city,
      country: country,
      products: cproducts,
      docuSignAgreement: docuSignAgreement ? docuSignAgreement : "",
      saveDocuSignAgreement: saveDocuSignAgreement,
      fees: fees,
      saveAddress: saveAddress,
      saveCard: saveCard,
      transportation,
      instruction,
      total: tempTotal,
    };

    dispatch(
      checkout({
        checkoutData: payload,
        token: localStorage.getItem("inktoken"),
      })
    );
    // }
  };

  const calculateDateDifference = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Calculate the difference in milliseconds
    const timeDifference = endDate - startDate;

    // Convert the time difference to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Calculate months, weeks, and additional days
    // const months = Math.floor(daysDifference / 30);
    // const remainingDays = daysDifference % 30;
    // const weeks = Math.floor(remainingDays / 7);
    // const additionalDays = remainingDays % 7;

    // return { days: additionalDays, weeks, months };
    return daysDifference + 1;
  };

  const getTaxRates = () => {
    let toAddress = {
      street1: Address,
      city,
      zip: zipCode,
      state: State,
    };
    let prodDetails = {};
    let rentalItem = [];
    let cartItems = JSON.parse(localStorage.getItem("cartItems"));
    let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    console.log(cartItems);
    if (cartItems && cartItems.length > 0) {
      // setLoaderBtnSpinner(true);
      for (let i = 0; i < cartItems.length; i++) {
        let item = cartItems[i];
        if (item.itemType == "buy" && !prodDetails[item.id]) {
          prodDetails[item.id] = {
            days: "buy",
          };
        } else if (item.itemType == "rent" && !prodDetails[item.id]) {
          let rentPeriodInDays = calculateDateDifference(
            item.checkoutRentalDateRange[0],
            item.checkoutRentalDateRange[1]
          );
          console.log("DAY DIFF", rentPeriodInDays);
          prodDetails[item.id] = {
            days: rentPeriodInDays,
          };
        }
      }
    }
    let token = localStorage.getItem("inktoken");
    setTaxCalculated(false);
    dispatch(
      getShippingPrice({
        shippingData: { toAddress, prodDetails, refer: discount, fees: fees },
        token,
      })
    );
  };

  const handleSetToken = (token) => {
    setToken(token);
    handleSubmit(token);
  };

  const validateShipping = (newActiveStep) => {
    if (
      pointOfContact == "" ||
      contactPhoneNumber == "" ||
      Address == "" ||
      State == "" ||
      zipCode == "" ||
      city == "" ||
      country == ""
    ) {
      toast.error("Please Fill Out all the Details", toastOption);
      return;
    } else {
      setLoaderBtnSpinner(true);
      getTaxRates();
      //setActiveStep(newActiveStep);
    }
  };

  const validateRentAgreement = (newActiveStep) => {
    if (!docusignDone) {
      toast.error("Please complete the rental agreement", toastOption);
    } else {
      setActiveStep(newActiveStep);
    }
  };

  // CUSTOM STEPPER
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 27,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,var(--black-color) 0%,var(--black-color),var(--black-color) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[500]
          : "var(--black-color)",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = React.memo(
    styled("div")(({ theme, ownerState }) => ({
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[700]
          : "var(--secondary-color)",
      zIndex: 1,
      color: "var(--white-color)",
      padding: "1rem",
      display: "flex",
      borderRadius: !ownerState.active ? "50%" : "30px",
      justifyContent: "center",
      alignItems: "center",
      ...(ownerState.active && {
        background: "var(--secondary-color)",
      }),
      cursor: "pointer",
      width: "fit-content",
      height: "60px",
    }))
  );

  function ColorlibStepIcon(props) {
    const { active, className } = props;
    let icons = null;
    if (!steps.includes("Rental Agreement")) {
      icons = {
        1: <ShoppingCartIcon />,
        2: <LocalShipping />,
      };
    } else {
      icons = {
        1: <ShoppingCartIcon />,
        2: <Handshake />,
        3: <LocalShipping />,
      };
    }

    return (
      <ColorlibStepIconRoot ownerState={{ active }} className={className}>
        {icons[String(props.icon)]}
        {active && (
          <Typography marginLeft={".5rem"}>{steps[props.icon - 1]}</Typography>
        )}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
    index: PropTypes.any,
  };
  // const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
  // END CUSTOM STEPPER

  return (
    <Container
      // maxWidth={"lg"}
      sx={{ margin: "2rem auto" }}
      data-aos="fade-in"
      data-aos-duration="1000"
    >
      <Stack
        sx={{ width: "100%" }}
        data-aos="fade-down"
        data-aos-duration="1200"
      >
        <Stepper
          activeStep={activeStep}
          alternativeLabel={true}
          connector={<ColorlibConnector />}
          // style={{ marginBottom: "2rem" }}
        >
          {steps.map((label, index) => {
            return (
              <DarkToolTip
                arrow
                className="gd-tooltip"
                placement="bottom"
                title={stepsTooltip[index]}
              >
                <Step key={label} onClick={handleStep(index)}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                </Step>
              </DarkToolTip>
            );
          })}
        </Stepper>
      </Stack>
      <div>
        <>
          {spinner1 && <Spinner pos={"relative"} />}
          <React.Fragment>
            <Box
              sx={{
                minHeight: "70vh",
              }}
              style={spinner1 ? { display: "none" } : {}}
            >
              {activeStep === 0 && (
                <CheckoutCart
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  steps={steps}
                  setTempTotal={setTempTotal}
                  setCart={setCart}
                  fees={fees}
                  setFees={setFees}
                  discount={discount}
                  spinner1={spinner1}
                  transportFees={transportation}
                />
              )}
              {steps.includes("Rental Agreement") ? (
                <>
                  {activeStep === 1 && (
                    <CheckoutRentalAgreement //RENTAL AGREEMENT
                      rrent={rrent}
                      setActiveStep={setActiveStep}
                      docusignDone={docusignDone}
                      setDocusignDone={setDocusignDone}
                      docuSignAgreement={docuSignAgreement}
                      setDocuSignAgreement={setDocuSignAgreement}
                      saveDocuSignAgreement={saveDocuSignAgreement}
                      setSaveDocuSignAgreement={setSaveDocuSignAgreement}
                    />
                  )}
                  {activeStep === 2 && (
                    <CheckoutShipping
                      pointOfContact={pointOfContact}
                      setPointOfContact={setPointOfContact}
                      contactPhoneNumber={contactPhoneNumber}
                      setContactPhoneNumber={setContactPhoneNumber}
                      Address={Address}
                      setAddress={setAddress}
                      instruction={instruction}
                      setInstruction={setInstruction}
                      State={State}
                      setState={setState}
                      zipCode={zipCode}
                      setZipCode={setZipCode}
                      city={city}
                      setCity={setCity}
                      country={country}
                      setCountry={setCountry}
                      //CHeckout Payment Props
                      tempTotal={tempTotal}
                      handleSubmit={handleSubmit}
                      spinner1={setSpinner1}
                      setSpinner1={setSpinner1}
                      tax={tax}
                      validateShipping={validateShipping}
                      taxCalculated={taxCalculated}
                      setToken={handleSetToken}
                      loaderBtnSpinner={loaderBtnSpinner}
                      setCardNumber={setCardNumber}
                      setCardExpiry={setCardExpiry}
                      setCardCvc={setCardCvc}
                      cardNumber={cardNumber}
                      cardExpiry={cardExpiry}
                      cardCvc={cardCvc}
                      cardDetails={cardSavedDetails}
                      setSaveCard={setSaveCard}
                      setSaveAddress={setSaveAddress}
                      fees={fees}
                      discount={discount}
                      transportFees={transportation}
                      setPromocode={setPromocode}
                      promocode={promocode}
                      secret={secret}
                    />
                  )}
                </>
              ) : (
                <>
                  {activeStep === 1 && (
                    <CheckoutShipping
                      pointOfContact={pointOfContact}
                      setPointOfContact={setPointOfContact}
                      contactPhoneNumber={contactPhoneNumber}
                      setContactPhoneNumber={setContactPhoneNumber}
                      Address={Address}
                      setAddress={setAddress}
                      instruction={instruction}
                      setInstruction={setInstruction}
                      State={State}
                      setState={setState}
                      zipCode={zipCode}
                      setZipCode={setZipCode}
                      city={city}
                      setCity={setCity}
                      country={country}
                      setCountry={setCountry}
                      //CHeckout Payment Props
                      tempTotal={tempTotal}
                      handleSubmit={handleSubmit}
                      spinner1={setSpinner1}
                      setSpinner1={setSpinner1}
                      tax={tax}
                      validateShipping={validateShipping}
                      taxCalculated={taxCalculated}
                      setToken={handleSetToken}
                      setCardNumber={setCardNumber}
                      setCardExpiry={setCardExpiry}
                      setCardCvc={setCardCvc}
                      cardNumber={cardNumber}
                      cardExpiry={cardExpiry}
                      cardCvc={cardCvc}
                      cardDetails={cardSavedDetails}
                      setSaveCard={setSaveCard}
                      setSaveAddress={setSaveAddress}
                      fees={fees}
                      discount={discount}
                      transportFees={transportation}
                      setPromocode={setPromocode}
                      promocode={promocode}
                      secret={secret}
                    />
                  )}
                </>
              )}
            </Box>
          </React.Fragment>
        </>
      </div>
    </Container>
  );
}
