import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Founder from "../../components/Founder/Founder";
import "./Signup.css";
import { Link } from "react-router-dom";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import {
  Formik,
  useFormik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
  useFormikContext,
} from "formik";
import { ToastContainer, toast } from "react-toastify";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  registerUser,
  reset,
} from "../../components/StateSlices/registerSlice";
import Spinner from "../../components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import FounderSignup from "../../components/FounderSignup/FounderSignup";
import SignupLogo from "../../assets/Pictures/signup-logo.png";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import GoogleIcon from "@mui/icons-material/Google";
import TagManager from "react-gtm-module";
import { FcGoogle } from "react-icons/fc";

const REDIRECT_URI = "http://localhost:3000/login";

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors, submitForm } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [provider, setProvider] = useState("email");
  const [couponApplied, setCouponApplied] = useState(null)

  const dispatch = useDispatch();
  let navigate = useNavigate();
  // const { submitForm } = useFormikContext();

  const { status, userRegistered, error, userDetails, amountPaid, referredBy, promoMessage } =
    useSelector((state) => state.register);

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  let toastOption2 = {
    ...toastOption,
    autoClose: false,
  };

  if (userRegistered) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'Signup Conversion',
        action: 'Successful Verification',
        label: 'Signup Conversion',
        affiliation:referredBy,
        coupon: couponApplied,
        userEmail:userDetails.userEmail,
        userId:userDetails._id,
        userMembership:userDetails.membership,
        subscriptionPaid:amountPaid
    });
    TagManager.dataLayer({ dataLayer: window.dataLayer });
    toast.success("User Succesfully Registered", toastOption);
    if(promoMessage){
      toast.info(promoMessage, toastOption2);
    }

    dispatch(reset());
    // setTimeout(() => {
    // if (provider === "email" && userDetails.membership !== "trial") {
    // navigate("/confirmation", { state: userDetails });
    // } else if (provider === "email" && userDetails.membership === "trial") {
    // navigate("/guest-confirmation", { state: userDetails });
    // } else {

    if(provider=="google"){
      navigate("/login");
    }else{
      navigate("/verify-email", { state: userDetails });
    }
    
    // }
    // }, 5000);
  }

  if (error) {
    toast.error(error, toastOption);
    dispatch(reset());
    setSpinner(false);
  }

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  let formikRef = useRef();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setSpinner(true);
          console.log(res.data);

          formikRef.current.setFieldValue("email", res.data.email);
          formikRef.current.setFieldValue("password", "google1234");
          formikRef.current.setFieldValue("confirmPassword", "google1234");
          setProvider("google");
          formikRef.current.setErrors({});
          setSpinner(false);
          // formikRef.current.touched({});
          // setTimeout(() => {
          //   formikRef.current.handleSubmit();
          // }, 1000);

          // submitForm();
          console.log(formikRef.current);
        })
        .catch((err) => console.log(err));
      console.log("Login Success:", codeResponse);
      // Send code to server to exchange for access token
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <div className="page-container" style={{ position: "relative" }}>
      {spinner ? <Spinner /> : ""}
      <Formik
        innerRef={formikRef}
        initialValues={{
          email: "",
          password: "",
          confirmPassword: "",
          membership: "free",
          promocode:""
        }}
        initialErrors={{
          email: "Enter Valid Email",
          password: "Please enter your Email",
          confirmPassword: "Please reenter your Password",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Enter Valid Email")
            .required("Please enter your Email"),
          password: Yup.string("Enter your Password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Please enter your Password"),
          confirmPassword: Yup.string().required(
            "Please reenter your Password"
          ),
        })}
        onSubmit={(values, actions) => {
          console.log("SUbmitting");
          setSpinner(true);

          if (values.password === values.confirmPassword) {
            let payload = {};
            console.log(values);
            payload = { ...values, email: values.email.toLowerCase() };

            if(values.promocode) setCouponApplied(values.promocode)
            // payload.token = id;
            payload.membership = "trial";
            payload.provider = provider;
            // payload.coupen = coupen;
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get("referral")) {
              payload.referral = decodeURIComponent(urlParams.get("referral"));
              console.log(payload.referral);
            }

            console.log(payload);
            dispatch(registerUser(payload));
          } else {
            setSpinner(false);
            toast.error("Password Doesn't Match", toastOption);
          }
          // } else {
          //   setSpinner(false);
          //   toast.error("Please Agree Terms & Conditions", toastOption);
          // }
          return false;
        }}
        render={(props) => {
          let {
            setFieldValue,
            values,
            handleBlur,
            handleChange,
            touched,
            errors,
            handleSubmit,
          } = props;

          return (
            <Box component={"div"} className="signup_con">
              <Box component={"div"} className="signup_wrapper">
                {/* <div className="left">
                  <FounderSignup />
                </div> */}
                <div className="right">
                  <div style={{ textAlign: "center", margin: "1rem auto" }}>
                    <img
                      src={SignupLogo}
                      width={"40px"}
                      alt="signup-logo img"
                    />
                  </div>
                  <Typography
                    variant="h3"
                    align="center"
                    marginBottom={"1rem"}
                    fontWeight={700}
                    className="mont-title"
                  >
                    Create New Account
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    fontWeight={700}
                    color={"text.secondary"}
                    mb={"1rem"}
                    textTransform={"uppercase"}
                  >
                    Start for Free
                  </Typography>
                  <Button
                    onClick={login}
                    sx={{
                      width: "100%",
                      background: "#f1f1f1",
                      color: "black",
                      fontWeight: "normal",
                      padding: "10px 0px",
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    <FcGoogle
                     
                        size={"1.5em"}
                    />{" "}
                    <span>Sign Up with Google</span>
                  </Button>
                  {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} onClick={login}/> */}
                  {/* <Divider sx={{ marginTop: ".5rem" }} /> */}
                  {/* <LoginSocialGoogle
                      client_id={
                        process.env.REACT_APP_GG_APP_ID ||
                        "847743167985-8r5scn91q6pbpo2m85jilhmvj3bjog14.apps.googleusercontent.com"
                      }
                      // onLoginStart={onLoginStart}
                      redirect_uri={REDIRECT_URI}
                      scope="openid profile email"
                      discoveryDocs="https://www.googleapis.com/discovery/v1/apis/oauth2/v2/rest"
                      access_type="offline"
                      onResolve={({ provider, data }) => {
                        console.log(provider);
                        console.log(data);
                        axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${data.code}`, {
                          headers: {
                              Authorization: `Bearer ${data.code}`,
                              Accept: 'application/json'
                          }
                      })
                      .then((res) => {
                          console.log(res.data);
                      })
                      .catch((err) => console.log(err));
                        // setFieldValue("email", data.email);
                        // setFieldValue("password", "google");
                        // setFieldValue("confirmPassword", "google");
                        // setProvider(provider);
                        // handleSubmit()
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <GoogleLoginButton
                        text="Sign up with Google"
                        style={{
                          minWidth: "100%",
                          boxShadow: "none",
                          background: "#f1f1f1",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px",
                        }}
                        activeStyle={{ background: "eee" }}
                      />
                    </LoginSocialGoogle> */}

                  {/* <button className='btn dark-btn facebook-btn social-btn-width'><i class="fab fa-facebook-f"></i>Continue With <strong>Facebook</strong></button> */}

                  {/* <LoginSocialFacebook
                      appId={process.env.REACT_APP_FB_APP_ID || "642917193774283"}
                      fieldsProfile={
                        "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                      }
                      // onLoginStart={onLoginStart}
                      // onLogoutSuccess={onLogoutSuccess}
                      redirect_uri={REDIRECT_URI}
                      onResolve={({ provider, data }) => {
                        console.log(provider);
                        console.log(data);
                          setFieldValue("email", data.email);
                          setProvider(provider);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <FacebookLoginButton
                        text="Sign up with Facebook"
                        style={{
                          minWidth: "100%",
                          boxShadow: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px",
                        }}
                      />
                    </LoginSocialFacebook> */}
                  {/* <br />
                    <Typography variant="h5" fontWeight={700} textAlign={"center"}>
                      OR
                    </Typography> */}

                  <form onSubmit={handleSubmit}>
                    <Box
                      component="form"
                      // sx={{
                      //   "& .MuiTextField-root": {
                      //     m: 1,
                      //     width: "40%",
                      //     justifyContent: "space-between",
                      //   },
                      // }}
                      noValidate
                      autoComplete="off"
                    >
                      {/* <Grid container spacing={1} sx={{ flexGrow: 1 }}>
                        <Grid item xs={6} spacing={1}>
                          <TextField
                            error={
                              touched.firstName && Boolean(errors.firstName)
                            } //true if error exists
                            required
                            name="firstName"
                            id="first-name"
                            label="First Name"
                            defaultValue=""
                            sx={{ width: "100%" }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                            helperText={touched.firstName && errors.firstName}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            name="lastName"
                            error={touched.lastName && Boolean(errors.lastName)} //true if error exists
                            id="last-name"
                            label="Last Name"
                            defaultValue=""
                            sx={{ width: "100%" }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                            helperText={touched.lastName && errors.lastName}
                          />
                        </Grid>
                      </Grid> */}
                      <TextField
                        label="Email Address"
                        fullWidth
                        margin="normal"
                        name="email"
                        disabled={provider==="google"?true:false}
                        //   onChange={handleChange}
                        //   value={values.email}
                        required
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        InputProps={
                          {
                            // startAdornment: (
                            //   <InputAdornment position="start">
                            //     <AccountCircle></AccountCircle>
                            //   </InputAdornment>
                            // ),
                          }
                        }
                      />
                      <TextField
                        label="Promo Code"
                        type={"text"}
                        fullWidth
                        margin="normal"
                        name="promocode"
                        id="promocode"
                        //   onChange={handleChange}
                        //   value={values.password}
                        // required
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.promocode}
                        // error={touched.password && Boolean(errors.password)}
                        // helperText={touched.password && errors.password}
                      />
                      {
                        provider=="google"?"":(
                          <>
                          <TextField
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          margin="normal"
                          name="password"
                          id="password"
                          //   onChange={handleChange}
                          //   value={values.password}
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          InputProps={{
                            // startAdornment: (
                            //   <InputAdornment position="start">
                            //     <Key></Key>
                            //   </InputAdornment>
                            // ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword((show) => !show)}
                                  onMouseDown={(event) => {
                                    event.preventDefault();
                                  }}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          label="Confirm Password"
                          type={showConfirmPassword ? "text" : "password"}
                          fullWidth
                          margin="normal"
                          name="confirmPassword"
                          id="password"
                          //   onChange={handleChange}
                          //   value={values.password}
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.confirmPassword}
                          error={
                            touched.confirmPassword &&
                            Boolean(errors.confirmPassword)
                          }
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          InputProps={{
                            // startAdornment: (
                            //   <InputAdornment position="start">
                            //     <Key></Key>
                            //   </InputAdornment>
                            // ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowConfirmPassword((show) => !show)
                                  }
                                  onMouseDown={(event) => {
                                    event.preventDefault();
                                  }}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        </>
                        )
                      }
                      
                      <Button
                        sx={{
                          marginTop: "2rem",
                          background: "var(--black-color)",
                          border: "1px solid transparent",
                          "&:hover": {
                            background: "var(--grey-color)",
                            borderColor: "var(--black-color)",
                            color: "var(--black-color)",
                          },
                        }}
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                      <Link to={"/login"}>
                        <Button
                          sx={{
                            my: "1rem",
                            color: "var(--black-color)",
                            borderColor: "var(--black-color)",
                            "&:hover": {
                              borderColor: "var(--dark-grey-color)",
                              background: "var(--grey-color)",
                            },
                          }}
                          fullWidth
                          variant="outlined"
                        >
                          Already a Member? Login
                        </Button>
                      </Link>
                    </Box>
                  </form>
                </div>
              </Box>
            </Box>
          );
        }}
      />
    </div>
  );
};

export default Signup;
