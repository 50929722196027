import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  dataStatus: "idle",
  dataInfo: null,
  dataError: null,
};

export const getUserDataExport = createAsyncThunk(
  "admin/getUserDataExport",
  async ({ token, p = 0, search, status = "active", type, filter = ["active"] }, { rejectWithValue }) => {
    try {
      let searchString = "";
      if (search) {
        searchString = "&search=" + encodeURIComponent(search);
      }
      const { data } = await axios.get(
        `/api/admin/getusersexport?page=${p}${searchString}&status=${status}&type=${type}&filter=${filter}?export=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "userDetails.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      // return new Blob(data);
      return { success: true };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProductDataExport = createAsyncThunk(
  "admin/getProductDataExport",
  async ({ token, p = 0, search, status = false, sort, report, purchased = false }, { rejectWithValue }) => {
    try {
      let searchString = "";
      if (search) {
        searchString = "&search=" + encodeURIComponent(search);
      }
      const { data } = await axios.get(
        `/api/admin/getproductsexport?page=${p}${searchString}&status=${status}&sort=${sort}&report=${report}&purchased=${purchased}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "productDetails.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      // return new Blob(data);
      return { success: true };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOrderDataExport = createAsyncThunk(
  "admin/getOrderDataExport",
  async ({ token, p = 0, search, tab  }, { rejectWithValue }) => {
    try {
      let searchString = "";
      if (search) {
        searchString = "&search=" + encodeURIComponent(search);
      }
      const { data } = await axios.get(
        `/api/admin/getcheckoutordersexport?page=${p}${searchString}&orderStatus=${tab}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "orderDetails.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      // return new Blob(data);
      return { success: true };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const adminDataExportSlice = createSlice({
  name: "getDataExport",
  initialState,
  reducers: {
    reset(state, action) {
      state.dataInfo = null;
      state.dataStatus = "idle";
      state.dataError = null;
    },
  },
  extraReducers: {
    [getUserDataExport.pending]: (state, action) => {
      state.dataStatus = "loading";
    },
    [getUserDataExport.fulfilled]: (state, action) => {
      state.dataStatus = "succeeded";
      state.dataInfo = action.payload.product;
    },
    [getUserDataExport.rejected]: (state, action) => {
      state.dataStatus = "failed";
      state.dataError = action.payload.message;
    },

    [getProductDataExport.pending]: (state, action) => {
      state.dataStatus = "loading";
    },
    [getProductDataExport.fulfilled]: (state, action) => {
      state.dataStatus = "succeeded";
      state.dataInfo = action.payload.product;
    },
    [getProductDataExport.rejected]: (state, action) => {
      state.dataStatus = "failed";
      state.dataError = action.payload.message;
    },
  },
});

export const { reset } = adminDataExportSlice.actions;
export default adminDataExportSlice.reducer;
