import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Container,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./SetupProfile.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { getuserInfo, reset } from "../../components/StateSlices/getuserSlice";
import Spinner from "../Spinner/Spinner";
import { CheckBox, VpnLock } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  closetSetup,
  resetSetup,
} from "../../components/StateSlices/closestsetupSlice";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import Confetti from "react-confetti";
import Select, { components, SingleValueProps } from "react-select";
import userAvatarIcon from "../../assets/godizel-logo.png";
import CryptoJS from "crypto-js";
import axios from "axios";
import { Select as MuiSelect } from "@mui/material";
import { formatPhoneNumber, US_PHONE_REGEX } from "../../utils/utils";
import TagManager from "react-gtm-module";


const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.value.structured_formatting.main_text}
    </components.SingleValue>
  );
};

const Input = (props) => <components.Input {...props} isHidden={false} />;

const SetupProfile = () => {
  const [persona, setPersona] = React.useState("buyer");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  const formRef2 = useRef();

  const selectRef = useRef();

  const [spinner, setSpinner] = useState(true);
  const [tc, setTc] = useState(false);
  const [accType, setAccType] = useState("individual");
  const [fullLocation, setFullLocation] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );

  const { setupStatus, userFullInfo, setupError } = useSelector(
    (state) => state.closet
  );

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    let token = localStorage.getItem("inktoken");
    if (token) {
      dispatch(getuserInfo({ token: token }));
      window.scrollTo(0, 0);
    } else {
      navigate("/signup");
    }
  }, []);

  useEffect(() => {
    // debugger;
    if (userInfo && userInfo.userDetailsId) {
      if (userInfo.userDetailsId.profileStatus === "complete") {
        navigate("/user/profile");
      }
      console.log(userInfo);
      let userDetail = userInfo.userDetailsId;

      console.log("babua", formRef, formRef2);
      setTc(false);
      if (userDetail) {
        formRef2.current.setFieldValue("firstName", userDetail.userFName);
        formRef2.current.setFieldValue("lastName", userDetail.userLName);
        formRef2.current.setFieldValue("email", userDetail.userEmail);
      }
      setTimeout(() => {
        setSpinner(false);
      }, 100);
    }
  }, [userInfo]);

  useEffect(() => {
    if (error) {
      console.log("aa gaya error", error);
      dispatch(reset());
      navigate("/signup");
    }
  }, [error]);

  useEffect(() => {
    if (userFullInfo) {
      // setShowConfetti(true);
      toast.success("User Profile set up", toastOption);
      // if (userFullInfo.referralInfo) {
      //   toast.info(
      //     "User is referred by " + userFullInfo.referralInfo.referredBy,
      //     toastOption
      //   );
      // }

      // dispatch(resetSetup());
      if(userFullInfo.user){
        console.log(userInfo);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'Setup Profile Conversion',
            action: 'Setup Profile',
            label: 'Setup Profile Conversion',
            companyName:userFullInfo.user.companyName,
            compnayDesc:userFullInfo.user.companyDesc,
            companyDesign:userFullInfo.user.companyDesgn,
            userEmail:userFullInfo.user.userEmail
        });
        TagManager.dataLayer({ dataLayer: window.dataLayer });
      }
      
      setTimeout(() => {
        navigate("/setup-profile-complete");
      }, 2000);
    }
  }, [userFullInfo]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "80vh",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  // CONFETTI
  const [showConfetti, setShowConfetti] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, [userFullInfo]);

  const onFocus = () => fullLocation && selectRef.current.inputRef.select();

  const onInputChange = (inputValue, { action }) => {
    // onBlur => setInputValue to last selected value
    // if (action === "input-blur") {
    //   setInputValue(value ? value.label : "");
    // }
    console.log(action);
    // onInputChange => update inputValue
    if (action === "input-change") {
      setInputValue(inputValue);
    }
  };

  const [imagePreviewUrl, setImagePreviewUrl] = useState("");

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <header>
              <Typography
                variant="h3"
                fontWeight={700}
                borderBottom={"1px solid var(--black-color)"}
              >
                Terms and Conditions
              </Typography>
            </header>

            <section>
              <Container
                maxWidth="md"
                sx={{ textAlign: "justify", marginBottom: "5rem" }}
              >
                <Box data-aos="fade-in" data-aos-duration={500}>
                  <Typography
                    variant="h1"
                    textAlign={"center"}
                    fontWeight={700}
                    component="h1"
                    gutterBottom
                    marginTop={"2rem"}
                  >
                    Terms and Conditions
                  </Typography>

                  <Typography
                    variant="body2"
                    color="textSecondary"
                    margin={"1rem 0"}
                    gutterBottom
                  >
                    Last modified: June 1, 2024
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Acceptance of the Terms of Use</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    These terms of use are entered into by and between You and
                    Dizel, Inc. <strong>(“Company,” “we,” or “us”)</strong>. The
                    following terms and conditions, together with any documents
                    they expressly incorporate by reference (collectively,{" "}
                    <strong>“Terms of Use”</strong>), govern your access to and
                    use of www.godizel.com, including any content, functionality
                    and services offered on or through www.godizel.com (the{" "}
                    <strong>“Website”</strong>), whether as a guest or a
                    registered user.
                  </Typography>

                  <Typography variant="body1" paragraph>
                    Please read the Terms of Use carefully before you start to
                    use the Website.{" "}
                    <strong>
                      By using the Website, you accept and agree to be bound and
                      abide by these Terms of Use and our Privacy Policy, found
                      at{" "}
                      <Link
                        to={"/privacy-policy"}
                        style={{
                          color: "blue",
                          borderBottom: "1px solid blue",
                        }}
                        target="_blank"
                      >
                        privacy-policy
                      </Link>
                      , incorporated herein by reference
                    </strong>
                    . If you do not want to agree to these Terms of Use or the
                    Privacy Policy, you must not access or use the Website.
                  </Typography>

                  <Typography variant="body1" paragraph>
                    This Website is offered and available to users who are 18
                    years of age or older. By using this Website, you represent
                    and warrant that you are of legal age to form a binding
                    contract with the Company and meet all of the foregoing
                    eligibility requirements. If you do not meet all of these
                    requirements, you must not access or use the Website.
                  </Typography>

                  {/* Subtitle 2 */}
                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Changes to the Terms of Use</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    We may revise and update these Terms of Use from time to
                    time in our sole discretion. All changes are effective
                    immediately when we post them and apply to all access to and
                    use of the Website thereafter.
                  </Typography>

                  <Typography variant="body1" paragraph>
                    Your continued use of the Website following the posting of
                    revised Terms of Use means that you accept and agree to the
                    changes. You are expected to check this page frequently so
                    you are aware of any changes, as they are binding on you.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Accessing the Website and Security</u>
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We reserve the right to withdraw or amend this Website, and
                    any service or material we provide on the Website, in our
                    sole discretion without notice. We will not be liable if for
                    any reason all or any part of the Website is unavailable at
                    any time or for any period. From time to time, we may
                    restrict access to some parts of the Website, or the entire
                    Website, to users.
                  </Typography>

                  <Typography variant="body1" paragraph>
                    You are responsible for both:
                  </Typography>

                  <ul>
                    <li>
                      Making all arrangements necessary for you to have access
                      to the Website.
                    </li>
                    <li>
                      Ensuring that all persons who access the Website through
                      your internet connection are aware of these Terms of Use
                      and comply with them.
                    </li>
                  </ul>
                  <br />
                  <Typography variant="body1" paragraph>
                    To access the Website or some of the resources it offers,
                    you may be asked to provide certain information. It is a
                    condition of your use of the Website that all the
                    information you provide on the Website is correct, current
                    and complete. You agree that all information you provide to
                    register with this Website or otherwise, including, but not
                    limited to, through the use of any interactive features on
                    the Website, is governed by our Privacy Policy, and you
                    consent to all actions we take with respect to your
                    information consistent with our Privacy Policy.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Intellectual Property Rights</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    The Website and its entire contents, features and
                    functionality (including but not limited to all information,
                    software, text, displays, images, video and audio, and the
                    design, selection and arrangement thereof), are owned by the
                    Company, its licensors or other providers of such material
                    and are protected by United States and international
                    copyright, trademark, patent, trade secret and other
                    intellectual property or proprietary rights laws.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    These Terms of Use permit you to use the Website for your
                    personal, non-commercial use only. You must not reproduce,
                    distribute, modify, create derivative works of, publicly
                    display, publicly perform, republish, download, store or
                    transmit any of the material on our Website. You must not
                    access or use for any commercial purposes any part of the
                    Website or any services or materials available through the
                    Website.
                  </Typography>

                  <Typography variant="body1" paragraph>
                    No right, title or interest in or to the Website or any
                    content on the Website is transferred to you, and all rights
                    not expressly granted are reserved by the Company. Any use
                    of the Website not expressly permitted by these Terms of Use
                    is a breach of these Terms of Use and may violate copyright,
                    trademark and other laws.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Trademarks</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    The term “DIZEL”, the Company logo and all related names,
                    logos, product and service names, designs and slogans are
                    trademarks of the Company or its affiliates or licensors.
                    You must not use such marks without the prior written
                    permission of the Company. All other names, logos, product
                    and service names, designs and slogans on this Website are
                    the trademarks of their respective owners.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Prohibited Uses</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    You may use the Website only for lawful purposes and in
                    accordance with these Terms of Use. You agree not to use the
                    Website:
                  </Typography>
                  <ul>
                    <li>
                      In any way that violates any applicable federal, state,
                      local or international law or regulation (including,
                      without limitation, any laws regarding the export of data
                      or software to and from the US or other countries).
                    </li>

                    <li>
                      For the purpose of exploiting, harming or attempting to
                      exploit or harm minors in any way by exposing them to
                      inappropriate content, asking for personally identifiable
                      information or otherwise.
                    </li>

                    <li>
                      To send, knowingly receive, upload, download, use or
                      re-use any material which does not comply with these Terms
                      of Use.
                    </li>
                    <li>
                      To transmit, or procure the sending of, any advertising or
                      promotional material, including any “junk mail,” “chain
                      letter,” “spam,” or any other similar solicitation.
                    </li>
                    <li>
                      To impersonate or attempt to impersonate the Company, a
                      Company employee, another user or any other person or
                      entity (including, without limitation, by using e-mail
                      addresses associated with any of the foregoing).
                    </li>
                    <li>
                      To engage in any other conduct that restricts or inhibits
                      anyone’s use or enjoyment of the Website, or which, as
                      determined by us, may harm the Company or users of the
                      Website, or expose them to liability.
                    </li>
                  </ul>

                  <br />
                  <Typography variant="body1" paragraph>
                    Additionally, you agree not to:
                  </Typography>
                  <ul>
                    <li>
                      Use the Website in any manner that could disable,
                      overburden, damage, or impair the site or interfere with
                      any other party’s use of the Website, including their
                      ability to engage in real time activities through the
                      Website.
                    </li>
                    <li>
                      Use any robot, spider or other automatic device, process
                      or means to access the Website for any purpose, including
                      monitoring or copying any of the material on the Website.
                    </li>
                    <li>
                      Use any manual process to monitor or copy any of the
                      material on the Website, or for any other purpose not
                      expressly authorized in these Terms of Use, without our
                      prior written consent.
                    </li>
                    <li>
                      Use any device, software or routine that interferes with
                      the proper working of the Website.
                    </li>
                    <li>
                      Introduce any viruses, trojan horses, worms, logic bombs
                      or other material which is malicious or technologically
                      harmful.
                    </li>
                    <li>
                      Attempt to gain unauthorized access to, interfere with,
                      damage or disrupt any parts of the Website, the server on
                      which the Website is stored, or any server, computer or
                      database connected to the Website.
                    </li>
                    <li>
                      Attack the Website via a denial-of-service attack or a
                      distributed denial-of-service attack.
                    </li>
                    <li>
                      Otherwise attempt to interfere with the proper working of
                      the Website.
                    </li>
                  </ul>
                  <Box gutterBottom>&nbsp;</Box>
                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Reliance on Information Posted</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    The information presented on or through the Website is made
                    available solely for general information purposes. We do not
                    warrant the accuracy, completeness or usefulness of this
                    information. Any reliance you place on such information is
                    strictly at your own risk. We disclaim all liability and
                    responsibility arising from any reliance placed on such
                    materials by you or any other visitor to the Website, or by
                    anyone who may be informed of any of its contents.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    This Website may include content provided by third parties,
                    including materials provided by bloggers and third-party
                    licensors, syndicators, aggregators and/or reporting
                    services. All statements and/or opinions expressed in these
                    materials, and all articles and responses to questions and
                    other content, other than the content provided by the
                    Company, are solely the opinions and the responsibility of
                    the person or entity providing those materials. These
                    materials do not necessarily reflect the opinion of the
                    Company. We are not responsible, or liable to you or any
                    third party, for the content or accuracy of any materials
                    provided by any third parties.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Changes to the Website</u>
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We may update the content on this Website from time to time,
                    but its content is not necessarily complete or up-to-date.
                    Any of the material on the Website may be out of date at any
                    given time, and we are under no obligation to update such
                    material.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Information About You and Your Visits to the Website</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    All information we collect on this Website is subject to our
                    Privacy Policy. By using the Website, you consent to all
                    actions taken by us with respect to your information in
                    compliance with the Privacy Policy.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Online Purchases and Other Terms and Conditions</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    All purchases through our site or other transactions for the
                    sale of goods formed through the Website, or as a result of
                    visits made by you are governed by our Rental Terms, [
                    <Link
                      to={"/rental-terms"}
                      style={{ color: "blue", borderBottom: "1px solid blue" }}
                      target="_blank"
                    >
                      rental-terms
                    </Link>
                    ], which are hereby incorporated into these Terms of Use.
                    Additional terms and conditions may also apply to specific
                    portions, services or features of the Website. All such
                    additional terms and conditions are hereby incorporated by
                    this reference into these Terms of Use.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Social Media Features</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    This Website may provide certain social media features that
                    enable you to:
                  </Typography>
                  <ul>
                    <li>
                      Link from your own or certain third-party websites to
                      certain content on this Website.
                    </li>
                    <li>
                      Send e-mails or other communications with certain content,
                      or links to certain content, on this Website.
                    </li>
                    <li>
                      Cause limited portions of content on this Website to be
                      displayed or appear to be displayed on your own or certain
                      third-party websites.
                    </li>
                  </ul>
                  <br />

                  <Typography variant="body1" paragraph>
                    You may use these features solely as they are provided by
                    us, solely with respect to the content they are displayed
                    with, and otherwise in accordance with any additional terms
                    and conditions we provide with respect to such features.
                    Subject to the foregoing, you must not:
                  </Typography>
                  <ul>
                    <li>Establish a link from any website.</li>
                    <li>
                      Cause the Website or portions of it to be displayed, or
                      appear to be displayed by, for example, framing, deep
                      linking or in-line linking, on any other site.
                    </li>
                    <li>Link to any part of the Website.</li>
                    <li>
                      Otherwise take any action with respect to the materials on
                      this Website that is inconsistent with any other provision
                      of these Terms of Use.
                    </li>
                  </ul>
                  <br />
                  <Typography variant="body1" paragraph>
                    You agree to cooperate with us in causing any unauthorized
                    framing or linking immediately to cease. We reserve the
                    right to withdraw linking permission without notice. We may
                    disable all or any social media features and any links at
                    any time without notice in our discretion.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Links from the Website</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    If the Website contains links to other sites and resources
                    provided by third parties, these links are provided for your
                    convenience only. This includes links contained in
                    advertisements, including banner advertisements and
                    sponsored links. We have no control over the contents of
                    those sites or resources and accept no responsibility for
                    them or for any loss or damage that may arise from your use
                    of them. If you decide to access any of the third party
                    websites linked to this Website, you do so entirely at your
                    own risk and subject to the terms and conditions of use for
                    such websites.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Geographic Restrictions</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    The owner of the Website is based in the State of Florida in
                    the United States. We provide this Website for use only by
                    persons located in the United States. We make no claims that
                    the Website or any of its content is accessible or
                    appropriate outside of the United States. Access to the
                    Website may not be legal by certain persons or in certain
                    countries. If you access the Website from outside the United
                    States, you do so on your own initiative and are responsible
                    for compliance with local laws.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Disclaimer of Warranties</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    You understand that we cannot and do not guarantee or
                    warrant that files available for downloading from the
                    internet or the Website will be free of viruses or other
                    destructive code. You are responsible for implementing
                    sufficient procedures and checkpoints to satisfy your
                    particular requirements for anti-virus protection and
                    accuracy of data input and output, and for maintaining a
                    means external to our site for any reconstruction of any
                    lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
                    CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR
                    OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
                    COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER
                    PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY
                    SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
                    DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE
                    LINKED TO IT.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR
                    ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE
                    WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED
                    THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS
                    AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
                    EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON
                    ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
                    REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
                    RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE
                    WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY
                    NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR
                    WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR
                    ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
                    RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
                    CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
                    AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR
                    THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                    THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                    WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
                    INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                    MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR
                    PURPOSE.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Limitation on Liability</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE
                    COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
                    PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE
                    LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
                    ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY
                    TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT
                    ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR
                    ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES,
                    INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                    CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                    TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
                    LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                    ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                    DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
                    BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Indemnification</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    You agree to defend, indemnify and hold harmless the
                    Company, its affiliates, licensors and service providers,
                    and its and their respective officers, directors, employees,
                    contractors, agents, licensors, suppliers, successors and
                    assigns from and against any claims, liabilities, damages,
                    judgments, awards, losses, costs, expenses or fees
                    (including reasonable attorneys’ fees) arising out of or
                    relating to your violation of these Terms of Use or your use
                    of the Website, including, but not limited to, your User
                    Contributions, any use of the Website’s content, services
                    and products other than as expressly authorized in these
                    Terms of Use, or your use of any information obtained from
                    the Website.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Governing Law and Jurisdiction</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    All matters relating to the Website and these Terms of Use,
                    and any dispute or claim arising therefrom or related
                    thereto (in each case, including non-contractual disputes or
                    claims), shall be governed by and construed in accordance
                    with the internal laws of the State of Florida without
                    giving effect to any choice or conflict of law provision or
                    rule (whether of the State of Florida or any other
                    jurisdiction).
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Any legal suit, action or proceeding arising out of, or
                    related to, these Terms of Use or the Website shall be
                    instituted exclusively in the federal courts of the United
                    States or the courts of the State of Florida, in each case
                    located in the City of Delray Beach and County of Palm
                    Beach, although we retain the right to bring any suit,
                    action or proceeding against you for breach of these Terms
                    of Use in your country of residence or any other relevant
                    country. You waive any and all objections to the exercise of
                    jurisdiction over you by such courts and to venue in such
                    courts.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Limitation on Time to File Claims</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                    RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE
                    COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                    ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
                    PERMANENTLY BARRED.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u> Waiver and Severability</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    No waiver of by the Company of any term or condition set
                    forth in these Terms of Use shall be deemed a further or
                    continuing waiver of such term or condition or a waiver of
                    any other term or condition, and any failure of the Company
                    to assert a right or provision under these Terms of Use
                    shall not constitute a waiver of such right or provision.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    If any provision of these Terms of Use is held by a court or
                    other tribunal of competent jurisdiction to be invalid,
                    illegal or unenforceable for any reason, such provision
                    shall be eliminated or limited to the minimum extent such
                    that the remaining provisions of the Terms of Use will
                    continue in full force and effect.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Entire Agreement</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    The Terms of Use, our Privacy Policy, and Terms of Sale
                    constitute the sole and entire agreement between you and
                    Dizel, Inc. with respect to the Website and supersede all
                    prior and contemporaneous understandings, agreements,
                    representations and warranties, both written and oral, with
                    respect to the Website.
                  </Typography>

                  <Typography
                    variant="h4"
                    component="h2"
                    fontWeight={700}
                    gutterBottom
                  >
                    <u>Your Comments and Concerns</u>
                  </Typography>

                  <Typography variant="body1" paragraph>
                    This Website is operated by Dizel, Inc., 4801 Linton Blvd
                    #11A PMB# 444, Delray Beach, FL 33445.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    All notices of copyright infringement claims, other
                    feedback, comments, request for technical support, and other
                    communications relating to the Website should be sent to [
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "blue", borderBottom: "1px solid blue" }}
                      href="mailto:support@godizel.com"
                    >
                      support@godizel.com
                    </a>
                    ].
                  </Typography>
                </Box>
              </Container>
            </section>
          </Box>
        </Fade>
      </Modal>
      <>
        <div className={`confetti-container ${showConfetti ? "show" : "hide"}`}>
          <Confetti
            wind={0.01}
            gravity={0.15}
            numberOfPieces={400}
            width={window.innerWidth - 30}
            height={2 * window.innerHeight}
            colors={["#161b21", "#f05023", "#f3a950", "#f1f1f1"]}
            recycle={showConfetti}
          />
        </div>
        <Box component={"div"} margin={"2rem"} padding={{ xs: 0, md: "2rem" }}>
          <Typography
            className="title mont-title"
            variant="h1"
            fontWeight={700}
          >
            Setup Profile
          </Typography>
          {spinner && !userInfo ? (
            <Spinner />
          ) : (
            <>
              {/* <ToggleButtonGroup
                title="Persona"
                sx={{ margin: "1rem 0" }}
                color="primary"
                value={persona}
                exclusive
                onChange={(e) => setPersona(e.target.value)}
                aria-label="Persona"
              >
                <ToggleButton value="buyer">User Details</ToggleButton>
                <ToggleButton value="seller">Seller</ToggleButton>
                <ToggleButton value="both">Both</ToggleButton>
              </ToggleButtonGroup> */}

              <CSSTransition
                in={persona === "buyer"}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <Box
                  className={"fade setup-profile-con"}
                  component={"div"}
                  marginTop={"1rem"}
                >
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      address: "",
                      email: "",
                      phone: "",
                      companyName: "",
                      companyDesc: "",
                      companyDesgn: "",
                      person: "buyer",
                      referralUrl: "",
                      userIcon: "",
                      city: "",
                      state: "",
                      zip: "",
                      // userTaxId: "",
                    }}
                    initialErrors={{
                      firstName: "Please enter your First Name",
                      lastName: "Please enter your Last Name",
                      address: "Enter Valid Address",
                      phone: "Please enter your Phone Numer",
                      email: "Please enter your Email",
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string().required(
                        "Please enter your First Name"
                      ),
                      lastName: Yup.string().required(
                        "Please enter your Last Name"
                      ),
                      address: Yup.string().required("Enter Valid Address"),
                      email: Yup.string()
                        .email("Enter Valid Email")
                        .required("Please enter your Email"),
                      phone: Yup.string()
                        .matches(US_PHONE_REGEX, "Invalid Phone Number")
                        .required("Phone number is required"),
                    })}
                    onSubmit={(values, actions) => {
                      window.scrollTo(0, 0);
                      console.log("SUbmitting");
                      if (!tc) {
                        toast.error(
                          "Please Agree Terms & Conditions",
                          toastOption
                        );
                        return false;
                      }
                      toast.info("Please wait...", toastOption);
                      if (
                        imagePreviewUrl !== "" &&
                        imagePreviewUrl !== values.userIcon
                      ) {
                        const timestamp = Math.round(
                          new Date().getTime() / 1000
                        );
                        const params = {
                          timestamp: timestamp,
                          transformation: "f_webp,q_70,c_scale,w_1000",
                          upload_preset: "closet-closest",
                          folder: "dizel_user_icons",
                          // add any additional parameters here, such as transformation options
                        };
                        const signature = CryptoJS.SHA1(
                          `timestamp=${params.timestamp}&transformation=${params.transformation}&upload_preset=${params.upload_preset}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`
                        ).toString();

                        const formData = new FormData();
                        formData.append("file", imagePreviewUrl);
                        formData.append("upload_preset", "closet-closest");
                        formData.append("cloud_name", "closet-closest");
                        formData.append(
                          "transformation",
                          "f_webp,q_70,c_scale,w_1000"
                        );
                        formData.append("signature", signature);
                        formData.append("timestamp", timestamp);
                        // formData.append("folder", "dizel_user_icons");
                        formData.append("api_key", "738997887579849");

                        // Use await to wait for the axios.post to complete
                        axios
                          .post(
                            "https://api.cloudinary.com/v1_1/closet-closest/image/upload",
                            formData,
                            {
                              headers: { "X-Requested-With": "XMLHttpRequest" },
                            }
                          )
                          .then((response) => {
                            const data = response.data;
                            const fileURL = data.secure_url; // You should store this URL for future references in your app
                            values.userIcon = fileURL;
                            console.log(values);
                            let token = localStorage.getItem("inktoken");
                            dispatch(closetSetup({ token, values }));
                          })
                          .catch((e) => {
                            console.log(e);
                            throw new Error("Something went Wrong!!!!");
                          });
                      } else {
                        let token = localStorage.getItem("inktoken");
                        dispatch(closetSetup({ token, values }));
                      }

                      // setSpinner(true);
                      console.log("values", values);
                    }}
                    innerRef={formRef2}
                    render={(props) => {
                      let {
                        setFieldValue,
                        values,
                        handleBlur,
                        handleChange,
                        touched,
                        errors,
                        handleSubmit,
                      } = props;

                      return (
                        <div className="container-fluid">
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} marginTop={"0.5rem"}>
                              {/* Field 1 */}
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  required
                                  label="First Name"
                                  name="firstName"
                                  id="first-name"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.firstName}
                                  fullWidth
                                  variant="outlined"
                                  helperText={
                                    touched.firstName && errors.firstName
                                      ? errors.firstName
                                      : "E.g. John"
                                  }
                                  error={
                                    touched.firstName &&
                                    Boolean(errors.firstName)
                                  }
                                />
                                <TextField
                                  sx={{ marginTop: "1rem" }}
                                  required
                                  label="Last Name"
                                  name="lastName"
                                  id="last-name"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.lastName}
                                  fullWidth
                                  variant="outlined"
                                  helperText={
                                    touched.lastName && errors.lastName
                                      ? errors.lastName
                                      : "E.g. Doe"
                                  }
                                  error={
                                    touched.lastName && Boolean(errors.lastName)
                                  }
                                />
                              </Grid>

                              {/* Field 2 */}
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                sx={{ textAlign: "center" }}
                              >
                                <label
                                  htmlFor="photo-upload"
                                  className="custom-file-upload fas"
                                >
                                  <div className="img-wrap img-upload">
                                    <img
                                      src={
                                        imagePreviewUrl !== ""
                                          ? imagePreviewUrl
                                          : userAvatarIcon
                                      }
                                      alt="Upload Preview"
                                    />
                                  </div>
                                  <input
                                    id="photo-upload"
                                    type="file"
                                    onChange={photoUpload}
                                  />
                                </label>
                                <br />
                                <input
                                  class="custom-file-input"
                                  type="file"
                                  onChange={photoUpload}
                                  id="photo-upload"
                                />
                              </Grid>

                              {/* Field 3 */}
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  required
                                  label="Email Address"
                                  name="email"
                                  id="email"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.email}
                                  fullWidth
                                  variant="outlined"
                                  helperText={
                                    touched.email && errors.email
                                      ? errors.email
                                      : "E.g. john.doe@godizel.com"
                                  }
                                  error={touched.email && Boolean(errors.email)}
                                />
                              </Grid>

                              {/* Field 4 */}
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  required
                                  label="Phone Number"
                                  name="phone"
                                  id="phone"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    const formattedNumber = formatPhoneNumber(
                                      e.target.value
                                    );
                                    setFieldValue("phone", formattedNumber);
                                  }}
                                  value={values.phone}
                                  fullWidth
                                  variant="outlined"
                                  helperText={
                                    touched.phone &&
                                    errors.phone &&
                                    errors.phone
                                  }
                                  error={touched.phone && Boolean(errors.phone)}
                                />
                              </Grid>

                              {/* Field 5 */}
                              {/* <Grid item xs={12}>
                          <TextField
                            required
                            label="Address"
                            name="address"
                            id="address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            helperText={
                              touched.address && errors.address
                                ? errors.address
                                : "E.g. XYZ Street, USA"
                            }
                            error={touched.address && Boolean(errors.address)}
                          />
                        </Grid> */}

                              <Grid
                                item
                                xs={12}
                                sx={{ background: "transparent" }}
                              >
                                <GooglePlacesAutocomplete
                                  selectProps={{
                                    ref: selectRef,
                                    value: fullLocation,
                                    onChange: (val) => {
                                      if (val) {
                                        geocodeByAddress(val.label)
                                          .then((results) => {
                                            console.log(results);
                                            let zipCode =
                                              results[0].address_components.find(
                                                (zp) =>
                                                  zp.types.includes(
                                                    "postal_code"
                                                  )
                                              );
                                            if (zipCode) {
                                              setFieldValue(
                                                "zip",
                                                zipCode.long_name
                                              );
                                            } else {
                                              setFieldValue("zip", "");
                                            }
                                          })
                                          .catch((error) =>
                                            console.error(error)
                                          );
                                        // console.log(val);
                                        setFullLocation(val);
                                        setInputValue(
                                          val
                                            ? val.value.structured_formatting
                                                .main_text
                                            : ""
                                        );
                                        setFieldValue("address", val.label);
                                        let total = val.value.terms.length;
                                        setFieldValue(
                                          "city",
                                          val.value.terms[total - 2] &&
                                            val.value.terms[total - 2].value
                                        );
                                        setFieldValue(
                                          "state",
                                          val.value.terms[total - 1] &&
                                            val.value.terms[total - 1].value
                                        );
                                        console.log(val);
                                        // setFieldValue(
                                        //   "city",
                                        //   val.terms.length > 3
                                        //     ? val.value.terms[
                                        //         val.value.terms.length - 3
                                        //       ]
                                        //     : val.value.terms[
                                        //         val.value.terms.length - 2
                                        //       ]
                                        // );
                                        // setFieldValue(
                                        //   "state",
                                        //   val.terms.length > 3
                                        //     ? val.value.terms[
                                        //         val.value.terms.length - 2
                                        //       ]
                                        //     : val.value.terms[
                                        //         val.value.terms.length - 1
                                        //       ]
                                        // );
                                      }
                                    },
                                    styles: {
                                      input: (provided) => ({
                                        ...provided,
                                        padding: 12,
                                        border: "none",
                                        background: "transparent",
                                      }),
                                      option: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none",
                                      }),
                                    },
                                    inputValue: inputValue,
                                    onFocus: onFocus,
                                    isClearable: true,
                                    onInputChange: onInputChange,
                                    controlShouldRenderValue: false,
                                    components: { SingleValue, Input },
                                    placeholder: "Street Address *",
                                  }}
                                  apiKey="AIzaSyAw_j2KY4CDtksQcd6JexS3J1xt4BdbnGQ"
                                  apiOptions={{ region: "US" }}
                                />
                                {errors.itemLocation && touched.itemLocation ? (
                                  <FormHelperText
                                    style={{ color: "red", marginLeft: "1rem" }}
                                  >
                                    {errors.itemLocation.label}
                                  </FormHelperText>
                                ) : (
                                  <FormHelperText
                                    style={{
                                      marginLeft: "1rem",
                                      // color: "text.secondary",
                                    }}
                                  >
                                    E.g XYZ Street, City, Country
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={4} spacing={1}>
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  error={touched.city && Boolean(errors.city)} //true if error exists
                                  required
                                  name="city"
                                  id="city"
                                  label="City"
                                  defaultValue=""
                                  sx={{
                                    width: "100%",
                                    // marginTop: ".5rem",
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.city}
                                  helperText={touched.city && errors.city}
                                />
                              </Grid>

                              <Grid item xs={4} spacing={1}>
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  error={touched.state && Boolean(errors.state)} //true if error exists
                                  required
                                  name="state"
                                  id="State"
                                  label="State"
                                  defaultValue=""
                                  sx={{
                                    width: "100%",
                                    // marginTop: ".5rem",
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.state}
                                  helperText={touched.state && errors.state}
                                />
                              </Grid>
                              <Grid item xs={4} spacing={1}>
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  error={touched.zip && Boolean(errors.zip)} //true if error exists
                                  required
                                  name="zip"
                                  id="zip"
                                  label="Zip Code"
                                  defaultValue=""
                                  sx={{
                                    width: "100%",
                                    // marginTop: ".5rem",
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.zip}
                                  helperText={touched.zip && errors.zip}
                                />
                              </Grid>

                              {/* <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          mt={1}
                          mx={2.5}
                        >
                          <Typography
                            fontWeight={
                              accType == "individual" ? 700 : "normal"
                            }
                          >
                            Buyer
                          </Typography>
                          <Switch
                            inputProps={{ "aria-label": "controlled" }}
                            onChange={(e) => {
                              setAccType(
                                e.target.checked ? "company" : "individual"
                              );
                              setFieldValue(
                                "accountType",
                                e.target.checked ? "company" : "individual"
                              );
                            }}
                          />
                          <Typography
                            fontWeight={accType == "company" ? 700 : "normal"}
                          >
                            Buyer & Seller
                          </Typography>
                        </Stack> */}

                              <Grid item xs={12}>
                                <Typography
                                  variant="h4"
                                  fontWeight={700}
                                  marginTop={"1rem"}
                                  marginBottom={"1.5rem"}
                                >
                                  Company (Asset Owner) Information
                                </Typography>
                                <TextField
                                  label="Company (Asset Owner) Name"
                                  name="companyName"
                                  id="company"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.companyName}
                                  fullWidth
                                  variant="outlined"
                                  helperText={
                                    touched.company && errors.company
                                      ? errors.company
                                      : "E.g. Tempshare INC."
                                  }
                                  error={
                                    touched.companyName &&
                                    Boolean(errors.companyName)
                                  }
                                />
                                {/* <TextField
                                  error={
                                    touched.companyDesc &&
                                    Boolean(errors.companyDesc)
                                  } //true if error exists
                                  name="companyDesc"
                                  id="company-name"
                                  label="Company (Seller) Description"
                                  defaultValue=""
                                  sx={{ width: "100%", marginTop: "1.2rem" }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.companyDesc}
                                  helperText={
                                    touched.companyDesc && errors.companyDesc
                                  }
                                /> */}
                                <Grid item xs={12}>
                                  <FormControl
                                    fullWidth
                                    sx={{
                                      marginTop: "1rem",
                                    }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Company (Asset Owner) Description
                                    </InputLabel>
                                    <MuiSelect
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select-label"
                                      value={values.companyDesc}
                                      name="companyDesc"
                                      label="Company (Asset Owner) Description"
                                      onChange={handleChange}
                                    >
                                      <MenuItem value={"rental_company"}>
                                        Rental Company
                                      </MenuItem>
                                      <MenuItem value={"contractor"}>
                                        Contractor
                                      </MenuItem>
                                    </MuiSelect>
                                  </FormControl>
                                </Grid>
                                <TextField
                                  error={
                                    touched.companyDesgn &&
                                    Boolean(errors.companyDesgn)
                                  } //true if error exists
                                  name="companyDesgn"
                                  id="company-desgn"
                                  label="Your Role/Title"
                                  defaultValue=""
                                  sx={{ width: "100%", marginTop: "1.2rem" }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.companyDesgn}
                                  helperText={
                                    touched.companyDesgn && errors.companyDesgn
                                      ? errors.companyDesgn
                                      : "E.g. CEO"
                                  }
                                />
                                {/* <TextField
                                  error={
                                    touched.userTaxId &&
                                    Boolean(errors.userTaxId)
                                  } //true if error exists
                                  name="userTaxId"
                                  id="tax-id"
                                  label="TaxID (Company) / SSN (Individual)"
                                  defaultValue=""
                                  sx={{ width: "100%", marginTop: "1rem" }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.userTaxId}
                                  helperText={
                                    touched.userTaxId && errors.userTaxId
                                      ? errors.userTaxId
                                      : ""
                                  }
                                /> */}
                              </Grid>
                              {/* <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            fontWeight={700}
                            marginTop={"1rem"}
                            marginBottom={"1rem"}
                          >
                            Referral Information
                          </Typography>
                          <TextField
                            label="Referral URL"
                            name="referralUrl"
                            id="referralUrl"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.referralUrl}
                            fullWidth
                            variant="outlined"
                            helperText={
                              touched.referralUrl && errors.referralUrl
                                ? errors.referralUrl
                                : "https://godizel.com/signup?referral=***&user=*-*"
                            }
                            error={
                              touched.referralUrl && Boolean(errors.referralUrl)
                            }
                          />
                        </Grid> */}

                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", gap: "1rem" }}
                              >
                                {/* <input
                                  type="checkbox"
                                  style={{
                                    width: "30px",
                                    cursor: "pointer",
                                    color: "var(--secondary-color)",
                                  }}
                                  onChange={() => setTc((prev) => !prev)}
                                  checked={tc}
                                /> */}
                                <FormGroup
                                  sx={{ marginTop: "1rem", cursor: "pointer" }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{
                                          padding: "0 .5rem",
                                        }}
                                        color="secondary"
                                        onChange={() => setTc((prev) => !prev)}
                                        checked={tc}
                                      />
                                    }
                                    label={
                                      <label style={{ cursor: "pointer" }}>
                                        I agree to{" "}
                                        {/* <span
                                          className="tc"
                                          style={{ cursor: "pointer" }}
                                          onClick={handleOpen}
                                        > */}
                                        <Link to={"/terms-and-conditions"} target="_blank" style={{color: "var(--secondary-color)"}}>
                                        Terms and Conditions
                                        </Link>,&nbsp;
                                        <Link to={"/privacy-policy"} target="_blank" style={{color: "var(--secondary-color)"}}>
                                        Privacy Policy
                                        </Link> &&nbsp;
                                        <Link to={"/rental-terms"} target="_blank" style={{color: "var(--secondary-color)"}}>
                                        Rental Agreement
                                        </Link>
                                         
                                        {/* </span> */}
                                      </label>
                                    }
                                  />
                                </FormGroup>
                              </Grid>
                            </Grid>
                            <Button
                              sx={{ marginTop: "2rem" }}
                              variant="contained"
                              type="submit"
                            >
                              Submit
                            </Button>
                          </form>
                        </div>
                      );
                    }}
                  />
                </Box>
              </CSSTransition>

              <CSSTransition
                in={persona === "seller"}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <Box className={"fade"} component={"div"}>
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      phone: "",
                      person: "seller",
                      address: "",
                      city: "",
                      state: "",
                      zip: "",
                      accountType: "individual",
                      companyName: "",
                      companyDesc: "",
                      companyDesgn: "",
                      // userTaxId: "",
                    }}
                    initialErrors={{
                      firstName: "Please enter your First Name",
                      lastName: "Please enter your Last Name",
                      address: "Enter Valid Address",
                      email: "Please enter your Email",
                      companyName:
                        accType === "company" && "Please enter company name",
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string().required(
                        "Please enter your First Name"
                      ),
                      lastName: Yup.string().required(
                        "Please enter your Last Name"
                      ),
                      address: Yup.string().required("Enter Valid Address"),
                      email: Yup.string()
                        .email("Enter Valid Email")
                        .required("Please enter your Email"),
                      phone: Yup.string()
                        .matches(US_PHONE_REGEX, "Invalid US phone number")
                        .required("Phone number is required"),
                      companyName: Yup.string().required(
                        "Please enter Company Name"
                      ),
                      city: Yup.string().required("Please enter your City"),
                      state: Yup.string().required("Please enter your State"),
                      zip: Yup.string().required("Please enter your Zip Code"),
                    })}
                    onSubmit={(values, actions) => {
                      toast.info("Please Wait...", toastOption);
                      console.log("SUbmitting");
                      if (!tc) {
                        toast.error(
                          "Please Agree Terms & Conditions",
                          toastOption
                        );
                        return false;
                      }
                      let token = localStorage.getItem("inktoken");
                      dispatch(closetSetup({ token, values }));
                      setSpinner(true);
                      console.log("values", values);
                    }}
                    innerRef={formRef2}
                    render={(props) => {
                      let {
                        setFieldValue,
                        values,
                        handleBlur,
                        handleChange,
                        touched,
                        errors,
                        handleSubmit,
                      } = props;

                      return (
                        <div className="container-fluid">
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                              {/* Field 1 */}
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  required
                                  label="First Name"
                                  name="firstName"
                                  id="first-name"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.firstName}
                                  fullWidth
                                  variant="outlined"
                                  helperText={
                                    touched.firstName && errors.firstName
                                      ? errors.firstName
                                      : "E.g. John"
                                  }
                                  error={
                                    touched.firstName &&
                                    Boolean(errors.firstName)
                                  }
                                />
                              </Grid>

                              {/* Field 2 */}
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  required
                                  label="Last Name"
                                  name="lastName"
                                  id="last-name"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.lastName}
                                  fullWidth
                                  variant="outlined"
                                  helperText={
                                    touched.lastName && errors.lastName
                                      ? errors.lastName
                                      : "E.g. Doe"
                                  }
                                  error={
                                    touched.lastName && Boolean(errors.lastName)
                                  }
                                />
                              </Grid>

                              {/* Field 3 */}
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  required
                                  label="Email Address"
                                  name="email"
                                  id="email"
                                  disabled
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.email}
                                  fullWidth
                                  variant="outlined"
                                  helperText={
                                    touched.email && errors.email
                                      ? errors.email
                                      : "E.g. john.doe@godizel.com"
                                  }
                                  error={touched.email && Boolean(errors.email)}
                                />
                              </Grid>

                              {/* Field 4 */}
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  required
                                  label="Phone Number"
                                  name="phone"
                                  id="phone"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    const formattedNumber = formatPhoneNumber(
                                      e.target.value
                                    );
                                    setFieldValue("phone", formattedNumber);
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  helperText={
                                    touched.phone &&
                                    errors.phone &&
                                    errors.phone
                                  }
                                  error={touched.phone && Boolean(errors.phone)}
                                />
                              </Grid>

                              {/* Field 5 */}
                              <Grid item xs={12}>
                                <TextField
                                  required
                                  label="Address"
                                  name="address"
                                  id="address"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  fullWidth
                                  variant="outlined"
                                  helperText={
                                    touched.address && errors.address
                                      ? errors.address
                                      : "E.g. XYZ Street, USA"
                                  }
                                  error={
                                    touched.address && Boolean(errors.address)
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  required
                                  label="Company Name"
                                  name="companyName"
                                  id="company"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  fullWidth
                                  variant="outlined"
                                  helperText={
                                    touched.companyName && errors.companyName
                                      ? errors.companyName
                                      : "E.g. GoDizel INC."
                                  }
                                  error={
                                    touched.companyName &&
                                    Boolean(errors.companyName)
                                  }
                                />
                              </Grid>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                my={1}
                              >
                                <Typography
                                  fontWeight={
                                    accType == "individual" ? 700 : "normal"
                                  }
                                >
                                  Individual
                                </Typography>
                                <Switch
                                  inputProps={{ "aria-label": "controlled" }}
                                  onChange={(e) => {
                                    setAccType(
                                      e.target.checked
                                        ? "company"
                                        : "individual"
                                    );
                                    setFieldValue(
                                      "accountType",
                                      e.target.checked
                                        ? "company"
                                        : "individual"
                                    );
                                  }}
                                />
                                <Typography
                                  fontWeight={
                                    accType == "company" ? 700 : "normal"
                                  }
                                >
                                  Company
                                </Typography>
                              </Stack>

                              {accType === "company" && (
                                <>
                                  <TextField
                                    error={
                                      touched.companyName &&
                                      Boolean(errors.companyName)
                                    } //true if error exists
                                    required
                                    name="companyName"
                                    id="company-name"
                                    label="Company Name"
                                    defaultValue=""
                                    sx={{ width: "100%" }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.companyName}
                                    helperText={
                                      touched.companyName && errors.companyName
                                    }
                                  />
                                  <TextField
                                    error={
                                      touched.companyDesc &&
                                      Boolean(errors.companyDesc)
                                    } //true if error exists
                                    name="companyDesc"
                                    id="company-name"
                                    label="Company Description"
                                    defaultValue=""
                                    sx={{ width: "100%", marginTop: "1.2rem" }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.companyDesc}
                                    helperText={
                                      touched.companyDesc && errors.companyDesc
                                    }
                                  />
                                  <TextField
                                    error={
                                      touched.companyDesgn &&
                                      Boolean(errors.companyDesgn)
                                    } //true if error exists
                                    name="companyDesgn"
                                    id="company-desgn"
                                    label="Company Designation"
                                    defaultValue=""
                                    sx={{ width: "100%", marginTop: "1.2rem" }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.companyDesgn}
                                    helperText={
                                      touched.companyDesgn &&
                                      errors.companyDesgn
                                    }
                                  />
                                </>
                              )}
                              <Grid container spacing={1} sx={{ flexGrow: 1 }}>
                                {/* <Grid item xs={6} spacing={1}>
                          <TextField
                            error={touched.street && Boolean(errors.street)} //true if error exists
                            required
                            name="street"
                            id="Stree"
                            label="Street Address"
                            defaultValue=""
                            sx={{
                              width: "100%",
                              marginTop:
                                accType !== "individual" ? "1.2rem" : 0,
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.street}
                            helperText={touched.street && errors.street}
                          />
                        </Grid> */}

                                <Grid
                                  item
                                  xs={6}
                                  spacing={1}
                                  marginTop={
                                    accType !== "individual" ? "1.2rem" : 0
                                  }
                                  sx={{ background: "transparent" }}
                                >
                                  <GooglePlacesAutocomplete
                                    selectProps={{
                                      value: fullLocation,
                                      onChange: (val) => {
                                        setFullLocation(val);
                                        setFieldValue("address", val.label);
                                        console.log(val);
                                      },
                                      styles: {
                                        input: (provided) => ({
                                          ...provided,
                                          padding: 12,
                                          border: "none",
                                          background: "transparent",
                                        }),
                                        option: (provided) => ({
                                          ...provided,
                                          zIndex: 9999,
                                        }),
                                        singleValue: (provided) => ({
                                          ...provided,
                                        }),
                                        indicatorSeparator: (provided) => ({
                                          ...provided,
                                          display: "none",
                                        }),
                                      },
                                      placeholder: "Street Address *",
                                    }}
                                    apiKey="AIzaSyAw_j2KY4CDtksQcd6JexS3J1xt4BdbnGQ"
                                    apiOptions={{ region: "US" }}
                                  />
                                  {errors.itemLocation &&
                                    touched.itemLocation && (
                                      <FormHelperText style={{ color: "red" }}>
                                        {errors.itemLocation.label}
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={6} spacing={1}>
                                  <TextField
                                    error={touched.city && Boolean(errors.city)} //true if error exists
                                    required
                                    name="city"
                                    id="city"
                                    label="City"
                                    defaultValue=""
                                    sx={{
                                      width: "100%",
                                      marginTop:
                                        accType !== "individual" ? "1.2rem" : 0,
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.city}
                                    helperText={touched.city && errors.city}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} sx={{ flexGrow: 1 }}>
                                <Grid item xs={6} spacing={1}>
                                  <TextField
                                    error={
                                      touched.state && Boolean(errors.state)
                                    } //true if error exists
                                    required
                                    name="state"
                                    id="State"
                                    label="State"
                                    defaultValue=""
                                    sx={{
                                      width: "100%",
                                      marginTop: "1.2rem",
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.state}
                                    helperText={touched.state && errors.state}
                                  />
                                </Grid>
                                <Grid item xs={6} spacing={1}>
                                  <TextField
                                    error={touched.zip && Boolean(errors.zip)} //true if error exists
                                    required
                                    name="zip"
                                    id="zip"
                                    label="Zip Code"
                                    defaultValue=""
                                    sx={{
                                      width: "100%",
                                      marginTop: "1.2rem",
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.zip}
                                    helperText={touched.zip && errors.zip}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", gap: "0.5rem" }}
                              >
                                <input
                                  type="checkbox"
                                  style={{ width: "30px", cursor: "pointer" }}
                                  onChange={() => setTc((prev) => !prev)}
                                  checked={tc}
                                />
                                <label>
                                  I agree to{" "}
                                  <span
                                    className="tc"
                                    style={{ cursor: "pointer" }}
                                    // onClick={() => setIsOpen((prev) => !prev)}
                                  >
                                    Terms and Conditions
                                  </span>
                                </label>
                              </Grid>
                            </Grid>

                            <Button
                              sx={{ marginTop: "2rem" }}
                              variant="contained"
                              type="submit"
                              color="secondary"
                            >
                              Submit
                            </Button>
                          </form>
                        </div>
                      );
                    }}
                  />
                </Box>
              </CSSTransition>

              <CSSTransition
                in={persona === "both"}
                timeout={500}
                classNames="fade"
                unmountOnExit
              >
                <Box className={"fade"} component={"div"}>
                  <Typography variant="h3" margin={20}>
                    Both Form
                  </Typography>
                </Box>
              </CSSTransition>
            </>
          )}
        </Box>
      </>
    </>
  );
};

export default SetupProfile;
