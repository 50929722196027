import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import "./Categories.css";
import { Link } from "react-router-dom";
import {
  categoies,
  categoryImagesOld,
  categoryPageMapping,
  itemGoalsNested,
  tempCategoryImagesOld,
} from "../../pages/AddNewItem/data";

const Categories = () => {
  const transformedData = Object.entries(itemGoalsNested).map(
    ([key, value]) => {
      return { [key]: Object.keys(value) };
    }
  );
  console.log(transformedData);
  return (
    <Container
      sx={{ minHeight: "100vh", margin: "4rem auto" }}
      data-aos="fade-in"
    >
      <Typography
        variant="h1"
        className="mont-title"
        textAlign={"center"}
        textTransform={"uppercase"}
      >
        Categories
      </Typography>
      <Stack
        margin={"2rem auto"}
        flexWrap={"wrap"}
        direction={"row"}
        justifyContent={"center"}
      >
        {transformedData.map((cat, ind) => {
          const [goal, subcats] = Object.entries(cat)[0]; // Destructure category and subcategories

          return subcats.map((subcat) => (
            <Link
              key={`${goal}-${subcat}`}
              to={`/shop?goal=${goal}&categoryName=${subcat}`}
            >
              <div className="category-image-card">
                <img
                  src={tempCategoryImagesOld[ind]}
                  alt={`Image of ${goal}`}
                />
              </div>
            </Link>
          ));
        })}
      </Stack>
    </Container>
  );
};

export default Categories;
