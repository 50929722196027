import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Avatar,
  Container,
  Box,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { getRecentPostedProducts } from "../StateSlices/getRecentPostedProductsSlice";
import Spinner from "../Spinner/Spinner";
import Product from "../Product/Product";
import ProductCard from "../ProductCard/ProductCard";
import { Link, useNavigate } from "react-router-dom";
import Confetti from "react-confetti";

const SetupProfileComplete = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.user);
  const { productStatus, productInfo } = useSelector(
    (state) => state.getrecentpostedproducts
  );

  const [productsSpinner, setProductsSpinner] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setShowConfetti(true);
    setProductsSpinner(true);
    dispatch(getRecentPostedProducts());
  }, []);

  useEffect(() => {
    if (productStatus === "succeeded") {
      setProductsSpinner(false);
      setProducts(productInfo);
    }

    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, [productInfo]);

  return (
    <>
      <div className={`confetti-container ${showConfetti ? "show" : "hide"}`}>
        <Confetti
          wind={0.01}
          gravity={0.15}
          numberOfPieces={400}
          width={window.innerWidth - 30}
          height={1.25 * window.innerHeight}
          colors={["#161b21", "#f05023", "#f3a950", "#f1f1f1"]}
          recycle={showConfetti}
        />
      </div>
      {userInfo && (
        <Box margin={"2rem auto"} data-aos="fade-up">
          <Container maxWidth="xl">
            <Typography
              variant="h1"
              fontWeight={700}
              textAlign={"center"}
              color={"var(--black-color)"}
            >
              Congratulations!
            </Typography>
            <Typography
              variant="h3"
              fontWeight={700}
              textAlign={"center"}
              color={"var(--dark-grey-color)"}
              marginTop={".5rem"}
            >
              Your Profile Setup Completed Sucessfully!
            </Typography>

            <Box
              component={"section"}
              margin={"1rem auto"}
              marginTop={"2rem"}
              sx={{
                background: "#f1f1f1",
                borderRadius: "10px",
                padding: "2rem",
                paddingBottom: "5rem",
                // border: "1px solid var(--black-color)",
              }}
            >
              <Typography
                variant="h3"
                fontWeight={700}
                textAlign={"left"}
                color={"var(--black-color)"}
              >
                Explore Equipment
              </Typography>
              <Typography
                variant="h5"
                fontWeight={400}
                textAlign={"left"}
                color={"var(--dark-grey-color)"}
                marginTop={".5rem"}
              >
                You can now start using the platform to find your desired
                equipment.
              </Typography>

              <Stack
                direction="row"
                spacing={2}
                marginTop="1rem"
                borderRadius={"10px"}
                flexWrap={"wrap"}
                justifyContent={"space-between"}
                gap={2}
                rowGap={4}
              >
                {productsSpinner ? (
                  <Spinner />
                ) : (
                  products && products.length > 0 && products.map((product, index) => (
                    <ProductCard
                      shopPageCardWidth={"330px"}
                      noMarginLeft={true}
                      shopPageCardHeight={"200px"}
                      key={index}
                      product={product}
                    />
                  ))
                )}
              </Stack>

              <Link to="/shop" style={{ textDecoration: "none" }}>
                <Button
                  fullWidth
                  variant="contained"
                  //   size="small"
                  sx={{
                    float: "right",
                    margin: "1rem auto",
                    background: "var(--black-color)",
                    "&:hover": { background: "var(--dark-grey-color)" },
                  }}
                >
                  Explore More
                </Button>
              </Link>
            </Box>
            <Typography
              variant="h3"
              fontWeight={700}
              textAlign={"center"}
              margin={"2rem auto"}
            >
              OR
            </Typography>
            <Box
              component={"section"}
              margin={"1rem auto"}
              marginTop={"2rem"}
              sx={{
                background: "#f1f1f1",
                borderRadius: "10px",
                padding: "2rem",

                // border: "1px solid var(--black-color)",
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                borderRadius={"10px"}
                justifyContent={"space-between"}
                gap={2}
              >
                <Button
                  //   fullWidth
                  onClick={() => {
                    navigate("/add-new-item/#/0");
                  }}
                  variant="contained"
                  // size="small"
                  sx={{
                    margin: "0 .5rem",
                    width: "100%",
                    background: "var(--black-color)",
                    "&:hover": { background: "var(--dark-grey-color)" },
                  }}
                >
                  Add an Equipment
                </Button>

                {/* <Link to="/my-equipment" style={{ textDecoration: "none" }}> */}
                <Button
                  //   fullWidth
                  onClick={() => {
                    navigate("/user/equipment");
                  }}
                  variant="contained"
                  // size="large"
                  sx={{
                    margin: "0 .5rem",
                    width: "100%",
                    background: "var(--black-color)",
                    "&:hover": { background: "var(--dark-grey-color)" },
                  }}
                >
                  My Equipment
                </Button>
                {/* </Link> */}
              </Stack>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default SetupProfileComplete;
