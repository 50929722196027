import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  productStatus: "idle",
  productInfo: null,
  error: null
}

export const getRecentPostedProducts = createAsyncThunk(
  "product/getRecentPostedProducts",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/product/getrecentpostedproducts`,
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const getRecentPostedProductsSlice = createSlice({
  name: "getrecentpostedproducts",
  initialState,
  reducers: {
    reset(state,action){
        state.productInfo = null
        state.productStatus="idle"
        state.error=null
      }
  },
  extraReducers: {
    [getRecentPostedProducts.pending]: (state, action) => {
      state.productStatus = "loading"
    },
    [getRecentPostedProducts.fulfilled]: (state, action) => {
      state.productStatus = "succeeded"
      state.productInfo = action.payload.suggestions
    },
    [getRecentPostedProducts.rejected]: (state, action) => {
      state.productStatus = "failed"
      state.error = action.payload.message
    },
  },
})

export const { reset } = getRecentPostedProductsSlice.actions
export default getRecentPostedProductsSlice.reducer 