import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  cancelStatus: "idle",
  cancelMessage: "",
  cancelError: null,
}

export const extendOrder = createAsyncThunk(
  "cancel/extendOrder",
  async ({extendData,token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/checkout/extendOrder`, extendData,
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const cancelOrder = createAsyncThunk(
  "cancel/order",
  async ({checkoutData,token}, { rejectWithValue }) => {
    try {
      console.log(checkoutData)
      const { data } = await axios.post(
        `/api/checkout/cancelorder`, checkoutData,
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data)
    }
  }
)

export const changeOrderStatus = createAsyncThunk(
  "cancel/changeOrderStatus",
  async ({checkoutData,token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/checkout/changeOrderStatus`, checkoutData,
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const CancelOrderSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    resetCancel(state,action){
        state.cancelMessage = ""
        state.cancelStatus="idle"
        state.cancelError=null
      }
  },
  extraReducers: {
    [cancelOrder.pending]: (state, action) => {
      state.cancelStatus = "loading"
    },
    [cancelOrder.fulfilled]: (state, action) => {
      state.cancelStatus = "succeeded"
      state.cancelMessage = action.payload.message
    },
    [cancelOrder.rejected]: (state, action) => {
      state.cancelStatus = "failed"
      state.cancelError = action.payload.message
    },

    [extendOrder.pending]: (state, action) => {
      state.cancelStatus = "loading"
    },
    [extendOrder.fulfilled]: (state, action) => {
      state.cancelStatus = "succeeded"
      state.cancelMessage = action.payload.message
    },
    [extendOrder.rejected]: (state, action) => {
      state.cancelStatus = "failed"
      state.cancelError = action.payload.message
    },

    [changeOrderStatus.pending]: (state, action) => {
      state.cancelStatus = "loading"
    },
    [changeOrderStatus.fulfilled]: (state, action) => {
      state.cancelStatus = "succeeded"
      state.cancelMessage = action.payload.message
    },
    [changeOrderStatus.rejected]: (state, action) => {
      state.cancelStatus = "failed"
      state.cancelError = action.payload.message
    },
  },
})

export const { resetCancel } = CancelOrderSlice.actions
export default CancelOrderSlice.reducer 