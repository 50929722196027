import React, { useEffect, useState } from "react";
import shortid from "shortid";
import "./FileUploadDragAndDropForInsurance.css";
import { Backdrop, Box, Button, Fade, Modal, Typography } from "@mui/material";
import swal from "sweetalert";
import { FaFileAlt } from "react-icons/fa";

const FileUploadDragAndDropForInsurance = ({
  Files,
  SetFiles,
  setFilePreviewModal,
  setRentalInsurance,
  rentalInsurance,
}) => {
  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const InputChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      SetFiles([
        {
          id: shortid.generate(),
          filename: e.target.files[0].name,
          filetype: e.target.files[0].type,
          file: reader.result,
          filesize: filesizes(e.target.files[0].size),
        },
      ]);
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(file);
    }
    setFilePreviewModal(true);
  };

  const DeleteFile = async (id) => {
    swal({
      title: "Are you sure?",
      text: "This file will be removed",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const result = Files.filter((data) => data.id !== id);
        SetFiles(result);
        if (rentalInsurance) {
          setRentalInsurance(null);
        }
        localStorage.removeItem("localUpload");
      } else {
        // alert('No');
      }
    });
  };
  return (
    <>
      <div className="fileupload-view">
        <div className="row justify-content-center m-0">
          <div className="col-md-6" style={{ width: "100%" }}>
            <div className="file-upload-card mt-5">
              <div className="file-upload-card-body">
                <div className="kb-data-box">
                  <div className="kb-modal-data-title">
                    <div className="kb-data-title"></div>
                  </div>
                  <form>
                    <div className="kb-file-upload">
                      <div className="file-upload-box">
                        <input
                          type="file"
                          id="fileupload"
                          className="file-upload-input"
                          onChange={InputChange}
                        />
                        <span>
                          Drag and drop or{" "}
                          <span className="file-link">Choose your file</span>
                        </span>
                      </div>
                    </div>
                  </form>

                  {Files && Files.length > 0 && (
                    <div className="kb-attach-box">
                      <hr />
                      {Files.map((data, index) => {
                        const { id, filename, fileimage, datetime, filesize } =
                          data;
                        return (
                          <div className="file-atc-box" key={index}>
                            <div className="file-image">
                              <FaFileAlt />
                            </div>
                            <div className="file-detail">
                              <h6>{filename ? filename : data}</h6>
                              <p>
                                {filesize && <span>Size : {filesize}</span>}
                              </p>
                              <div className="file-actions">
                                <button
                                  className="file-action-btn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    DeleteFile(id);
                                  }}
                                >
                                  Delete
                                </button>
                                <button
                                  href={fileimage}
                                  className="file-action-btn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setFilePreviewModal(true);
                                  }}
                                >
                                  Preview
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUploadDragAndDropForInsurance;
