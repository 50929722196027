import React, { useState } from "react";
import "./ProductCardNew.css";
import {
  FaCircleInfo,
  FaMoneyCheckDollar,
  FaRegHeart,
  FaTrash,
  FaMapLocationDot,
} from "react-icons/fa6";
import { FaEdit, FaPen } from "react-icons/fa";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateRangePicker } from "rsuite";
import { shouldDisableDatee } from "../../utils/utils";
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxWidth: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
  zIndex: 1,
};

const ProductCardNew = ({
  handleProductRange,
  product,
  handleProductRemove,
  key,
  rentEstimates,
  prices,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const [showModalContent, setShowModalContent] = useState(null);
  let totalRentPrice = 0;
  let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const modalContentData = [
    {
      title: "Detailed Equipment Summary",
      desc: "This Modal Popup will show all details of the equipment and seller -- Coming Soon!",
    },
    {
      title: "Detailed Price Calculation",
      desc: "This Modal Popup will show all calculations of the rental equipment -- Coming Soon!",
    },
    {
      title: "Location Info",
      desc: "This Modal Popup will redirect to google maps for locating seller's location -- Coming Soon!",
    },
    {
      title: "Edit Rental Period",
      desc: "This Modal Popup will edit the rental period if user wants to revise the rental dates -- Coming Soon!",
    },
  ];

  if (rentEstimates && rentEstimates.length > 0) {
    totalRentPrice = rentEstimates
      .map((estimate) => estimate[product._id]?.totalRentPrice)
      .find((price) => price !== undefined);
  }
  const {
    itemImages,
    itemName,
    itemDescription,
    itemModel,
    itemDeposit,
    itemAmount,
    itemDesc,
    itemLocation,
    itemBrand,
    itemYearManufacture,
    itemForRent,
    merchantFeed,
    _id,
    itemCity,
    itemState,
    itemAvailability,
    itemLeaseFields,
    buyRange,
  } = product;
  const rentEstimateIndexNumber =
    (rentEstimates &&
      rentEstimates.length > 0 &&
      rentEstimates.findIndex((estimate) => estimate[product._id])) ||
    0;

  return (
    <>
      <div class="cp-card">
        <div className="cp-row-1">
          <div class="cp-image">
            <img alt={itemName} height="100%" src={itemImages[0]} />
          </div>
          <div class="cp-info">
            <Typography variant="h3" fontWeight={700} class="title">
              {itemName}
            </Typography>
            {!merchantFeed ? (
              <Typography
                variant="h5"
                className="normal-text"
                fontSize="14px"
                marginTop={".5rem"}
              >
                {[itemCity, itemState].join(", ")}
              </Typography>
            ) : (
              <Typography
                variant="h5"
                className="normal-text"
                fontSize="14px"
                marginTop={".5rem"}
              >
                &nbsp;
              </Typography>
            )}
            <Typography
              variant="h5"
              className="normal-text"
              fontSize="14px"
              marginTop={".5rem"}
            >
              {itemModel}
            </Typography>
          </div>
          <div className="cp-ver-divider"></div>
          <div class="cp-price">
            <div className="cp-cost">
              <Box sx={{ textAlign: "left" }}>
                {itemForRent &&
                  cartItems &&
                  cartItems.length > 0 &&
                  cartItems.find((ct) => ct.id === _id)?.itemType ===
                    "rent" && (
                    <>
                      {/* {product.itemDailyPrice &&
                        product.itemDailyPrice !== 0 &&
                        prices.includes("day") && (
                          <>
                            <Typography variant="h5" fontSize="14px">
                              Item Daily Price:&nbsp;&nbsp; $
                              {product.itemDailyPrice.toLocaleString("en-US")}
                            </Typography>
                          </>
                        )}
                      {product.itemWeeklyPrice &&
                        product.itemWeeklyPrice != 0 &&
                        prices.includes("week") && (
                          <>
                            <Typography variant="h5" fontSize="14px">
                              Item Weekly Price:&nbsp;&nbsp; $
                              {product.itemWeeklyPrice.toLocaleString("en-US")}
                            </Typography>
                          </>
                        )}
                      {product.itemMonthlyPrice &&
                        product.itemMonthlyPrice != 0 &&
                        prices.includes("month") && (
                          <>
                            <Typography variant="h5" fontSize="14px">
                              Item Monthly Price:&nbsp;&nbsp; $
                              {product.itemMonthlyPrice.toLocaleString("en-US")}
                            </Typography>
                          </>
                        )} */}

                      {/* <Typography variant="h5" fontSize="14px">
                      Rental Deposit:&nbsp;&nbsp; ${product.itemDeposit}
                    </Typography> */}

                      <Typography variant="h5" fontSize="14px">
                        Cleaning Fees:&nbsp;&nbsp; $
                        {product.itemCleaningPrice
                          ? product.itemCleaningPrice.toLocaleString("en-US")
                          : 0}
                      </Typography>
                      <Typography variant="h5" fontSize="14px">
                        Maintenance Fees:&nbsp;&nbsp; $
                        {product.itemMaintenancePrice
                          ? product.itemMaintenancePrice.toLocaleString("en-US")
                          : 0}
                      </Typography>
                      <Typography variant="h5" fontSize="14px">
                        Fuel Fees:&nbsp;&nbsp; $
                        {product.itemFuelPrice
                          ? product.itemFuelPrice.toLocaleString("en-US")
                          : 0}
                      </Typography>
                      <Typography
                        variant="h5"
                        fontSize="14px"
                        marginTop={"1rem"}
                      >
                        Rental Period&nbsp;{" "}
                        <FaPen
                          onClick={handleModalOpen}
                          style={{ cursor: "pointer" }}
                          fontSize={".75rem"}
                        />
                        <br />
                        {new Date(
                          rentEstimates &&
                            rentEstimates.length > 0 &&
                            rentEstimates[rentEstimateIndexNumber] &&
                            rentEstimates[rentEstimateIndexNumber][
                              product._id
                            ] &&
                            rentEstimates[rentEstimateIndexNumber][product._id]
                              .rentalPeriodRange[0]
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })}{" "}
                        -{" "}
                        {new Date(
                          rentEstimates &&
                            rentEstimates.length > 0 &&
                            rentEstimates[rentEstimateIndexNumber] &&
                            rentEstimates[rentEstimateIndexNumber][
                              product._id
                            ] &&
                            rentEstimates[rentEstimateIndexNumber][product._id]
                              .rentalPeriodRange[1]
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })}{" "}
                      </Typography>

                      <Typography
                        variant="h4"
                        marginTop={"1rem"}
                        fontWeight={700}
                      >
                        {/* $ {rentEstimate} */}
                      </Typography>
                    </>
                  )}
              </Box>
              <Typography variant="h2" class="price">
                {rentEstimates && rentEstimates.length > 0 &&  rentEstimates.find((p) => p[product._id])?.[product._id]
                  ? "$" +
                    parseInt(
                      parseInt(
                        rentEstimates.find((p) => p[product._id])[product._id]
                          .totalRentPrice
                      ) +
                        parseInt(
                          rentEstimates.find((p) => p[product._id])[product._id]
                            .incCost
                        )
                    )
                  : product.itemToSell ? "$" +product.itemAmount : "N/A"}
              </Typography>
            </div>
          </div>
          <div
            className="cp-actions"
            style={{ position: "absolute", bottom: "0rem", right: "1rem" }}
          >
            <FaTrash
              style={{ cursor: "pointer", color: "var(--secondary-color)" }}
              onClick={() => handleProductRemove(product._id)}
              size={"1.2em"}
            />
          </div>
        </div>
      </div>

      {/* MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={modalStyle} className="checkout-rental-info-modal">
            <Typography
              id="transition-modal-title"
              variant="h3"
              fontWeight={700}
            >
              Edit Rental Range
            </Typography>
            <Typography id="transition-modal-description" variant="h6">
              Please provide rental information for your selected item for the
              checkout
            </Typography>
            <br />

            <Typography variant="h6" margin={".5rem 0"}>
              Rental Period
            </Typography>
            <DateRangePicker
              ranges={
                rentEstimates &&
                rentEstimates[rentEstimateIndexNumber] &&
                rentEstimates[rentEstimateIndexNumber][product?._id]
                  ? [
                      rentEstimates[rentEstimateIndexNumber][product._id][
                        "rentalPeriodRange"
                      ][0],
                      rentEstimates[rentEstimateIndexNumber][product._id][
                        "rentalPeriodRange"
                      ][1],
                    ]
                  : []
              }
              defaultOpen
              shouldDisableDate={(date) =>
                itemAvailability &&
                shouldDisableDatee(date, itemLeaseFields, buyRange)
              }
              style={{ width: "100%" }}
              onOk={
                (newValue) => {
                  handleProductRange(
                    newValue,
                    rentEstimateIndexNumber,
                    product
                  );
                  handleModalClose();
                }
                // setCheckoutRentalDateRange(newValue)
              }
            />
            <br />
          </Box>
        </Fade>
      </Modal>
      {/* END MODAL */}
    </>
  );
};

export default ProductCardNew;
