import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  searchstatus: "idle",
  searchinfo: {},
  error: null,
  productsSearchInfo: {}
};

export const getElasticSearch = createAsyncThunk(
  "product/getElasticSearchedProduct",
  async (searchQuery, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/product/getSuggestions?search=${searchQuery}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getElasticSearchForProducts = createAsyncThunk(
  "product/getElasticSearchedProductsOnly",
  async (searchQuery, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/product/getProductSuggestions?search=${searchQuery}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const ElasticSearchedSlice = createSlice({
  name: "elasticsearch",
  initialState,
  reducers: {
    resetp(state, action) {
      state.searchinfo = {};
      state.searchstatus = "idle";
      state.productsSearchInfo = {}
    },
  },
  extraReducers: {
    [getElasticSearch.pending]: (state, action) => {
      state.searchstatus = "loading";
    },
    [getElasticSearch.fulfilled]: (state, action) => {
      state.searchstatus = "succeeded";
      state.searchinfo = action.payload.suggestions;
    },
    [getElasticSearch.rejected]: (state, action) => {
      state.searchstatus = "failed";
      state.error = action.payload.message;
    },
    [getElasticSearchForProducts.pending]: (state, action) => {
      state.searchstatus = "loading";
    },
    [getElasticSearchForProducts.fulfilled]: (state, action) => {
      state.searchstatus = "succeeded";
      state.searchinfo = action.payload.suggestions;
    },
    [getElasticSearchForProducts.rejected]: (state, action) => {
      state.searchstatus = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { resetp } = ElasticSearchedSlice.actions;
export default ElasticSearchedSlice.reducer;
