import {
  Box,
  Button,
  Typography,
  Stack,
  Modal,
  Fade,
  Backdrop,
  Grid,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Notifications.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  notificationSeen,
  resetNotification,
} from "../StateSlices/notificationSlice";
import { getuserInfo } from "../StateSlices/getuserSlice";
import noitem from "../../assets/no-item.png";
import { InfoOutlined } from "@mui/icons-material";
import { modalStyle } from "../../utils/utils";

const Notifications = () => {
  const dispatch = useDispatch();

  const { status, userInfo, profile } = useSelector((state) => state.user);
  const { notificationMessage } = useSelector((state) => state.notification);
  const [isPriceInfoOpen, setIsPriceInfoOpen] = useState(null);
  const [isPriceStats, setIsPriceStats] = useState(null);

  const handlePriceModal = (props) => {
    setIsPriceStats(props);
    setIsPriceInfoOpen(true);
    console.log(props);
    let productRentalRange = props?.checkoutDetail[0].products.find(
      (prod) => prod.productId === props.productId
    )?.rentalDateRange;
    setIsPriceStats({
      rentalRangeFrom: productRentalRange
        ? new Date(productRentalRange[0]).toLocaleDateString()
        : "",
      rentalRangeTo: productRentalRange
        ? new Date(productRentalRange[1]).toLocaleDateString()
        : "",
      transportationCost: props?.checkoutDetail[0].transportation,
      additionalAmount: props?.checkoutDetail[0].fees,
      maintenanceFee: props?.productDetail.itemMaintenancePrice,
      cleaningFee: props?.productDetail.itemCleaningPrice,
    });
  };

  useEffect(() => {
    if (
      userInfo &&
      userInfo.userNotification &&
      userInfo.userNotification.notification.length > 0
    ) {
      // console.log(userInfo.userNotification.notification)
      const countSeenFalse = userInfo.userNotification.notification.filter(
        (item) => item.seen === false
      ).length;
      if (countSeenFalse > 0) {
        let token = localStorage.getItem("inktoken");
        dispatch(notificationSeen({ token }));
      }
    }
  }, []);

  useEffect(() => {
    if (notificationMessage) {
      dispatch(resetNotification());
      let token = localStorage.getItem("inktoken");
      dispatch(getuserInfo({ token }));
    }
  }, [notificationMessage]);

  const notifications = [1, 2, 3, 4];
  return (
    <Box component={"div"}>
      <Box component={"div"} marginTop={"2rem"}>
        <Typography
          variant="h2"
          mt={"4rem"}
          mb={"1rem"}
          className="title mont-title"
        >
          My Notifications
        </Typography>
        <Box marginTop={"2rem"}>
          {userInfo &&
          userInfo.userNotification &&
          userInfo.userNotification.notification.length > 0 ? (
            <>
              {userInfo.userNotification.notification.map((notif) => (
                <Box
                  component={"div"}
                  key={notif.orderId}
                  className="notif-card"
                  gap={2}
                  mt={"1rem"}
                >
                  <Box component={"div"} className="notif-line-divider">
                    &nbsp;
                  </Box>
                  <Box component={"div"} maxWidth={"250px"}>
                    <Typography variant="h3" my={1}>
                      {notif.eventName === "ordered"
                        ? "Rented-Gear"
                        : "Rented-Out"}
                    </Typography>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        Name:{" "}
                      </strong>
                      {notif.productName}
                    </Typography>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        Date:{" "}
                      </strong>
                      {new Date(notif.createdAt).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </Typography>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        Delivery Date:{" "}
                      </strong>
                      {notif.deliveryDate
                        ? new Date(notif.deliveryDate).toLocaleDateString(
                            "en-US",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "N/A"}
                    </Typography>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        Pickup Date:{" "}
                      </strong>
                      {notif.pickupDate
                        ? new Date(notif.pickupDate).toLocaleDateString(
                            "en-US",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "N/A"}
                    </Typography>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        Amount{" "}
                        {notif.eventName == "purchase" ? "Earned" : "Paid"}:{" "}
                      </strong>
                      <span>
                        $ {notif.pricePaid.toLocaleString("en-US")} &nbsp;{" "}
                        <span
                          onClick={() => {
                            handlePriceModal(notif);
                          }}
                        >
                          <InfoOutlined
                            sx={{
                              fontSize: 16,
                              position: "relative",
                              top: 3,
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      </span>
                    </Typography>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        {notif.eventName === "purchase" && "Delivery "} Address:{" "}
                      </strong>
                      {notif.address}
                    </Typography>
                  </Box>
                  <Box component={"div"} maxWidth={"250px"}>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        Company Name:{" "}
                      </strong>
                      {notif?.userDetail ? notif.userDetail.companyName : "--"}
                    </Typography>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        Contact Phone:{" "}
                      </strong>
                      {notif.eventName === "purchase"
                        ? notif.checkoutDetail[0]
                          ? notif.checkoutDetail[0].phone
                            ? notif.checkoutDetail[0].phone
                            : "--"
                          : "--"
                        : notif?.userDetail
                        ? notif.userDetail.userPhone
                        : "--"}
                    </Typography>
                    {notif?.contact && (notif.eventName !== "ordered" || notif.checkoutDetail[0]?.status === "complete" || !notif.checkoutDetail[0]?.status) && (
                      <Typography
                        variant="h6"
                        my={1}
                        color={"var(--grey-color)"}
                      >
                        <strong style={{ color: "var(--white-color)" }}>
                          Email:{" "}
                        </strong>
                        {notif?.contact}
                      </Typography>
                    )}

                    {
                      (notif.eventName === "ordered" && notif.checkoutDetail[0]?.status) && (
                        <>
                          <Typography variant="h6"
                        my={1}
                        color={"var(--grey-color)"}>
                            <strong style={{ color: "var(--white-color)" }}>Order Status:</strong> {notif.checkoutDetail[0].status}
                          </Typography>
                          {
                            (notif.checkoutDetail[0].status === "pending" || notif.checkoutDetail[0].status === "processing") && (
                            <Typography variant="h6"
                            my={1}
                            color={"var(--grey-color)"}>
                              <strong style={{ color: "var(--white-color)" }}>
                                {notif.checkoutDetail[0].status === "pending"?"Next Step":"Info"}: </strong>
                                {notif.checkoutDetail[0].status==="pending"?`Please Complete the next step as posted `:"It can take up to 2-3 days to reflect the order complete status"} {notif.checkoutDetail[0].status==="pending" && (
                                <Link
                                  to={notif.checkoutDetail[0].next_step}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: "underline",
                                    color: "var(--primary-color)",
                                    fontSize: "1.2rem",
                                    fontWeight: "bold",
                                    }}
                                >
                                  here
                                </Link>
                              )}
                              
                            </Typography>
                            )
                          }
                        </>
                      )
                    }
                  </Box>
                  <Box component={"div"} className="notif-btns">
                    {/* <Link
                      to={"/product/" + notif.productId}
                      state={{ id: notif.productId }}
                      style={{
                        textDecoration: "none",
                      }}
                    > */}
                    <Link
                      to={`/order-details/${notif.orderId}/${notif.productId}`}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          color: "var(--black-color)",
                          background: "var(--white-color)",
                          my: 1,
                          fontSize: "14px",
                          border: "none",
                          "&:hover": { background: "var(--grey-color)" },
                          minWidth: "250px",
                        }}
                      >
                        View Details
                      </Button>
                    </Link>
                    {/* <Link to={`/talkjs?useremail=${notif.contact}`}> */}
                    {
                      (notif.eventName !== "ordered" || notif.checkoutDetail[0]?.status === "complete" || !notif.checkoutDetail[0]?.status) && (
                        <Link to={"/chat-support" } state={{sellerEmail: notif.contact}}>
                          <Button
                            // href={`mailto:${notif.contact}`}
                            // onClick={(e) => {
                            //   e.preventDefault();

                            //   window.open(`mailto:${notif.contact}`, "_blank");
                            // }}
                            variant="contained"
                            fullWidth
                            sx={{
                              color: "var(--black-color)",
                              fontSize: "14px",
                              background: "var(--white-color)",
                              my: 1,
                              border: "none",
                              "&:hover": { background: "var(--grey-color)" },
                              minWidth: "250px",
                            }}
                            // target="_blank"
                          >
                            Contact{" "}
                            {notif.eventName === "ordered"
                              ? "Machine Owner"
                              : "Renter"}
                          </Button>
                        </Link>
                      )
                    }
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              minHeight={"60vh"}
              width={"100%"}
              sx={{
                background: `url(${noitem})`,
                backgroundSize: "cover",
              }}
            >
              <Typography color={"var(--black-color)"} variant="h3">
                No new notifications found!
              </Typography>
            </Stack>
          )}
        </Box>
      </Box>

      {/* PRICE INFO MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isPriceInfoOpen}
        onClose={() => {
          setIsPriceInfoOpen(false);
          setIsPriceStats(null);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 250,
          },
        }}
      >
        <Fade in={isPriceStats}>
          <Box sx={modalStyle}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className="mont-title"
              component="h2"
            >
              Price Drilldown
            </Typography>
            <Divider sx={{ margin: "1rem 0" }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Rental Range:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {`${isPriceStats?.rentalRangeFrom || "-"} to ${
                    isPriceStats?.rentalRangeTo || "-"
                  }`}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Transportation Cost:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {isPriceStats?.transportationCost
                    ? `$ ${Number(
                        isPriceStats.transportationCost
                      ).toLocaleString("en-US")}`
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Maintenance Fee:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {isPriceStats?.maintenanceFee
                    ? `$ ${Number(isPriceStats.maintenanceFee).toLocaleString(
                        "en-US"
                      )}`
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Cleaning Fee:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {isPriceStats?.cleaningFee
                    ? `$ ${Number(isPriceStats.cleaningFee).toLocaleString(
                        "en-US"
                      )}`
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Platform Fee:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {isPriceStats?.additionalAmount
                    ? `$ ${Number(isPriceStats.additionalAmount).toLocaleString(
                        "en-US"
                      )}`
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Notifications;
