import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  dsStatus: "idle",
  dsInfo: null,
  dsError: null,
  dsFileInfo:null,
};

export const docuSign = createAsyncThunk(
  "ds/sendDSEnvelop",
  async ({ token, values }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/ds/sendDSEnvelope",
        { signerEmail: values.signerEmail, signerName: values.signerName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getdocuSign = createAsyncThunk(
  "ds/getDSDocument",
  async ({ token, id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/ds/getDSDocument/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const docuSignForRentalProtection = createAsyncThunk(
  "ds/sendDSEnvelopForRentalProtection",
  async ({ token, values }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/ds/sendDSEnvelopeForRentalProtection",
        { signerEmail: values.signerEmail, signerName: values.signerName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const docuSignSlice = createSlice({
  name: "docusign",
  initialState,
  reducers: {
    resetBrand(state, action) {
      state.dsError = null;
      state.dsStatus = "idle";
      state.dsInfo = null;
      state.dsFileInfo = null;
    },
  },
  extraReducers: {
    [docuSign.pending]: (state, action) => {
      state.dsStatus = "loading";
    },
    [docuSign.fulfilled]: (state, action) => {
      state.dsStatus = "succeeded";
      state.dsInfo = action.payload.redirectUrl;
    },
    [docuSign.rejected]: (state, action) => {
      state.dsStatus = "failed";
      state.dsError = action.payload;
    },

    [getdocuSign.pending]: (state, action) => {
      state.dsStatus = "loading";
    },
    [getdocuSign.fulfilled]: (state, action) => {
      state.dsStatus = "succeeded";
      state.dsFileInfo = action.payload.data;
    },
    [getdocuSign.rejected]: (state, action) => {
      getdocuSign.dsStatus = "failed";
      state.dsError = action.payload.message;
    },
    [docuSignForRentalProtection.pending]: (state, action) => {
      state.dsStatus = "loading";
    },
    [docuSignForRentalProtection.fulfilled]: (state, action) => {
      state.dsStatus = "succeeded";
      state.dsInfo = action.payload.redirectUrl;
    },  
    [docuSignForRentalProtection.rejected]: (state, action) => {
      state.dsStatus = "failed";
      state.dsError = action.payload;
    },
  },
});

export const { resetBrand } = docuSignSlice.actions;
export default docuSignSlice.reducer;
